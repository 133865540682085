import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import s from './Congratulations.module.scss'
import { useDispatch } from "react-redux"
import { gfBilling } from '../../../../../goldfish/gfBilling'
import Loader from "../../../../Components/Loader"
import { getSubscriptionThunk } from '../../../../../redux/actions/subscription'
import {useTranslation} from "react-i18next";

const Congratulations = ( {subscriptions, appDelegate} ) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    useEffect( () => {
        appDelegate.getData()
        dispatch( getSubscriptionThunk( appDelegate.state.authToken ) )
    }, [] )

    return appDelegate.state.user.subscriptions.length !== 0 && subscriptions !== null ? <div className={s.wrapper}>
        <h2>
            {t('billing.success.title')}
        </h2>
        <div className={s.title}>
            {t('billing.success.plan')}<br/>
            <span>На {gfBilling.tariffs[appDelegate.state.user.subscriptions.id].period} <span
                className={s.price}>{gfBilling.tariffs[appDelegate.state.user.subscriptions.id].price} $</span></span>
        </div>
        <div className={s.info}>
            <p>
                {t('billing.success.order')}
            </p>
            <p>
                {t('billing.success.questions')}
                - <a href="mailto: info@educat.courses">info@educat.courses</a>
            </p>
            <p>
                {t('billing.success.wish')}
            </p>
            <Link to="/all-courses" className={s.chooseCourses}>
                {t('billing.success.choose')}
            </Link>
        </div>
        <a href="/" className={s.linkToMainPage}>
            {t('billing.success.main')}
        </a>
        <div className={s.cat}/>
    </div> : <Loader/>
}

export default Congratulations
