import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Subscription.module.css';

const SubscriptionAccent = (props) => {
    const renderIfStrikedOutPrice = () => {
        if (props.crossedOutPrice) {
            return <p className={styles.crossedOutPrice}>{props.crossedOutPrice}</p>;
        }
    };

    const renderPeriod = () => {
        return props.period.toLowerCase() === `навсегда` 
              ? <p>разово</p> 
              : <p>/ {props.period.toLowerCase()}</p>;
    };

    return (
        <div className={styles.wrapperAccent}>
            <div className={styles.topHighlight}>{props.topHighlight}</div>
            <div className={styles.headerWrapper}>
                <h4 className={styles.period}>{props.period}</h4>
                {renderIfStrikedOutPrice()}
            </div>
            <div className={styles.priceWrapper}>
                <h2 className={styles.price}>{props.price}</h2>
                {renderPeriod()}
            </div>
            <h5 className={styles.subheader}>{props.subheader}</h5>
            <ul className={styles.featureList}>
                {props.features.map((feature, i) => <li className={styles.featureListItem} key={`${feature}-${i}`}>{feature}</li>)}
            </ul>
            <Link className={styles.buySubscriptionLinkAccent}>Купить подписку</Link>
        </div>
    )
}

export default SubscriptionAccent;