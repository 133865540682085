import React from "react";



const Exercise = ({ themeDelegate, exercise, index }) => (
    <div className={"exercise"}>
        <h3>Задание {index + 1}</h3>
        <div dangerouslySetInnerHTML={{__html: exercise.description}} className={"html-data"} />
        <div onClick={() => themeDelegate.togglePractice(index)} className={"practice-open text"}>Попробовать в работе</div>
    </div>
);


export default Exercise;