import React, {useState} from 'react';
import styled from './Subscription.module.scss';
import arrowImg from "../../img/arrow-more.png";
import {useHistory} from "react-router";
import {AccountData, AppDelegateType, SubscriptionsType} from '../../../../../types/app-delegate';
import {useTranslation} from "react-i18next";

type Props = {
    appDelegate: AppDelegateType;
}

const Subscription = ({appDelegate}: Props) => {
    const [showMobileSubscription, setShowMobileSubscription] = useState<boolean>(false);
    const {user} = appDelegate?.state as AccountData;
    const history = useHistory();
    const {id} = user?.subscriptions || {id: 2}; // Added temporary fallback as it is crases when subscriptions is []
    const { t } = useTranslation();


    const arraySubscriptions = [
        {duration: t('subscription.duration1'), price: "9.99 $"},
        {duration: t('subscription.duration2'), price: "99.99 $"},
        {duration: t('subscription.duration3'), price: "14900 $"}
    ];

    const typeSubscriptions = {
        2: arraySubscriptions[0],
        3: arraySubscriptions[1],
        4: arraySubscriptions[2],
        5: arraySubscriptions[2]
    };

    const changePathOnBilling = () => {
        let path = '/billing';
        history.push(path);
    };

    const handleMobileSubscription = () => {
        setShowMobileSubscription(prev => !prev);
    };


    return <div className={styled.infoUser}>
        <div className={styled.nameUser}>
            <p>
                {t('subscription.greetings')} <span>{user?.name} {user?.last_name}</span>
            </p>
        </div>
        <div className={styled.subscriptions}>
            <div className={styled.subscriptionUser}>
                <div className={styled.currentTitleSubscription}>
                    {t('subscription.plan.title')}
                </div>
                <div>
                    {Array.isArray(user?.subscriptions)
                        ? <div className={styled.iconSubscription}>
                            <h2>
                                {t('subscription.plan.none')}
                            </h2>
                        </div>
                        : <div className={styled.iconSubscription}>
                            <h2>{typeSubscriptions[(id as keyof typeof typeSubscriptions)].duration}</h2>
                            <h1>{typeSubscriptions[(id as keyof typeof typeSubscriptions)].price}</h1>
                        </div>}
                    {!Array.isArray(user?.subscriptions) && <span className={styled.dataSubscription}>
                        * {t('subscription.plan.valid')} {user?.subscriptions?.date}
                    </span>}
                </div>
            </div>
            <div className={styled.mobileMenu} onClick={handleMobileSubscription}>
                <span>
                    {t('subscription.plan.change')}
                </span>
                <img src={arrowImg} alt=">"/>
            </div>
            {Array.isArray(user?.subscriptions) ? <div>
                {showMobileSubscription && <div className={styled.mobileSubscription}>
                    {arraySubscriptions.slice(0, 2).map(({duration, price}, index) => <div key={index}
                                                                                           onClick={changePathOnBilling}
                                                                                           className={styled.othersSubscription}>
                        <span className={styled.period}>{duration}</span><span className={styled.price}>{price} $</span>
                    </div>)}

                </div>}
                <div className={styled.otherSubscription}>
                    <div className={styled.currentTitleSubscription}>
                        {t('subscription.plan.change')}
                    </div>
                    <div style={{display: 'flex'}}>
                        {arraySubscriptions.slice(0, 2).map(({duration, price}, index) => <div
                            onClick={changePathOnBilling}
                            key={index}
                            className={styled.iconSubscription}>
                            <h2>{duration}</h2><h1>{price}</h1></div>)}
                    </div>
                </div>
            </div> : null}
        </div>
    </div>;
};

export default Subscription;
