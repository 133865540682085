import React from 'react';
import {useSpring, animated} from 'react-spring'
import styles from './Screen1.module.css';
import Subscription from './Subscription/Subscription';
import SubscriptionAccent from './Subscription/SubscriptionAccent';

const Screen1 = () => {
    const headerAnimation = useSpring({opacity: 1, transform: "translateY(0)", from: {opacity: 0, transform: "translateY(-100px)"}});
    const subHeaderAnimation = useSpring({opacity: 1, transform: "translateX(0)", from: {opacity: 0, transform: "translateX(-100px)"}});
    return (
        <div className={styles.wrapper}>
            <animated.h1 
                className={styles.title} 
                style={headerAnimation}>
                    <span className={styles.highlighted}>
                        Купи подписку</span> и учись кодить, когда тебе удобно
            </animated.h1>
            <animated.p className={styles.subheader} 
                style={subHeaderAnimation}>
                    Подписка дает полный доступ ко всем тестам Educat.
            </animated.p>
            <ul className={styles.subscriptionsList}>
                <li className={styles.subscriptionsListItem}>
                    <Subscription period="Месяц"
                        crossedOutPrice="$14"
                        price="$7"
                        features={[
                            `Полный доступ ко всем тестам на месяц.`,
                            `Недорогая возможность проверить свои знания.`
                        ]} />
                </li>
                <li className={styles.subscriptionsListItem}>
                    <SubscriptionAccent period="Год"   
                        crossedOutPrice="$120"
                        price="$60"
                        features={[
                            `Полный доступ ко всем тестам на месяц.`,
                            `Недорогая возможность проверить свои знания.`
                        ]}
                        subheader="Скидка действует еще 7 дней"
                        topHighlight="Экономия 50%" />
                </li>
                <li className={styles.subscriptionsListItem}>
                    <Subscription period="Навсегда"
                        price="$399"
                        features={[
                            `Полный доступ ко всем тестам на месяц.`,
                            `Недорогая возможность проверить свои знания.`
                        ]} />
                </li>
            </ul>
        </div>
    )
}

export default Screen1;