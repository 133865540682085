import React from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const MenuMobile = ({appDelegate}) => {
  const { t } = useTranslation();
  return (
    <div className="header-mobile">
      <div className="menu-content">
        {
          appDelegate.state.anonymous ? (
            <div className="menu-box">
              <a onClick={appDelegate.toggleMenu} href={'https://educat.courses/about'} className={'menu'}>
                  {t('nav.about')} О нас
              </a>
              <div onClick={() => appDelegate.toggleRegistration(false)} className={'menu'}>
                  {t('nav.account')}
              </div>
            </div>
          ) : (
            <div className="menu-box">
              <NavLink exact={true} className={'button-menu all-parts'}
                       onClick={appDelegate.toggleMenu}
                       to={'/'}>
                <img className={'icon'} src="/Images/all-parts.png" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/all-parts-hover.png" alt={'😼'}/>
                <span>
                    {t('sidebar.sections')}
                </span>
              </NavLink>
              <NavLink className={'button-menu first-style'}
                       onClick={appDelegate.toggleMenu}
                       to={'/tests'}>
                <img className={'icon'} src="/Images/tests.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/tests-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.tasks')}
                </span>
              </NavLink>
              <NavLink exact={true} className={'button-menu first-style'}
                       onClick={appDelegate.toggleMenu}
                       to={'/all-courses'}>
                <img className={'icon'} src="/Images/all-courses.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/all-courses-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.courses')}
                </span>
              </NavLink>
              <NavLink className={'button-menu'}
                       onClick={appDelegate.toggleMenu}
                       to={'/practices'}>
                <img className={'icon'} src="/Images/practice.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/practice-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.practice')}
                </span>
              </NavLink>
              <NavLink className={'button-menu'}
                       onClick={appDelegate.toggleMenu}
                       to={'/games'}>
                <img className={'icon'} src="/Images/games.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/games-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.games')}
                </span>
              </NavLink>
              <NavLink className={'button-menu'}
                       onClick={appDelegate.toggleMenu}
                       to={'/webinars'}>
                <img className={'icon'} src="/Images/vebinar.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/vebinar-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.video')}
                </span>
              </NavLink>
              <NavLink className={'button-menu'}
                       onClick={appDelegate.toggleMenu}
                       to={'/jobs'}>
                <img className={'icon'} src="/Images/career.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/career-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.work')}
                </span>
              </NavLink>
              {/*<NavLink className={"button-menu"}*/}
              {/*         onClick={appDelegate.toggleMenu}*/}
              {/*         to={"/certificate"}>*/}
              {/*    <img className={"icon"} src="/Images/certificate.svg" alt={"😼"}/>*/}
              {/*    <img className={"icon-hover"} src="/Images/certificate-hover.svg" alt={"😼"}/>*/}
              {/*    <span>Сертификаты</span>*/}
              {/*</NavLink>*/}
              <NavLink className={'button-menu'}
                       onClick={appDelegate.toggleMenu}
                       to={'/helper'}>
                <img className={'icon'} src="/Images/tel.svg" alt={'😼'}/>
                <img className={'icon-hover'} src="/Images/tel-hover.svg" alt={'😼'}/>
                <span>
                    {t('sidebar.contacts')}
                </span>
              </NavLink>
            </div>
          )
        }

      </div>
    </div>
  )
}

export default MenuMobile
