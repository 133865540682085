import React, {useEffect, useRef, useState} from "react"
import {v4 as uuidv4} from 'uuid'

const Range = (props) => {

    const ref = useRef(null)
    const id = uuidv4()

    const [value, setValue] = useState(-1)
    const [width, setWidth] = useState(0)


    useEffect(() => {
        setProgress()
        ref.current.addEventListener("input", setProgress)
        return () => {
            ref.current.removeEventListener("input", setProgress)
        }

    }, [props])

    const dotes = () => {
        let i = props.min
        let arr = []
        while (i <= props.max) {
            arr.push(i)
            i++
        }
        return arr
    }


    const setProgress = () => {
        setValue(ref.current.value)
        setWidth(ref.current.clientWidth)
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
        const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)

        if (isChrome || isSafari) {
            const percent = (ref.current.value - ref.current.min) / (ref.current.max - ref.current.min) * 100
            ref.current.style.background = `linear-gradient(to right, #0a54ff 0%, #0a54ff ${percent}%, #dadce0 ${percent}%, #dadce0 100%)`
        }

    }

    return (
        <div className={"range"}>
            <input id={id} style={{
                clipPath: `polygon(${value > props.min ? "8px" : "0px"} -100px, ${value < props.max ? `${width - 8}px` : `${width}px`} -100px, ${value < props.max ? `${width - 8}px` : `${width}px`}  200px, ${value > props.min ? "8px" : "0px"} 200px)`,
                WebkitClipPath: `polygon(${value > props.min ? "8px" : "0px"} -100px, ${value < props.max ? `${width - 8}px` : `${width}px`} -100px, ${value < props.max ? `${width - 8}px` : `${width}px`}  200px, ${value > props.min ? "8px" : "0px"} 200px)`
            }} ref={ref} {...props} type="range"/>
            <div className={"range__track"}>
                {dotes().map((item) => (
                    <div
                        className={Number(item) === Number(value) ? "dot current" : Number(item) > Number(value) ? "dot greater" : "dot"}
                        key={item}>
                        {item === props.min || item === props.max ? (<span>{item}</span>) : ""}
                        <label htmlFor={id}>{item}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default Range