import React from 'react';
import styled from './style.module.scss'
import classnames from 'classnames'
import {PeriodSubscription} from '../../../../../constats/billing'
import {useTranslation} from "react-i18next";

type Props = {
    switcher: boolean;
    handlerSwitcher: () => void;
};

const SwitcherSubscription = ({switcher, handlerSwitcher}: Props) => {
    const { t } = useTranslation();
    return <div className={styled.wrapperDesktop}>
        <span className={classnames({
            [styled.active]: switcher
        })}>{ t(PeriodSubscription.oneMonth) }</span>
        <div className={styled.lineSwitcher}
             style={{justifyContent: switcher ? 'flex-start' : 'flex-end'}}
             onClick={handlerSwitcher}
        >
            <div className={styled.square} />
        </div>
        <span className={classnames({
            [styled.active]: !switcher
        })}>{ t(PeriodSubscription.oneYear) }</span>
        <span className={styled.saving}>
            {t('subscription.saving')}
        </span>
    </div>
};

export default SwitcherSubscription;
