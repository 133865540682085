import React from 'react';
import styles from './Screen2.module.css';

const Screen2 = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Что дает <span className={styles.highlighted}>подписка</span>?</h2>
            <ul className={styles.benefitsList}>
                <li className={styles.benefitsListItem}>
                    Неограниченный доступ ко всем программам обучения.
                </li>
                <li className={styles.benefitsListItem}>
                    Возможность обучаться онлайн в любое удобное время.
                </li>
                <li className={styles.benefitsListItem}>
                    Занимайтесь оффлайн через мобильное приложение.
                </li>
            </ul>
        </div>
    )
}

export default Screen2;