import React from 'react';
import catImage from './image/billing.png'
import style from './RedirectBilling.module.scss';
import {Link} from "react-router-dom"

const RedirectBilling = (props) => {
    const tariff = ["Бронза", "Серебро", "Золото", "Платина"];

    const id = props.match.params.id;

    return <main className="practice-home content-main">
        <div className={style.wrapper}>
            <img src={catImage} alt="Котик"/>
            <h1>Увы, сюда нельзя</h1>
            <div className={style.redirectText}>
                <p>У вас не хватает прав для просмотра этого контента! <br/>
                    Этот уровень прооектных работ доступен только <br/>
                    для тарифного плана <span>"{tariff[id - 1]}"</span> :(</p>
            </div>
            <div className={style.buttons}>
                <div className={style.oneButton}><Link to={"/practices"}><p>Вернуться назад</p></Link></div>
                <div className={style.twoButton}><Link to={`/billing/${id}?`}><p>Перейти на тарифный план {tariff[id - 1]}</p></Link></div>
            </div>
        </div>
    </main>
}

export default RedirectBilling;