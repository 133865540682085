import React from 'react';
import { Link } from 'react-router-dom';
import Review from './Review/Review';
import styles from './Screen4.module.css';

const Screen4 = (props) => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Пользователи о тестах</h2>
            <p className={styles.subheader}>Посмотрите, что говорят люди, купившие подписку, о тестах Educat.</p>
            <ul className={styles.reviewList}>
                {
                    props.reviews.map((review, i) => <li className={styles.reviewListItem} key={`Review-${i}`}>
                        <Review
                            color={review.color}
                            name={review.name}
                            text={review.text} />
                    </li>)
                }
            </ul>
            <Link className={styles.showMoreLink}>Показать больше</Link>
        </div>
    )
}

export default Screen4;