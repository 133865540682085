import React, {useState} from 'react'
import styled from './CertificateItem.module.scss'
import tmtIcon from '../../Pages/Certificates/img/logo-tmt.png'
import educatIcon from '../../Pages/Certificates/img/logo-educat.png'
import noActiveIcon from '../../Pages/Certificates/img/no-active-certificate.png'
import disableIcon from '../../Pages/Certificates/img/disable-icon.png'
import doneIcon from '../../Pages/Certificates/img/checkyes.png'
import config from "../../../etc/config"
import YandexShare from "react-yandex-share"
import {useDispatch} from "react-redux"
import {verifyCertificateThunk} from "../../../redux/certificatesReducer";

const CertificateItem = ({course, code, user, date, id, authToken, isActive}) => {
    const [name, setName] = useState('')
    const dispatch = useDispatch()
    const changeName = (e) => {
        setName(e.target.value)
    }
    const sendVerifyCertificate = () => {
        dispatch(verifyCertificateThunk(authToken, id, name))
    }

    return <div className={styled.wrapper}>
        <div className={styled.containerCertificate}>
            <h1>CEРТИ<span>&lt;/&gt;</span>ИКАТ</h1>
            <div className={styled.infoCertificate}>
                <span>Сертификат подрверждает, что</span>
                {isActive
                    ? <h4>{user}</h4>
                    : <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="text" placeholder={user} value={name} onChange={changeName}/>
                        {name.length === 0
                            ? <img src={disableIcon} alt="disable"/>
                            : <img onClick={sendVerifyCertificate} src={noActiveIcon}/>}
                    </div>}
                <span>Успешно окончил(а) курс:</span>
                <h4>{course}</h4>
                <span className={styled.date}>Дата выдачи: {date}</span>
                <hr/>
                <h5>Александр Мазуркевич</h5>
                <h5>Основатель Educat</h5>
            </div>
            <div className={styled.bottomCertificate}>
                <div className={styled.verifyCertificate}>
                    <span>Проверочный код: {code}</span>
                    <span>Подтвердить по адресу: <a href={`${config.MAIN_URL}/certificate-verification/${code}`}
                                                    target={"_blank"}>Кликни сюда</a></span>
                </div>
                <div className={styled.aboutCompany}>
                    <img src={tmtIcon} alt="tmt"/>
                    <span>EDUCAT s.r.o., Freyova 82/27, Praga. 190 00 Czech Republic</span>
                    <img src={educatIcon} alt="Educat"/>
                </div>
            </div>
        </div>
        {isActive && <footer>
            <span>Перейти к курсу</span>
            <div className={styled.shareNetwork}>
                <span className={styled.text}>Поделиться</span>
                <YandexShare
                    content={{
                        title: "Проверить мой сертификат на подлинность.",
                        url: `${config.MAIN_URL}/certificate-verification/${code}`
                    }}
                    theme={{lang: 'ru', services: 'vkontakte,facebook,twitter,telegram'}}
                />
            </div>
        </footer>}
        {!isActive &&
        <span className={styled.helpText}>Введите свои настроящие Имя и Фамилию для активации сертификата</span>}
    </div>
}

export default CertificateItem