import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CertificateItem from "../../../Components/CertificateItem/CertificateItem";

const DialogCertificate = ({course, code, user, date, id, authToken, isActive, showDialog, closeDialog}) => {
    return (
        <div style={{width: '200px'}}>
            <Dialog
                open={showDialog}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CertificateItem
                    code={code}
                    course={course}
                    id={id}
                    date={date}
                    user={user}
                    isActive={isActive}
                    authToken={authToken}
                />
            </Dialog>
        </div>
    );
}

export default DialogCertificate