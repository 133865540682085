import React from 'react'
import styled from './WhoIsThisProfession.module.scss'

const dataProfession = [
    {
        title: 'Тем, кто хочет освоить программирование',
        text: 'Вы можете быть абсолютным новичком в программировании, не обладать математической подготовкой и иметь минимальные знания в английском. Но если ваша цель освоить весь процесс веб-разработки - от верстки страниц до построения логики приложений, - то вы сделали правильный выбор.'
    },
    {
        title: 'Тем, кто обучался программированию самостоятельно',
        text: 'Бывает так, что у вас есть опыт в самостоятельном обучении вёрстке и программировании. Или даже вы имеете опыт работы с кодом приложений. Но вам не хватает систематизации информации и закрепления навыков. \n' +
            'Курсы Educat помогут во всем разобраться, упорядочить и развить ваши знания.'
    },
    {
        title: 'Тем, кто хочет сменить профессию',
        text: 'Вы чувствуете себя не на своём месте? Наконец-то поняли, что настало время что-то менять в своей жизни? Без надёжного фундамента в освоении новой профессии вам не обойтись. Educat станет для вас надёжным помощником, хорошим другом и поддержит вас на каждом шаге обучения по нашей программе.'
    },
]

const WhoIsThisProfession = () => {
    return <div className={styled.wrapperWhoThisProfession}>
        <h2>Кому подойдёт эта профессия</h2>
        <div className={styled.content}>
            {dataProfession.map(({title, text}, index) => <div className={styled.cell} key={index}>
                <h4>{title}</h4>
                <span>{text}</span>
            </div>)}
        </div>
    </div>
}

export default WhoIsThisProfession