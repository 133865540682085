import React from 'react'
import SupportBoxSms from "./SupportBoxSms";
import SupportBoxEmail from "./SuppurtBoxEmail";
import SupportBoxMessenger from "./SupportBoxMessenger";
import Loader from "../../../Components/Loader";
import {useTranslation} from "react-i18next";

const Support = ({accountDelegate, appDelegate}) => {
    const { t } = useTranslation();
    return (<React.Fragment>
        {accountDelegate.state.support ? (
            <div className={"support"}>
                <h3>
                    {t('support.title')}
                </h3>
                <p className={"support-text"}>
                    {t('support.desc')}
                </p>
                <SupportBoxSms data={accountDelegate.state.support.phone} accountDelegate={accountDelegate}/>
                <SupportBoxEmail data={accountDelegate.state.support.email} accountDelegate={accountDelegate}/>
                <SupportBoxMessenger appDelegate={appDelegate} data={accountDelegate.state.support.social} accountDelegate={accountDelegate}/>

                {accountDelegate.state.successUpdated ? (
                    <div className="save">
                        <img src="/Images/tick.svg"/>
                        <span>
                            {t('support.saved')}
                        </span>
                    </div>
                ) : ""}

            </div>
        ) : (
            <Loader/>
        )}
    </React.Fragment>);
}
export default Support