import {
    CHANGE_IS_LOADING,
    SET_TEST,
    CLOSE_END_PAGE_TEST,
    CLOSE_THEME,
    CLOSE_PAGE_SUCCESS_PASSED_THEME,
    SET_CURRENT_TASK,
    CHANGED_PROGRESS_THEME,
    CHECKED_THE_TASK,
    RESET_TEST,
    SET_ALL_TESTS,
    SHOW_THEME,
    SWITCH_NEXT_TASK,
    SWITCH_ON_BACK_SUCCESS_TASK,
    SWITCH_ON_NEXT_SUCCESS_TASK
} from '../../actions/tests';
import {AnyAction} from 'redux';
import {TaskItem, TestReducerType, ThemeItem} from '../../../types/tests';


let initialState: TestReducerType = {
    tests: [],
    test: null,
    currentTheme: null,
    currentTask: null,
    isShowTheme: false,
    isPageSuccessPassedTheme: false,
    isResetTest: false,
    categories: [],
    totalTests: 0,
    perPage: 0,
    currentPage: 0,
    isLoading: false
};

const tasksReducer = (state = initialState, {type, payload}: AnyAction): TestReducerType => {
    switch (type) {
        case SET_ALL_TESTS: {
            const nullCategory = {
                id: 0,
                name: 'Все',
            };
            return {
                ...state,
                tests: payload.data,
                categories: [nullCategory, ...payload.categories],
                totalTests: payload.total,
                perPage: payload.perPage,
                currentPage: payload.currentPage,
                isLoading: false
            };
        }
        case SET_TEST: {
            return {
                ...state,
                test: payload,
            };
        }
        case RESET_TEST: {
            return {
                ...state,
                isResetTest: payload
            };
        }
        case SHOW_THEME: {
            return {
                ...state,
                isShowTheme: true,
                currentTheme: state.test?.levels?.reduce<ThemeItem>((acc, level) => (level.themes.find(theme => theme.id === payload.idTheme) || acc), null),
                currentTask: state.test?.levels?.reduce<TaskItem>((acc, level) => (level.themes.reduce<any>((accTheme: number, theme: ThemeItem) => theme?.tasks?.find(task => task.id === payload.idTask) || accTheme, null) || acc), null),
            };
        }
        case CLOSE_THEME: {
            return {
                ...state,
                isShowTheme: false
            };
        }
        case CHANGED_PROGRESS_THEME: {
            return {
                ...state,
                currentTheme: {
                    ...state.currentTheme,
                    tasks_correct: state.currentTheme.tasks_correct + 1
                }
            };
        }
        case CHECKED_THE_TASK: {
            return {
                ...state,
                currentTask: {
                    ...state.currentTask,
                    is_correct: payload.is_correct,
                    answer: payload.answer,
                    is_answered: 1
                },
                currentTheme: {
                    ...state.currentTheme,
                    tasks: state.currentTheme.tasks.map(task => {
                        if (task.id === payload.id) {
                            return {...task, is_correct: payload.is_correct, is_answered: 1};
                        } else {
                            return task;
                        }
                    })
                }
            };
        }
        case SWITCH_NEXT_TASK: {
            if (state.currentTheme.tasks.every(task => task.is_correct || task.is_correct === false)) {
                return {
                    ...state,
                    isPageSuccessPassedTheme: true
                };
            } else {
                return {
                    ...state,
                    currentTask: state.currentTheme.tasks.find(task => task.is_correct === 0)
                };
            }
        }
        case SWITCH_ON_NEXT_SUCCESS_TASK: {
            const nextIndex = state.currentTheme.tasks.findIndex(({id}) => id === state.currentTask.id) + 1;
            if (nextIndex < state.currentTheme.tasks.length) {
                return {
                    ...state,
                    currentTask: state.currentTheme.tasks[nextIndex]
                };
            }
            break
        }
        case SWITCH_ON_BACK_SUCCESS_TASK: {
            const backIndex = state.currentTheme.tasks.findIndex(({id}) => id === state.currentTask.id) - 1;
            if (backIndex >= 0) {
                return {
                    ...state,
                    currentTask: state.currentTheme.tasks[backIndex]
                };
            }
            break
        }
        case CLOSE_PAGE_SUCCESS_PASSED_THEME: {
            if (state.currentTheme.tasks.some(task => !task.is_correct)) {
                return {
                    ...state,
                    isPageSuccessPassedTheme: false,
                    currentTheme: {
                        ...state.currentTheme,
                        tasks: state.currentTheme.tasks.map(task => {
                            if (task.is_correct === false) {
                                return {...task, is_correct: 0};
                            }
                            return {...task};
                        })
                    }
                };
            } else {
                return {
                    ...state,
                    isPageSuccessPassedTheme: false,
                    isShowTheme: false
                };
            }
        }
        case CLOSE_END_PAGE_TEST: {
            return {
                ...state,
                isPageSuccessPassedTheme: false,
                isShowTheme: false
            };
        }
        case SET_CURRENT_TASK: {
            return {
                ...state,
                currentTask: state.currentTheme.tasks.find(task => task.is_correct === 0)
            };
        }
        case CHANGE_IS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }
        default:
            return state;
    }
};

export default tasksReducer;
