import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './reducers';

const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};

const configureStore = (preloadedState: any) => createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)));

const store = configureStore({});
export type AppDispatch = typeof store.dispatch;

export default store;
