import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom"


const Offers = ({offers}) => {

    const [height, setHeight] = useState(0)
    const [opened, toggle] = useState(false)

    const refContainer = useRef(null);

    useEffect(() => {
        setHeight(refContainer.current.scrollHeight);
    }, [])


    return (
        <div className={"stages-box offers"}>
            <div onClick={() => toggle(!opened)} className={"box"}>
                <div className={"left-stages"}>
                    <img style={{transform: `rotate(${opened ? `0deg` : `180deg`})`, transition: "all 0.2s linear"}}
                         src={"/Images/arrow-black.svg"} alt={"😼"}/>
                    <div className={"stages-name"}>
                        Заказы
                    </div>
                    <span className={"offers-count"}>{offers.length}</span>
                </div>

            </div>
            <div style={{ maxHeight: `${ opened ? `${height}px` : `0px` }` }} ref={refContainer} className={"themes-box"}>
                {offers.map((item, index) => (
                    <Link to={`/orders/${item.id}`} key={index} className={"themes-box__cell"}>
                        <div className={"offer-info"}>
                            <div className={"offer-info__top"}>
                                {item.name}
                            </div>
                            <div className={"offer-info__bottom"}>
                                <span>Заказчик: {item.company.name}</span>
                                <span>Технологии: {item.technology}</span>
                                <span>Стоимость: {item.price}Р</span>
                                <span>Сроки: {item.days} дней</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

        </div>
    )
}


export default Offers