import React from 'react'
import withOrders from "./HOCs/init";
import {Link} from 'react-router-dom'

export const days =(count) =>{

    let length = count.toString().length
    let str =  count.toString()

    if ((count > 9 && str[length - 2] === "1") || (Number(str[length - 1]) === 0 || (Number(str[length - 1]) > 4))) {
        return "дней"
    } else if (str[length - 1] === "1") {
        return "день"
    }
    return "дня"
}

const Orders = ({ordersDelegate}) => (
    <main className={"practice-home content-main"}>
        <div className={"all-courses"}>
            <h1>Заказы</h1>
            <div className={"top"}>
                <p>Здесь вы можете применить свои навыки на деле и заработать на этом. Выполняйте реальные заказы компаний-партнеров Educat с нашей поддержкой.</p>
            </div>
            {ordersDelegate.state.data.my.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>Заказы в работе</h4>
                    <div className={"profession"}>
                        {ordersDelegate.state.data.my.map((item, index) => (
                            <Link to={`/orders/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>{item.company.name}</span>
                                        <span>Срок: {item.days} {days(Number(item.days))}</span>
                                    </p>
                                    <h3>{item.name}</h3>
                                    <div className={"info"}>
                                        <span> {item.technology}</span>
                                        <span> {item.price} P</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}

            {ordersDelegate.state.data.checking.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>Заказы на проверке</h4>
                    <div className={"profession"}>
                        {ordersDelegate.state.data.checking.map((item, index) => (
                            <Link to={`/orders/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>{item.company.name}</span>
                                        <span>Срок: {item.days} {days(Number(item.days))}</span>
                                    </p>
                                    <h3>{item.name}</h3>
                                    <div className={"info"}>
                                        <span> {item.technology}</span>
                                        <span> {item.price} P</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}

            {ordersDelegate.state.data.offers.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>Открытые заказы</h4>
                    <div className={"profession"}>
                        {ordersDelegate.state.data.offers.map((item, index) => (
                            <Link to={`/orders/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>{item.company.name}</span>
                                        <span>Срок: {item.days} {days(Number(item.days))}</span>
                                    </p>
                                    <h3>{item.name}</h3>
                                    <div className={"info"}>
                                        <span> {item.technology}</span>
                                        <span> {item.price} P</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}

            {ordersDelegate.state.data.completed.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>Завершенные заказы</h4>
                    <div className={"profession"}>
                        {ordersDelegate.state.data.completed.map((item, index) => (
                            <Link to={`/orders/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>Заказ: {item.company.name}</span>
                                        <span>Срок: {item.days} {days(Number(item.days))}</span>
                                    </p>
                                    <h3>{item.name}</h3>
                                    <div className={"info"}>
                                        <span> {item.technology}</span>
                                        <span> {item.price} P</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}

        </div>
    </main>
)
export default withOrders(Orders)