import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SwitcherComponentFull from './SwitcherComponentFull/SwitcherComponentFull';
import s from './QuestionsAndTarif.module.scss'
import {questions} from "../../Pages/Billing/components/FAQ/questions";
import Tarifs from '../../Pages/Billing/components/tarifsConst'
import {useTranslation} from "react-i18next";

type Props = {
    purchasedTariff?: any;
}

const QuestionsAndTariff = ({ purchasedTariff }: Props) => {
    const [selectedTariff, setSelectedTariff] = useState(Tarifs[1]);
    const { t } = useTranslation();
    const isSecondSelected = selectedTariff === Tarifs[1];

    const invertSelection = () => {
        if (isSecondSelected) {
            setSelectedTariff(Tarifs[0])
        } else {
            setSelectedTariff(Tarifs[1])
        }
    };

    return <div className={s.questionsAndTarif}>
        <div className={s.questions}>
            <h4>
                {t('faq.title')}
            </h4>
            <ul>
                <li><Link to="/faq">{ t(questions[0].title) }</Link></li>
                <li><Link to="/faq">{ t(questions[1].title) }</Link></li>
            </ul>
            <Link to="/faq">
                {t('faq.link')}
            </Link>
        </div>
        {purchasedTariff
            ? <div className={s.tarif}>
                <h4>
                    {t('tariff.title')}
                </h4>
                <div className={s.plate}>
                    1 {Tarifs[purchasedTariff].title}
                    <br />
                    <span className={s.price}>
                        {Tarifs[purchasedTariff].price} $
                </span>
                </div>
                <Link to="/">
                    {t('tariff.change')}
                </Link>
            </div>
            : <div className={s.tarif}>
                <h4>
                    {t('tariff.choose')}
                </h4>
                <SwitcherComponentFull
                    tarifs={Tarifs}
                    invertSelection={invertSelection}
                    isSecondSelected={isSecondSelected}
                />
                <Link to="/billing">
                    {t('tariff.choose2')}
                </Link>
            </div>}
    </div>;
}

export default QuestionsAndTariff;
