import React, {useState} from 'react'
import Level from "./Level"
import styled from './ProgramLearn.module.scss'
import openIcon from '../../img/plus.svg'
import closeIcon from '../../img/close-circle.svg'

const Stage = ({title, requirements, index, stages}) => {
    const [openLevels, setOpenLevels] = useState(false)

    const handlerOpenLevels = () => {
        openLevels ? setOpenLevels(false) : setOpenLevels(true)
    }

    return <div className={styled.stagesCourse}>
        <div className={styled.container} onClick={handlerOpenLevels}>
            <div className={styled.left}>
                <span className={styled.countNumber}>{index + 1}</span>
                <span className={styled.titleStages}>{title}</span>
                {requirements && <span className={styled.skillsDesktop}>Необходимые навыки: {requirements}</span>}
            </div>
            <div className={styled.right}>
                <img src={!openLevels ? openIcon : closeIcon}
                     alt="+"/>
            </div>
        </div>
        <span className={styled.skillsMobile}>Необходимые навыки: {requirements}</span>
        {openLevels && <div className={styled.levelWrapper}>
            {stages.map(({title}, index) => <Level key={index} title={title}/>)}
        </div>}
    </div>
}

export default Stage