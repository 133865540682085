import React, {Fragment} from "react";
import {Checkbox, RadioGroup} from "../../../Components/Checkbox";
import withTask from "../HOCs/withTask";
import {Link} from "react-router-dom";

const Task = ({test, index, taskDelegate, enable, isSubscribed,themeDelegate }) => (
    <div className={"test-content"}>
        <div className={"menu-test"}>
            <div className={"menu-list"}>
                <div className={"menu-left"}>
                    <div onClick={() => taskDelegate.setHint(false)}
                         className={!taskDelegate.state.hint ? "menu text active" : "menu text"}>Тест {index + 1} {!test.free ? (
                        <img src="/Images/pro.svg" alt="pro"/>
                    ) : ""}</div>
                    {enable && test.hint && test.hint !== "<p><br></p>" && test.hint !== "<p></p>" ? (
                        <div onClick={() => taskDelegate.setHint(true)}
                             className={taskDelegate.state.hint ? "menu text active" : "menu text"}>Подсказка
                        </div>
                    ) : ""}
                </div>
                <div className={"ball"}>MAX: {test.score} балла {taskDelegate.state.attempts ?
                    <span>(-{taskDelegate.state.attempts})</span> : ""}</div>
            </div>
        </div>

        {enable ? (
            <Fragment>
                {taskDelegate.state.hint ? (
                    <div dangerouslySetInnerHTML={{__html: test.hint}} className={"question html-data"}/>
                ) : (
                    <Fragment>
                        <div dangerouslySetInnerHTML={{__html: test.question}} className={"question html-data"}/>
                        <div className={"exercise"}>
                            {test.type === "inline" ? (
                                <div className={"inline-cell"}>
                                    <input onChange={taskDelegate.handlerChange} disabled={taskDelegate.state.taskState === "done"}
                                           value={taskDelegate.state.value || ""} type="text" placeholder={'Введите ваш ответ'}/>
                                </div>
                            ) : test.type === "many_to_many" ? (
                                <form>
                                    {test.options.map((option, idx) => (
                                        <div className={"to-many"} key={idx}>
                                            <Checkbox onChange={taskDelegate.handlerChange}
                                                      checked={taskDelegate.state.value && taskDelegate.state.value.findIndex(x => x.toString() === option.id.toString()) > -1}
                                                      disabled={taskDelegate.state.taskState === "done"} id={`q-${index}-oid-${option.id}`} value={option.id}
                                                      name={`q-${index}-oid-${option.id}`}/>
                                            <label htmlFor={`q-${index}-oid-${option.id}`}>{option.value}</label>
                                        </div>
                                    ))}
                                </form>
                            ) : test.type === "one_to_many" ? (
                                <RadioGroup disabled={taskDelegate.state.taskState === "done"} value={taskDelegate.state.value} name={index}
                                            handler={taskDelegate.handlerChange} options={test.options}/>
                            ) : ""}
                            <div className={"exercise__bottom"}>
                                <div onClick={() => {
                                    if (taskDelegate.state.taskState === "done" || taskDelegate.state.taskState === "empty" || taskDelegate.state.taskState === "error")
                                        return;
                                    taskDelegate.pushAnswer();
                                }}
                                     className={
                                         taskDelegate.state.taskState === "done" ?
                                             "button done"
                                             : taskDelegate.state.taskState === "error" ?
                                             "button error"
                                             : taskDelegate.state.taskState === "empty" ?
                                                 "button empty"
                                                 : "button"
                                     }>{taskDelegate.state.taskState === "done" ? "Верно" : taskDelegate.state.taskState === "error" ? "Не верно" : "Проверить"}</div>
                            </div>

                        </div>
                    </Fragment>

                )}
            </Fragment>
        ) : (
            <div className={"turn-on"}>
                <div className={"turn-on__left"}>
                    <img src={"../Images/invalid-name.svg"} alt={"😼"}/>
                    <p>Включите опцию, чтобы пройти тест</p>
                </div>
                {/*<Link  to={isSubscribed ? '/subscription' : `/billing/${themeDelegate.state.data.stage.level.course.id}`}>*/}
                <Link  to={`/billing/`}>
                    <div className={"turn-on__button"}>Включить</div>
                </Link>
            </div>
        )}




    </div>
);


export default withTask(Task)