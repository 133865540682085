import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Subscription.module.css';

const Subscription = (props) => {
    const renderIfStrikedOutPrice = () => {
        if (props.crossedOutPrice) {
            return <p className={styles.crossedOutPrice}>{props.crossedOutPrice}</p>;
        }
    };

    const renderPeriod = () => {
        return props.period.toLowerCase() === `навсегда` 
              ? `разово`
              : `/ ${props.period.toLowerCase()}`;
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
                <h4 className={styles.period}>{props.period}</h4>
                {renderIfStrikedOutPrice()}
            </div>
            <div className={styles.priceWrapper}>
                <h2 className={styles.price}>{props.price}</h2>
                <p className={styles.periodAfterPrice}>{renderPeriod()}</p> 
            </div>
            <ul className={styles.featureList}>
                {props.features.map((feature, i) => <li className={styles.featureListItem} key={`${feature}-${i}`}>{feature}</li>)}
            </ul>
            <Link className={styles.buySubscriptionLink}>Купить подписку</Link>
        </div>
    )
}

export default Subscription;