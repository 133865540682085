import React, {useState} from 'react'
import styled from './CertificateList.module.scss'
import YandexShare from "react-yandex-share"
import config from "../../../../../etc/config"
import DialogCertificate from "../DialogCertificate";

const CertificateList = ({course, code, user, date, id, authToken, isActive, index}) => {
    const [showDialog, setShowDialog] = useState(false)

    const closeDialog = () => {
        showDialog ? setShowDialog(false) : setShowDialog(true)
    }

    return <div className={styled.wrapper}>
        <div className={styled.title}>
            <span className={styled.countNumber}>{index + 1}</span>
            <h3>{course}</h3>
        </div>
        <div className={styled.descriptions}>
            <span className={`${styled.showCertificate} ${styled.text}`} onClick={closeDialog}>Посмотреть сертификат</span>
            <span className={styled.text}>Перейти к курсу</span>
            <div className={styled.text}>
            <span className={styled.socialNetwork}>Поделиться


            </span>
                <YandexShare
                    content={{
                        title: "Проверить мой сертификат на подлинность.",
                        url: `${config.MAIN_URL}/certificate-verification/${code}`
                    }}
                    theme={{lang: 'ru', services: 'vkontakte,facebook,twitter,telegram'}}
                />
            </div>
        </div>
        <div style={{display: 'none'}}>
            <DialogCertificate
                course={course}
                cod={code}
                user={user}
                date={date}
                id={id}
                authToken={authToken}
                isActive={isActive}
                showDialog={showDialog}
                closeDialog={closeDialog}
            />
        </div>
    </div>
}

export default CertificateList