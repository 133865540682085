import {dashboardAPI} from "../api/api";
import {Dispatch} from "redux";
const SET_DATA_WINDOW_ERROR = 'SET_DATA_WINDOW_ERROR';
const CHANGE_STATUS_RETURNED_ERROR = 'CHANGED_STATUS_RETURNED_ERROR';
const SET_DASHBOARD = 'SET_DASHBOARD'

export type CoursesType = {
    data: Array<CourseItemType>
    currentPage: number
    perPage: number
    totalPage: number

}

export type CourseItemType = {
    duration: number
    icon: string
    id: number
    isFree?: number
    isPlaned: boolean
    progress?: number
    tasks: number
    themes?: Array<object>
    title: string
    type?: string
    videos: number
}

export type PracticesType = {
    icon: string
    id: number
    levels: number
    progress: number
    stack: string
    tasks: number
    themes: Array<object>
    title: string
}

export type CareersType = {
    company: object
    employment: string
    experience: string
    id: number
    position: string
    salary: string
}

type TestsType = {
    icon: string
    id: number
    isFree: number
    levels: Array<object>
    participants: number
    progress: 0
    questions: number
    title: string
}

type CertificatesType = {
    code: string
    course: string
    date: string
    id: number
    isActive: boolean
    user: string
}

export type InitialStateDashboardType = {
    courses: CoursesType | null
    practices: Array<PracticesType> | null
    careers: Array<CareersType> | null
    tests: Array<TestsType> | null
    categoriesError: Array<object> | null
    statusErrorReport: number | null
    certificates: Array<CertificatesType> | null
    videos: Array<object>
}

type VideoType = {
    title: string
}

let initialState: InitialStateDashboardType = {
    courses: null,
    practices: null,
    careers: null,
    tests: null,
    categoriesError: null,
    statusErrorReport: null,
    certificates: null,
    videos: [
        {
            title: "CSS уроки. Свойство background-size",
        },
        {
            title: "CSS уроки. Свойство background-position",
        },
        {
            title: "HTML CSS для начинающих. CSS анимация Часть 1 [Урок 36]",
        },
        {
            title: "Javascript с нуля. События",
        },
        {
            title: "CSS уроки. Свойство background-size",
        },
        {
            title: "CSS уроки. Свойство background-position",
        },
        {
            title: "CSS уроки. Свойство background-repeat",
        },
        {
            title: "CSS уроки. Свойство background-image",
        }
    ] as Array<VideoType>
}

const dashboardReducer = (state = initialState, action: ActionsTypes): InitialStateDashboardType => {
    switch (action.type) {
        case SET_DASHBOARD: {
            return {
                ...state,
                courses: action.courses,
                practices: action.practice,
                careers: action.jobs,
                tests: action.tests,
                certificates: action.certificates
            }

        }
        case SET_DATA_WINDOW_ERROR: {
            return {
                ...state,
                categoriesError: action.categories
            }
        }
        case CHANGE_STATUS_RETURNED_ERROR: {
            return {
                ...state,
                statusErrorReport: action.status
            }
        }
        default:
            return state;
    }
}

type ActionsTypes = SetDataWindowErrorActionType | ChangeStatusReturnedErrorType | SetDashboardType

type SetDashboardType = {
    type: typeof SET_DASHBOARD
    courses: CoursesType
    practice: Array<PracticesType>
    jobs: Array<CareersType>
    tests: Array<TestsType>
    certificates: Array<CertificatesType>
}

const setDashboard = ({courses, practice, jobs, tests, certificates}: any): SetDashboardType => {
    return {
        type: SET_DASHBOARD,
        courses, practice, jobs, tests, certificates
    }
}

type SetDataWindowErrorActionType = {
    type: typeof SET_DATA_WINDOW_ERROR
    categories: Array<object>
}

const setDataWindowError = (categories: Array<object>): SetDataWindowErrorActionType => {
    return {
        type: SET_DATA_WINDOW_ERROR,
        categories
    }
}

type ChangeStatusReturnedErrorType = {
    type: typeof CHANGE_STATUS_RETURNED_ERROR
    status: number
}

export const changedStatusReturnedError = (status: number): ChangeStatusReturnedErrorType => {
    return {
        type: CHANGE_STATUS_RETURNED_ERROR,
        status
    }
}

export const getDataForDashboard = (authToken: string) => {
    return async (dispatch: Dispatch<ActionsTypes>) => {
        let response = await dashboardAPI.getDataForDashboard(authToken)
        try {
            dispatch(setDashboard(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getDataWindowError = (authToken: string) => {
    return async (dispatch: any) => {
        let response = await dashboardAPI.getCategoriesErrors(authToken)
        try {
            dispatch(setDataWindowError(response.data.categories))
        } catch (error) {
            console.log(error)
        }
    }
}

export const sendReportError = (authToken: string, url: string, categoryId: number, messageError: string) => {
    return async (dispatch: any) => {
        let response = await dashboardAPI.sendReportError(authToken, url, categoryId, messageError)
        try {
            dispatch(changedStatusReturnedError(response.data.status))
        } catch (error) {
            console.log(error)
        }
    }
}


export default dashboardReducer;
