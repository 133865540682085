import React, {  useEffect, useState } from "react";
import Loader from "../Loader";
import config from "../../../etc/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";

const PhoneValidator = ({ appDelegate }) => {
  const [viewState, setViewState] = useState("loading");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [buttonState, setButtonState] = useState("normal");

  const [min, setMin] = useState("");
  const [sec, setSec] = useState("");

  let expire = null;

  let interval = null;

  useEffect(() => {
    fetch(`${config.API_PREFIX}users/verify-status`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${appDelegate.state.authToken}`
      }
    })
      .then(res => res.json())
      .then(res => {
        switch (Number(res.status)) {
          case 1:
            setViewState("start");
            break;
          case 2:
            expire = {
              min: moment(res.valid_to).get("minutes"),
              sec: moment(res.valid_to).get("seconds")
            };
            setViewState("waiting");
            setPhone(res.phone);
            createInterval();
            break;
          case 3:
            setPhone(res.phone.replace("+", ""));
            setViewState("start");
            break
          default:
            break
        }
      })
      .catch(err => {
        console.log(err);
      });
    return () => {
      if (interval) createInterval(interval);
    };
  }, []);

  const createInterval = () => {

    interval = setInterval(() => {
      if (!expire) {
        clearInterval(interval);
        return;
      }
      let current = {
        min: moment().get("minutes"),
        sec: moment().get("seconds")
      };

      let currentTime = current.min * 60 + current.sec;
      let expiredTime = expire.min * 60 + expire.sec;

      if (currentTime > expiredTime) {
        current.min = -(3600 - currentTime);
      }

      let restTime = expiredTime - currentTime;

      if (restTime > 0) {
        let min = Math.floor(restTime / 60);
        let sec = restTime % 60;

        if (sec < 0) sec = 60 + sec;

        setMin(min < 10 ? `0${min}` : min.toString());
        setSec(sec < 10 ? `0${sec}` : sec.toString());
      } else {
        clearInterval(interval);
        expire = null;
        setMin("");
        setSec("");
      }
    }, 1000);
  };

  const pushPhone = e => {
    e.preventDefault();
    setButtonState("loading");
    fetch(`${config.API_PREFIX}users/verify-phone`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${appDelegate.state.authToken}`
      },
      body: JSON.stringify({ phone: `${phone}` })
    })
      .then(res => res.json())
      .then(res => {
        setButtonState("normal");
        expire = {
          min: moment(res.valid_to).get("minutes"),
          sec: moment(res.valid_to).get("seconds")
        };
        setViewState("waiting");
        createInterval();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const pushCode = e => {
    e.preventDefault();
    setButtonState("loading");
    fetch(`${config.API_PREFIX}users/verify-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${appDelegate.state.authToken}`
      },
      body: JSON.stringify({ code: code })
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setButtonState("normal");
        if (res.status === 0) {
          setViewState("ok");
          appDelegate.phoneValidationCompletion()
        } else {
          alert("Неверный код, попробуйте еще раз");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div onClick={appDelegate.togglePhoneValidator}  className={"phone-validator"}>
      {viewState === "loading" ? (
        <Loader />
      ) : (
        <div onClick={e => {
          e.stopPropagation()
        }} className={"phone-validator__container"}>
          <button onClick={appDelegate.togglePhoneValidator}  className="close">
            <img src="/Images/close.svg" alt={"😼"} />
          </button>
          {viewState === "start" ? (
            <form onSubmit={pushPhone}>
              <h2>SMS уведомление</h2>
              <h5>
                Используйте номер телефона для оповещений – вы сможете получать
                уведомления о проверке домашнего задания и сбрасывать пароль,
                если забудете его.
              </h5>
              <label htmlFor="phone">Укажите свой номер телефона</label>
              <PhoneInput
                country={"ru"}
                containerClass={"phone-input"}
                value={phone}
                onChange={phone => setPhone(phone)}
              />
              <p>
                Чтобы убедиться, что этот номер принадлежит именно вам, мы
                отправим на него сообщение с кодом подтверждения. Плата за
                доставку сообщений взимается в соответствии с вашим тарифом.
              </p>
              <button disabled={buttonState === "loading"} type={"submit"}>
                {buttonState !== "loading" ? "Далее" : "...ждем..."}{" "}
              </button>
            </form>
          ) : viewState === "waiting" ? (
            <form onSubmit={pushCode}>
              <p className={"phone-number"}>{phone}</p>
              <h5>Введите код, который мы отправили вам в SMS.</h5>
              {min && sec ? (
                <p className="time">
                  {min}:{sec}
                </p>
              ) : (
                <p className="time">
                  Не получили код?{" "}
                  <span
                    onClick={() => {
                      setViewState("start");
                    }}
                  >
                    Отправить новый код
                  </span>
                </p>
              )}
              <input
                type={"text"}
                value={code}
                onChange={e => setCode(e.target.value)}
                placeholder={"Введите код"}
                className="code"
              />
              <button
                disabled={buttonState === "loading" || !code}
                type={"submit"}
              >
                {buttonState !== "loading" ? "Подтвердить" : "...ждем..."}{" "}
              </button>
            </form>
          ) : (
            <div className="confirmed-box">
              <img className="ok" src="/Images/ok-img.svg" alt={"😼"} />
              <p className="confirmed">Ваш номер подтвержден</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PhoneValidator;
