import React, {useEffect, useState} from 'react'
import styled from './Blog.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {changeCategory, getBlogThunk} from '../../../redux/blogReducer'
import Loader from '../../Components/Loader'
import PostItem from './components/PostItem/PostItem'
import AskedQuestions from '../../Components/AskedQuestions'
import catIcon from './img/img-coming-soon.jpg'
import {questions} from '../Billing/components/FAQ/questions';
import {useTranslation} from "react-i18next";

const Blog = () => {
    const dispatch = useDispatch()
    const dataBlog = useSelector(state => state.blogReducer)
    const [activeSlug, setActiveSlug] = useState(0)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getBlogThunk())
    }, [])

    const changeSlug = (index, slug) => {
        setActiveSlug(index)
        slug === null
            ? dispatch(getBlogThunk())
            : dispatch(changeCategory(slug))

    }

    return !dataBlog.isLoading ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <div className={styled.title}>
                <h1>
                    {t('blog.title')}
                </h1>
                <p>
                    {t('blog.desc')}
                </p>
                <p>
                    {t('blog.desc2')}
                </p>
            </div>
            <div className={styled.categories}>
                <span>
                    {t('blog.choose')}
                </span>
                <div className={styled.slugs}>
                    {dataBlog.categories.map(({name, slug}, index) => <div
                        className={index !== activeSlug ? styled.slug : `${styled.slug} ${styled.active}`}
                        onClick={() => changeSlug(index, slug)} key={index}>{name}</div>)}
                </div>
            </div>
            <div className={styled.content}>
                {dataBlog.posts.length !== 0 ? dataBlog.posts.map(({date, content, id, image, slug, title, views, category}, index) => <PostItem
                    key={index}
                    date={date}
                    content={content}
                    slug={slug}
                    id={id}
                    image={image}
                    categories={category}
                    title={title}
                    views={views}
                />) : <img className={styled.iconNoPost} src={catIcon} alt="Cat-Educat" />}
            </div>
        </div>
        <AskedQuestions questions={questions} />
    </div> : <Loader/>
}

export default Blog
