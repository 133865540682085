import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styled from './style.module.scss';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SelectCountry from "../SelectCountry/SelectCountry";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import ContactInfo from "../ContactInfo";
import PATH from '../pathConst';
import {BillingType} from '../../../../../types/subsription';
import {useTranslation} from "react-i18next";

const Methods = [
    {
        name: 'Банковская карта',
        methods: [
            {
                method: 'Visa',
                class: 'buttonVisa'
            },
            {
                method: 'MasterCard',
                class: 'buttonMasterCard'
            },
            {
                method: 'Maestro',
                class: 'buttonMasterCard'
            }
        ]
    },
    {
        name: 'Электронные кошельки',
        methods: [
            {
                method: 'Visa',
                class: 'buttonVisa'
            },
            {
                method: 'MasterCard',
                class: 'buttonMasterCard'
            },
            {
                method: 'Maestro',
                class: 'buttonMasterCard'
            },
            {
                method: 'Visa',
                class: 'buttonVisa'
            },
            {
                method: 'MasterCard',
                class: 'buttonMasterCard'
            },
            {
                method: 'Maestro',
                class: 'buttonMasterCard'
            },
            {
                method: 'Visa',
                class: 'buttonVisa'
            }
        ]
    },
    {
        name: 'Криптовалюты',
        methods: [
            {
                method: 'Visa',
                class: 'buttonVisa'
            },
            {
                method: 'MasterCard',
                class: 'buttonMasterCard'
            },
            {
                method: 'Maestro',
                class: 'buttonMasterCard'
            }
        ]
    },
    {
        name: 'Системы переводов',
        methods: [
            {
                method: 'MasterCard',
                class: 'buttonMasterCard'
            },
            {
                method: 'Maestro',
                class: 'buttonMasterCard'
            },
            {
                method: 'Visa',
                class: 'buttonVisa'
            }
        ]
    },
];

type Props = {
    step: string;
    authToken: string;
    subscriptions: BillingType;
    changeTariff: () => void;
}

const Payment = ({step, authToken, subscriptions, changeTariff}: Props) => {
        const stripePromise = loadStripe(subscriptions?.pk);
        const [selectedMethod, setMethod] = useState('Все способы');
        const { t } = useTranslation();
        const selectedMethods = selectedMethod === 'Все способы'
            ? Methods
            : [Methods.find((m) => m.name === selectedMethod)];

        return (
            <div className={styled.wrapper}>
                <h2 className={styled.title}>
                    {t('billing.chosen')}
                    <br/><span>{subscriptions.tariff?.title} <span
                    className={styled.price}>{subscriptions.tariff?.price} $</span></span></h2>

                <div className={styled.changeTariff}>
                    <div>
                        {t('billing.change')}
                    </div>
                    <button
                        onClick={changeTariff}>{subscriptions.currentTariff
                        ? `${subscriptions.subscriptions[0].prices[0].title} ${subscriptions.subscriptions[0].prices[0].price} $`
                        : `${subscriptions.subscriptions[0].prices[1].title} ${subscriptions.subscriptions[0].prices[1].price} $`
                    }
                    </button>
                </div>

                {step === `2` && <>
                    <h4>
                        {t('billing.card.type')}
                    </h4>
                    <SelectCountry/>
                    <div className={styled.chooseMethod}>
                        <div className={styled.chooseCardType}>
                            {t('billing.card.type')}:
                        </div>
                        <select value={selectedMethod} onChange={(evt) => setMethod(evt.target.value)}>
                            <option>{t('billing.card.method1')}</option>
                            {
                                Methods.map((m, i) => <option key={`option ${i}`} value={m.name}>{m.name}</option>)
                            }
                        </select>
                    </div>
                    <PaymentMethods methods={selectedMethods}/>
                </>}

                {step === `3` && <>
                    <h4>
                        {t('billing.contact')}
                    </h4>
                    <Elements stripe={stripePromise}>
                        <ContactInfo tariff={subscriptions.tariff} authToken={authToken}/>
                    </Elements>
                </>}

                {Number(step) !== 3 && <div className={styled.bottomButtons}>
                    <div className={styled.buttonBack}><Link to={`${PATH}${+step - 1}`}>
                        {t('btn.back')}
                    </Link></div>
                    <div className={styled.buttonSubmit}><Link to={`${PATH}${+step + 1}`}>
                        {t('btn.pay')}
                    </Link></div>
                </div>}
                <p>
                    * {t('subscription.note')}
                </p>
                <p>This site is protected by reCAPTCHA and the Google <Link to="#">Privacy Policy</Link> and <Link to="#">Terms
                    of Service</Link> apply.</p>
                <div className={styled.cat}/>
            </div>
        );
    }
;

export default Payment;
