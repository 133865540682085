import React from "react";
import config from "../../../../etc/config";
import ErrorPage from "../../ErrorPage";
import Loader from "../../../Components/Loader";

const withAccount = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);

            this.state = {
                data: false,
                errorPage: null,
                email: "",
                phone: "+",
                name: "",
                last_name: "",
                date_of_birth: "",
                gender: null,
                education_level: null,
                twitter: "",
                facebook: "",
                linkedIn: "",
                old_password: "",
                new_password: "",
                repeat_password: "",
                educationTypes: [{id: "middle", name: "Среднее образование"}, {id: "special_middle", name: "Среднее специальное образование"},
                    {id: "high", name: "Высшее образование"}
                ],
                anonymous: false,
                codes: [],
                search: [],
                contentType: "info",
                support: null,
                successUpdated: false,
                notification: null
            };
            this.setContentType = this.setContentType.bind(this);
            this.getData = this.getData.bind(this);
        }

        componentDidMount() {
            this.getData();
            document.title = "Account";
            window.scrollTo({top: 0, left: 0});

            this.getCodes();

            if (window.jivo_is_initialize) {
                window.initChat()
            } else {
                document.addEventListener("jivoOnLoad", window.initChat)
            }
        }

        setContentType(i){
            this.setState({contentType: i})
            if(i === "support"){
                this.getDataSupport()
            } else if (i === "notification") {
                this.getNotification()
            }
        }

        toggleSupportItems = (id, enabled) => {
            fetch(`${config.API_PREFIX}user/support`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    enabled: enabled,
                    channel_id: id
                })

            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then ( res => {
                  if(res.status === 0){
                      this.setState({
                          successUpdated: true
                      })

                      setTimeout(() => {
                          this.setState({
                              successUpdated: false
                          })
                      }, 1500)
                  }

                })
                .catch(err => {
                    console.log(err)
                })
        }

        togglePhoneBindToMessenger = (enabled) => {
            fetch(`${config.API_PREFIX}user/messenger`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    enabled: enabled
                })

            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then ( res => {
                    if(res.status === 0){
                        this.setState({
                            successUpdated: true
                        })

                        setTimeout(() => {
                            this.setState({
                                successUpdated: false
                            })
                        }, 1500)
                    }

                })
                .catch(err => {
                    console.log(err)
                })
            console.log(enabled)
        }

        changeMessengerNumber = (messengerId, number) => {
            const support = JSON.parse(JSON.stringify(this.state.support))
            if (support.social.messengers.find(x => x.id === messengerId)) {
                support.social.messengers.find(x => x.id === messengerId).phone = number
            }
            this.setState({support})
        }

        saveMessengerNumber = (messengerId, number, enabled = true) => {
            fetch(`${config.API_PREFIX}user/support/messengers`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    enabled: enabled,
                    messenger_id: messengerId,
                    phone: number
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then ( res => {
                    if(res.status === 0){
                        this.setState({
                            successUpdated: true
                        })

                        setTimeout(() => {
                            this.setState({
                                successUpdated: false
                            })
                        }, 1500)
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        }

        toggleNotification = (id, enabled) => {
            fetch(`${config.API_PREFIX}user/notification-channels`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    enabled: enabled,
                    channel_id: id
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then ( res => {
                    console.log(res)
                    })
                .catch(err => {
                    console.log(err)
                })

        }

        toggleNotificationEvents = (id, enabled, event_id) => {

            const notification = JSON.parse(JSON.stringify(this.state.notification))

            for (let key in notification) {
                if (notification[key].id === id) {
                    const index = notification[key].events.findIndex(x => x.id === event_id)
                    if (index === -1)
                        return
                    notification[key].events[index].enabled = enabled
                    this.setState({notification})
                    break
                }
            }

            fetch(`${config.API_PREFIX}user/notification-channels/events`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    enabled: enabled,
                    channel_id: id,
                    event_id: event_id
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then ( res => {
                    console.log(res)
                })
                .catch(err => {
                    console.log(err)
                })

        }

        componentWillUnmount() {
            document.removeEventListener("jivoOnLoad", window.initChat)
        }

        getCodes = () => {
            fetch("/codes.json")
                .then(res => res.json())
                .then(codes => {
                    this.setState({codes})
                })
                .catch(err => {
                    console.log(err)
                })
        };

        save = () => {

            const {
                email,
                name,
                last_name,
                date_of_birth,
                gender,
                education_level,
                twitter,
                facebook,
                linkedIn,
                phone
            } = this.state;


            fetch(`${config.API_PREFIX}user`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    email,
                    name,
                    last_name,
                    date_of_birth,
                    gender: gender ? gender.id : null,
                    education_level: education_level ? education_level.id : null,
                    twitter,
                    facebook,
                    linkedIn,
                    phone
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if (res.status === 0){
                        if (this.state.anonymous)
                            this.props.appDelegate.logoutWitMessage(email);
                        else {
                            alert("Данные сохранены");
                            this.props.appDelegate.changeUserInfo({ name: "name", value: name });
                        }

                    } else if (res.status === 1) {
                        let message = "";
                        for (let i = 0; i < res.data.length; i++) {
                            message += `\n${res.data[i]}`;
                        }
                        alert(message);
                    } else {
                        alert("Server error!")
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        };

        setCode = () => {
            const {phone, codes} = this.state;
            const search = codes.filter(x => x.dial_code && x.dial_code.replace(" ", "").toString().search(phone.replace("+", "")) !== -1);
            this.setState({ search });
        };

        getData = () => {
            fetch(`${config.API_PREFIX}user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    const {educationTypes} = this.state;
                    this.setState({
                        data: true,
                        email: res.email || "",
                        name: res.name || "",
                        last_name: res.last_name || "",
                        date_of_birth: res.date_of_birth || "",
                        gender: res.gender === "male" ? {id: "male", name: "Мужской"} : res.gender === "female" ? {id: "female", name: "Женский"} : null,
                        education_level: educationTypes.findIndex(x => x.id === res.education_level) === -1 ? null : educationTypes[educationTypes.findIndex(x => x.id === res.education_level)],
                        twitter: res.twitter || "",
                        facebook: res.facebook ||  "",
                        linkedIn: res.linkedIn ||  "",
                        phone: res.phone || "+",
                        anonymous: res.email === null,
                        photo: res.photo || null
                    })
                })
                .catch(err => {
                    console.log(err);
                })
        };

        getDataSupport =() => {
            fetch(`${config.API_PREFIX}user/support`, {
                method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
            }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({ support: { phone: res.find(x => x.id === 1), email: res.find(x => x.id === 2), social: res.find(x => x.id === 3) } })
                })
                .catch(err => {
                    console.log(err);
                })

        }

        getNotification = () => {
            fetch(`${config.API_PREFIX}user/notification-channels`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({notification: { phone:res.find(x => x.id === 1), email: res.find(x => x.id === 2), browser: res.find(x => x.id === 3) }})
                })
                .catch(err => {
                    console.log(err);
                })
        }



        handlerChange = (event) => {
            const name = event.target.name;
            const value = event.target.value;
            this.setState({[name]: value}, () => {
                if (name === "phone")
                    this.setCode();
                if (name === "photo")
                    this.props.appDelegate.changeUserInfo({ name, value });
            })
        };

        savePassword = () => {
            const old_password = this.state.old_password;
            const new_password = this.state.new_password;
            const repeat_password = this.state.repeat_password;
            if (old_password.length === 0 || old_password === " ") {
                alert("Вы не указали старый пароль");
                return
            }
            if (new_password.length === 0 || new_password === " ") {
                alert("Вы не указали новый пароль");
                return
            }
            if (new_password !== repeat_password) {
                alert("Пароли не совпадают");
                return
            }
            const headers = new Headers();
            headers.append("Content-Type", "application/json")
            headers.append("Accept", "application/json")
            headers.append("Authorization", `Bearer ${this.props.appDelegate.state.authToken}`)

            const data = {
                password: old_password,
                new_password: new_password
            };
            fetch(
                `${config.API_PREFIX}user/password`,
                {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(data)
                }
            )
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if(res.status === 0){
                        alert("Пароль изменен")
                    } else if (res.status === 1) {
                        let message = "";
                        for (let i = 0; i < res.data.length; i++) {
                            message += `\n${res.data[i]}`;
                        }
                        alert(message);
                    } else {
                        alert("Server error!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        };

        render() {

            if (this.state.data && !this.state.errorPage) {
                return <Component accountDelegate={this} {...this.props} />
            } else  if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage} />
            } else {
                return <Loader />
            }

        }
    }

    HOC.displayName = `withAccount(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withAccount;
