import Home from "./View/Pages/Home"
import Course from "./View/Pages/Course";
import Theme from "./View/Pages/Theme";
import Account from "./View/Pages/Account";
import PracticalTask from "./View/Pages/PracticalTask";
import ResultPurchase from "./View/Pages/ResultPurchase";
import ContactUs from "./View/Pages/ContactUs";
import OfferContract from "./View/Pages/OfferContract";
import Policy from "./View/Pages/Policy";
import RedirectFromYandex from "./View/Pages/RedirectFromYandex";
import Review from "./View/Pages/Review";
import TechnicalWork from "./View/Pages/TechnicalWork";
import Career from "./View/Pages/Сareer";
import Orders from "./View/Pages/Orders";
import OrderDetail from "./View/Pages/OrderDetail";
import Vacancy from "./View/Pages/Vacancy";
import Pexeso from "./View/Pages/Games/Pexeso";
import RedirectBilling from "./View/Pages/RedirectBilling/RedirectBilling";
import NewBilling from "./View/Pages/NewBilling/NewBilling";
import Dashboard from "./View/Pages/Dashboard/Dashboard";
import Feedback from "./View/Components/Feedback/Feedback";
import AllCourses from "./View/Pages/AllCourses/AllCourses";
import PracticesNew from "./View/Pages/PracticesNew/Practices";
import Videos from "./View/Pages/Videos/Videos";
import Tasks from "./View/Pages/Tasks/Tasks";
import MainPage from "./View/Pages/MainPage";
import Certificates from "./View/Pages/Certificates/Certificates";
import AboutCourse from "./View/Pages/AboutCourse/AboutCourse";
import FAQ from "./View/Pages/FAQ/FAQ";
import Blog from "./View/Pages/Blog/Blog";
import ThemeBlog from "./View/Pages/ThemeBlog/ThemeBlog";
import ErrorPage from "./View/Pages/ErrorPage";
import Billing from "./View/Pages/Billing"
import ConditionsDraw from "./View/Pages/Billing/components/ConditionsDraw/ConditionsDraw";
import PaymentCard from "./View/Pages/Billing/components/PaymentCard";
import {TESTS_ROUTE} from './utils/consts';
import Description from './View/Pages/Description'
import Roulette from './View/Pages/Roulette/Roulette';

const routes = [
    {
        path: "/main",
        exact: true,
        component: MainPage,
        title: "Main"
    },
    {
        path: "/faq",
        exact: true,
        component: FAQ,
        title: "FAQ"
    },
    {
        path: "/blog",
        exact: true,
        component: Blog,
        title: "Blog"
    },
    {
        path: "/blog/posts/:category/:post",
        exact: true,
        component: ThemeBlog,
        title: "Post"
    },
    {
        path: "/billing",
        exact: true,
        component: Billing,
        title: "Подписка"
    },
    {
        path: "/conditions",
        exact: true,
        component: ConditionsDraw ,
        title: "Подписка"
    },
    {
        path: "/roulette",
        exact: true,
        component: Roulette,
        title: "Рулетка"
    },
    {
        path: "/payment/:step?",
        exact: true,
        component: PaymentCard,
        title: "Оплата"
    },
    {
        path: "/all-courses",
        exact: true,
        component: AllCourses,
        title: "Template"
    },
    {
        path: "/",
        exact: true,
        component: Dashboard,
        title: "Dashboard"
    },
    {
        path: "/description/:id",
        exact: true,
        component: Description,
        title: "Description"
    },
    {
        path: "/course",
        exact: true,
        component: Home,
        title: "Template"
    },
    {
        path: "/about-course/:id",
        exact: true,
        component: AboutCourse,
        title: "Описание"
    },
    {
        path: "/about-test/:id",
        exact: true,
        component: AboutCourse,
        title: "Описание"
    },
    {
        path: "/about-practice/:id",
        exact: true,
        component: AboutCourse,
        title: "Описание"
    },
    {
        path: "/helper",
        exact: true,
        component: Feedback,
        title: "Template"
    },
    {
        path: "/description/course/:id",
        exact: true,
        component: Description,
        title: "Содержание"
    },
    {
        path: "/description/test/:id",
        exact: true,
        component: Description,
        title: "Содержание"
    },
    {
        path: "/description/practices/:id",
        exact: true,
        component: Description,
        title: "Содержание"
    },
    {
        path: "/course/:id/:level?/:stage?",
        exact: true,
        component: Course,
        title: "Course"
    },
    {
        path: "/practices/:id/:level?/:stage?",
        exact: true,
        component: Course,
        title: "Practice"
    },
    {
        path: "/theme/:id",
        exact: true,
        component: Theme,
        title: "Theme"
    },
    {
        path: "/practice-theme/:id",
        exact: true,
        component: Theme,
        title: "Theme"
    },
    {
        path: "/account",
        exact: true,
        component: Account,
        title: "Account"
    },
    {
        path: "/practical-task/:id",
        exact: true,
        component: PracticalTask,
        title: "PracticalTask"
    },
    {
        path: "/project-practical-task/:id",
        exact: true,
        component: PracticalTask,
        title: "PracticalTask"
    },
    {
        path: "/billing/:id?",
        exact: true,
        component: Billing,
        title: "Billing"
    },
    {
        path: "/redirect-billing/:id",
        exact: true,
        component: RedirectBilling,
        title: "RedirectBilling"
    },
    {
        path: "/payment/:result",
        exact: true,
        component: ResultPurchase,
        title: "Billing"
    },
    {
        path: "/tests-billing",
        exact: true,
        component: NewBilling,
        title: "Billing"
    },
    {
        path: "/contact-us",
        exact: false,
        component: ContactUs,
        title: "Контакты"
    },
    {
        path: "/offer",
        exact: false,
        component: OfferContract,
        title: "Договор оферты"
    },
    {
        path: "/policy",
        exact: false,
        component: Policy,
        title: "Политика конфидициальности"
    },
    {
        path: "/subscription",
        exact: false,
        component: NewBilling,
        title: "Управление подпиской"
    },
    {
        path: "/trial/group/:id",
        exact: false,
        component: RedirectFromYandex,
        title: ""
    },
    {
        path: "/review/:id",
        exact: false,
        component: Review,
        title: ""
    },
    {
        path: "/technical-work",
        exact: false,
        component: TechnicalWork,
        title: ""
    },
    {
        path: "/practices",
        exact: true,
        component: PracticesNew,
        title: ""
    },
    {
        path: "/webinars",
        exact: true,
        component: Videos,
        title: ""
    },
    {
        path: "/jobs",
        exact: true,
        component: Career,
        title: ""
    },
    {
        path: "/orders",
        exact: true,
        component: Orders,
        title: ""
    },
    {
        path: "/certificate",
        exact: true,
        component: Certificates,
        title: ""
    },
    {
        path: "/orders/:id",
        exact: true,
        component: OrderDetail,
        title: ""
    },
    {
        path: "/jobs/:id",
        exact: true,
        component: Vacancy,
        title: ""
    },
    {
        path: "/games",
        exact: true,
        component: Pexeso,
        title: "Игра память"
    },
    {
        path: TESTS_ROUTE,
        exact: true,
        component: Tasks,
        title: "Задачи на удачу"
    },
    {
        path: "/error",
        exact: true,
        component: ErrorPage,
        title: "Ошибка"
    }

];

export default routes
