import React from 'react'
import styled from './ContactSocialNetwork.module.scss'
import {useTranslation} from "react-i18next";

const ContactSocialNetwork = () => {
    const { t } = useTranslation();
    return <div className={styled.wrapper}>
        <div className={styled.container}>
            <h2>
                {t('socials.title')}
            </h2>
            <div className={styled.links}>
                <a href="https://www.instagram.com/educat.courses/">Instagram</a>
                <a href="https://www.youtube.com/channel/UCoL3qFc1sS8iS_P30bdS7VA?view_as=subscriber">YouTube</a>
                <a href="https://www.facebook.com/educat.courses/">Facebook</a>
                <a href="https://vk.com/educat.courses">Вконтакте</a>
                <a href="https://zen.yandex.ru/id/5ec3e7407f5192548fb2a990">Яндекс Дзен</a>
            </div>
        </div>
    </div>
}

export default ContactSocialNetwork