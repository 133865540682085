import React, {useMemo} from 'react'
import styled from './style.module.scss'
import AskedQuestions from '../../../../Components/AskedQuestions'
import {useSelector} from 'react-redux'
import Loader from '../../../../Components/Loader'
import {RootState} from '../../../../../redux/reducers'
import {SubscriptionsType} from '../../../../../types/app-delegate'
import {Link} from 'react-router-dom'
import {questions} from '../FAQ/questions';
import {useTranslation} from "react-i18next";

type Props = {
    subscriptions: SubscriptionsType;
}

const PaidSubscription = ({subscriptions}: Props) => {
    const tariffs = useSelector((state: RootState) => state.subscriptionReducer.subscriptions);
    const { t } = useTranslation();
    const infoCourses = [
        t('subscription.payed.props1'),
        t('subscription.payed.props2'),
        t('subscription.payed.props3'),
        t('subscription.payed.props4'),
        t('subscription.payed.props5')
    ]
    const currentTariff = useMemo(() => {
        return subscriptions.id === 2 ? 1 : 0
    }, [subscriptions])

    return tariffs.length !== 0 ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <h1>
                {t('subscription.payed.title')}
            </h1>
            <p className={styled.parOne}>
                {t('subscription.payed.desc1')}
            </p>
            <p className={styled.parTwo}>
                {t('subscription.payed.desc2')}
            </p>

            <div className={styled.contentSubscriptions}>
                <div
                    className={currentTariff ? styled.currentBlockSubscriptions : `${styled.currentBlockSubscriptions} ${styled.activeBlock}`}>
                    <span className={styled.endSubscription}>
                        {t('subscription.payed.for')} {subscriptions.end} {t('subscription.payed.year')}
                    </span>
                    <h4 className={styled.currentTitle}>
                        {t('subscription.payed.plan.title')}
                    </h4>
                    <div className={styled.tariffPlan}>
                        <h4>{tariffs[0].prices[currentTariff].title}</h4>
                        <span>{tariffs[0].prices[currentTariff].price} $</span>
                    </div>
                    {subscriptions.id === 2 &&
                    <span className={styled.yearTariff}>({tariffs[0].prices[currentTariff].price} {t('subscription.payed.per.year')})</span>
                    }
                    <a className={styled.footerBlock} href={subscriptions.id === 2 ? '/billing/3' : '/'}>
                        {t('subscription.payed.cta1')}
                    </a>
                </div>

                {subscriptions.id === 2 && <div
                    className={currentTariff ? styled.yearBlockSubscriptions : `${styled.yearBlockSubscriptions} ${styled.activeBlock}`}>
                    <h4 className={styled.currentTitle}>
                        {t('subscription.payed.cta2')}
                    </h4>
                    <div className={styled.tariffPlan}>
                        <h4>{tariffs[0].prices[0].title}</h4>
                        <span>{tariffs[0].prices[0].price} $</span>
                    </div>
                    <span className={styled.yearTariff}>
                        {t('subscription.payed.saving')}
                    </span>
                    <a className={styled.footerBlock} href={'/payment/2'}>
                        {t('subscription.payed.change')}
                    </a>
                </div>}
            </div>
            <a className={styled.cancelLink} href={'/account'}>
                {t('subscription.payed.disable')}
            </a>
            <div
                className={styled.coursesEducat}
                style={{display: subscriptions.id === 2 ? "none" : "block"}}
            >
                <h3>
                    {t('subscription.payed.props')}
                </h3>
                <div className={styled.listsLearn}>
                    {infoCourses.map((item, index) => <span key={index}>{item}</span>)}
                </div>
            </div>

            <div className={styled.allLinks}>
                <Link to="/all-courses">
                    {t('subscription.payed.courses')}
                </Link>
                <Link to="/tests">
                    {t('subscription.payed.tasks')}
                </Link>
                <Link to="/practices">
                    {t('subscription.payed.practices')}
                </Link>
                <Link to="/games">
                    {t('subscription.payed.games')}
                </Link>
            </div>

        </div>
        <div className={styled.giftBlock}>
            <div className={styled.giftIntoBlock}>
                <div className={styled.giftCat}/>
                <div className={styled.giftText}>
                    <p>
                        <span>
                            {t('subscription.roulette')}
                        </span>
                    </p>
                    <Link to='/conditions'>
                        {t('subscription.conditions')}
                    </Link>
                </div>
            </div>
        </div>
        <AskedQuestions questions={questions}/>
    </div> : <Loader/>
}

export default PaidSubscription
