import React from 'react'
import s from '../../Task/styled.module.scss'
import styled from './AddText.module.scss'
import Input from "./components/Input"

const AddText = ({writeText, isCorrectTask, isViewAnswer, variants, passedRightAnswer, rightAnswer, addText}) => {
    let count = [];
    const flatMap = (array, fn) => {
        let result = [];
        for (let i = 0; i < array.length; i++) {
            let mapping = fn(array[i], i);
            result = result.concat(mapping);
        }
        return result;
    }

    let array = variants.map((line, index) => {
        line = flatMap(line.split('##'), function (part, idx) {
            if (count[index] !== undefined) {
                count[index] += 1
            } else {
                count[index] = 0
            }

            let indexInput = index > 0 ? count.reduce((acc, item, ind) => {
                if (ind < index) {
                    acc += item
                }
                return acc
            }, 0) + idx : idx;

            return [part, <Input key={indexInput}
                                 isCorrectTask={isCorrectTask}
                                 index={indexInput}
                                 passedRightAnswer={passedRightAnswer}
                                 isViewAnswer={isViewAnswer}
                                 rightAnswer={rightAnswer}
                                 changeText={(text) => {
                                        return writeText(text, indexInput)
                                    }
                                 }
                                 addText={addText}
            />]
        })
        line.pop()
        return line
    })

    return (<div className={s.containerTask}>
        {array.map((item, index) => <div key={index} className={styled.writeTextMechanic}>
            <span>{index + 1}</span>
            <span className={styled.rowTask}>{item}</span>
        </div>)}
    </div>)
}

export default AddText;
