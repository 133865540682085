import React from 'react';
import styles from './Review.module.css';

const Review = (props) => {
    return (
        <div>
            <h4 className={`${styles.title} ${styles[props.color]}`}>{props.name}</h4>
            <p className={styles.text}>{props.text}</p>
        </div>
    )
}

export default Review;