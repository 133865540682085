import React from 'react';
import Card from "./Card/Card";
import style from './Cards.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {finishGameThunk} from "../../../../redux/gameReducer";
import FinishedGame from "../FinishedGame/FinishedGame";
import {useTranslation} from "react-i18next";

const Cards = props => {
    let dispatch = useDispatch()
    const { t } = useTranslation();
    const tags = useSelector(state => state.gameReducer.game.tags)
    const countGuessed = useSelector(state => state.gameReducer.game.countGuessed)
    const countStep = useSelector(state => state.gameReducer.game.countStep)
    const textStep = useSelector(state => state.gameReducer.game.textStep)
    const column = useSelector(state => state.gameReducer.game.column)
    const level = useSelector(state => state.gameReducer.game.level)
    const isAllGuessedCard = useSelector(state => state.gameReducer.game.isAllGuessedCard)


    let finishGame = () => {
        dispatch(finishGameThunk())
    }

    let columnGrid = '';
    let rowGrid = ''

    for (let i = 1; i <= column; i++) {
        if(tags.length === 24) {
            rowGrid = '80px 80px 80px 80px 80px 80px'
        } else {
            rowGrid += '80px '
        }
        columnGrid += '80px '
    }

    let columnGridStyle = columnGrid.trim()
    let rowGridStyle = rowGrid.trim()

    let opacity = '0';
    let filter = 'none';

    if(isAllGuessedCard) {
        opacity = '0.2';
        filter = 'blur(5px)';
    } else {
        opacity = '1';
        filter = 'none';
    }

    const divWrapperStyle = {
        display: 'grid',
        gridTemplateColumns: columnGridStyle,
        gridTemplateRows: rowGridStyle,
        opacity: opacity,
        filter: filter,
        zIndex: '1'
    }

    let divBlockLevelGame = {
        backgroundColor: level.levelColor,
        width: '70px',
        height: '20px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    let cards = tags.map(({tag, id, isActive, isGuessed, classListCard}, index) => <Card
        tag={tag}
        key={index}
        index={index}
        isActive={isActive}
        isGuessed={isGuessed}
        id={id}
        activeCard={props.activeCard}
        classListCard={classListCard}
    />)


    return <div className={style.wrapper}>
        {isAllGuessedCard && <FinishedGame />}
        <div className={style.divWrapperStyle} style={divWrapperStyle}>
            {cards}
        </div>
        <div className={style.resultGameWrapper}>
            <p className={style.divCard}>{countGuessed} / {tags.length}</p>
            <div className={style.blockLevelGame}>
                <div style={divBlockLevelGame}><p>{level.levelGame}</p></div>
            </div>
            <h1>
                {t('game.single.title')}
            </h1>
            <p className={style.infoRuleGame}>
                {t('game.single.desc')}
            </p>
            <div className={style.blockCountStep}>
                {!countStep ? <h4>{t('game.single.cta')}</h4> : <h4>{t('game.single.steps')} <span>{countStep} {textStep}</span></h4>}
            </div>
            <button onClick={finishGame}>
                {t('game.single.btn')}
            </button>

        </div>
    </div>
}

export default Cards;