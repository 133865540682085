import React, {useState, useRef, useEffect, Fragment} from "react"
import {Link} from "react-router-dom"

const Stages = ({stage, courseDelegate, available, isStarted, isIsLast, appDelegate, levelIndex, stageIndex}) => {
    const [isOpen, setOpen] = useState(false)
    const [initialized, setInit] = useState(false)
    const [containerHeight, setHeight] = useState(0)

    const refContainer = useRef()

    useEffect(() => {
        setHeight(refContainer.current.scrollHeight)
        if (courseDelegate.state.activeStage.toString() === stage.id.toString() && !initialized) {
            setOpen(true)
            setInit(true)
        }

    })


    const accessibility = (idx) => {
        if (!available || !isStarted)
            return false
        if (idx === 0) {
            return true
        }
        return stage.themes[idx - 1].done
    }


    return (
        <div className={"stages-box"}>
            <div onClick={() => setOpen(!isOpen)} className={isIsLast || isOpen ? "box last" : "box"}>
                <div className={"left-stages"}>
                    {stage.themes.findIndex(x => x.done === false) > -1 || !stage.done ? (
                        <img alt={""}
                             style={{transform: `rotate(${isOpen ? `0deg` : `180deg`})`, transition: "all 0.2s linear"}}
                             src={"/Images/arrow-black.svg"}/>
                    ) : (
                        <div>
                            <img alt={""} src={"/Images/yes.svg"}/>
                        </div>
                    )}
                    <div className={"stages-name"}>
                        {stage.stage_name}
                    </div>
                </div>
                <div className={"right"}>
                    {stage.notify || (levelIndex === 0 && stageIndex === 0 && stage.themes[0] && !stage.themes[0].done) ? (
                        <div className={"notify-top"}/>
                    ) : ""}
                </div>
            </div>
            <div style={{maxHeight: `${isOpen ? `${containerHeight}px` : `0px`}`}} ref={refContainer}
                 className={"themes-box"}>
                {stage.themes.map((theme, index) => (
                    <Link
                        to={available && accessibility(index) ? `/${courseDelegate.isPractice ? "practice-theme" : "theme"}/${theme.id}?course_id=${courseDelegate.state.data.id}&level_id=${courseDelegate.state.data.levels[levelIndex].id}&stage_id=${stage.id}` : ""}
                        onClick={(e) => !available || !accessibility(index) ? e.preventDefault() : true} key={index}
                        className={"themes-box__cell"}>
                        <div className={"left"}>
                            <div>
                                {index === 0 && !theme.done && levelIndex === 0 && stageIndex === 0 ? (
                                    <div className={"notify-theme"}/>
                                ) : (
                                    <img
                                        src={!available || !accessibility(index) ? "/Images/lock.svg" : !theme.done ? "/Images/no-black.svg" : "/Images/yes-black.svg"}
                                        alt={""}/>
                                )}

                            </div>
                            <div
                                className={!available || !accessibility(index) ? "themes-name lock" : "themes-name"}>{theme.theme_name}</div>
                        </div>
                        {!available || !accessibility(index) ? "" : (
                            <div className={"right"}>
                                {theme.score.received} из {theme.score.max} баллов
                            </div>
                        )}
                    </Link>
                ))}
                {/*{((appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.items.includes("practices"))) || !available || levelIndex === 0 ? (*/}
                    {appDelegate.state.user.subscriptions || !available || levelIndex === 0 ? (
                            <Link
                        to={available && accessibility(stage.themes.length - 1) ? `/${courseDelegate.isPractice ? "project-practical-task" : "practical-task"}/${stage.id}?course_id=${courseDelegate.state.data.id}&level_id=${courseDelegate.state.data.levels[levelIndex].id}` : ""}
                        onClick={(e) => !available || !accessibility(stage.themes.length - 1) ? e.preventDefault() : true}
                        className={"task text"}>
                        <img
                            src={!available || !accessibility(stage.themes.length) ? "/Images/lock-task.svg" : !stage.done ? "/Images/no-white.svg" : "/Images/yes-white.svg"}
                            alt={""}/>
                        <div className={"practice-title"}>Практическое задание</div>
                        {stage.notify ? (
                            <div className={"notify"}/>
                        ) : ""}
                    </Link>
                ) : (
                    <Fragment>
                        <div className={"none text"}>
                            <div className={"none__left"}>
                                <img
                                    src={!available || !accessibility(stage.themes.length) ? "/Images/lock-task.svg" : !stage.done ? "/Images/no-white.svg" : "/Images/yes-white.svg"}
                                    alt={""}/>
                                <div className={"practice-title"}>Практическое задание</div>
                            </div>
                            <Link
                                // to={appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date ? '/subscription' : `/billing/${courseDelegate.state.data.id}`}>
                                to={`/billing/`}>
                                <button>Включить опцию</button>
                            </Link>
                        </div>
                        <div className={"margin"} style={{minHeight: "8px", width: "100%"}}/>
                    </Fragment>

                )}

            </div>
        </div>
    )
}
export default Stages
