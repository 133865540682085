import React, {useEffect, useMemo, useState} from 'react'
import styled from './ThemeTask.module.scss'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import {useDispatch, useSelector} from "react-redux"
import Theory from "../Theory"
import Task from "../Task"
import EndPageLevel from "../EndPageLevel"
import {changeNextTask, closeThemeTest, getTestThunk} from '../../../../../redux/actions/tests';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ThemeTask = ({appDelegate, id}) => {
    const tasksData = useSelector(state => state.tasksReducer)
    const [showTask, setShowTask] = useState(false)
    const [isViewAnswer, setIsViewAnswer] = useState(false)
    const dispatch = useDispatch()
    const handleShowTask = () => {
        setShowTask(prev => !prev)
    };
    useEffect(() => {
        dispatch(getTestThunk(id, appDelegate.state.authToken))
    }, [])

    useEffect(() => {
        if (tasksData.currentTask?.is_correct === 1) {
            setShowTask(true)
            setIsViewAnswer(true)
        } else if (tasksData.currentTask?.is_correct === true
            || tasksData.currentTask?.is_correct === false
            || tasksData.currentTask?.theory === null) {
        } else {
            setShowTask(false)
            setIsViewAnswer(false)
        }
    }, [tasksData.currentTask])

    const progressTheme = useMemo(() => {
        return tasksData.currentTheme?.tasks_correct * 100 / tasksData.currentTheme?.tasks_total
    }, [tasksData.currentTheme?.tasks_correct])

    const switchNextTask = () => {
        dispatch(changeNextTask(appDelegate.state.user.subscriptions))
        setShowTask(false)
    };

    return <Dialog fullScreen open={tasksData.isShowTheme} onClose={closeThemeTest}
                   TransitionComponent={Transition}>
        <div className={styled.wrapper}>
            <div className={styled.wrapperContent}>
                {showTask ? <Task task={tasksData.currentTask}
                                  isViewAnswer={isViewAnswer}
                                  switchNextTask={switchNextTask}
                                  progressTheme={progressTheme}
                                  infoUser={appDelegate.state}
                                  id={id}
                    />
                    : <Theory progressTheme={progressTheme}
                              task={tasksData.currentTask}
                              titleTheme={tasksData.currentTheme?.name}
                              authToken={appDelegate.state.authToken}
                              handleShowTask={handleShowTask}
                              setShowTask={setShowTask}
                              id={id}
                    />
                }
            </div>
        </div>
        <EndPageLevel isShowEndPageLevel={tasksData.isPageSuccessPassedTheme}
                      progressTheme={progressTheme}
                      titleCurrentTheme={tasksData.currentTheme}
                      id={id}
                      authToken={appDelegate.state.authToken}
                      handleShowTask={setShowTask}
        />
    </Dialog>
}

export default ThemeTask;
