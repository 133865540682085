import React, {useMemo} from 'react'
import s from '../../Task/styled.module.scss'
import styled from './ChangeOneAnswer.module.scss'

const ChangeOneAnswer = ({
                             rightAnswer,
                             oneAnswer,
                             changeAnswer,
                             isCorrectTask,
                             isViewAnswer,
                             passedRightAnswer,
                             variants
                         }) => {
    let answerTask = isViewAnswer ? passedRightAnswer : rightAnswer
    const objectVariants = useMemo(() => {
        if (variants) {
            return variants.map(variant => ({
                title: variant,
                isActive: oneAnswer.includes(variant) && oneAnswer.length === variant.length,
                isCorrect: answerTask && answerTask.includes(variant)
            }))
        }
        return []
    }, [variants, rightAnswer, oneAnswer])

    return (
        <div className={s.containerTask}>
            <div className={styled.radioWrapper}>
                {objectVariants.map(({title, isCorrect, isActive}, index) => (
                    <div key={index} className={isViewAnswer
                        ? isCorrect
                            ? styled.radioInput + ' ' + styled.isRight
                            : styled.radioInput
                        : isCorrect === undefined
                            ? styled.radioInput
                            : isCorrectTask
                                ? isActive
                                    ? styled.radioInput + ' ' + styled.isRight
                                    : styled.radioInput
                                : isActive
                                    ? styled.radioInput + ' ' + styled.isFalse
                                    : styled.radioInput} onClick={() => changeAnswer(title)}>

                        {!isViewAnswer && isCorrectTask === 0
                            ? <input id={index} name="radio-group" type="radio" />
                            : isCorrectTask === false
                                ? <input id={index} name="radio-group" type="radio" checked={!isCorrect && isActive} disabled />
                                : <input id={index} name="radio-group" type="radio" checked={isCorrect && isActive || isCorrect} disabled />

                        }
                        <label htmlFor={index}>{title}</label>
                    </div>))}
            </div>
        </div>
    );
}

export default ChangeOneAnswer;
