import React, {useRef, useState} from 'react'
import s from './Feedback.module.scss'
import QuestionsAndTarif from '../QuestionsAndTarif/QuestionsAndTarif'
import ReCAPTCHA from 'react-google-recaptcha'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../Loader'
import ContactSocialNetwork from '../ContactSocialNetwork/ContactSocialNetwork'
import {sendDataFeedbackThunk, setIsStatusFeedback} from '../../../redux/actions/subscription';
import {useTranslation} from "react-i18next";

const Feedback = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isNotRobot, setIsNotRobot] = useState(false);
    const isStatusFeedback = useSelector(state => state.subscriptionReducer.isStatusFeedback)
    const isLoadingFeedback = useSelector(state => state.subscriptionReducer.isLoadingFeedback)
    const dispatch = useDispatch()
    const invertIsChecked = (stateSetFunc) => stateSetFunc((prevState) => !prevState);
    const { t } = useTranslation();

    const purchasedTarif = props.purchasedTarif;
    // Если подписка приобретена - в пропсы передается id купленного тарифа - индекс в массиве тарифов

    const formRef = useRef();

    const linkToMainFeedback = () => {
        setIsChecked(false)
        setIsNotRobot(false)
        dispatch(setIsStatusFeedback(null))
    }

    function onChange(value) {
        setIsNotRobot(true)
        // console.log("Captcha value:", value);
    }

    const onSubmitHandler = (evt) => {
        evt.preventDefault();

        // Name

        const clearCustomValidityName = () => {
            name.setCustomValidity('');
            name.removeEventListener('input', clearCustomValidityName);
        };

        const validationHandlerName = () => {
            name.addEventListener('input', clearCustomValidityName);
        };

        // Phone

        const clearCustomValidityPhone = () => {
            phone.setCustomValidity('');
            phone.removeEventListener('input', clearCustomValidityPhone);
        };

        const validationHandlerPhone = () => {
            phone.addEventListener('input', clearCustomValidityPhone);
        };

        // Email

        const clearCustomValidityEmail = () => {
            email.setCustomValidity('');
            email.removeEventListener('input', clearCustomValidityEmail);
        };

        const validationHandlerEmail = () => {
            email.addEventListener('input', clearCustomValidityEmail);
        };

        // Message

        const clearCustomValidityMessage = () => {
            message.setCustomValidity('');
            message.removeEventListener('input', clearCustomValidityMessage);
        };

        const validationHandlerMessage = () => {
            message.addEventListener('input', clearCustomValidityMessage);
        };

        // Validation

        const name = formRef.current.elements.name;
        if (!(/^[а-яёa-z ]*$/i.test(name.value)) || !name.value) {
            name.setCustomValidity(`Please check the required field «name». It must contain letters only`);
            validationHandlerName();
        }

        const phone = formRef.current.elements.phone;
        if (!(/^[0-9 +()]*$/i.test(phone.value)) || !phone.value) {
            phone.setCustomValidity(`Please check the required field «phone number». It must contain «+» and digits only`);
            validationHandlerPhone();
        }

        const email = formRef.current.elements.email;
        if (!email.value) {
            email.setCustomValidity(`This field is required`);
            validationHandlerEmail();
        }

        const message = formRef.current.elements.message;
        if (!message.value) {
            message.setCustomValidity(`This field is required`);
            validationHandlerMessage();
        }

        dispatch(sendDataFeedbackThunk(props.appDelegate.state.authToken, name.value, phone.value, email.value, message.value))
    };

    return !isLoadingFeedback ? <div className={s.wrapper}>
        <h1>
            {t('feedback.title')}
        </h1>
        <h5>
            {t('feedback.subtitle1')}
        </h5>
        <h6>
            {t('feedback.subtitle2')}
        </h6>

        <div className={s.formAndMoney}>
            {isStatusFeedback !== 0
                ? <form onSubmit={onSubmitHandler} ref={formRef}>
                    <h4>
                        {t('feedback.form.desc')}
                    </h4>
                    <div className={s.nameAndPhone}>
                        <label>
                            *{t('feedback.form.name')}
                            <input type="text" name="name"/>
                        </label>
                        <label>
                            *{t('feedback.form.phone')}
                            <input type="tel" name="phone"/>
                        </label>
                    </div>

                    <label>
                        *{t('feedback.form.email')}
                        <input type="email" name="email"/>
                    </label>
                    <label>
                        *{t('feedback.form.msg')}
                        <textarea rows="5" name="message"/>
                    </label>
                    <label className={s.checkLabel}>
                        <input type="checkbox"
                               checked={isChecked}
                               onChange={() => invertIsChecked(setIsChecked)}
                        />
                        {t('feedback.form.agree')}
                    </label>
                    <div className={s.captchaAndSubmit}>
                        <ReCAPTCHA
                            sitekey="6LfqwYkaAAAAANJC4n72oluJ9CAopCoLBwewOP8x"
                            onChange={onChange}
                        />
                        <button
                            type="submit"
                            disabled={!(isNotRobot && isChecked)}>
                            {t('btn.send')}
                        </button>
                    </div>
                </form>
                : <div className={s.successSend}>
                    <h2>
                        {t('feedback.response.title')}
                    </h2>
                    <h5>
                        {t('feedback.response.msg')}
                    </h5>
                    <span onClick={linkToMainFeedback}>
                        {t('feedback.response.reset')}
                    </span>
                </div>}
            <div className={s.moneyTransfer}>
                <h2>Transfer Money Technologies s.r.o.</h2>
                <h3>
                    {t('feedback.address')}
                </h3>
                <p>
                    {t('feedback.cta')}
                </p>
                <p>
                    {t('feedback.hours')}
                </p>
                <div className={s.email}>
                    <div className={s.emailIcon}></div>
                    <a href="mailto:info@educat.courses">info@educat.courses</a>
                </div>
            </div>
        </div>
        <ContactSocialNetwork />
        <QuestionsAndTarif
            purchasedTarif={purchasedTarif}
        />
    </div> : <Loader />
}

export default Feedback;
