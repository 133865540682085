export default function useCheckAdmin(email: string) {
    const admins = [
        'audiquattro66077@gmail.com',
        'robystudio@gmail.com',
        'skeli.lilit@gmail.com',
        'alexandr1ivkin@gmail.com',
        'dacasvv@gmail.com',
        'pavlo.tsimbota@gmail.com'
    ]

    return admins.some(item => item === email)
};
