import React from 'react'

import {NavLink} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const MenuMobileAccount = ({appDelegate}) => {
  const { t } = useTranslation();
  return (
    <div className="header-mobile-account">
      <div className="menu-content">
        <div className="menu-box">
          <NavLink className={'button-menu'}
                   onClick={appDelegate.toggleMenuAccount}
                   to={'/account'}>
            <img className={'icon'} src="/Images/lada.svg" alt={'😼'}/>
            <span>
              {t('nav.profile')}
            </span>
          </NavLink>
          <NavLink className={'button-menu'}
                   onClick={appDelegate.toggleMenuAccount}
                   to={'/blog'}>
            <img className={'icon'} src="/Images/lada.svg" alt={'😼'}/>
            <span>
              {t('nav.blog')}
            </span>
          </NavLink>
          <NavLink className={'button-menu'}
                   onClick={appDelegate.toggleMenuAccount}
            // to={appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date ? "/subscription"
            // : "/billing"}>
                   to={'/billing'}>
            <img className={'icon'} src="/Images/check-big.svg" alt={'😼'}/>

            <span>
              {t('header.signup')}
            </span>
          </NavLink>
          <NavLink className={'button-menu'}
                   onClick={appDelegate.toggleMenuAccount}
                   onMouseDown={appDelegate.logout}
                   to={'/practices'}>
            <img className={'icon'} src="/Images/logout.svg" alt={'😼'}/>
            <span>
              {t('header.exit')}
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  )

}

export default MenuMobileAccount