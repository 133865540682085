import React from 'react'
import s from './Task.module.scss'
import {useHistory} from "react-router"
import {Link} from 'react-router-dom'
import {declOfNum} from "../../../../../../helpers/declOfNum";
import {useTranslation} from "react-i18next";

const Task = ({id, title, participants, icon, questions}) => {
    const history = useHistory()
    const changePathToTest = (id) => {
        let path = `/description/test/${id}`
        history.push(path)
    };
    const { t } = useTranslation();

    return <div className={s.wrapper}>
        <div className={s.title}>
            <img src={icon} alt=""/>
            <p className={s.title}>{title}</p>
        </div>
        <p className={s.description}>
            {t('task.learn')} {participants} {t('task.users')}. {questions} {declOfNum(questions, [t('task.question1'), t('task.question2'), t('task.question3')])} {t('task.theory')}
        </p>
        <div className={s.linkAndButton}>
            <Link to={`/about-test/${id}`}>
                {t('task.desc')}
            </Link>
            <button onClick={() => changePathToTest(id)}>
                {t('task.start')}
            </button>
        </div>
    </div>
};

export default Task;
