import React from 'react'
import s from './ConditionsDraw.module.scss'
import classnames from "classnames";
import {questions} from "../FAQ/questions";
import FAQ from "../FAQ/FAQ";

const subscriptions = {
    [2]: {period: "1 месяц"},
    [3]: {period: "1 год"}
}

const ConditionsDraw = ({appDelegate}) => {

    const mockFeatures = {
        title: 'Условия розыгрыша',
        features: [
            `Учебный контент (видео и текстовый)`,
            `Реальные проекты`,
            `Возможность получать ответы`,
            `Доступ к пройденным материалам`,
            `Обучение через тестирование`
        ],
        steps: [
            [
                {
                    title: 'Оформите подписку в Educat на 1 месяц или 1 год',
                    description: 'К участию в розыгрыше допускаются пользователи, обучающиеся на курсах Educat.',
                    description1: null,
                    info: {
                        text: 'Оформите тарифный план, чтобы участвовать в розыгрыше',
                        text1: null,
                        className: 'linkDescription'
                    }
                },
                {
                    title: 'Проходите курсы, выполняйте практику и решайте задачи',
                    description: 'Будьте увлечены и активны обучением на платформе, мы обязательно оценим ваши старания и отметим их призами.',
                    description1: null,
                    info: {
                        text: 'У вас пройдено _XXX_ курсов и _XXX_ задач',
                        text1: null,
                        className: null
                    }
                },
                {
                    title: 'Выигрывайте подарки от Educat',
                    description: 'Оформляя подписку в Educat, вы автоматически становитесь участником наших розыгрышей.',
                    description1: '*Розыгрыш проводится 2 раза за месяц. Для тех, кто приобрел подписку - 1-го числа и для тех, кто достиг прогресса в учёбе - 15-го числа каждого месяца.',
                    info: null
                }
            ],
            [
                {
                    title: 'Оформите подписку в Educat на 1 месяц или 1 год',
                    description: 'К участию в розыгрыше допускаются пользователи, обучающиеся на курсах Educat.',
                    description1: null,
                    info: {
                        text: `У вас оформлена подписка на ${subscriptions[appDelegate.state.user.subscriptions.id || 2].period}`,
                        text1: `Действует до ${appDelegate.state.user.subscriptions.end || null}`,
                        className: 'linkDescription'
                    }
                },
                {
                    title: 'Проходите курсы, выполняйте практику и решайте задачи',
                    description: 'Будьте увлечены и активны обучением на платформе, мы обязательно оценим ваши старания и отметим их призами.',
                    description1: null,
                    info: {
                        text: 'У вас пройдено _XXX_ курсов ',
                        text1: 'и _XXX_ задач',
                        className: null
                    }
                },
                {
                    title: 'Выигрывайте подарки от Educat',
                    description: 'Оформляя подписку в Educat, вы автоматически становитесь участником наших розыгрышей.',
                    description1: '*Розыгрыш проводится 2 раза за месяц. Для тех, кто приобрел подписку - 1-го числа и для тех, кто достиг прогресса в учёбе - 15-го числа каждого месяца.',
                    info: null
                }
            ]
        ]
    };

    const subscription = appDelegate.state.user.subscriptions.length !== 0
    return <div className={s.wrapper}>
        <h1>{mockFeatures.title}</h1>
        {mockFeatures.steps[subscription ? 1 : 0].map(({title, description, description1, info, text, text1, className}, index) => <div
            className={s.conditions}>

            <div className={s.left}>
                {index + 1}
            </div>

            <div className={s.right}>
                <h2>{title}</h2>
                <p>{description} </p>
                <p>{description1}</p>

                {info && <div className={classnames(s.descriptionStepsText, {
                    [s.linkDescription]: info.className === 'linkDescription',
                    [s.paid]: subscription,
                    [s.free]: !subscription
                })}>
                    <a href={'/payment/3'}>{info.text}</a>
                    <a href={''}>{info.text1}</a>
                </div>}
            </div>
        </div>)}

        {/*{renderBlock()}*/}

        <FAQ questions={questions}/>
    </div>
}

export default ConditionsDraw;
