import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import withPracticalTask from "./HOCs/init";
import Evaluation from "./Components/Evaluation";
import FileLoader from "../../Components/FileLoader";
import Review from "../Review"
import Question from "../../Components/Question"
import TrustBox from "../../Components/TrustBox";

const PracticalTask = ({practicalDelegate, appDelegate, match}) => {
    return (<div className={"content-main practical-page"}>
        <div className={"theme-top"}>
            <div className={"top-content"}>
                <div className={"top-left"}>
                    <Link
                        to={`/${practicalDelegate.isPractice ? "practices" : "course"}/${practicalDelegate.state.data.stage.level.course.id}/${practicalDelegate.state.data.stage.level.id}`}>{practicalDelegate.state.data && practicalDelegate.state.data.stage ? practicalDelegate.state.data.stage.level.name : ""}</Link>
                    <img src={"/Images/arrow-grey.svg"} alt={"😼"}/>
                    <Link
                        to={`/${practicalDelegate.isPractice ? "practices" : "course"}/${practicalDelegate.state.data.stage.level.course.id}/${practicalDelegate.state.data.stage.level.id}/${practicalDelegate.state.data.stage.id}`}>{practicalDelegate.state.data && practicalDelegate.state.data.stage ? practicalDelegate.state.data.stage.name : ""}</Link>
                </div>
            </div>
        </div>
        <div className={"theme-content"}>
            <h1 className={"title"}>{practicalDelegate.state.data ? practicalDelegate.state.data.name : ""}</h1>
            {practicalDelegate.state.data && practicalDelegate.state.data.html ? (
                <div
                    dangerouslySetInnerHTML={{__html: practicalDelegate.state.data.html.replace("#name#", appDelegate.state.user.name || "John").replace("#lastname#", appDelegate.state.user.last_name || "Smith").replace("#id#", appDelegate.state.user.id)}}
                    className={"html-data"}/>
            ) : ""}
        </div>
        <div className={"mission"}>
            <FileLoader appDelegate={appDelegate} completion={practicalDelegate.completion}
                        token={appDelegate.state.authToken}
                        resource={`stages/${practicalDelegate.state.data.id}/upload?level_id=${practicalDelegate.state.data.stage.level.id}&course_id=${practicalDelegate.state.data.stage.level.course.id}`}/>
            {practicalDelegate.state.data.evaluation ? (
                <Fragment>
                    {practicalDelegate.state.data.evaluation.map((evaluation, index) => (
                        <Evaluation key={index} index={index} evaluation={evaluation}/>
                    ))}
                </Fragment>
            ) : ""}

        </div>
        <Review query={practicalDelegate.props.location.search || ""} practicalDelegate={practicalDelegate}
                appDelegate={appDelegate} match={match}/>
        {practicalDelegate.state.notify ? (
            <div className={"practical-page__notify"}>{practicalDelegate.state.notify}</div>
        ) : ""}
        {/*<Question*/}
        {/*    allowed={appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.items && appDelegate.state.user.subscriptions.items.includes("tutor")}*/}
        {/*    subscribed={appDelegate.state.user && appDelegate.state.user.subscriptions}/>*/}
        <TrustBox/>

    </div>)
}
export default withPracticalTask(PracticalTask)
