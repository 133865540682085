import {RouletteType} from '../../../types/roulette';
import {AnyAction} from 'redux';
import {CHANGE_LOADING_TO_ROULETTE, SET_DATA_FOR_ROULETTE} from '../../constants';

const initialState: RouletteType = {
    isLoading: false,
    date_competition: '',
    prev_date_competition: '',
    participants: [],
    prizes: [
        {color: "#FF9D11", label: "5 руб."},
        {color: "#0D4DE5", label: "Подписка 1 месяц"},
        {color: "#69F11A", label: "200 руб."},
        {color: "#2565FF", label: "50 руб."},
        {color: "#0D4DE5", label: "100 руб."},
        {color: "#69F11A", label: "Подписка 1 год"},
        {color: "#FFD110", label: "500 руб."},
        {color: "#FF9D11", label: "1000 руб."},
        {color: "#0D4DE5", label: "5000 руб."},
        {color: "#FF6456", label: "850 руб."},
    ],
    colors_confetti: [
        {front: '#60C81C', back: '#399300'},
        {front: '#FF9D11', back: '#AF6700'},
        {front: '#E23D34', back: '#FF6456'},
        {front: '#EFA788', back: '#8F1F17'},
        {front: '#8167A9', back: '#3D2F53'},
        {front: '#0091FA', back: '#192BCD'},
        {front: '#C8AD3E', back: '#0EA4A4'}
    ]
};

const rouletteReducer = (state = initialState, {type, payload}: AnyAction): RouletteType => {
    switch (type) {
        case SET_DATA_FOR_ROULETTE: {
            return {
                ...state,
                ...payload,
                date_competition: '12.01.2022'
            };
        }
        case CHANGE_LOADING_TO_ROULETTE: {
            return {
                ...state,
                isLoading: payload
            };
        }
        default:
            return state;
    }
};

export default rouletteReducer;

