import React, {FC} from 'react';
import styled from '../AllCourses/AllCourses.module.scss'
import TablePractices from '../TableHOC/TablePractices'
import Loader from '../../../../Components/Loader'
import checkIcon from '../../img/check-icon.png'
import {PracticesType} from '../../../../../redux/dashboardReducer'
import {useTranslation} from "react-i18next";

type PropsType = {
    practices: Array<PracticesType> | null
}

const Practices: FC<PropsType> = ({practices}) => {
    const { t } = useTranslation();
    return practices !== null ? (<div className={styled.wrapper}>
        <div className={styled.title}>
            <div className={styled.left}>
                <div className={styled.iconTitle}><img src={checkIcon} alt='v'/></div>
                <h2>
                    {t('practices.title')}
                </h2>
            </div>
        </div>
        <TablePractices practices={practices} />
    </div>) : <Loader />
}

export default Practices;