import React, {useCallback, useMemo, useState} from 'react';
import styled from './TableCareer.module.scss';
import arrowImg from '../../img/arrow-more.png';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

const TableCareer = ({careers}) => {
    const history = useHistory();
    const [countShow, setCountShow] = useState(4);
    const { t } = useTranslation();

    const changePathToCareer = (id) => {
        let path = `/jobs/${id}`
        history.push(path)
    }

    const handlerCountShow = useCallback(() => {
        setCountShow((prev) => prev + 4)
    }, [])

    const arrayCareers = useMemo(() => {
        return careers.slice(0, countShow)
    }, [countShow])

    return <div>
        <div className={styled.wrapper}>
            <div className={styled.header}>
                <div className={styled.columnOne}>
                    {t('careers.table.vacancy')}
                </div>
                <div className={styled.columnTwo}>
                    {t('careers.table.company')}
                </div>
                <div className={styled.columnThree}>
                    {t('careers.table.exp')}
                </div>
                <div className={styled.columnFour}>
                    {t('careers.table.task')}
                </div>
                <div className={styled.columnFive}>
                    {t('careers.table.salary')}
                </div>
                <div className={styled.columnSix}>
                    {t('careers.table.link')}
                </div>
            </div>
            <div className={styled.rows}>
                {arrayCareers.map(({company, employment, experience, id, position, salary}, index) =>
                    <div key={index} className={styled.rowTable}>
                        <div className={styled.columnOne + ' ' + styled.cell}>
                            <div className={styled.infoCourse}>
                                <h4>{position}</h4>
                            </div>
                        </div>
                        <div className={styled.columnTwo + ' ' + styled.cell}>
                            <div className={styled.titleCompany}>
                                <img src={company.logo} alt="Logo"/>
                                <h4>{company.name}</h4>
                            </div>
                            <span>{company.address}</span>
                        </div>
                        <div className={styled.columnThree + ' ' + styled.cell}>
                            <span>{experience}</span>
                        </div>
                        <div className={styled.columnFour + ' ' + styled.cell}>
                            <span>{employment}</span>
                        </div>
                        <div className={styled.columnFive + ' ' + styled.cell}>
                            <span>{salary}</span>
                        </div>
                        <div className={styled.columnSix + ' ' + styled.cell}>
                            <button onClick={() => changePathToCareer(id)}>
                                {t('btn.pass')}
                            </button>
                        </div>
                    </div>)}
                {countShow < careers.length && <footer onClick={handlerCountShow}>
                    {t('btn.more')} <img src={arrowImg} alt="=>"/>
                </footer>}
            </div>
        </div>

        <div className={`${styled.wrapper} ${styled.mobileWrapper}`}>
            <div className={styled.rows}>
                {arrayCareers.map(({company, employment, experience, id, position, salary}, index) =>
                    <div key={index} className={styled.rowTable}>
                        <div className={styled.rowOne}>
                            <h4>{position}</h4>
                        </div>
                        <div className={styled.rowTwo}>
                                <img src={company.logo} alt="Logo"/>
                                <h4>{company.name}</h4>
                            <span>{company.address}</span>
                        </div>
                        <div className={styled.rowThree}>
                            <div className={styled.container}>
                                <span className={styled.title}>
                                    {t('careers.table.exp')}
                                </span>
                                <span className={styled.value}>{experience}</span>
                            </div>
                            <div className={styled.container}>
                                <span className={styled.title}>
                                    {t('careers.table.task')}
                                </span>
                                <span className={styled.value}>{employment}</span>
                            </div>
                            <div className={styled.container}>
                                <span className={styled.title}>
                                    {t('careers.table.salary')}
                                </span>
                                <span className={styled.value}>{salary}</span>
                            </div>
                        </div>
                        <div className={styled.rowFour}>
                            <button onClick={() => changePathToCareer(id)}>
                                {t('btn.pass')}
                            </button>
                        </div>
                    </div>)}
            </div>
            {countShow < careers.length && <footer onClick={handlerCountShow}>
                {t('btn.more')} <img src={arrowImg} alt="=>"/>
            </footer>}
        </div>
    </div>
}

export default TableCareer;