import React from 'react';
import style from "./LevelGame.module.scss";
import {useDispatch} from "react-redux";
import {startGameThunk} from "../../../../redux/gameReducer";
import {useTranslation} from "react-i18next";

const LevelGame = (props) => {
    let dispatch = useDispatch()
    const { t } = useTranslation();
    let chooseLevelGame = () => {
        dispatch(startGameThunk(props.tags, props.column, props.levelGame, props.color))
    }

    let divBlockLevelGame = {
        backgroundColor: props.color,
        width: '70px',
        height: '20px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
}

    return <div className={style.blockLevel}>
        <div className={style.cardInfo}>
            <span>{props.level}</span>
            <p>{props.cards}</p>
        </div>
        <div className={style.cardImg}>
            <div style={divBlockLevelGame}><p>{props.levelGame}</p></div>
            <img src={props.img} alt="levelka"/>
        </div>
        <div className={style.buttonCard}>
            <button onClick={chooseLevelGame}>
                {t('game.level.btn')}
            </button>
        </div>
    </div>
}

export default LevelGame;
