import React from "react";
import { useHistory } from "react-router";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "etc/config";
import { AuthSubmitHandlerFactory } from "View/Pages/RegistrationAlternative/types";
import AuthModeToggler from "View/Pages/RegistrationAlternative/components/AuthModeToggler";
import AuthViaSocialMedia from "View/Pages/RegistrationAlternative/components/AuthViaSocialMedia";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";
import { useDocumentTitle } from "View/Pages/RegistrationAlternative/hooks/useDocumentTitle";
import { useSignupApi } from "./hooks/useSignupApi";
import { useSignupInitialValues } from "./hooks/useSignupInitialValues";
import { SignupFormValues, SignupProps, UseSignupApiResult } from "./types";
import AuthForm from "../../components/AuthForm";
import { isFieldInValid } from "../../utils/isFieldInValid";
import { isAxiosError } from "../../utils/isAxiosError";
import {useTranslation} from "react-i18next";

const SignupValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email("Введите правильный Email")
		.required("Обязательно для заполнения"),
	name: Yup.string()
		.min(1, "Имя должно содержать больше символов")
		.required("Обязательно для заполнения")
});

const SignupSubmitHandlerFactory: AuthSubmitHandlerFactory<SignupFormValues, UseSignupApiResult> = (
	appDelegate, 
	signupApiCallback,
	options = {}
) => async (
	values, 
	helpers
) => {
	try {
		const response: AxiosResponse<{status: number, data: string[]}> = await signupApiCallback(values);

		if(response.data.status === 1) {
			// TODO: Must be change further because of not existance in server multi-language errors
			const message = response.data.data.join(); 
			helpers.setErrors({
				name: message,
			});
			throw new Error(message);
		}

		if(response.data.status === 0) {
			if(appDelegate.setPopupAlert !== undefined) {
				appDelegate.setPopupAlert({ // TODO: Alert is not working
					message: "На ваш email были отправленны данные для входа.",
					title: "Спасибо за регистрацию",
					handler: () => appDelegate.setPopupAlert(null),
					buttonText: "Отлично",
					close: () => appDelegate.setPopupAlert(null)
				});
			}
			if(window.ym !== undefined) {
				window.ym(62295580, "reachGoal", "authorization");
			}

			if(options.onSuccess) {
				options.onSuccess(values);
			}
		}
	} catch (error) {

		if(error instanceof Error) {
			if(isAxiosError(error)) {
				if(error.response.status === 403 || error.response.status === 404 || error.response.status === 500) {
					helpers.setFieldError("email", "Ошибка на сервере");
				} else {
					helpers.setFieldError("name", error.message);
				}
			} else {
				helpers.setFieldError("name", error.message);
			}
		}
		console.error("Error when attempting to signup", values, error);
	}
};

function Signup({ appDelegate }: SignupProps): JSX.Element {
	const sigupInitialValues = useSignupInitialValues();
  const signupApiCallback = useSignupApi();
	const history = useHistory();
	const { t } = useTranslation();

	const onSuccess = React.useCallback((values: SignupFormValues) => {
		history.push({
			pathname: RegistrationRoutes.SIGN_IN,
			search: `?email=${values.email}`
		});
	}, [history]);
  const formik = useFormik<SignupFormValues>({
    initialValues: sigupInitialValues,
		validationSchema: SignupValidationSchema,
    onSubmit: SignupSubmitHandlerFactory(
			appDelegate, 
			signupApiCallback, 
			{ onSuccess }
		)
  });

	const NAME_IS_INVALID = isFieldInValid(formik, "name");
	const EMAIL_IS_INVALID = isFieldInValid(formik, "email");
	const FORM_IS_INVALID = Boolean(NAME_IS_INVALID || EMAIL_IS_INVALID);

	useDocumentTitle(t('sign.up'));

  return (
		<AuthForm
			isInValid={FORM_IS_INVALID}
			onSubmit={formik.handleSubmit}
		>	
			<AuthModeToggler/>
			
			<AuthViaSocialMedia/>

			<label 
				className={ 
					NAME_IS_INVALID ? "error" : ""
				} 
				htmlFor="name"
			>
				{ 
					NAME_IS_INVALID ? formik.errors.name : t('auth.name')
				}
			</label>
			<input 
				name="name"
				className={
					NAME_IS_INVALID ? "error" : ""
				}
				value={formik.values.name}
				onChange={formik.handleChange}
				type="text" 
				id={"name"} 
				placeholder={t('auth.enter.name')}
			/>

			<label
				className={
					EMAIL_IS_INVALID ? "error" : ""
				}
				htmlFor="email"
			>
				{ EMAIL_IS_INVALID ? formik.errors.email : "Email" }
			</label>
			<input 
				name="email"
				className={
					EMAIL_IS_INVALID ? "error" : ""
				}
				value={formik.values.email}
				onChange={formik.handleChange}
				type="email" 
				id={"email"} 
				placeholder={t('auth.email')}
			/>

			<button
				disabled={formik.isSubmitting}
				type={"submit"}
			>
				{t('sign.up')}
			</button>
		</AuthForm>
  );
}

export default Signup;