import React from 'react';

import withHome from "./withHome";
import {Link} from "react-router-dom";
import AllCourses from "./Components/AllCourses";
import MyCourses from "./Components/MyCourses";
import Question from "../../Components/Question";
import TrustBox from "../../Components/TrustBox"


const Home = ({appDelegate, homeDelegate}) => {
    return <div className="home-page content-main">
        <div className={"home-page__content"}>
            {homeDelegate.props.location.pathname === "/all-courses" ? (
                <AllCourses appDelegate={appDelegate} homeDelegate={homeDelegate}/>

            ) : homeDelegate.props.location.pathname === "/course" ? (
                <React.Fragment>
                    {homeDelegate.state.data.addedCourses.length > 0 ? (
                        <MyCourses appDelegate={appDelegate} homeDelegate={homeDelegate}/>
                    ) : (
                        <div className={"home-page__my-courses"}>
                            <img src="/Images/hand.png" alt={"😼"}/>
                            <h2>Добро пожаловать в ваш личный кабинет</h2>
                            <p className={"text-account"}>Мы знаем, что сейчас он выглядит немного пустым. Но здесь вы
                                найдете свои курсы , а также все бесплатные
                                вебинары и дополнительные мини курсы.</p>
                            <Link to={"/"} className={"button"}><img src="/Images/pluse.svg" alt={"😼"}/>Добавить
                                курс</Link>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={"home-page__helper"}>
                    <Question appDelegate={appDelegate}/>
                    <TrustBox/>
                </div>
            )}

        </div>

    </div>
}

export default withHome(Home);