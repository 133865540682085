import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from './redux/store';
import "./services/i18n";

import "./Font/Inter-Bold-slnt=0.ttf";
import "./Font/Inter-ExtraBold-slnt=0.ttf";
import "./Font/Inter-Medium-slnt=0.ttf";
import "./Font/Inter-Regular-slnt=0.ttf";
import "./Font/Inter-SemiBold-slnt=0.ttf";
import "./Font/Festus-font.TTF";
import "./Font/Golos/Golos_Text_Medium/Golos_Text_Medium.woff";
import "./Font/Golos/Golos_Text_Medium/Golos_Text_Medium.woff2";

import "./Font/Golos/Golos_Text_Bold/Golos_Text_Bold.woff";
import "./Font/Golos/Golos_Text_Bold/Golos_Text_Bold.woff2";

import "./Font/Golos/Golos_Text_Regular/Golos_Text_Regular.woff";
import "./Font/Golos/Golos_Text_Regular/Golos_Text_Regular.woff2";

import "./Font/Golos/Golos_Text_DemiBold/Golos_Text_DemiBold.woff";
import "./Font/Golos/Golos_Text_DemiBold/Golos_Text_DemiBold.woff2";
import Loader from "./View/Components/Loader";

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <Suspense fallback={<Loader/>}>
                <App/>
            </Suspense>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


window.addEventListener("load", () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/worker.js')
            .then((register) => {
                // return subscription(register)
            })
            .catch(err => {
                console.log(err)
            })
    }
})

const subscription = async (register) => {
    try {
        let user = await register.pushManager.getSubscription()
        if (user) {
            await user.unsubscribe()
        }

        let subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array("BJTF8pSjAJGdoamlxLW9oLl-m7MgmlF6f6DlFL-lvQmgc2Cigab6naT3T44BNNeywadUagljO4nCnH34PDOaHSY")
        })

        let response = await fetch(`http://localhost:8086/api/v1/users/subscribe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(subscription)
        })

        let json = await response.json()
        console.log(json)

    } catch (e) {
        throw e
    }
}