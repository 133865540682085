import {certificatesAPI} from "../api/api";

const SET_CERTIFICATES = 'SET_CERTIFICATES'
const VERIFY_CERTIFICATE = 'VERIFY_CERTIFICATE'

let initialState = {
    certificates: []
}

const certificatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CERTIFICATES: {
            return {
                ...state,
                certificates: action.certificates.map(item => {
                    return {
                        ...item,
                        status: 0
                    }
                })
            }
        }
        case VERIFY_CERTIFICATE: {
            return {
                ...state,
                certificates: state.certificates.map(item => {
                    if((item.id === action.id) && (action.status === 0)) {
                        return {
                            ...item,
                            user: action.name,
                            status: 1,
                            isActive: true
                        }
                    }
                    return item
                })
            }
        }
        default:
            return state;
    }
}

const setCertificates = ({certificates}) => {
    return {
        type: SET_CERTIFICATES,
        certificates
    }
}

const setVerifyCertificate = (status, name, id) => {
    return {
        type: VERIFY_CERTIFICATE,
        status, name, id
    }
}

export const getCertificates = (authToken) => {
    return async (dispatch) => {
        let response = await certificatesAPI.getCertificates(authToken)
        try {
            dispatch(setCertificates(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const verifyCertificateThunk = (authToken, id, name) => {
    let fullName = name.split(' ')
    return async (dispatch) => {
        let response = await certificatesAPI.verifyCertificate(authToken, id, fullName[0], fullName[1])
        try {
            dispatch(setVerifyCertificate(response.data.status, name, id))
        } catch (error) {
            console.log(error)
        }
    }
}

export default certificatesReducer;