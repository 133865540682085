import React from 'react'
import s from './SwitcherComponentFull.module.scss'
import {useTranslation} from "react-i18next";

const SwitcherComponentFull = ( {tarifs, invertSelection, isSecondSelected} ) => {
    const { t } = useTranslation();

    return <div className={s.wrapper}>
        <div>
            1 { t(tarifs[0].title) }
            <br/>
            <span className={!isSecondSelected ? `${s.selected} ${s.price}` : s.price}>
                {`${tarifs[0].price} $`}
            </span>
        </div>

        <div className={`${s.toggler} ${!isSecondSelected ? s.firstOptionSelected : ''}`}
             onClick={invertSelection}></div>

        <div>
            1 { t(tarifs[1].title) }
            <br/>
            <span className={isSecondSelected ? `${s.selected} ${s.price}` : s.price}>
                {`${tarifs[1].price} $`}
            </span>
        </div>
    </div>
}

export default SwitcherComponentFull
