import React, {useState} from "react"
import moment from "moment"
import {days} from "../../Orders"
import {Link} from "react-router-dom"


const Popup = ({offerDetailDelegate}) => {

    const [checked, toggle] = useState(false)

    return (
        <div onClick={() => offerDetailDelegate.togglePopup(false)} className={"popup"}>
            <div onClick={e => e.stopPropagation()} className={"popup__container"}>
                <h4>Новый заказ</h4>
                <p className={"warning"}>Взяв заказ, вы обязуетесь довести его до конца к назначенному сроку
                    — {moment().add(offerDetailDelegate.state.data.days, "days").format("DD.MM.YYYY")}. Срок проекта
                    – {offerDetailDelegate.state.data.days} {days(offerDetailDelegate.state.data.days)}.</p>

                <div className={"offer"}>
                    <input type="checkbox" id={"checkbox"} onChange={() => toggle(!checked)} hidden={true}/>
                    <label htmlFor="checkbox">
                        <span/> Я ознакомился с
                    </label>
                    <Link to="/offer"> Условиями соглашения</Link>
                </div>
            <div className={"buttons"}>
                <button onClick={() => offerDetailDelegate.togglePopup(false)}>Пока не готов</button>
                <button onClick={offerDetailDelegate.startProject} className={"submit"} disabled={!checked}>{offerDetailDelegate.state.loading.includes("start") ? "Загрузка..." : "Взять заказ"}</button>
            </div>

            </div>
        </div>
    )
}


export default Popup