import React from 'react';
import styled from '../ReportABag.module.scss';

const ErrorItem = ({title, id, messageError, changeTextarea, isShowTextarea, openTextarea}) => {
    return <>
        <span className={isShowTextarea ? styled.focusSpan : null} onClick={() => openTextarea(id)}>{title}</span>
        {isShowTextarea && <textarea onChange={changeTextarea} placeholder="Пожалуйста, опишите проблему" value={messageError}></textarea>}
    </>
}

export default ErrorItem;