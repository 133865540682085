import React, {useEffect, useState} from "react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';

require('prismjs/components/prism-jsx');

const ThemePractice = ({ themeDelegate, exercise }) => {

    const [code, changeCode] = useState(exercise.code);
    const [ help, toggleHelp ] = useState(false);

    useEffect(() => {
       window.addEventListener("keyup", e => {
           if(e.key === "Escape") {
               toggleHelp(false);
           }
       });
        return () => {
            window.removeEventListener("keyup", e => {
                if(e.key === "Escape") {
                    toggleHelp(false);
                }
            });
        }
    });

    return (
        <div className={"theme-practice"}>
            {help ? (
                <div onClick={() => toggleHelp(false)} className={"theme-practice__help"}>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: exercise.description}} className={"html-data "}/>
                    </div>
                </div>
            ) : ""}

            <div className={"theme-practice__top"}>
                <div className={"design"}>
                    <div className={"design__container"} style={{ backgroundImage: `url(${exercise.image})` }}/>
                    <div className={"design__task"} onClick={() => toggleHelp(true)}>Напомнить задание</div>
                </div>
                <div className={"result"}>
                    <div className={"result__container"}  dangerouslySetInnerHTML={{__html: code}}/>
                </div>
            </div>

            <div className={"theme-practice__editor"}>
                <Editor
                    value={code}
                    onValueChange={changeCode}
                    highlight={code => highlight(code, languages[exercise.type === "css" ? "html" : exercise.type])}
                    padding={10}
                    placeholder={"Пишите код сюда"}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                    }}
                    />
            </div>

            {themeDelegate.state.loader.href ? (
                <a className={"theme-practice__download"} href={themeDelegate.state.loader.href} download={themeDelegate.state.loader.download}>Скачать</a>
            ) : (
                <div className={"theme-practice__download"} onClick={() => themeDelegate.createFileForLocale(code, exercise.type === "html" ? "text/html" : exercise.type === "js" || exercise.type === "jsx" ? "text/javascript" : "text/css" , exercise.type)}>Сформировать файл для скачивания</div>
            )}

            <div onClick={() => themeDelegate.togglePractice(null)} className={"theme-practice__close"}><img src={"/Images/close.svg"} alt={"😼"} /> Закрыть редактор</div>
        </div>
    )
};



export default ThemePractice;