import React, {useEffect, useState} from 'react';
import styled from './ReportABag.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {changedStatusReturnedError, getDataWindowError, sendReportError} from "../../../redux/dashboardReducer";
import ErrorItem from "./components/ErrorItem";
import {useTranslation} from "react-i18next";

const ReportABag = ({appDelegate}) => {
    const [showForm, setShowForm] = useState(false)
    const [idError, setIdError] = useState(null)
    const {categoriesErrors, statusErrorReport} = useSelector(state => state.dashboardReducer)
    const [messageError, setMessageError] = useState('')
    const [requestData, setRequestData] = useState(null)
    const dispatch = useDispatch()
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getDataWindowError(appDelegate.state.authToken))
    }, [])

    useEffect(() => {
        setRequestData(categoriesErrors?.map(item => ({
            ...item,
            isShowTextarea: false
        })) || null)
    }, [categoriesErrors])

    const handleShowForm = () => {
        setShowForm(prev => !prev)
    }

    const changeTextarea = (e) => {
        setMessageError(e.target.value)
    }

    const openTextarea = (id) => {
        setMessageError('')
        setIdError(id)
        setRequestData(prev => prev.map(item => ({
            ...item,
            isShowTextarea: item.id === id && !item.isShowTextarea
        })))
    }

    const openMainWindowError = () => {
        dispatch(changedStatusReturnedError(null))
    }

    const sendError = () => {
        dispatch(sendReportError(appDelegate.state.authToken, window.location.href, idError, messageError))
        setRequestData(prev => prev.map(item => ({
            ...item,
            isShowTextarea: false
        })))
        setMessageError('')
    }

    return <div className={styled.wrapper}>
        <div className={showForm ? styled.openWindowError : styled.closeWindowError}>
            <div className={styled.title} onClick={handleShowForm}>
                <span>{ t('report.bag.title') }</span>
                <img src="/Images/error-report-icon.png" alt="Error"/>
            </div>
            {statusErrorReport === null ? categoriesErrors !== null && <div className={styled.content}>
                <h4>{ t('report.bag.select') }</h4>
                <div className={styled.allErrors}>
                    {requestData?.map(({id, title, isShowTextarea}) => <ErrorItem key={id}
                                                                                  title={title}
                                                                                  id={id}
                                                                                  messageError={messageError}
                                                                                  changeTextarea={changeTextarea}
                                                                                  isShowTextarea={isShowTextarea}
                                                                                  openTextarea={openTextarea}
                    />)}
                </div>
                <button disabled={messageError === ''} onClick={sendError}>{ t('report.bag.send') }</button>
            </div>
                : statusErrorReport === 0
                    ? <div className={styled.successSendError}>
                    <h3 onClick={openMainWindowError}>{ t('report.bag.success.title') }</h3>
                    <p>{ t('report.bag.success.text1') }</p>
                    <p>{ t('report.bag.success.text2') }</p>
                </div>
                :  <div className={styled.successSendError}>
                        <h3 onClick={openMainWindowError}>{ t('report.bag.error.title') }</h3>
                        <p>{ t('report.bag.error.text') }</p>
                    </div>
            }
        </div>
    </div>
}

export default ReportABag;
