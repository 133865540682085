import React from 'react'
import styled from '../PassedCourses/PassedCourses.module.scss'
import TablePassedTasks from '../TableHOC/TablePassedTasks'
import Loader from '../../../../Components/Loader'
import {useTranslation} from "react-i18next";

const PassedTasks = ({tests}) => {
    const { t } = useTranslation();
    return tests !== null ? <div className={styled.wrapper}>
        <div className={styled.title}>
            <h2>
                {t('task.passed.title')}
            </h2>
            {/*<SortBy arraySort={arraySort} />*/}
        </div>
        <TablePassedTasks tests={tests} />
    </div> : <Loader />
}

export default PassedTasks;