import React, {useCallback, useState} from 'react'
import styled from './ReplayComment.module.scss'
import * as moment from "moment"
import replyIcon from "../../../../Pages/Tasks/img/reply-icon.svg"
import likeHoverIcon from "../../../../Pages/Tasks/img/like-icon-hover.svg"
import likeIcon from "../../../../Pages/Tasks/img/like-icon.svg"
import {useDispatch} from "react-redux"
import {changeStatusLikeThunk, replyUserOnCommentThunk} from "../../../../../redux/discussionReducer";
import {useTranslation} from "react-i18next";

const ReplayComment = ({comment, avatar, icon, createdAt, fullName, id, idComment, isLiked, likes, authToken}) => {
    const [likeComment, setLikeComment] = useState(isLiked)
    const [countLike, setCountLike] = useState(likes)
    const dispatch = useDispatch()
    const [showTextarea, setShowTextarea] = useState(false)
    const [replyComment, setReplyComment] = useState(`${fullName}, `)
    const { t } = useTranslation();

    const handleShowTextarea = () => {
        showTextarea ? setShowTextarea(false) : setShowTextarea(true)
    }

    const sendReplyComment = () => {
        dispatch(replyUserOnCommentThunk(authToken, idComment, replyComment))
        showTextarea ? setShowTextarea(false) : setShowTextarea(true)
        setReplyComment(`${fullName}, `)
    }

    const changeReplyComment = useCallback((e) => {
        setReplyComment(e.target.value)
    }, [setReplyComment])

    const changeLike = (id) => {
        likeComment ? setLikeComment(false) : setLikeComment(true)
        likeComment ? setCountLike(countLike - 1) : setCountLike(countLike + 1)
        dispatch(changeStatusLikeThunk(authToken, id))
    }

    return <div className={styled.wrapper}>
        <div className={styled.content}>
            <div style={{display: 'flex'}}>
                {avatar !== null
                    ? <img className={styled.userIcon} src={avatar} alt="Image"/>
                    : <img className={styled.userIcon} src={icon} alt="Image"/>}
                <div className={styled.description}>
                    <h3>{fullName}</h3>
                    <span className={styled.date}>{moment(createdAt).fromNow()}</span>
                    <span className={styled.comment}>{comment}</span>
                    <div className={styled.buttons}>
                        <button className={styled.buttonReply} onClick={handleShowTextarea}>
                            <img src={replyIcon} alt="reply"/> {t('btn.reply')}
                        </button>
                        <div className={styled.likeBox} onClick={() => changeLike(id)}>
                            {likeComment ? <img src={likeHoverIcon} alt="<3"/> : <img src={likeIcon} alt="<3"/>}
                            <span>{countLike}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                {showTextarea && <div className={styled.replyComment}>
                    {avatar !== null
                        ? <img className={styled.userIcon} src={avatar} alt="Image"/>
                        : <img className={styled.userIcon} src={icon} alt="Image"/>}
                    <div className={styled.textareaForm}>
                        <textarea value={replyComment} onChange={changeReplyComment}></textarea>
                        <button className={styled.buttonSend} onClick={sendReplyComment}>
                            {t('btn.send')}
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    </div>
}

export default ReplayComment;