import React from 'react';
import YouTube from 'react-youtube';
import './Youtube.scss';

const ReactYoutube = ({link}) => {
    let video_id = link.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if(ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }
    const opts = {
        playerVars: {
            autoplay: false,
        },
    };

    return <YouTube
        className="youtube"
        videoId={video_id}
        opts={opts}
    />;
}

export default ReactYoutube;