import CourseHelper from "../CourseHelper";
import React, {useRef, useEffect} from "react";
import Stages from "./Stages";
import moment from "moment";
import Offers from "./Offers"
import Vacancies from "./Vacancies"

const Levels =({level, courseDelegate, available, appDelegate, levelIndex})=> {
    const container = useRef();
    useEffect(() => {
        if (courseDelegate.state.activeLevel.toString() === level.id.toString())
            window.scrollTo({ top: container.current.offsetTop, left: 0 })
    });
    return (
        <div className={available ? "big-box" : "big-box disable"} ref={container}>
            <h2>{level.level_name}</h2>
            <div className={"date-box"}>
                <div className={"left"}>
                    {/*{courseDelegate.state.data.free ? (*/}
                    {/*    <Fragment>*/}
                    {/*        <div className={"start"}>*/}
                    {/*            Триальный пакет*/}
                    {/*        </div>*/}
                    {/*    </Fragment>*/}
                    {/*) : (*/}
                    {/*    <Fragment>*/}
                    {/*        <div className={"start"}>*/}
                    {/*            <span className={"span"}>Дата старта:</span> <span>{moment(level.date_of_start).date()} {months[moment(level.date_of_start).month()].substring(0, 3)} {moment(level.date_of_start).format("YYYY")}</span>*/}
                    {/*        </div>*/}
                    {/*        <div className={"arrow"}>*/}
                    {/*            <img src={"/Images/arrow-right.svg"}/>*/}
                    {/*        </div>*/}
                    {/*        <span className={"tire"}>-</span>*/}
                    {/*        <div className={"deadline"}>*/}
                    {/*            <span className={"span"}>Дедлайн:</span> <span>{moment(level.deadline).date()} {months[moment(level.deadline).month()].substring(0, 3)} {moment(level.deadline).format("YYYY")}</span>*/}
                    {/*        </div>*/}
                    {/*    </Fragment>*/}
                    {/*)}*/}

                </div>
                <div className={"right"}>
                    <span className={"right__desktop"}>{CourseHelper.getThemesInfo(level.stages).desktop}</span>
                    <span className={"right__mobile"}>{CourseHelper.getThemesInfo(level.stages).mobile}</span>
                </div>
                <div style={{ width: `${CourseHelper.getPercent(level.stages)}%` }} className={"date-box__percent"}/>
            </div>
            {level.stages.map((stage, index) => (
                <Stages stageIndex={index} levelIndex={levelIndex} appDelegate={appDelegate} isIsLast={index === level.stages.length - 1 && (!level.offers || level.offers.length === 0)} isStarted={(moment().isSameOrAfter(moment(level.date_of_start), "day")) && ((index === 0) || (level.stages[index - 1].themes.findIndex(x => x.done === false) === -1 && level.stages[index - 1].done) )} available={available} courseDelegate={courseDelegate} key={index} stage={stage}/>
            ))}
            {level.jobs && level.jobs.length > 0 ? (
                <Vacancies jobs={level.jobs}/>
            ) : ""}
            {level.offers && level.offers.length > 0 ? (
                <Offers offers={level.offers}/>
            ) : ""}
        </div>
    )
};


export default Levels;
