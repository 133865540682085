const rouletteCss = require('./admin-roulette.scss')
let style = null;
let winnersRoulette = [null, null, null]
let count_wheel = 0
let participants = []
let dateCompetition = '';
let checkedDate = false

const screenCanvas = {
    width: 714,
    height: 714
}

export function _renderCompetition(_data) {
    participants = _data.users_participants
    _setCss();
    _setFonts();
    _draw(_data);
    _drawListUsers(_data, participants)
    _roulette(_data);
}

const shortName = (name) => {
    let short = ''
    let arrayName = name.split(' ')
    for (let i = 0; i < arrayName.length; i++) {
        short += arrayName[i][0]
    }
    return short
}

const showListParticipants = () => {
    document.getElementById('users').classList.add('active')
}

const checkedParticipant = (id) => {
    return winnersRoulette.some(item => item?.id === id)
}

function _renderWinners(winner, prize, _data) {
    const app = document.getElementById('victory');
    app.innerHTML = `<div class="winner" 
                            id="winner"
                            style="background: ${style.bcg_winner};
                                   border: 1px solid ${style.border_winner};
                                   box-shadow: ${style.shadow_winner};
                            ">
        <canvas id="confetti" class="confetti"></canvas>
        <div class="container">
            <p class="date-competition">${_data.text_page.main_admin.date_competition}</p>
            <p class="count-winner">${_data.text_page.main_admin.winner_text}<div class="info-winner" style="background: ${style.bcg_info_winner}">
                <h3>${winner.full_name}</h3>
                <span>Подписка: ${winner.subscription}</span>
                <h4>${prize}</h4>
            </div>
        </div>
        <p class="administrator">${_data.text_page.main_admin.administration}</p>
        <button id="hide-winner" 
                        style="background: ${style.bcg_button};
                        text-shadow: ${style.shadow_btn};">${count_wheel !== winnersRoulette.length ? _data.text_page.main_admin.btn_next_step : _data.text_page.main_admin.btn_end_roulette}</button>
    </div>`

    _drawConfetti(_data.colors_confetti);
    const btn = document.getElementById('hide-winner')
    btn.onclick = function () {
        document.getElementById('winner').classList.remove('active')
        if (count_wheel === winnersRoulette.length) {
            setTimeout(() => {
                showListParticipants()
            }, 500)
        }
    };

    setTimeout(() => {
        document.getElementById('winner').classList.add('active')
    }, 200)

    _drawConfetti(_data.colors_confetti);
}

const checkDate = (date) => {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const nowDate = dd + '.' + mm + '.' + yyyy;
    return nowDate === date
}

const timer = () => {
    let today = new Date()
    let date_competition = dateCompetition.split('.')
    const achiveDate = new Date(Number(date_competition[2]), Number(date_competition[1]) - 1, Number(date_competition[0]), 17, 0, 0);
    const result = (achiveDate - today) + 1000;
    let seconds = Math.floor((result / 1000) % 60);
    let minutes = Math.floor((result / 1000 / 60) % 60);
    let hours = Math.floor((result / 1000 / 60 / 60) % 24);
    let days = Math.floor(result / 1000 / 60 / 60 / 24);
    if (seconds < 10) seconds = '0' + seconds;
    if (minutes < 10) minutes = '0' + minutes;
    if (hours < 10) hours = '0' + hours;
    const count = document.getElementById('wheel-roulette');
    count.innerHTML = `<h1 style="color: ${style.bcg_button}" >${days}<span class="colon" style="color: ${style.color_text}">:</span>${hours}<span class="colon" style="color: ${style.color_text}">:</span>${minutes}<span class="colon" style="color: ${style.color_text}">:</span><span class="seconds">${seconds}</span></h1>`
    setTimeout(timer, 1000);
}


function _drawListUsers(_data, participants) {
    const listUsers = document.getElementById('users')
    listUsers.innerHTML = `<div class="title-users">
             <img src=${style.icon_close} alt="x" id="close" class="close">
                <h3 style="color: ${style.color_title}">${_data.text_page.main_admin.users_competition}</h3>
                <p style="color: ${style.color_text}">${_data.text_page.main_admin.date_competition}</p>         
                <div class="winners-roulette">
                    ${winnersRoulette.map((item, index) => {
        if (item === null) {
            return `<div class="winner-roulette" 
                                         style="background: ${style.bcg_winner}; 
                                         border: 2px solid ${style.border_winner};
                                         color: ${style.color_text}">${index + 1}</div>`
        } else {
            return `<div class="container-winner__roulette">
                        <div class="winner-roulette" 
                             style="background: ${style.bcg_button}; 
                                    border: 2px solid ${style.border_winner};
                                    color: ${style.color_text}">${shortName(item.full_name)}</div>
                                    <h4 style="color: ${style.color_text};">${item.full_name}</h4>
                                    <h5 style="color: ${style.bcg_button};">${item.prize}</h5>
                    </div>`
        }
    }).reduce((acc, item) => acc + item)}
                </div>
                <ul class="list-users" style="border-top: 1px solid ${style.border_winner};">
                    ${participants.map(({
                                                                                                                                                                               full_name,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  subscription,
                                                                                                                                                                               id
                                                                                                                                                                           }, index) => `<li style="border-bottom: 1px solid ${style.border_winner};">
                        <div class="left">
                            <h3 class="count-participant" style="color: ${style.color_text}">${index + 1}</h3>
                            <div class="info-participant">
                                <div class="image" style="background: ${checkedParticipant(id) ? style.bcg_button : style.border_winner}; color: ${style.color_text}">
                                    ${shortName(full_name)}
                                </div>
                                <div class="name-participant">
                                    <p style="color: ${style.color_text}">${_data.text_page.main_admin.participant}</p>
                                    <h2 style="color: ${style.color_text}">${full_name}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <p style="color: ${style.color_text}">${_data.text_page.table_winners.passed}</p>
                            <h2 style="color: ${style.color_text}">${subscription}</h2>
                        </div>
                    </li>`).reduce((acc, item) => acc + item)}
                </ul>
            </div>`

    const closeUsers = document.getElementById('close')
    closeUsers.onclick = function () {
        document.getElementById('users').classList.remove('active')
    }
}

function _draw(_data) {
    style = _data.style
    dateCompetition = _data.date_competition
    checkedDate = checkDate(dateCompetition)
    const app = document.getElementById('root');
    app.innerHTML = `<div class="wrapper" 
                        id="wrapper"
                        style="background-image: url(${style.bcg_image}); 
                        background-color: ${style.bcg_roulette}">
    <div class="content">
        <div class="menu">
            <a href="https://cabinet.educat.courses/" style="color: ${style.color_text}" class="prev-page"><img src=${style.prev_page} alt="=>" /> ${_data.text_page.prev_page}</a>
            <span id="btn-users"
                style="color: ${style.color_text}">${_data.text_page.main_admin.users}</span>
        </div>
        <div class="users" id="users" 
             style="border: 2px solid ${style.border_winner};
             box-shadow: ${style.shadow_winner};
             background: ${style.bcg_winner}">
        </div>
        <h1 style="color: ${style.color_title}">${_data.text_page.main_admin.title}</h1>
        <div id="victory"></div>
        <p style="color: ${style.color_text}">${_data.text_page.main_admin.date_competition}</p>
        ${checkedDate
        ? `<button id="wheel-roulette"
                      style="background: ${style.bcg_button};
                      text-shadow: ${style.shadow_btn};"
                >${_data.text_page.main_admin.btn_next_step}</button>`
        : `<div id="wheel-roulette" class="timer"></div>`}       
    </div>
    <div class="canvas">    
        <img src=${style.arrow_roulette} alt="=>">
        <div class="canvas-roulette">
            <canvas id="roulette" width=${screenCanvas.width} height=${screenCanvas.height}></canvas>
        </div>
    </div>
 </div>`

    const btnUsers = document.getElementById('btn-users')
    if (!checkedDate) timer()
    btnUsers.onclick = function () {
        showListParticipants()
    }
}

function _setCss() {
    const style = document.createElement('style')
    style.type = 'text/scss'
    style.innerHTML = rouletteCss
    style.id = 'styled'
    document.head.appendChild(style)
    document.querySelector('head').innerHTML += '<meta name="viewport" content="width=device-width, initial-scale=1" />';
}

function _setFonts() {
    document.querySelector('head').innerHTML += '<link rel="preconnect" href="https://fonts.gstatic.com">'
    document.querySelector('head').innerHTML += '<link rel = "preconnect" href = "https://fonts.googleapis.com">'
    document.querySelector('head').innerHTML += '<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet">'
    document.querySelector('head').innerHTML += '<link href = "https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel = "stylesheet">'
}

function _roulette(_data) {
    let prize = null;

    const rand = (m, M) => Math.random() * (M - m) + m;
    const tot = _data.prizes.length;
    const EL_spin = document.querySelector("#wheel-roulette");
    const ctx = document.querySelector("#roulette").getContext('2d');
    const dia = ctx.canvas.width;
    const rad = dia / 2;
    const radiusRoulette = rad - 52;
    const PI = Math.PI;
    const TAU = 2 * PI;
    const arc = TAU / _data.prizes.length;

    const friction = 0.991; // 0.995=soft, 0.99=mid, 0.98=hard
    let angVel = 0; // Angular velocity
    let ang = 0; // Angle in radians

    const getIndex = () => Math.floor(tot - ang / TAU * tot) % tot;

    function drawSector(sector, i) {
        const ang = arc * i;
        ctx.save();
        // COLOR

        // Main border roulette
        ctx.beginPath();
        ctx.fillStyle = _data.roulette_style.main_border;
        ctx.moveTo(rad, rad);
        ctx.arc(rad, rad, rad - 25, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        // Second border roulette
        ctx.beginPath();
        ctx.fillStyle = _data.roulette_style.second_border;
        ctx.moveTo(rad, rad);
        ctx.arc(rad, rad, rad - 40, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        // Roulette
        ctx.beginPath();
        ctx.fillStyle = sector.color;
        ctx.moveTo(rad, rad);
        ctx.arc(rad, rad, radiusRoulette, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        // внутренний круг
        ctx.beginPath();
        ctx.fillStyle = _data.roulette_style.main_arc;
        ctx.arc(rad, rad, 24, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        // внутренний круг
        ctx.beginPath();
        ctx.fillStyle = _data.roulette_style.second_arc;
        ctx.moveTo(rad, rad);
        ctx.arc(rad, rad, 21, ang, ang + arc);
        ctx.lineTo(rad, rad);
        ctx.fill();

        // TEXT
        ctx.translate(rad, rad);
        ctx.rotate(ang + arc / 2);
        ctx.textAlign = "right";
        ctx.fillStyle = _data.roulette_style.color_text;
        ctx.font = "normal 20px Roboto";
        ctx.fillText(sector.label.toUpperCase(), rad - 70, 10);
        ctx.restore();
    };

    function disabledBtn() {
        const button = document.getElementById('wheel-roulette')
        button.disabled = true
    }

    function rotate() {
        const sector = _data.prizes[getIndex()];
        ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
        if (!angVel && prize !== null) {
            let winner = _data.users_participants[Math.floor(Math.random() * _data.users_participants.length)]
            winner = {...winner, prize: prize}
            _data.users_participants = _data.users_participants.filter(user => user.id !== winner.id)
            winnersRoulette[count_wheel] = winner
            count_wheel++
            _drawListUsers(_data, participants)
            _renderWinners(winner, prize, _data);
            if (count_wheel === winnersRoulette.length) {
                disabledBtn()
            }
        } else {
            prize = sector.label
        }
    }

    function frame() {
        if (!angVel) return;
        angVel *= friction; // Decrement velocity by friction
        if (angVel < 0.002) angVel = 0; // Bring to stop
        ang += angVel; // Update angle
        ang %= TAU; // Normalize angle
        rotate();
    }

    function engine() {
        frame();
        requestAnimationFrame(engine)
    }

// INIT
    _data.prizes.forEach(drawSector);
    rotate(); // Initial rotation
    engine(); // Start engine
    EL_spin.addEventListener("click", () => {
        if (checkedDate) angVel = rand(0.25, 0.35);
    });
}

function _drawConfetti(colors) {
    const confetti = document.getElementById('confetti');
    const confettiCtx = confetti.getContext('2d');
    let container, confettiElements = [], clickPosition;

// helper
    const rand = (min, max) => Math.random() * (max - min) + min;

// params to play with
    const confettiParams = {
        // number of confetti per "explosion"
        number: 70,
        // min and max size for each rectangle
        size: {x: [5, 20], y: [10, 18]},
        // power of explosion
        initSpeed: 25,
        // defines how fast particles go down after blast-off
        gravity: 0.65,
        // how wide is explosion
        drag: 0.08,
        // how slow particles are falling
        terminalVelocity: 6,
        // how fast particles are rotating around themselves
        flipSpeed: 0.017,
    };

    setupCanvas();
    updateConfetti();

    confetti.addEventListener('click', addConfetti);
    window.addEventListener('resize', () => {
        setupCanvas();
        hideConfetti();
    });

// Confetti constructor
    function Conf() {
        this.randomModifier = rand(-1, 1);
        this.colorPair = colors[Math.floor(rand(0, colors.length))];
        this.dimensions = {
            x: rand(confettiParams.size.x[0], confettiParams.size.x[1]),
            y: rand(confettiParams.size.y[0], confettiParams.size.y[1]),
        };
        this.position = {
            x: clickPosition[0],
            y: clickPosition[1]
        };
        this.rotation = rand(0, 2 * Math.PI);
        this.scale = {x: 1, y: 1};
        this.velocity = {
            x: rand(-confettiParams.initSpeed, confettiParams.initSpeed) * 0.4,
            y: rand(-confettiParams.initSpeed, confettiParams.initSpeed)
        };
        this.flipSpeed = rand(0.2, 1.5) * confettiParams.flipSpeed;

        if (this.position.y <= container.h) {
            this.velocity.y = -Math.abs(this.velocity.y);
        }

        this.terminalVelocity = rand(1, 1.5) * confettiParams.terminalVelocity;

        this.update = function () {
            this.velocity.x *= 0.98;
            this.position.x += this.velocity.x;

            this.velocity.y += (this.randomModifier * confettiParams.drag);
            this.velocity.y += confettiParams.gravity;
            this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
            this.position.y += this.velocity.y;

            this.scale.y = Math.cos((this.position.y + this.randomModifier) * this.flipSpeed);
            this.color = this.scale.y > 0 ? this.colorPair.front : this.colorPair.back;
        }
    }

    function updateConfetti() {
        confettiCtx.clearRect(0, 0, container.w, container.h);

        confettiElements.forEach((c) => {
            c.update();
            confettiCtx.translate(c.position.x, c.position.y);
            confettiCtx.rotate(c.rotation);
            const width = (c.dimensions.x * c.scale.x);
            const height = (c.dimensions.y * c.scale.y);
            confettiCtx.fillStyle = c.color;
            confettiCtx.fillRect(-0.5 * width, -0.5 * height, width, height);
            confettiCtx.setTransform(1, 0, 0, 1, 0, 0)
        });

        confettiElements.forEach((c, idx) => {
            if (c.position.y > container.h ||
                c.position.x < -0.5 * container.x ||
                c.position.x > 1.5 * container.x) {
                confettiElements.splice(idx, 1)
            }
        });
        window.requestAnimationFrame(updateConfetti);
    }

    function setupCanvas() {
        container = {
            w: confetti.clientWidth,
            h: confetti.clientHeight
        };
        confetti.width = container.w;
        confetti.height = container.h;
    }

    function addConfetti(e) {
        const canvasBox = confetti.getBoundingClientRect();
        if (e) {
            clickPosition = [
                e.clientX - canvasBox.left,
                e.clientY - canvasBox.top
            ];
        } else {
            clickPosition = [
                canvasBox.width * Math.random(),
                canvasBox.height * Math.random()
            ];
        }
        for (let i = 0; i < confettiParams.number; i++) {
            confettiElements.push(new Conf())
        }
    }

    function hideConfetti() {
        confettiElements = [];
        window.cancelAnimationFrame(updateConfetti)
    }

    confettiLoop();

    function confettiLoop() {
        addConfetti();
        setTimeout(confettiLoop, 700 + Math.random() * 1700);
    }
}
