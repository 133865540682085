import React from "react";
import withResultPurchase from "./init";
import {Link} from "react-router-dom";



const ResultPurchase = ({ resultDelegate }) => (
    <div className={"result-purchase-page"}>
        <img src={"/Images/man.png"} alt={"😼"}/>
        <div className={"result text"}>
            {resultDelegate.state.result === "success" ? "Поздравляем!" : "Упс!"}<br/>
            {resultDelegate.state.result === "success" ?
                `Вы успешно приобрели курс «${resultDelegate.state.data.course_name || ""}»` :
                "Что-то пошло не так и ваш платеж не прошел."
            }
        </div>
        <Link to={"/"} className={"go-to"}>
            {resultDelegate.state.result === "success" ? "Начать обучение" : "Пойти на главную"}
        </Link>
    </div>
);



export default withResultPurchase(ResultPurchase);