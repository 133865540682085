import config from "../etc/config";
import levelEasy from '../View/Pages/Games/img/level_easy.png';
import levelMedium from '../View/Pages/Games/img/level_medium.png';
import levelHard from '../View/Pages/Games/img/level_hard.png';

const GET_ALL_TAGS = 'GET-ALL_TAGS';
const SET_TAGS = 'SET_TAGS';
const SET_TAGS_WITH_LOCAL_STORAGE = 'SET_TAGS_WITH_LOCAL_STORAGE';
const SET_ACTIVE = 'SET_ACTIVE';
const SET_INACTIVE = 'SET_INACTIVE';
const COUNT_STEP = 'COUNT_STEP';
const GUESSED_CARD = 'GUESSED_CARD';
const SET_ITEM_LOCAL_STORAGE = 'SET_ITEM_LOCAL_STORAGE';
const CHANGE_IS_PROCESS_GAME = 'CHANGE_IS_PROCESS_GAME'
const IS_START_GAME = 'IS_START_GAME';
const SAVE_PROCESS_GAME = 'SAVE_PROCESS_GAME';
const CHECK_ON_GUESSED_CARD = 'CHECK_ON_GUESSED_CARD';
const CHANGE_ON_GUESSED_CARD = 'CHANGE_ON_GUESSED_CARD';
const CHANGE_TEXT_STEP = 'CHANGE_TEXT_STEP';
const IS_ACTIVE_ALL_CARDS = 'IS_ACTIVE_ALL_CARDS';
const CHANGE_CLASSLIST_CARD_NO_GUESSED = 'CHANGE_CLASSLIST_CARD_NO_GUESSED';
const RETURN_TO_FINISHED = 'BACK_TO_FINISHED';

let initialState = {
    activeCard: 0,
    tags: [],
    levels: [
        {
            levelText: '4 x 4',
            levelGame: 'Простой',
            cards: '16 карточек',
            tags: 8,
            column: 4,
            color: '#28C38A',
            img: levelEasy
        },
        {
            levelText: '4 x 6',
            levelGame: 'Средний',
            cards: '24 карточки',
            tags: 12,
            column: 4,
            color: '#FECD40',
            img: levelMedium
        },
        {
            levelText: '6 x 6',
            levelGame: 'Сложный',
            cards: '36 карточек',
            tags: 18,
            column: 6,
            color: '#FE9B40',
            img: levelHard
        }
    ],
    isFinishedButton: false,
    isStartChangeLevelGame: false,
    isSaveGame: false,
    game: {
        tags: [],
        column: 0,
        countStep: 0,
        textStep: '',
        isFinishedGame: false,
        countGuessed: 0,
        level: {levelGame: null, levelColor: null},
        isAllGuessedCard: false
    },
    shuffle(array) {
        array.sort(() => Math.random() - 0.5)
    }
}

const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TAGS: {
            return {
                ...state,
                tags: [...action.tags]
            }
        }
        case SET_TAGS: {
            let tags = [];
            state.shuffle(state.tags)
            for (let i = 0; i < action.tags; i++) {
                tags.push(state.tags[i])
            }
            let copyTags = tags.concat(tags)
            state.shuffle(copyTags)
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: [...copyTags],
                    column: action.column,
                    countStep: 0,
                    countGuessed: 0,
                    textStep: '',
                    level: {
                        ...state.game.level,
                        levelGame: action.levelGame,
                        levelColor: action.color
                    }
                }
            }
        }
        case SET_TAGS_WITH_LOCAL_STORAGE: {
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: [...action.tags],
                    countStep: action.countStep,
                    textStep: action.textStep,
                    column: action.column,
                    countGuessed: action.countGuessed,
                    level: {
                        ...state.game.level,
                        levelGame: action.levelGame,
                        levelColor: action.levelColor
                    }
                }
            }
        }
        case IS_ACTIVE_ALL_CARDS: {
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: state.game.tags.map(tag => {
                        return {...tag, isActive: action.isValue}
                    })
                }
            }
        }
        case SET_ACTIVE: {
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: state.game.tags.map((tag, index) => {
                        if (index === action.index) {
                            return {...tag, isActive: action.isActive}
                        } else {
                            return tag
                        }
                    })
                }
            }

        }
        case SET_INACTIVE: {
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: state.game.tags.map(item => {
                        if (item.id === action.id) {
                            return {...item, isActive: false, isGuessed: false}
                        } else {
                            return item
                        }
                    })
                },
                activeCard: 0,
            }
        }
        case CHANGE_CLASSLIST_CARD_NO_GUESSED: {
            return {
                ...state,
                game: {
                    ...state.game,
                    tags: state.game.tags.map(item => {
                        if (item.id === action.id) {
                            return {...item, classListCard: action.classListCard}
                        } else {
                            return item
                        }
                    })
                }
            }
        }
        case COUNT_STEP: {
            return {
                ...state,
                activeCard: state.activeCard + 1,
                game: {
                    ...state.game,
                    countStep: state.game.countStep + 1
                }

            }
        }
        case CHANGE_TEXT_STEP: {
            let stepText = '';
            if (state.game.countStep === 1 || state.game.countStep === 21 || state.game.countStep === 31 || state.game.countStep === 41 || state.game.countStep === 51 || state.game.countStep === 61 || state.game.countStep === 71) {
                stepText = 'ход'
            } else if (state.game.countStep >= 2 && 4 >= state.game.countStep || state.game.countStep >= 22 && 24 >= state.game.countStep || state.game.countStep >= 32 && 34 >= state.game.countStep || state.game.countStep >= 42 && 44 >= state.game.countStep) {
                stepText = 'хода'
            } else if (state.game.countStep >= 5) {
                stepText = 'ходов'
            }
            return {
                ...state,
                game: {
                    ...state.game,
                    textStep: stepText
                }

            }
        }
        case GUESSED_CARD: {
            return {
                ...state,
                game: {
                    ...state.game,
                    countGuessed: state.game.countGuessed + 2,
                    tags: state.game.tags.map(item => {
                        if (item.id === action.id) {
                            return {...item, isGuessed: action.isValue}
                        } else {
                            return item
                        }
                    })
                },
                activeCard: 0,
            }
        }
        case SET_ITEM_LOCAL_STORAGE: {
            localStorage.setItem("game", JSON.stringify(state.game))
            return state
        }
        case IS_START_GAME: {
            return {
                ...state,
                isStartChangeLevelGame: action.value
            }
        }
        case CHECK_ON_GUESSED_CARD: {
            if (state.game.tags.every(card => card.isGuessed === true)) {
                localStorage.clear()
                return {
                    ...state,
                    // isFinishedButton: false,
                    // isSaveGame: false,
                    // isStartButton: false,
                    game: {
                        ...state.game,
                        isAllGuessedCard: true
                        // isFinishedGame: true
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        }
        case RETURN_TO_FINISHED: {
            return {
                ...state,
                game: {
                    ...state.game,
                    isAllGuessedCard: false
                }
            }
        }
        case CHANGE_IS_PROCESS_GAME: {
            return {
                ...state, isFinishedButton: action.value
            }
        }
        case SAVE_PROCESS_GAME: {
            localStorage.clear()
            return {
                ...state,
                isSaveGame: action.value
            }
        }
        case CHANGE_ON_GUESSED_CARD: {
            return {
                ...state,
                game: {
                    ...state.game,
                    isFinishedGame: false
                }
            }
        }
        default:
            return state
    }
}

export const getAllTags = (tags) => {
    return {
        type: GET_ALL_TAGS,
        tags
    }
}

export const isStartGame = (value) => {
    return {
        type: IS_START_GAME,
        value
    }
}

export const saveProcessGame = (value) => {
    return {
        type: SAVE_PROCESS_GAME,
        value
    }
}

const countStep = () => {
    return {
        type: COUNT_STEP
    }
}

const changeTextStep = () => {
    return {
        type: CHANGE_TEXT_STEP
    }
}

const setTags = (tags, column, levelGame, color) => {
    return {
        type: SET_TAGS,
        tags, column, levelGame, color
    }
}

const setTagsWithLocalStorage = (tags, countStep, textStep, column, countGuessed, levelGame, levelColor) => {
    return {
        type: SET_TAGS_WITH_LOCAL_STORAGE,
        tags, countStep, textStep, column, countGuessed, levelGame, levelColor
    }
}

const guessedCard = (id, isValue) => {
    return {
        type: GUESSED_CARD,
        id, isValue
    }
}

const setItemLocalStorage = () => {
    return {
        type: SET_ITEM_LOCAL_STORAGE
    }
}

export const setActiveCard = (index, id) => {
    return {
        type: SET_ACTIVE,
        index, id, isActive: true
    }
}

const isActiveAllCards = (isValue) => {
    return {
        type: IS_ACTIVE_ALL_CARDS,
        isValue
    }
}

const setInActive = (id) => {
    return {
        type: SET_INACTIVE,
        id
    }
}

export const changeIsProcessGame = (value) => {
    return {
        type: CHANGE_IS_PROCESS_GAME,
        value
    }
}

const checkOnGuessedCard = () => {
    return {
        type: CHECK_ON_GUESSED_CARD
    }
}

export const changeOnGuessedCard = () => {
    return {
        type: CHANGE_ON_GUESSED_CARD
    }
}

export const returnToFinished = () => {
    return {
        type: RETURN_TO_FINISHED
    }
}

const changeClassListCardNoGuessed = (id, idCard, classListCard) => {
    return {
        type: CHANGE_CLASSLIST_CARD_NO_GUESSED,
        id, idCard, classListCard
    }
}

export const localStorageGetItemGameThunk = (token) => {
    if (localStorage.getItem('game') !== null) {
        return (dispatch) => {
            let game = JSON.parse(localStorage.getItem('game'))
            dispatch(changeIsProcessGame(true))
            dispatch(setTagsWithLocalStorage(game.tags, game.countStep, game.textStep, game.column, game.countGuessed, game.level.levelGame, game.level.levelColor))
        }
    } else {
        return (dispatch) => {
            dispatch(getTags(token))
        }
    }
}

let activeCard = 0;
let idCard = 0;

export const setActiveThunk = (index, id) => {
    return (dispatch) => {
        dispatch(countStep())
        dispatch(changeTextStep())
        dispatch(setActiveCard(index, id))
        if (activeCard !== 1) {
            idCard = id;
        }
        activeCard += 1;
        if (activeCard === 2 && idCard !== id) {
            setTimeout(() => {
                dispatch(changeClassListCardNoGuessed(id, idCard, 'cardNoGuessed'))
            }, 900)
            setTimeout(() => {
                dispatch(setInActive(idCard))
                dispatch(setInActive(id))
                dispatch(setItemLocalStorage())
                dispatch(changeClassListCardNoGuessed(id, idCard, ''))
            }, 1500)
            activeCard = 0;
        } else if (activeCard === 2 && idCard === id) {
            activeCard = 0;
            setTimeout(() => {
                dispatch(guessedCard(id, true))
                dispatch(setItemLocalStorage())
            }, 700)
            setTimeout(() => {
                dispatch(checkOnGuessedCard())
            }, 1500)
        }
    }
}

export const startChangeLevelThunk = () => {
    return (dispatch) => {
        dispatch(isStartGame(true))
        dispatch(saveProcessGame(false))
        dispatch(changeOnGuessedCard())
    }
}

export const startGameThunk = (tags, column, levelGame, color) => {
    return (dispatch) => {
        dispatch(setTags(tags, column, levelGame, color))
        setTimeout(() => {
            dispatch(isActiveAllCards(true))
        }, 700)
        dispatch(changeIsProcessGame(true))
        dispatch(saveProcessGame(false))
        setTimeout(() => {
            dispatch(isActiveAllCards(false))
        }, 5000)
    }
}

export const finishGameThunk = () => {
    return (dispatch) => {
        dispatch(isStartGame(false))
        dispatch(changeIsProcessGame(false))
        dispatch(saveProcessGame(true))
        dispatch(returnToFinished())
    }
}

export const getTags = (token) => {
    return (dispatch) => {
        fetch(`${config.API_PREFIX}games/pexeso/init`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 401)
                this.props.appDelegate.logout();

            if (res.status === 403 || res.status === 404 || res.status === 500) {
                this.setState({errorPage: res.status});
                throw new Error(res.statusText);
            }

            return res.json();
        })
            .then(res => {
                dispatch(getAllTags(res.tags))
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export default gameReducer;