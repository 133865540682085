import React, {useCallback, useEffect, useState} from 'react'
import styled from './Dasboard.module.scss'
import ChooseNewTask from './components/ChooseNewTask/ChooseNewTask'
import Practices from './components/Practices/Practices'
import PassedTasks from './components/PassedTasks/PassedTasks'
import Careers from './components/Careers/Careers'
import {useDispatch, useSelector} from "react-redux";
import {getDataForDashboard, InitialStateDashboardType} from '../../../redux/dashboardReducer'
import Subscription from './components/Subscription/Subscription'
import {Link} from 'react-scroll'
import {RootState} from '../../../redux/reducers';
import {AccountData, AppDelegateType} from '../../../types/app-delegate';
import {useTranslation} from "react-i18next";

type Props = {
    appDelegate: AppDelegateType;
}

const Dashboard = ({appDelegate}: Props) => {
    const dashboardData: InitialStateDashboardType = useSelector((state: RootState) => state.dashboardReducer)
    const dispatch = useDispatch()
    const {authToken} = appDelegate?.state as AccountData;
    const [showMobileNav, setShowMobileNav] = useState(false)
    const { t } = useTranslation();

    const handleMobileMenu = useCallback((): void => {
        showMobileNav ? setShowMobileNav(() => false) : setShowMobileNav(() => true)
    }, [showMobileNav, setShowMobileNav])

    useEffect(() => {
        dispatch(getDataForDashboard(authToken))
    }, [])
    return <div className="practice-home content-main">
        <div className={styled.wrapper}>
            <Subscription appDelegate={appDelegate}/>
            <div className={styled.navMenu}>
                <Link to="passed-tasks" spy={true} smooth={true} duration={1000}>
                    {t('dashboard.tasks')}
                </Link>
                <Link to="practices" spy={true} smooth={true} duration={1000}>
                    {t('dashboard.practice')}
                </Link>
                <Link to="careers" spy={true} smooth={true} duration={1000}>
                    {t('dashboard.career')}
                </Link>
            </div>
            <div className={styled.navMenuMobile}>
                <span onClick={handleMobileMenu}>
                    {!showMobileNav ? t('dashboard.open') : t('dashboard.close')} {t('dashboard.contents')}
                </span>
                {showMobileNav && <div className={styled.links}>

                    <Link to="passed-tasks" spy={true} smooth={true} duration={1000}>
                        {t('dashboard.tasks')}
                    </Link>
                    <Link to="practices" spy={true} smooth={true} duration={1000}>
                        {t('dashboard.practice')}
                    </Link>
                    <Link to="careers" spy={true} smooth={true} duration={1000}>
                        {t('dashboard.career')}
                    </Link>
                </div>}
            </div>
            <div className={styled.content}>
                <div id={"practices"}>
                    <Practices practices={dashboardData.practices}/>
                </div>
                <div id={"tests"}>
                    <ChooseNewTask tests={dashboardData.tests}/>
                </div>
                <div id={"passed-tasks"}>
                    <PassedTasks tests={dashboardData.tests}/>
                </div>
                <div id={"careers"}>
                    <Careers careers={dashboardData.careers}/>
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard;
