import React from "react"
import { Link } from "react-router-dom"

const Navigator = ({ next, prev, done, is_open, stage_id, query, isPractice }) => {
    return (
        <div className={"navigator"}>
            {prev ? (
                <Link className={"navigator__item prev"}
                      to={`/${isPractice ? "practice-theme" : "theme"}/${prev.id}${query}`}>
                    <div className={"container"}>
                        <div className={"container__image"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                <g fill="none" transform="matrix(-1 0 0 1 48 0)">
                                    <circle cx="24" cy="24" r="24" fill="#F2F3F5" transform="matrix(-1 0 0 1 48 0)" />
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="M32.803 28.596L22.197 29.303 22.904 18.697"
                                          transform="scale(-1 1) rotate(45 0 -42.39)" />
                                    <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M12 24h22" />
                                </g>
                            </svg>
                        </div>
                        <span>{prev.name}</span>
                    </div>
                </Link>
            ) : ""}
            {next ? (
                <Link
                    className={prev ? `navigator__item next ${!done || !is_open ? "disabled" : ""}` : `navigator__item next alone ${(!done && !is_open) ? "disabled" : ""}`}
                    to={(done || is_open) ? `/${isPractice ? "practice-theme" : "theme"}/${next.id}${query}` : ""}
                >
                    <div className={"container"}>
                        <span>{next.name}</span>
                        <div className={"container__image"}>
                            {done || is_open ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                    <g fill="none" transform="matrix(-1 0 0 1 48 0)">
                                        <circle cx="24" cy="24" r="24" fill="#F2F3F5"
                                                transform="matrix(-1 0 0 1 48 0)" />
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2" d="M32.803 28.596L22.197 29.303 22.904 18.697"
                                              transform="scale(-1 1) rotate(45 0 -42.39)" />
                                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                                              d="M12 24h22" />
                                    </g>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     width="48" height="48" viewBox="0 0 48 48">
                                    <defs>
                                        <path id="prefix__a"
                                              d="M30 58c15.464 0 28-12.536 28-28S45.464 2 30 2 2 14.536 2 30s12.536 28 28 28zm0 2C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30z" />
                                    </defs>
                                    <g fill="none" fillRule="evenodd">
                                        <g fillRule="nonzero" transform="translate(10, 10)">
                                            <rect width="18" height="12" x="3" y="9" fill="currentColor" rx="2" />
                                            <circle cx="12" cy="15" r="2" fill="#FFF" />
                                            <path stroke="currentColor" strokeWidth="2"
                                                  d="M17 8c0-2.761-2.239-5-5-5S7 5.239 7 8" />
                                        </g>
                                        <use fill="currentColor" fillRule="nonzero" transform="matrix(-1 0 0 1 60 0)"
                                             xlinkHref="#prefix__c" />
                                    </g>
                                </svg>

                            )}
                        </div>
                    </div>
                </Link>
            ) : (
                <Link
                    className={prev ? `navigator__item next ${!done ? "disabled" : ""}` : `navigator__item next alone ${!done ? "disabled" : ""}`}
                    to={done || is_open ? `/${isPractice ? "project-practical-task" : "practical-task"}/${stage_id}${query}` : ""}
                    onClick={(e) => {
                        if (!done || is_open) {
                            e.preventDefault()
                        }
                    }}>
                    <div className={"container"}>
                        <span>Практическое задание</span>
                        <div className={"container__image"}>
                            {done ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                    <g fill="none" transform="matrix(-1 0 0 1 48 0)">
                                        <circle cx="24" cy="24" r="24" fill="#F2F3F5"
                                                transform="matrix(-1 0 0 1 48 0)" />
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2" d="M32.803 28.596L22.197 29.303 22.904 18.697"
                                              transform="scale(-1 1) rotate(45 0 -42.39)" />
                                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2"
                                              d="M12 24h22" />
                                    </g>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     width="48" height="48" viewBox="0 0 48 48">
                                    <defs>
                                        <path id="prefix__a"
                                              d="M30 58c15.464 0 28-12.536 28-28S45.464 2 30 2 2 14.536 2 30s12.536 28 28 28zm0 2C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30z" />
                                    </defs>
                                    <g fill="none" fillRule="evenodd">
                                        <g fillRule="nonzero" transform="translate(10 10)">
                                            <rect width="18" height="12" x="3" y="9" fill="currentColor" rx="2" />
                                            <circle cx="12" cy="15" r="2" fill="#FFF" />
                                            <path stroke="currentColor" strokeWidth="2"
                                                  d="M17 8c0-2.761-2.239-5-5-5S7 5.239 7 8" />
                                        </g>
                                        <use fill="currentColor" fillRule="nonzero" transform="matrix(-1 0 0 1 60 0)"
                                             xlinkHref="#prefix__c" />
                                    </g>
                                </svg>

                            )}
                        </div>
                    </div>
                </Link>
            )}
        </div>
    )
}


export default Navigator
