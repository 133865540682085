import React, {Fragment, useEffect, useRef, useState} from "react";

const Video = ({link}) => {
    const [width, setWidth] = useState(0);
    const container = useRef();

    let video_id = link.split('com/')[1];
    const ampersandPosition = video_id.indexOf('&');
    if(ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }

    useEffect(() => {
        setWidth(container.current.clientWidth)
    }, []);

    return (
            <div ref={container}>
                    <Fragment>
                                <iframe
                                    src={`https://player.vimeo.com/video/${video_id}`}
                                    width={width}
                                    height={width * 9 / 16}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                />
                    </Fragment>
            </div>
    )
}
export default Video;