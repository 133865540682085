import React from 'react';
import styles from './Screen3.module.css';

const Screen3 = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Зачем вам <span className={styles.highlighted}>тесты</span>?</h2>
            <ul className={styles.whyTestsList}>
                <li className={styles.whyTestsListItem}>
                    <h4 className={styles.whyTestsHeading}>Это эффективно</h4>
                    <p className={styles.whyTestsDescription}>Тесты Educat помогают усваивать информацию быстрее, благодаря простоте подачи информации и интерактивности.</p>
                </li>
                <li className={styles.whyTestsListItem}>
                    <h4 className={styles.whyTestsHeading}>Это удобно</h4>
                    <p className={styles.whyTestsDescription}>Делать тесты можно, как на компьтере, так и на телефоне. Это очень удобно, не зависимо от того, где вы находитесь.</p>
                </li>
            </ul>
        </div>
    )
}

export default Screen3;