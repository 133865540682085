import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from './Videos.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {getWebinarsForDescription} from "../../../redux/videosReducer";
import Loader from "../../Components/Loader";
import ItemVideo from "./ItemVideo";
import SortVideos from "./components/SortVideos";
import {useTranslation} from "react-i18next";

const Videos = ({appDelegate}) => {
    const dispatch = useDispatch()
    const videosData = useSelector(state => state.videosReducer)
    const [countShow, setCountShow] = useState(window.innerWidth >= 1746 ? 12 : window.innerWidth >= 799 ? 9 : 6)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getWebinarsForDescription(appDelegate.state.authToken))
    }, [])

    const handlerCountShow = useCallback(() => {
        if(window.innerWidth >= 1746) {
            setCountShow(prev => prev + 8)
        } else if (window.innerWidth >= 799) {
            setCountShow(prev => prev + 6)
        } else {
            setCountShow(prev => prev + 4)
        }
    }, [])

    const arrayVideos = useMemo(() => {
        if (videosData.videos !== null) {
            return videosData.videos.slice(0, countShow)
        }
    }, [countShow, videosData.videos])

    return videosData.videos !== null ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <div className={styled.header}>
                <h1>
                    {t('videos.title')}
                </h1>
                <SortVideos categories={videosData.categories} />
            </div>
            <div className={styled.videos}>
                {arrayVideos.map(({title, link}, index) => <ItemVideo key={index} title={title} link={link}/>)}
            </div>
            {videosData.videos.length > 12 && <button className={styled.btnShowMore} onClick={handlerCountShow}>
                {t('btn.more')}
            </button>}
        </div>
    </div> : <Loader/>
}

export default Videos;