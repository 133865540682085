import { authApi } from "api/api";
import { History } from "history";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";

const redirectToProvider = async (provider: string, history: History): Promise<void> => {
  try {
    const response = await authApi.getSigninLinkViaSocialMedia(provider);
    window.location.replace(response.data.redirect);
  } catch (error) {
    console.error('Error while atempting to signin via social media', error);
		history.push(RegistrationRoutes.SIGN_IN);
  }
};

/** Hook to redirect to provider's page */
export const useRedirectToProvider = (): void => {
  const { search } = useLocation();
  const { provider } = useParams<{provider: string}>();
	const history = useHistory();

  React.useEffect(() => {
    if(search === "") {
      redirectToProvider(provider, history);
    } 
  }, [provider, search, history]);
};