import React, {useCallback} from 'react'
import s from './styled.module.scss'
import exitImg from '../../img/exit.png'
import {useDispatch} from "react-redux"
import ProgressCourse from "../ProgressCourse";
import {getTestForDescription} from '../../../../../redux/actions/description';
import {closeThemeTest} from '../../../../../redux/actions/tests';

type Props = {
    authToken: string;
    progressTheme: number;
    id: number;
}

const HeaderTheme = ({authToken, progressTheme, id}: Props) => {
    const dispatch = useDispatch()

    const closeTheme = useCallback(() => {
        dispatch(getTestForDescription(authToken, id))
        dispatch(closeThemeTest())
    }, [dispatch])

    return (<div className={s.wrapper}>
        <div className={s.left}>
            <div className={s.wrapperImg} onClick={closeTheme}><img src={exitImg} alt="X"/></div>
        </div>
        <div className={s.right}>
            <ProgressCourse progressTheme={progressTheme} />
        </div>
    </div>)
}

export default HeaderTheme;
