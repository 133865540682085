import React from "react";
import Task from "./Task";

const Test = ({themeDelegate, appDelegate, isSubscribed}) => (
    <div className={"test"}>
        <h2 className={"test-title"}>Тестирование</h2>

        {themeDelegate.state.data && themeDelegate.state.data.test ? themeDelegate.state.data.test.map((test, index) => (
            // <Index isSubscribed={isSubscribed} enable={test.free || (appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.items && appDelegate.state.user.subscriptions.items.includes("tests"))} themeDelegate={themeDelegate} appDelegate={appDelegate} key={index} test={test} index={index}/>
            <Task isSubscribed={isSubscribed} enable={test.free || (appDelegate.state.user && appDelegate.state.user.subscriptions.length !== 0)} themeDelegate={themeDelegate} appDelegate={appDelegate} key={index} test={test} index={index}/>
        )) : ""}
    </div>
)
export default Test;
