import React from 'react';
import style from "./StartGame.module.scss";
import {useDispatch} from "react-redux";
import {startChangeLevelThunk} from "../../../../redux/gameReducer";
import {useTranslation} from "react-i18next";

const StartGame = () => {
    let dispatch = useDispatch()
    const { t } = useTranslation();

    let startGame = () => {
        dispatch(startChangeLevelThunk())
    }

    return <div className={style.wrapper}>
        <h1>
            {t('startgame.title')}
        </h1>
        <p>
            {t('startgame.desc')}
        </p>
        <hr/>
        <div className={style.wrapperContent}>
            <div className={style.mainImg}>
                <button onClick={startGame}>
                    {t('startgame.btn')}
                </button>
            </div>
        </div>
    </div>
}

export default StartGame;