import React from "react";
import {Link} from "react-router-dom";

const ErrorPage = ( { error } ) => (
    <div>
        <h2>Error.</h2>
        <p>{error}</p>
        <Link to={"/"}>Go home</Link>
    </div>
)



export default ErrorPage;