import React, {useCallback, useState} from 'react'
import styled from './CommentItem.module.scss'
import replyIcon from '../../../../Pages/Tasks/img/reply-icon.svg'
import likeIcon from '../../../../Pages/Tasks/img/like-icon.svg'
import likeHoverIcon from '../../../../Pages/Tasks/img/like-icon-hover.svg'
import * as moment from "moment"
import {useDispatch} from "react-redux"
import {
    changeStatusLikeThunk,
    replyUserOnCommentThunk
} from '../../../../../redux/discussionReducer'
import ladaIcon from "../../../../Pages/Tasks/img/lada.svg"
import ReplayComment from "../ReplayComment/ReplayComment"
import {useTranslation} from "react-i18next";

const CommentItem = ({comment, avatar, icon, createdAt, fullName, idComment, isLiked, likes, replies, authToken}) => {
    const [showTextarea, setShowTextarea] = useState(false)
    const dispatch = useDispatch()
    const [replyComment, setReplyComment] = useState(`${fullName}, `)
    const { t } = useTranslation();

    const handleShowTextarea = () => {
        showTextarea ? setShowTextarea(false) : setShowTextarea(true)
    }

    const sendReplyComment = () => {
        dispatch(replyUserOnCommentThunk(authToken, idComment, replyComment))
        showTextarea ? setShowTextarea(false) : setShowTextarea(true)
        setReplyComment(`${fullName}, `)
    }

    const changeReplyComment = useCallback((e) => {
        setReplyComment(e.target.value)
    }, [setReplyComment])

    const changeLikeComment = (id) => {
        dispatch(changeStatusLikeThunk(authToken, id))
    }

    return <div className={styled.wrapper}>
        <div className={styled.content}>
            <div className={styled.mainComment}>
                {avatar !== null
                    ? <img className={styled.userIcon} src={avatar} alt="Image"/>
                    : <img className={styled.userIcon} src={icon} alt="Image"/>}
                <div className={styled.description}>
                    <h3>{fullName}</h3>
                    <span className={styled.date}>{moment(createdAt).fromNow()}</span>
                    <span className={styled.comment}>{comment}</span>
                    <div className={styled.buttons}>
                        <button className={styled.buttonReply} onClick={handleShowTextarea}><img src={replyIcon}
                                                                                                 alt="reply"/>
                            {t('btn.reply')}
                        </button>
                        <div className={styled.likeBox} onClick={() => changeLikeComment(idComment)}>
                            {isLiked ? <img src={likeHoverIcon} alt="<3"/> : <img src={likeIcon} alt="<3"/>}
                            <span>{likes}</span>
                        </div>
                    </div>
                </div>
            </div>
            {replies.length !== 0 && <div className={styled.replaysComments}>
                {replies.map(({comment, avatar, icon, createdAt, fullName, id, isLiked, likes}, index) => <div
                    className={styled.replayCommentItem}><ReplayComment key={index}
                                                                        comment={comment}
                                                                        avatar={avatar}
                                                                        createdAt={createdAt}
                                                                        fullName={fullName}
                                                                        id={id}
                                                                        idComment={idComment}
                                                                        isLiked={isLiked}
                                                                        likes={likes}
                                                                        icon={ladaIcon}
                                                                        authToken={authToken}
                /></div>)}
            </div>}
            {showTextarea && <div className={styled.replyComment}>
                {avatar !== null
                    ? <img className={styled.userIcon} src={avatar} alt="Image"/>
                    : <img className={styled.userIcon} src={icon} alt="Image"/>}
                <div className={styled.textareaForm}>
                    <textarea value={replyComment} onChange={changeReplyComment}></textarea>
                    <button className={styled.buttonSend}
                            onClick={sendReplyComment}>
                        {t('btn.send')}
                    </button>
                </div>
            </div>}
        </div>
    </div>
}

export default CommentItem;