import React from 'react';
import {Link} from "react-router-dom";
import useCheckAdmin from "../../../hooks/useCheckAdmin";
import {useTranslation} from "react-i18next";

const Footer = ({appDelegate}) => {
    const role = useCheckAdmin(appDelegate.state.user.email)
    const { t } = useTranslation();
    return <div className="footer">
        <div className={"footer__content"}>
            <div className={"footer-left"}>
                <div className={"footer-left__top"}>
                    {/*<a href={appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date ? "/subscription" : "/billing"}*/}
                    <a href={"/billing"} className={"top-menu"}>
                        {t('header.signup')} <img src={'/Images/arrow-grey.svg'} alt={""}/>
                    </a>
                    <Link className="top-menu" to={"/contact-us"}>
                        {t('nav.contacts')} <img src={'/Images/arrow-grey.svg'} alt={""} />
                    </Link>
                    <a href={"https://educat.courses/about"} className="top-menu">
                        {t('nav.about')} <img src={'/Images/arrow-grey.svg'} alt={""}/>
                    </a>
                    {role && <Link to={{
                        pathname: '/roulette',
                        state: { admin: true }
                    }} className={"top-menu"}>
                        {t('nav.roulette')}
                    </Link>}
                </div>
                <div className="footer-left__bottom">
                    <span>© {(new Date()).getFullYear()} educat.courses</span>
                    <Link className="name" to={"/policy"}>
                        {t('nav.policy')}
                    </Link>
                    <Link className="name" to={"/offer"}>
                        {t('nav.offer')}
                    </Link>
                </div>
                <div className={"footer-left__bottom-mobile"}>
                    <span>© {(new Date()).getFullYear()} educat.courses</span>
                    <div className={"mobile-menu"}>
                        <Link className="name" to={"/policy"}>
                            {t('nav.policy.m')}
                        </Link>
                        <Link className="name" to={"/offer"}>
                            {t('nav.offer.m')}
                        </Link>
                    </div>

                </div>
            </div>
            <div className={"footer-right"}>
                <p>
                    {t('footer.cta')}
                </p>
                <div className={"social"}>
                    <a href="https://www.instagram.com/educat.courses/" rel="noopener noreferrer" target={"_blank"}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28"
                             height="28" viewBox="0 0 28 28">
                            <defs>
                                <path id="l8k6zv8vea" d="M1 1L27 1 27 27 1 27z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <mask id="qfj2jlb0pb" fill="#fff">
                                    <use xlinkHref="#l8k6zv8vea"/>
                                </mask>
                                <path fill="currentColor"
                                      d="M22.955 18.447c-.05 1.071-.227 1.653-.378 2.042-.201.514-.438.877-.823 1.265-.385.381-.751.622-1.269.82-.385.15-.967.33-2.038.38-1.161.054-1.51.062-4.447.062-2.937 0-3.282-.008-4.443-.061-1.071-.05-1.654-.23-2.042-.381-.514-.198-.88-.439-1.265-.82-.385-.388-.622-.751-.824-1.265-.15-.389-.33-.971-.377-2.042-.054-1.158-.068-1.51-.068-4.443 0-2.94.014-3.286.068-4.45.047-1.068.226-1.654.377-2.039.202-.514.439-.88.824-1.265.384-.385.751-.622 1.265-.824.388-.15.97-.33 2.042-.38 1.161-.05 1.506-.062 4.443-.062 2.937 0 3.286.011 4.447.061 1.071.05 1.653.23 2.038.381.518.202.884.439 1.269.824.385.384.622.751.823 1.265.151.385.327.97.378 2.038.053 1.165.064 1.51.064 4.45 0 2.934-.01 3.286-.064 4.444m1.98-8.98c-.054-1.172-.24-1.97-.51-2.67-.284-.723-.658-1.338-1.27-1.95-.614-.61-1.225-.988-1.947-1.268-.701-.273-1.5-.46-2.671-.51C17.36 3.013 16.987 3 14 3s-3.361.014-4.533.068c-1.172.05-1.974.238-2.67.51-.723.281-1.338.659-1.95 1.27-.61.61-.988 1.226-1.268 1.948-.27.701-.457 1.5-.51 2.671C3.013 10.639 3 11.017 3 14.004s.014 3.357.068 4.529c.054 1.175.241 1.974.51 2.675.281.722.659 1.337 1.27 1.948.61.607 1.226.985 1.948 1.265.697.273 1.5.46 2.671.514 1.172.05 1.546.065 4.533.065s3.361-.014 4.537-.065c1.171-.054 1.97-.24 2.67-.514.723-.28 1.334-.658 1.949-1.265.611-.611.985-1.226 1.269-1.948.27-.701.456-1.5.51-2.675.05-1.172.065-1.542.065-4.53 0-2.987-.014-3.364-.065-4.536M14 17.667c-2.024 0-3.667-1.64-3.667-3.663 0-2.028 1.643-3.67 3.667-3.67 2.027 0 3.667 1.642 3.667 3.67 0 2.023-1.64 3.663-3.667 3.663m0-9.314c-3.117 0-5.647 2.53-5.647 5.65 0 3.117 2.53 5.648 5.647 5.648 3.12 0 5.651-2.53 5.651-5.647 0-3.12-2.53-5.651-5.651-5.651m4.551-.223c0 .73.593 1.319 1.323 1.319.726 0 1.32-.59 1.32-1.32 0-.729-.594-1.319-1.32-1.319-.73 0-1.323.59-1.323 1.32"
                                      mask="url(#qfj2jlb0pb)"/>
                            </g>
                        </svg>
                    </a>
                    {/*<a href="https://www.facebook.com/educat.courses/" rel="noopener noreferrer" target={"_blank"}>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">*/}
                    {/*        <defs>*/}
                    {/*            <path id="gfxcopm9aa" d="M0 0H24V24H0z"/>*/}
                    {/*            <path id="canfhyq9gc" d="M0 0H24V24H0z"/>*/}
                    {/*        </defs>*/}
                    {/*        <g fill="none" fillRule="evenodd" transform="translate(2 2)">*/}
                    {/*            <mask id="g1anqx2sqb" fill="#fff">*/}
                    {/*                <use xlinkHref="#gfxcopm9aa"/>*/}
                    {/*            </mask>*/}
                    {/*            <g mask="url(#g1anqx2sqb)">*/}
                    {/*                <mask id="sfagykhc6d" fill="#fff">*/}
                    {/*                    <use xlinkHref="#canfhyq9gc"/>*/}
                    {/*                </mask>*/}
                    {/*                <path fill="currentColor" d="M13.729 23.158V12.98h3.416l.51-3.97H13.73V6.48c0-1.148.32-1.933 1.965-1.933h2.1V.997c-.365-.046-1.61-.155-3.06-.155-3.03 0-5.103 1.848-5.103 5.243v2.924H6.206v3.97h3.425v10.18h4.098z" mask="url(#sfagykhc6d)"/>*/}
                    {/*            </g>*/}
                    {/*        </g>*/}
                    {/*    </svg>*/}
                    {/*</a>*/}
                    <a href="https://vk.com/educat.courses" rel="noopener noreferrer" target={"_blank"}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <path id="exdvc3vsna" d="M0.596 0.596L25.748 0.596 25.748 25.748 0.596 25.748z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                <mask id="nslo5dhixb" fill="#fff">
                                    <use xlinkHref="#exdvc3vsna"/>
                                </mask>
                                <path fill="currentColor" d="M24.586 7.025c.168-.555 0-.96-.792-.96h-2.613c-.67 0-.977.353-1.145.74 0 0-1.33 3.245-3.215 5.355-.607.607-.885.798-1.22.798-.168 0-.405-.19-.405-.746V7.025c0-.659-.196-.96-.746-.96h-4.116c-.417 0-.665.313-.665.602 0 .63.942.775 1.04 2.55v3.857c0 .844-.156 1-.485 1-.89 0-3.048-3.261-4.325-6.99-.255-.723-.503-1.018-1.174-1.018h-2.62c-.745 0-.896.352-.896.74 0 .694.885 4.134 4.135 8.679 2.162 3.105 5.21 4.793 7.979 4.793 1.665 0 1.873-.376 1.873-1.023v-2.342c0-.752.156-.896.683-.896.393 0 1.052.19 2.607 1.688 1.775 1.775 2.07 2.573 3.065 2.573h2.62c.745 0 1.12-.376.901-1.11-.231-.74-1.081-1.81-2.203-3.076-.613-.717-1.526-1.492-1.804-1.885-.387-.497-.277-.717 0-1.162 0 0 3.186-4.487 3.521-6.02" mask="url(#nslo5dhixb)"/>
                            </g>
                        </svg>

                    </a>
                    <a href="https://www.youtube.com/channel/UCoL3qFc1sS8iS_P30bdS7VA?view_as=subscriber" rel="noopener noreferrer" target={"_blank"}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <path id="zb1mi1ciba" d="M0 0L26 0 26 26 0 26z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                <mask id="gww2sz1jjb" fill="#fff">
                                    <use xlinkHref="#zb1mi1ciba"/>
                                </mask>
                                <path fill="currentColor" d="M10.6 16.6V9.4l6.236 3.602L10.6 16.6zm13.899-9.379c-.277-1.029-1.088-1.843-2.124-2.12C20.506 4.6 13 4.6 13 4.6s-7.506 0-9.379.501c-1.032.277-1.843 1.091-2.12 2.12C1 9.094 1 13.001 1 13.001s0 3.905.501 5.778c.277 1.029 1.088 1.843 2.12 2.12C5.494 21.4 13 21.4 13 21.4s7.506 0 9.375-.501c1.036-.277 1.847-1.091 2.124-2.12.501-1.873.501-5.777.501-5.777s0-3.908-.501-5.78z" mask="url(#gww2sz1jjb)"/>
                            </g>
                        </svg>

                    </a>
                    <a href="https://twitter.com/Alexand91261471" rel="noopener noreferrer" target={"_blank"}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <path id="gi8ywpyora" d="M0 0L26 0 26 26 0 26z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                <mask id="cwqfuz97kb" fill="#fff">
                                    <use xlinkHref="#gi8ywpyora"/>
                                </mask>
                                <path fill="currentColor" d="M21.41 6.545c.932-.544 1.648-1.408 1.983-2.435-.873.506-1.838.87-2.866 1.069-.824-.854-1.996-1.389-3.296-1.389-2.491 0-4.513 1.971-4.513 4.4 0 .345.04.68.118 1-3.752-.182-7.077-1.935-9.303-4.597-.387.65-.61 1.405-.61 2.21 0 1.527.797 2.874 2.008 3.66-.738-.022-1.434-.22-2.045-.55v.055c0 2.13 1.556 3.91 3.621 4.312-.377.1-.778.154-1.188.154-.292 0-.575-.029-.85-.08.574 1.747 2.242 3.02 4.214 3.055-1.546 1.18-3.489 1.884-5.606 1.884-.365 0-.723-.022-1.077-.06 1.996 1.247 4.37 1.977 6.92 1.977 8.301 0 12.841-6.703 12.841-12.515 0-.192-.003-.381-.013-.57.883-.62 1.648-1.395 2.252-2.278-.808.355-1.677.592-2.59.698z" mask="url(#cwqfuz97kb)"/>
                            </g>
                        </svg>

                    </a>
                    <a href="https://www.tumblr.com/blog/educatcourses" rel="noopener noreferrer" target={"_blank"}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                            <defs>
                                <path id="78ya37t8fa" d="M1 1L27 1 27 27 1 27z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <mask id="h5u4b2f5xb" fill="#fff">
                                    <use xlinkHref="#78ya37t8fa"/>
                                </mask>
                                <path fill="currentColor" d="M16.31 20.136c-1.671.044-1.994-1.181-2.009-2.07v-6.172h3.895V8.317h-3.88V3h-3.058c-.05 0-.138.045-.15.157-.18 1.637-.942 4.511-4.108 5.66v3.077h2.41v6.497c0 2.35 1.426 5.686 5.973 5.608 1.535-.026 3.24-.673 3.617-1.23l-1.004-2.995c-.389.185-1.13.347-1.686.361v.001z" mask="url(#h5u4b2f5xb)"/>
                            </g>
                        </svg>

                    </a>
                </div>

            </div>

        </div>

    </div>
}

export default Footer;