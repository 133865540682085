import React, {useEffect} from 'react'
import styled from './styled.module.scss'
import HeaderTheme from "../HeaderTheme"
import Video from "../Video/Video"
import {TaskItem} from '../../../../../types/tests';
import {useTranslation} from "react-i18next";

type Props = {
    handleShowTask: () => void;
    authToken: string;
    progressTheme: number;
    setShowTask: (value: boolean) => void;
    task: TaskItem;
    titleTheme: string;
    id: number;
}

const Theory = ({handleShowTask, authToken, progressTheme, setShowTask, task, titleTheme, id}: Props) => {
    const videoPlayer = task.video_link?.split('/').includes('www.youtube.com')
    const { t } = useTranslation();

    useEffect(() => {
        if(task.theory === null || task.video_link === null) {
            setShowTask(true)
        }
    }, [])

    return task.theory !== null && <div className={styled.wrapper}>
        <div className={styled.wrapperContent}>
            <HeaderTheme authToken={authToken} progressTheme={progressTheme} id={id} />
            <div className={styled.titleTheme}>
                <h4>{titleTheme}</h4>
            </div>
            <div className={styled.content}>
                <div className={styled.video}>
                    {!videoPlayer
                        ? <Video link={task.video_link}/>
                        : null
                    }
                </div>
                <h6>{task.title}</h6>
                <p>{task.theory}</p>
                {task.code_example !== null &&
                <div className={styled.exampleCodeWrapper}>
                    <div className={styled.exampleCode}>
                        <div
                            dangerouslySetInnerHTML={{__html: task.code_example}}
                            className={"html-data"}/>
                    </div>
                </div>}

            </div>
        </div>
        <footer>
            <button onClick={handleShowTask}>
                {t('task.single.start')}
            </button>
        </footer>
    </div>
}

export default Theory
