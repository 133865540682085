import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {useStudentAccess} from '../../../hooks/useStudentAccess';
import {AppDelegateType} from '../../../types/app-delegate';
import {useTranslation} from "react-i18next";

type Props = {
    appDelegate: AppDelegateType;
}

const MenuAccount = ({appDelegate}: Props) => {
    const [withGlobalMenu, toggleGlobalMenu] = useState<boolean>(false);
    const isCheckedStudent = useStudentAccess(appDelegate.state!.user.email);
    const { t } = useTranslation();
    return (
        <aside className={"menu-account"}>
            <button onClick={() => toggleGlobalMenu(!withGlobalMenu)} className={"button-mobile"}>
                {t('sidebar.title')}
            </button>
            <div className={withGlobalMenu ? "menu-account__main active" : "menu-account__main"}>
                <div className={"main-content"}>
                    <a className={"menu-logo first-style"} href={"/main"}><img src={"/Images/logo.svg"} alt={"😼"}/></a>
                    <div className={"menu-box"}>
                        <NavLink exact={true} className={"button-menu first-style"}
                                 onClick={() => toggleGlobalMenu(false)} to={"/"}>
                            <img className={"icon"} src="/Images/all-parts.png" alt={"😼"}/>
                            <img className={"icon-hover"} src="/Images/all-parts-hover.png" alt={"😼"}/>
                            {t('sidebar.sections')}
                        </NavLink>
                        <div className={"important-part"}>
                            <NavLink className={"button-menu second-style"}
                                     onClick={() => toggleGlobalMenu(false)} to={"/tests"}>
                                <img className={"icon"} src="/Images/tests.svg" alt={"😼"}/>
                                <img className={"icon-hover"} src="/Images/tests-hover.svg" alt={"😼"}/>
                                {t('sidebar.tasks')}
                            </NavLink>
                        </div>
                        <div className={"important-part"}>
                            <NavLink exact={true} className={"button-menu second-style"}
                                     onClick={() => toggleGlobalMenu(false)} to={"/all-courses"}>
                                <img className={"icon"} src="/Images/all-courses.svg" alt={"😼"}/>
                                <img className={"icon-hover"} src="/Images/all-courses-hover.svg" alt={"😼"}/>
                                {t('sidebar.courses')}
                            </NavLink>
                        </div>
                        <div className={"important-part"}>
                            <NavLink className={"button-menu first-style"}
                                     onClick={() => toggleGlobalMenu(false)} to={"/practices"}>
                                <img className={"icon"} src="/Images/practice.svg" alt={"😼"}/>
                                <img className={"icon-hover"} src="/Images/practice-hover.svg" alt={"😼"}/>
                                {t('sidebar.practice')}
                            </NavLink>
                        </div>

                        <NavLink className={"button-menu first-style"}
                                 onClick={() => toggleGlobalMenu(false)} to={"/games"}>
                            <img className={"icon"} src="/Images/games.svg" alt={"😼"}/>
                            <img className={"icon-hover"} src="/Images/games-hover.svg" alt={"😼"}/>
                            {t('sidebar.games')}
                        </NavLink>
                        <NavLink className={"button-menu first-style"}
                                 onClick={() => toggleGlobalMenu(false)} to={"/webinars"}>
                            <img className={"icon"} src="/Images/vebinar.svg" alt={"😼"}/>
                            <img className={"icon-hover"} src="/Images/vebinar-hover.svg" alt={"😼"}/>
                            {t('sidebar.video')}
                        </NavLink>
                        <NavLink className={"button-menu first-style"}
                                 onClick={() => toggleGlobalMenu(false)} to={"/jobs"}>
                            <img className={"icon"} src="/Images/career.svg" alt={"😼"}/>
                            <img className={"icon-hover"} src="/Images/career-hover.svg" alt={"😼"}/>
                            {t('sidebar.work')}
                        </NavLink>
                        {/*<NavLink className={"button-menu first-style"}*/}
                        {/*      onClick={() => toggleGlobalMenu(false)} to={"/certificate"}>*/}
                        {/*    <img className={"icon"} src="/Images/certificate.svg" alt={"😼"}  />*/}
                        {/*    <img className={"icon-hover"} src="/Images/certificate-hover.svg" alt={"😼"} />*/}
                        {/*    Сертификаты</NavLink>*/}
                        {/*<NavLink className={"button-menu first-style"}*/}
                        {/*      onClick={() => toggleGlobalMenu(false)} to={"/orders"}>*/}
                        {/*    <img className={"icon"} src="/Images/order.svg" alt={"😼"}  />*/}
                        {/*    <img className={"icon-hover"} src="/Images/order-hover.svg" alt={"😼"} />*/}
                        {/*    Заказы</NavLink>*/}
                        <NavLink className={"button-menu first-style"}
                                 onClick={() => toggleGlobalMenu(false)} to={"/helper"}>
                            <img className={"icon"} src="/Images/tel.svg" alt={"😼"}/>
                            <img className={"icon-hover"} src="/Images/tel-hover.svg" alt={"😼"}/>
                            {t('sidebar.contacts')}
                        </NavLink>
                    </div>
                </div>
            </div>
        </aside>
    );
};
export default MenuAccount;
