import React, {useEffect, useState} from 'react';
import style from './styled.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {arrayMove} from "react-sortable-hoc";
import Footer from "../Footer/Footer";
import HeaderTheme from "../HeaderTheme";
import ChangeOneAnswer from "../TypesTasks/ChangeOneAnswer/ChangeOneAnswer";
import ChangeSomeAnswers from "../TypesTasks/ChangeSomeAnswer/ChangeSomeAnswers";
import AddText from "../TypesTasks/AddText/AddText";
import DragAndDropColumn from "../TypesTasks/DropAndDrag/DragAndDropColumn";
import DragAndDropRow from "../TypesTasks/DropAndDrag/DragAndDropRow";
import RightAnswer from "../RightAnswer/RightAnswer";
import PromptTask from "../PromptTask/PromptTask";
import Discussion from "../../../../Components/Discussion/Discussion";
import AnswerTrue from "../ResultAnswer/AnswerTrue";
import AnswerFalse from "../ResultAnswer/AnswerFalse";
import {useHistory} from "react-router-dom";
import ScrollToTop from '../../../../Components/ScrollToTop/ScrollToTop';
import {checkTaskThunk} from '../../../../../redux/actions/tests';

const Task = ({isViewAnswer, progressTheme, infoUser, switchNextTask, task, id}) => {
    const dispatch = useDispatch();
    const [currentTaskVariants, setCurrentTaskVariants] = useState(useSelector(state => state.tasksReducer.currentTask.variants));
    const [oneAnswer, setOneAnswer] = useState('female');
    const [someAnswers, setSomeAnswers] = useState([]);
    const [resultDropAndDrag, setDropAndDragResult] = useState([]);
    const [hideResultAnswer, setResultAnswer] = useState(task.is_answered);
    const [addText, setAddText] = useState([]);
    const [helpTask, setHelpTask] = useState(false);
    const [showDiscussion, setShowDiscussion] = useState(false);
    const history = useHistory();
    const arrayTasks = useSelector(state => state.tasksReducer.currentTheme);

    useEffect(() => {
        if (task.is_free === 0 && infoUser.user.subscriptions.length === 0) {
            history.push(`/billing`);
        }
    }, []);

    useEffect(() => {
        if (task.is_correct === true || task.is_correct === 1) {
            setResultAnswer(true);
        }
        if (task.is_correct === false) {
            setResultAnswer(false);
        }
    }, [task.is_correct]);

    const verifyAnswerUser = () => {
        switch (task.type_task) {
            case 1:
                dispatch(checkTaskThunk(task.id, oneAnswer.split(), infoUser.authToken));
                break;
            case 2:
                dispatch(checkTaskThunk(task.id, addText, infoUser.authToken));
                break;
            case 3:
                dispatch(checkTaskThunk(task.id, currentTaskVariants, infoUser.authToken));
                break;
            case 4:
                const body = someAnswers.sort((a, b) => a.index > b.index ? 1 : -1).map(i => i.value);
                dispatch(checkTaskThunk(task.id, body, infoUser.authToken));
                break;
            case 5:
                dispatch(checkTaskThunk(task.id, resultDropAndDrag, infoUser.authToken));
        }
    };

    //выбрать один вариант ответа
    const changeAnswer = (answer) => {
        setOneAnswer(answer);
    };

    // выбрать несколько вариантов ответа
    const changeSomeAnswer = (value, index) => {
        const copyAnswers = [...someAnswers];
        const idx = copyAnswers.findIndex(x => x.index === index);
        if (idx > -1) {
            copyAnswers.splice(idx, 1);
        } else {
            copyAnswers.push({index, value});
        }
        setSomeAnswers(copyAnswers);
    };

    // вписать текст
    const writeText = (value, index) => {
        setAddText(prev => {
            prev[index] = value;
            return [...prev];
        });
    };

    // перетащить текст по строке(добавить слово)
    const dropAndDragAddWord = (item) => {
        if (!isViewAnswer && task.is_correct === 0) {
            setDropAndDragResult([...resultDropAndDrag, item]);
        }
    };

    // перетащить текст по строке(Удалить слово)
    const dropAndDragDeleteWord = (item) => {
        setDropAndDragResult(resultDropAndDrag.filter(i => item !== i));
    };

    // перетащить текст по колонке
    const handleDropAndDragColumn = ({oldIndex, newIndex}) => {
        setCurrentTaskVariants(prev => arrayMove(prev, oldIndex, newIndex));
    };

    return <div className={style.wrapperTask}>
        <ScrollToTop/>
        <div className={style.wrapperContent}>
            <HeaderTheme authToken={infoUser.authToken} progressTheme={progressTheme} id={id}/>
            <div className={style.titleTask}>
                <div
                    dangerouslySetInnerHTML={{__html: task.question}}
                    className={style.questionTask}/>
            </div>
            {task.type_task === 1 ? (
                    <ChangeOneAnswer rightAnswer={task.answer}
                                     isCorrectTask={task.is_correct}
                                     oneAnswer={oneAnswer}
                                     changeAnswer={changeAnswer}
                                     isViewAnswer={isViewAnswer}
                                     passedRightAnswer={task.right_answer}
                                     variants={task.variants}
                    />)
                : task.type_task === 4 ? (
                        <ChangeSomeAnswers rightAnswer={task.answer}
                                           someAnswers={someAnswers}
                                           changeAnswers={changeSomeAnswer}
                                           isCorrectTask={task.is_correct}
                                           isViewAnswer={isViewAnswer}
                                           passedRightAnswer={task.right_answer}/>)
                    : task.type_task === 2 ? (
                            <AddText writeText={writeText}
                                     isCorrectTask={task.is_correct}
                                     isViewAnswer={isViewAnswer}
                                     passedRightAnswer={task.right_answer}
                                     rightAnswer={task.answer}
                                     addText={addText}
                                     variants={task.variants}
                            />)
                        : task.type_task === 3 ? (
                                <DragAndDropColumn isRightAnswer={isViewAnswer}
                                                   variants={currentTaskVariants}
                                                   handleDropAndDrag={handleDropAndDragColumn}
                                                   isCorrectTask={task.is_correct}
                                />)
                            : task.type_task === 5 ? (
                                    <DragAndDropRow variants={task.variants}
                                                    result={resultDropAndDrag}
                                                    isCorrectTask={task.is_correct}
                                                    changeItem={dropAndDragAddWord}
                                                    deleteItem={dropAndDragDeleteWord}
                                                    isViewAnswer={isViewAnswer}
                                                    passedRightAnswer={task.right_answer}
                                    />)
                                : ''}
            <RightAnswer isCorrectTask={task.is_correct} rightAnswer={task.answer}/>
        </div>
        <Footer
            showDiscussion={setShowDiscussion}
            showHelpTask={setHelpTask}
            verifyAnswerUser={verifyAnswerUser}
            task={task}
            isViewAnswer={isViewAnswer}
            arrayTasks={arrayTasks}
            isDisabled={task.type_task === 2 && !addText.length}
        />
        <div style={{display: "none"}}><PromptTask task={task} helpTask={helpTask} closeHelpTask={setHelpTask}/></div>
        <div style={{display: "none"}}><Discussion task={task} showDiscussion={showDiscussion}
                                                   closeDiscussion={setShowDiscussion} infoUser={infoUser}/></div>

        {!isViewAnswer &&
        hideResultAnswer === true
            ? <AnswerTrue hideResult={switchNextTask}
                          openHelpTask={setHelpTask}
                          openDiscussion={setShowDiscussion}
                          task={task}
            />
            : hideResultAnswer === false ?
                <AnswerFalse hideResult={switchNextTask}
                             openHelpTask={setHelpTask}
                             openDiscussion={setShowDiscussion}
                             task={task}
                />
                : null
        }
    </div>;
};

export default Task;
