import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLocales} from "../../../redux/localesReducer";
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import _ from "underscore";

const LangSwitcher = ({appDelegate}) => {
    const [isOpen, toggleOpen] = useState(false);
    const authToken = appDelegate.state.authToken;
    const currentLocale = appDelegate.state.user.locale;
    const dispatch = useDispatch();
    const locales = useSelector(state => state.localesReducer.locales, _.isEqual);
    const { i18n } = useTranslation();

    const handleLangToggle = () => {
        toggleOpen(!isOpen);
    };

    const handleLangChange = (locale) => {
        i18n.changeLanguage(locale.locale);
        appDelegate.changeLocale(locale);
        toggleOpen(false);
    }

    useEffect(() => {
        dispatch(getLocales(authToken));
        i18n.changeLanguage(currentLocale);
    },[locales]);

    const localeDropdownItems = locales.map((locale, index) => {
        const isActive = locale.locale === currentLocale;
        return <a key={`locale${index}`} onClick={()=> handleLangChange(locale)} className={isActive ? "active" : ''}>{locale.locale}</a>
    });

    const innerContent = isOpen ? (
        <div className={"lang-switcher__inner"}>
            {localeDropdownItems}
        </div>
    ) : null;

    return (
        <div className={"lang-switcher"}>
            <Button onClick={() => handleLangToggle()} className={"lang-switcher__current"}>
                {currentLocale}
            </Button>
            {innerContent}
        </div>
    );
};



export default LangSwitcher;