import React from 'react';
import { useLocation } from 'react-router'
import { ResetPasswordSearchParams } from '../types';

export const useResetPasswordSearchParams = (): ResetPasswordSearchParams => {
  const { search } = useLocation();
  const searchParams = React.useMemo<ResetPasswordSearchParams>(() => {
    const searchParams = new URLSearchParams(search);

    return {
      email: searchParams.get('email') || '',
      token: searchParams.get('token') || ''
    };
  }, [search]);

  return searchParams;
}