import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import './DragAndDrop.scss';
import {useSelector} from "react-redux";

const SortableItem = SortableElement(({value, isRightAnswer, isCorrectTask}) => {
    return <li isRightAnswer={isRightAnswer} style={{zIndex: 99999999}}
               className={isCorrectTask === 0 ? "SortableItem" : isCorrectTask ? "SortableItem isRight" : "SortableItem isFalse"}>{value}</li>
});

const SortableList = SortableContainer(({items, isCorrectTask}) => {
    return (
        <ol className="SortableList">
            {items.map((value, index) => (<div className="wrapperListVariants">
                    <span>{index + 1}</span>
                    <SortableItem key={`item-${index}`}
                                  value={value}
                                  index={index}
                                  sortIndex={index}
                                  style={{zIndex: 99999999}}
                                  isCorrectTask={isCorrectTask}/>
                </div>
            ))}
        </ol>
    );
});

const DragAndDropColumn = ({variants, handleDropAndDrag, isRightAnswer, isCorrectTask}) => {
    let rightAnswer = useSelector(state => state.tasksReducer.currentTask.right_answer);
    return <div className="wrapperDragInDrop">
        <SortableList items={!isRightAnswer ? variants : rightAnswer}
                      onSortEnd={handleDropAndDrag}
                      style={{zIndex: 999999}}
                      helperClass="SortableHelper"
                      isRightAnswer={isRightAnswer}
                      isCorrectTask={isCorrectTask}
        />
    </div>
}


export default DragAndDropColumn;