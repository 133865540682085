import {practicesAPI} from "../api/api";

const SET_PRACTICES = `SET_PRACTICES`;
const RETURN_ALL_PRACTICES = 'RETURN_ALL_PRACTICES';
const SORT_CATEGORY_PRACTICES = 'SORT_CATEGORY_PRACTICES';
const SORT_COMPLEXITY_PRACTICES = 'SORT_COMPLEXITY_PRACTICES';

let initialState = {
    initialPractices: null,
    practices: null,
    categories: null,
    complexities: null
}

const practicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRACTICES: {
            return {
                ...state,
                practices: action.practics,
                categories: action.categories,
                complexities: action.complexities,
                initialPractices: action.practics
            }
        }
        case SORT_CATEGORY_PRACTICES: {
            return {
                ...state,
                practices: state.initialPractices.filter(({category}) => category === action.idCategory),
            }
        }
        case SORT_COMPLEXITY_PRACTICES: {
            return {
                ...state,
                practices: state.initialPractices.filter(({complexity}) => complexity === action.idComplexity)
            }
        }
        case RETURN_ALL_PRACTICES: {
            return {
                ...state,
                practices: state.initialPractices
            }
        }
        default:
            return state;
    }
}

export const returnAllPractices = () => {
    return {
        type: RETURN_ALL_PRACTICES
    }
}

export const sortCategoryPracticesAction = (idCategory) => {
    return {
        type: SORT_CATEGORY_PRACTICES,
        idCategory
    }
}

export const sortComplexityPracticesAction = (idComplexity) => {
    return {
        type: SORT_COMPLEXITY_PRACTICES,
        idComplexity
    }
}

const setPractices = ({categories, complexities, practics}) => {
    return {
        type: SET_PRACTICES,
        categories, complexities, practics
    }
};


export const getPracticesThunk = (authToken) => {
    return async (dispatch) => {
        let response = await practicesAPI.getAllPractices(authToken)
        try {
            dispatch(setPractices(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export default practicesReducer;