import React from 'react';
import style from './FinishedGame.module.scss';
import {useDispatch} from "react-redux";
import {finishGameThunk, returnToFinished} from "../../../../redux/gameReducer";
import {useTranslation} from "react-i18next";

const FinishedGame = () => {
    let dispatch = useDispatch();
    const { t } = useTranslation();

    let startNewGame = () => {
        dispatch(finishGameThunk())
    }

    let returnToFinishAction = () => {
        dispatch(returnToFinished())
    }

    return  <div className={style.wrapper}>
        <div className={style.buttonsFinishedGame}>
            <button className={style.closeWindowButton} onClick={returnToFinishAction}>
                {t('game.finish.back')}
            </button>
            <button className={style.newGameButton} onClick={startNewGame}>
                {t('game.finish.new')}
            </button>
        </div>
    <div className={style.wrapperFinishedGame}></div>
    </div>
}

export default FinishedGame;