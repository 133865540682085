import React from 'react'
import {Link} from 'react-router-dom'
import s from './Tarif.module.scss'
import PATH from '../pathConst'
import {useTranslation} from "react-i18next";

const Tarif = ({features, subscriptions}) => {
    const { t } = useTranslation();
    return <article className={s.tarif}>
        <div className={s.innerBorder}>
            <div className={s.title}>
                {t('tariff.title')} <strong>{subscriptions.tariff.title} <span>
            {subscriptions.tariff.price} $</span></strong>
                {subscriptions.currentTariff
                    ? <h6 className={s.subHeader}>
                        {t('subscription.month.title')}
                </h6>
                    : <h6 className={s.subHeader}>
                        {t('subscription.year.cta')}
                    </h6>}
            </div>
            <ul>
                {features.map((f, i) => <li key={`feature ${i}`}>{f}</li>)}
            </ul>
            <div className={s.button}><Link to={`${PATH}3`}>
                {t('subscription.btn.buy')}
            </Link></div>
            <p className={s.bottomSmallText}>
                * {t('subscription.note')}
            </p>
            <div className={s.cat}/>
        </div>
    </article>
}

export default Tarif;
