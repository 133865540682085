import React from "react";
import { authApi } from "api/api";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { SignupFormValues, UseSignupApiResult } from "../types";

export const useSignupApi = (): UseSignupApiResult => {
	const { executeRecaptcha } = useGoogleReCaptcha();
  const authSocialMediaApi = React.useCallback( async (payload: SignupFormValues) => {
		if (!executeRecaptcha) {
			console.error('recaptcha is unavailable');
			return;
		}
		payload.gReCaptchaToken = await executeRecaptcha('register');
		return await authApi.signup(payload);
	}, [executeRecaptcha]);

	return authSocialMediaApi;
}