import React from 'react'
import styled from './style.module.scss'
import doneIcon from '../../img/done-stage.png'
import lockIcon from '../../img/lock-stage.png'
import processIcon from '../../img/video.svg'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {HalfCircleSpinner} from 'react-epic-spinners'
import {ScoreType, ThemeType} from '../../../../../types/description'
import {RootState} from '../../../../../redux/reducers'
import {showThemeTest} from '../../../../../redux/actions/tests';
import {useTranslation} from "react-i18next";

type PropsType = {
    indexTheme: number;
    index: number;
    score?: ScoreType | null | undefined;
    courseId: number | undefined;
    levelId: number | undefined;
    themeId: number | undefined;
    stageId: number | undefined;
    opened: boolean;
    is_open: boolean;
}

const Stages = ({
                    title,
                    status,
                    indexTheme,
                    index,
                    courseId,
                    levelId,
                    themeId,
                    stageId,
                    opened,
                    is_open
                }: ThemeType & PropsType) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {test} = useSelector((state: RootState) => state.tasksReducer)
    const { t } = useTranslation();
    const redirectToTheme = () => {
        if (window.location.href.includes("/course")) {
            history.push(`../../theme/${stageId}?course_id=${courseId}&level_id=${levelId}&stage_id=${themeId}`)
        } else if (window.location.href.includes("/test")) {
            dispatch(showThemeTest(themeId, stageId))
        } else if (window.location.href.includes("/practices")) {
            history.push(`../../practice-theme/${stageId}?course_id=${courseId}&level_id=${levelId}&stage_id=${themeId}`)
        }
    }

    return <div
        className={styled.wrapper}
        onClick={(is_open || status || opened || (indexTheme === 0 && index === 0)) && test ? redirectToTheme : () => {
        }}
    >
        <div className={styled.title}>
            {status
                ? <img src={doneIcon} alt="done" />
                : opened || (indexTheme === 0 && index === 0) || is_open ? <img src={processIcon} alt="process" />
                    : <img src={lockIcon} alt="lock" />
            }
            <span>{indexTheme + 1}.{index + 1}</span>
            <span>{title}</span>
        </div>
        <div className={styled.info}>
            {test === null ? <HalfCircleSpinner size={20} color={'#4B67A1'} /> : status
                ? <span className={styled.linkActive}>
                {t('task.single.passed')}
              </span>
                : opened || (indexTheme === 0 && index === 0) || is_open ?
                    <span className={styled.linkActive}>
                      {t('task.single.link')}
                    </span>
                    : <span className={styled.disabledLink}>
                  {t('task.single.disabled')}
                    </span>
            }
        </div>
    </div>
};

export default Stages;
