import {
    CHANGE_IS_LOADING_FEEDBACK,
    CHANGE_TARIFF,
    PROGRESS_BILLING, SET_CARDS, SET_INVOICES,
    SET_STATUS_FEEDBACK,
    SET_SUBSCRIPTIONS
} from '../../actions/subscription'
import {BillingType} from '../../../types/subsription'
import {AnyAction} from 'redux'

let initialState: BillingType =  {
    subscriptions: [],
    pk: '',
    isStatus: null,
    isStatusFeedback: null,
    isLoadingFeedback: false,
    invoices: null,
    cards: null,
    currentTariff: 0,
    tariff: null
}

const subscriptionReducer = (state = initialState, {type, payload}: AnyAction): BillingType => {
    switch (type) {
        case SET_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptions: payload.products,
                pk: payload.pk,
                tariff: payload.products[0].prices[state.currentTariff]
            }
        }
        case CHANGE_TARIFF: {
            return {
                ...state,
                tariff: state.subscriptions[0].prices[state.currentTariff ? 0 : 1],
                currentTariff: state.currentTariff ? 0 : 1
            }
        }
        case PROGRESS_BILLING: {
            return {
                ...state,
                isStatus: payload,
            }
        }
        case SET_STATUS_FEEDBACK: {
            return {
                ...state,
                isStatusFeedback: payload,
                isLoadingFeedback: false
            }
        }
        case CHANGE_IS_LOADING_FEEDBACK: {
            return {
                ...state,
                isLoadingFeedback: payload,
            }
        }
        case SET_INVOICES: {
            return {
                ...state,
                invoices: {...payload},
            }
        }
        case SET_CARDS: {
            return {
                ...state,
                cards: payload,
            }
        }
        default:
            return state;
    }
}

export default subscriptionReducer;
