import React, {useState} from 'react'

const SelectWithRadio = ({data, name, title, handler = (name, value) => true, defaultSelected = null}) => {

    const [selected, select] = useState(Number(defaultSelected))
    const [opened, toggle] = useState(false)

    return(
        <div className={opened ? "accordion-radio active" : "accordion-radio"}>
            <div onClick={()=> toggle(!opened)}  className={"accordion-head"}>
                <p className={"title"}>{selected && data.find(item => item.id === selected) ?  data.find(item => item.id === selected).name : title}</p>
                <img src="/Images/arrow-down-black.svg" alt="open"/>
            </div>
            <div className={"accordion-info"}>
                {/*<label>*/}
                {/*        <input onChange={() => {*/}
                {/*            select(null)*/}
                {/*            toggle(false)*/}
                {/*        }} type="radio" name={name} value={null}/>*/}
                {/*    <div className={"radio"}/>*/}
                {/*    Все*/}
                {/*</label>*/}
                {data.map((item,index)=>(
                    <label key={index}>
                        <input checked={selected === item.id} onChange={() => {
                            select(item.id)
                            handler(name, item)
                            toggle(false)
                        }} type="radio" name={name} value={item.id}/>
                        <div className={"radio"}/>
                        {item.name}
                    </label>
                ))}
            </div>
        </div>
    )
}
export default SelectWithRadio