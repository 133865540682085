import React, {useState} from 'react';
import styled from './style.module.scss';
import doneIcon from '../../img/done.png';
import processIcon from '../../img/process.svg';
import uncheckIcon from '../../img/uncheck.svg';
import questionsIcon from "../../img/questions.svg";
import videoIcon from "../../img/video.svg";
import commentariesIcon from "../../img/commentaries.svg";
import arrowIcon from '../../img/arrow.png';
import Stages from '../Stages';
import {declOfNum} from "../../../../../helpers/declOfNum";
import {StageType} from '../../../../../types/description'
import {useTranslation} from "react-i18next";

type PropsType = {
    themeId: number | undefined;
    answers: number;
    index: number;
    levelId: number | undefined;
    courseId: number;
    opened: boolean;
    is_open: boolean;
}

const Theme = ({
                   themeId,
                   title,
                   status = 2,
                   themes,
                   answers = themes.length,
                   videos,
                   comments,
                   index,
                   progress,
                   levelId,
                   is_open,
                   courseId,
                   opened
               }: StageType & PropsType) => {
    const [showStages, setShowStages] = useState<boolean>(false);
    const indexTheme = index;
  const { t } = useTranslation();

    const handleShowStage = () => {
        setShowStages(prev => !prev);
    }

    return <>
        <div className={styled.rowDesktop} onClick={handleShowStage}>
            <div className={styled.title}>
                <div className={`${styled.cell} ${styled.icon}`}>
                    {status === 1
                        ? <img src={uncheckIcon} alt="disabled" />
                        : status === 2
                            ? <img src={processIcon} alt="process" />
                            : <img src={doneIcon} alt="Ok" />
                    }
                </div>
                <div className={styled.cell}>
                    <p>{index + 1}. {title}</p>
                </div>
            </div>
            <div className={styled.content}>
                <div className={styled.cell}>
                    <div className={styled.details}>
                        {!!answers && <div className={styled.detailItem}>
                            <img src={questionsIcon} alt="?" />
                            <span>{answers} {declOfNum(answers, [t('task.question1'), t('task.question2'), t('task.question3')])}</span>
                        </div>}
                        {!!videos && <div className={styled.detailItem}>
                            <img src={videoIcon} alt=">" />
                            <span>{videos} {t('course.video')}</span>
                        </div>}
                        {!!comments && <div className={styled.detailItem}>
                            <img src={commentariesIcon} alt="!" />
                            <span>{comments} {declOfNum(comments, [t('task.comment1'), t('task.comment2'), t('task.comment3')])}</span>
                        </div>}
                    </div>
                </div>
                <div className={styled.cell}>
                    <span className={styled.columnFour}>{t('task.single.passed')} {progress}%</span>
                </div>
                <div className={styled.cell}>
                    <img src={arrowIcon}
                         className={!showStages ? styled.columnSix : `${styled.columnSix} ${styled.active}`}
                         alt={'arrow-open'}
                    />
                </div>
            </div>
        </div>

        {/*верстка на 1400px*/}
        <div className={`${styled.rowDesktop} ${styled.rowTablet}`} onClick={handleShowStage}>
            <div className={styled.title}>
                <div className={styled.name}>
                    <div className={styled.icon}>
                        {status === 1
                            ? <img src={uncheckIcon} alt="disabled" />
                            : status === 2
                                ? <img src={processIcon} alt="process" />
                                : <img src={doneIcon} alt="Ok" />
                        }
                    </div>
                    <p>{index + 1}. {title}</p>
                </div>
                <div className={styled.right}>
                    <div className={styled.progress}>{progress}%</div>
                    <img src={arrowIcon}
                         className={!showStages ? styled.columnSix : `${styled.columnSix} ${styled.active}`}
                    />
                </div>
            </div>
            <span className={styled.progressMobile}>
              {t('task.single.passed')} {progress}%
            </span>
            <div className={styled.details}>
                {!!answers && <div className={styled.detailItem}>
                    <img src={questionsIcon} alt="?" />
                    <span>{answers} {declOfNum(answers, [t('task.question1'), t('task.question2'), t('task.question3')])}</span>
                </div>}
                {!!videos && <div className={styled.detailItem}>
                    <img src={videoIcon} alt=">" />
                    <span>{videos} {t('course.video')}</span>
                </div>}
                {!!comments && <div className={styled.detailItem}>
                    <img src={commentariesIcon} alt="!" />
                    <span>{comments} {declOfNum(comments, [t('task.comment1'), t('task.comment2'), t('task.comment3')])}</span>
                </div>}
            </div>
        </div>
        <div className={!showStages ? styled.closeStages : styled.stages}>
            {themes?.map(({id, title, status, score}, index) => <Stages key={id}
                                                                        title={title}
                                                                        status={status}
                                                                        indexTheme={indexTheme}
                                                                        index={index}
                                                                        courseId={courseId}
                                                                        levelId={levelId}
                                                                        themeId={themeId}
                                                                        stageId={id}
                                                                        is_open={is_open}
                                                                        score={score}
                                                                        opened={(index === 0 && opened) || (index > 0 && themes[index - 1].status)}
            />)}
        </div>
    </>
}

export default Theme;
