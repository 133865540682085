import React, {useEffect, useState} from 'react'
import styled from './Certificates.module.scss'
import blockIcon from './img/type-block.png'
import listIcon from './img/type-list.png'
import blockActiveIcon from './img/type-block-active.png'
import listActiveIcon from './img/type-list-active.png'
import QuestionsAndTarif from "../../Components/QuestionsAndTarif/QuestionsAndTarif";
import {useDispatch, useSelector} from "react-redux";
import {getCertificates} from "../../../redux/certificatesReducer";
import CertificateList from "./components/CertificateList/CertificateList";
import CertificateItem from "../../Components/CertificateItem/CertificateItem";

const Certificates = ({appDelegate}) => {
    const dispatch = useDispatch()
    const certificates = useSelector(state => state.certificatesReducer.certificates)
    const [typeDisplayContent, setTypeDisplayContent] = useState(true)

    const handlerDisplayContentType = () => {
        typeDisplayContent
            ? setTypeDisplayContent(false)
            : setTypeDisplayContent(true)
    }

    useEffect(() => {
        dispatch(getCertificates(appDelegate.state.authToken))
    }, [])

    return <div className={styled.wrapper}>
        <div className={styled.container}>
            <div className={styled.top}>
                <h1>Сертификаты</h1>
                <div className={styled.descriptionsBlock}>
                    <div className={styled.description}>
                        <span>После прохождения серии задач и практик вы получаете Сертификат от нашей компании, которым вы можете не только поделиться с вашими друзьями, коллегами, заказчиками, но и предоставить потенциальному работодателю.</span>
                    </div>
                    <div className={styled.filterBlock}><span>Вид отображения: </span>
                        {typeDisplayContent ? <img src={blockActiveIcon} alt="block"/> :
                            <img src={blockIcon} alt="block" onClick={handlerDisplayContentType}/>}
                        {typeDisplayContent ? <img src={listIcon} alt="list" onClick={handlerDisplayContentType}/> :
                            <img src={listActiveIcon} alt="list"/>}
                    </div>
                </div>
            </div>
            {typeDisplayContent
                ? <div className={styled.contentColumn}>
                    {certificates.length !== 0 && certificates.map(({code, course, date, user, id, isActive}, index) => <CertificateItem
                        key={index}
                        code={code}
                        course={course}
                        id={id}
                        date={date}
                        user={user}
                        isActive={isActive}
                        authToken={appDelegate.state.authToken}
                    />)}
                    <div className={styled.mainCertificate}>
                        <div className={styled.titleCertificate}>
                            <h2>Получите свой первый</h2>
                            <h1>CEРТИ<span>&lt;/&gt;</span>ИКАТ</h1>
                        </div>
                        <div className={styled.links}>
                            <a href="/all-courses">Все курсы</a>
                            <a href="/tests">Все задачи</a>
                            <a href="/practices">Все практики</a>
                            <a href="/games">Все игры</a>
                        </div>
                    </div>
                </div>
                : <div className={styled.contentList}>
                    {certificates.map(({code, course, date, user, id, isActive}, index) => <CertificateList
                        key={index}
                        index={index}
                        code={code}
                        course={course}
                        id={id}
                        date={date}
                        user={user}
                        isActive={isActive}
                        authToken={appDelegate.state.authToken}
                    />)}
                    <div className={styled.mainCertificateList}>
                        <div className={styled.title}>
                            <span>+</span>
                            Получи ещё один сертификат
                        </div>
                        <div className={styled.links}>
                            <a href="/all-courses">Все курсы</a>
                            <a href="/tests">Все задачи</a>
                            <a href="/practices">Все практики</a>
                            <a href="/games">Все игры</a>
                        </div>
                    </div>
                </div>}
            <QuestionsAndTarif/>
        </div>
    </div>
}

export default Certificates
