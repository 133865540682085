import React from "react";
import { useLocation } from "react-router";
import { SigninFormValues } from "../types";

export const useSigninInitialValues = (): SigninFormValues => {
	const { search } = useLocation();
	return React.useMemo(() => {
		const params = new URLSearchParams(search);
		return {
			email: params.get("email") || "",
			password: params.get("name") || "",
		}
	}, [search]);
};