import React from "react";
import { Redirect } from "react-router"


const RedirectFromYandex = (props) => {

    return <Redirect to={`/course/${props.match.params.id}`}/>
};


export default RedirectFromYandex;