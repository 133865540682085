import React from 'react'
import s from './Answer.module.scss'
import imgTrue from '../../img/checkedTask.png'
import {Spring} from "react-spring/renderprops";
import {useTranslation} from "react-i18next";

const AnswerTrue = ({hideResult, openDiscussion, openHelpTask, task}) => {
    const { t } = useTranslation();
    return <div className={s.wrapper}>
        <Spring
            from={{opacity: 0, transform: "translateY(4rem)"}}
            to={{opacity: 1, transform: "translateY(0rem)"}}
            config={{duration: 500}}
        >
            {props => (<div style={props} className={s.wrapperTrue}>
                <div className={s.left}>
                    <img src={imgTrue} alt="true"/>
                    <h2>
                        {t('task.single.correct')}
                    </h2>
                </div>
                <div className={s.right}>
                    <div className={s.trueReview} onClick={() => openDiscussion(true)}></div>
                    {task.theory !== null && <div className={s.truePrompt} onClick={() => openHelpTask(true)}></div>}
                    <button onClick={hideResult}>
                        {t('course.continue')}
                    </button>
                </div>
            </div>)}
        </Spring>
    </div>
}

export default AnswerTrue;