import { API_GAME_AXIOS, API_PREFIX_AXIOS } from "./instance"

export const dashboardAPI = {
    getDataForDashboard( authToken ) {
        return API_GAME_AXIOS.get( `web/dashboard`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    getCategoriesErrors( authToken ) {
        return API_GAME_AXIOS.get( `web/reports/init`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    sendReportError( authToken, url, categoryId, messageError ) {
        return API_GAME_AXIOS.post( `web/reports`, {"url": url, "categoryId": categoryId, "message": messageError}, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const subscriptionsAPI = {
    getSubscriptions( authToken ) {
        return API_GAME_AXIOS.get( `subscriptions`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    sendStripe( authToken, token_id, product_id, price_id ) {
        return API_GAME_AXIOS.post( `payment_process`,
            {
                "token_id": token_id,
                "product_id": product_id,
                "price_id": price_id
            },
            {
                headers: {
                    "Authorization": `Bearer ${authToken}`
                }
            }
        )
    },

    getInvoices( authToken ) {
        return API_PREFIX_AXIOS.get( `invoices`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    getCards( authToken ) {
        return API_PREFIX_AXIOS( `cards`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    sendDataFeedback( authToken, name, phone, email, message ) {
        return API_PREFIX_AXIOS.post( `feedback`,
            {
                "name": name,
                "phone": phone,
                "email": email,
                "message": message
            },
            {
                headers: {
                    "Authorization": `Bearer ${authToken}`
                }
            }
        )
    },

    untieTheCard( authToken, id_card ) {
        return API_PREFIX_AXIOS.delete( `cards/${id_card}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    deleteAutoSubscriptions( authToken, id_card ) {
        return API_PREFIX_AXIOS.delete( `subscriptions/${id_card}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const descriptionsAPI = {
    getTestForDescriptions( authToken, id ) {
        return API_GAME_AXIOS.get( `web/dashboard/tests/${id}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },

    getCourseForDescriptions( authToken, id ) {
        return API_GAME_AXIOS.get( `web/dashboard/courses/${id}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    getPracticeForDescriptions( authToken, id ) {
        return API_GAME_AXIOS.get( `practices/${id}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
}

export const certificatesAPI = {
    getCertificates( authToken ) {
        return API_PREFIX_AXIOS.get( `certificates`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    verifyCertificate( authToken, id, user_name, user_last_name ) {
        return API_PREFIX_AXIOS.put( `certificates/${id}`, {
            "user_name": user_name,
            "user_last_name": user_last_name
        }, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const infoCourseAPI = {
    getInfoCourse( id ) {
        return API_PREFIX_AXIOS.get( `courses/${id}/info` )
    },
    getInfoTest( id ) {
        return API_PREFIX_AXIOS.get( `tests/${id}/info` )
    },
    getInfoPractice( id ) {
        return API_PREFIX_AXIOS.get( `practices/${id}/info` )
    }
}

export const blogApi = {
    getBlog() {
        return API_GAME_AXIOS.get( `blog` )
    },
    getPostForBlog( category, post ) {
        return API_GAME_AXIOS.get( `blog/posts/${category}/${post}` )
    },
    changeCategory( category_slug ) {
        return API_GAME_AXIOS.get( `blog/categories/${category_slug}` )
    }
}

export const coursesAPI = {
    getAllCourses( authToken ) {
        return API_PREFIX_AXIOS.get( `courses`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const practicesAPI = {
    getAllPractices( authToken ) {
        return API_PREFIX_AXIOS.get( `practics`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const tasksAPI = {
    getAllTests( authToken ) {
        return API_GAME_AXIOS.get( `web/courses`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    getPaginationTest( authToken, page, category ) {
        return API_GAME_AXIOS.get( `web/courses?page=${page}&category=${category}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    sortCategoryTests( authToken, category ) {
        return API_GAME_AXIOS.get( `web/courses?category=${category}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    getTest( id, authToken ) {
        return API_GAME_AXIOS.get( `web/courses/${id}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    resetTest( id, authToken ) {
        return API_GAME_AXIOS.get( `web/reset/${id}`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    checkTheTask( id, answers, authToken ) {
        return API_GAME_AXIOS.post( `web/answers`, {
            "question_id": id,
            "answer": answers
        }, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const discussionAPI = {
    getCommentsForTests( authToken, id ) {
        return API_GAME_AXIOS.get( `web/questions/${id}/comments`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    sendCommentForTaskThunk( id, authToken, comment ) {
        return API_GAME_AXIOS.post( `web/questions/${id}/comments`, {
            "comment": comment
        }, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
    replyUserOnComment( authToken, id, comment ) {
        return API_GAME_AXIOS.post( `web/questions/comments/${id}/reply`, {
            "comment": comment
        }, {
            headers: {
                "Authorization": `Bearer ${authToken}`

            }
        } )
    },
    changeLikeStatus( authToken, id ) {
        return API_GAME_AXIOS.post( `web/questions/comments/${id}/like`, {}, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const rouletteAPI = {
    getAdminRoulette( authToken ) {
        return API_PREFIX_AXIOS.get( `roulette`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    }
}

export const authApi = {
    /**
     * method to get signin link depending on social media provider
     * @param { string } provider - Social media provider name such as `google` or `github`
     * @returns { Promise<AxiosResponse<{redirect: string}>> } Redirection link
     */
    getSigninLinkViaSocialMedia( provider ) {
        return API_PREFIX_AXIOS.get( `login/${provider}` )
    },

    /**
     * method to signin via provider and search params
     * @param {{provider: string, searchParams: string}} param
     * @returns { Promise<AxiosResponse<{status: number, access_token: string, message: string}>> }
     */
    signinViaCallback( {provider, searchParams} ) {
        return API_PREFIX_AXIOS.get( `login/${provider}/callback${searchParams}` )
    },

    /**
     * method to signin by email and password
     * @param {{email: string, password: string}} payload
     * @returns { Promise<AxiosResponse<{status: number, access_token: string, message: string}>> }
     */
    signin( payload ) {
        return API_PREFIX_AXIOS.post( 'oauth/token', payload )
    },

    /**
     * method to signup by email and name
     * @param {{email: string, name: string}} payload
     * @returns { Promise<AxiosResponse<{status: number, data: string[]}>> }
     */
    signup( payload ) {
        return API_PREFIX_AXIOS.post( 'register', payload )
    },

    /**
     * method to drop password by email
     * @param {{email: string}} payload
     * @returns { Promise<AxiosResponse<{status: number, message?: string}>> }
     */
    dropPassword( payload ) {
        return API_PREFIX_AXIOS.post( 'password/email', payload )
    },

    /**
     * method to reset password by email, old password and token
     * @param {{email: string, password: string, token: string}} payload
     * @returns { Promise<AxiosResponse<unknown>> }
     */
    resetPassword( payload ) {
        return API_PREFIX_AXIOS.post( 'password/reset', payload )
    }
}

export const localesAPI = {
    getLocales( authToken ) {
        return API_PREFIX_AXIOS.get( `locales`, {
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        } )
    },
}
