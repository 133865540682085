import React from "react";
import { useLocation } from "react-router"

export const useDropPasswordInitialValues = () => {
  const { search } = useLocation();
  const initialValues = React.useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      email: params.get("email") || ""
    }
  }, [search]);

  return initialValues;
}