import React from "react";
import config from "../../../../etc/config";
import Loader from "../../../Components/Loader";
import ErrorPage from "../../ErrorPage";


const withOfferDetail = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                errorPage: null,
                copied: false,
                popup: false,
                loading: []
            }

            this.clipboardRef = React.createRef()
        }

        componentDidMount() {
            this.getData();
            window.scrollTo({top: 0, left: 0});
            document.title = "Карьера"
        }


        startProject = () => {
            const loading = JSON.parse(JSON.stringify(this.state.loading))
            loading.push("start")
            this.setState({loading})

            fetch(`${config.API_PREFIX}offers/${this.props.match.params.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    loading.splice(loading.indexOf("start", 1))
                    this.setState({loading})
                    this.togglePopup(false)
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if (res.status === 0){
                        const data = JSON.parse(JSON.stringify(this.state.data))
                        data.status_id = 2
                        data.time = 0
                        this.setState({data})
                    } else {
                        alert("Server error!")
                    }

                })
                .catch(err => {
                    console.log(err);
                })
        }


        completeProject = () => {
            const loading = JSON.parse(JSON.stringify(this.state.loading))
            loading.push("complete")
            this.setState({loading, popup: false})

            fetch(`${config.API_PREFIX}offers/${this.props.match.params.id}/complete`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    loading.splice(loading.indexOf("complete", 1))
                    this.setState({ loading})
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if (res.status === 0){
                        const data = JSON.parse(JSON.stringify(this.state.data))
                        data.status_id = 3
                        this.setState({data})
                    } else {
                        alert("Server error!")
                    }

                })
                .catch(err => {
                    console.log(err);
                })
        }


        copyToClipboard = () => {
            const el = this.clipboardRef.current
            el.select()
            el.setSelectionRange(0, 99999)
            document.execCommand('copy')
            this.setState({copied: true}, () => {
                setTimeout(() => {
                    this.setState({copied: false})
                }, 2000)
            })
        }

        togglePopup = (popup) => {
            this.setState({popup}, () => {
                document.body.style.overflowY = popup ? "hidden" : "auto"
            })
        }

        getData = () => {
            fetch(`${config.API_PREFIX}offers/${this.props.match.params.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if (res.status === 0){
                        this.setState({data: res.offer})
                        document.title = res.offer.name
                    }

                })
                .catch(err => {
                    console.log(err);
                })
        };


        render() {
            if (!this.state.data && !this.state.errorPage)
                return <Loader/>;
            else if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else
                return <Component offerDetailDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withOfferDetail(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withOfferDetail;