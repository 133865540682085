import {CHANGE_LOADER_FOR_DESCRIPTION, SET_CURRENT_COURSE} from '../constants';
import {CourseType} from '../../types/description'
import {descriptionsAPI} from '../../api/api'
import {AppDispatch} from '../store'

export const setCurrentCourse = (course: CourseType) => ({
    type: SET_CURRENT_COURSE,
    payload: course
});

export const changeLoaderForDescription = (isLoading: boolean) => ({
    type: CHANGE_LOADER_FOR_DESCRIPTION,
    payload: isLoading
});

export const getTestForDescription = (authToken: string, id: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeLoaderForDescription(true))
        let response = await descriptionsAPI.getTestForDescriptions(authToken, id)
        try {
            dispatch(setCurrentCourse(response.data.data))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoaderForDescription(false))
        }
    }
}

export const getCourseForDescription = (authToken: string, id: number) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeLoaderForDescription(true))
        let response = await descriptionsAPI.getCourseForDescriptions(authToken, id)
        try {
            dispatch(setCurrentCourse(response.data.data))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoaderForDescription(false))
        }
    }
}

export const getPracticeForDescription = (authToken: string, id: number) => {
    return async (dispatch: any) => {
        dispatch(changeLoaderForDescription(true))
        let response = await descriptionsAPI.getPracticeForDescriptions(authToken, id)
        try {
            dispatch(setCurrentCourse(response.data.data))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoaderForDescription(false))
        }
    }
}
