import { authApi } from "api/api";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";
import { AxiosResponse } from "axios";
import { AppDelegateType } from "types/app-delegate";

type SigninViaCallbackResponse = AxiosResponse<{status: number, access_token: string, message: string}>;

const signinBySearch = async (
  searchParams: string, 
  provider: string, 
  options: {
    onSuccess: (access_token: string) => void;
    onError: () => void;
  }
  
) => {
  try {
    const response: SigninViaCallbackResponse = await authApi.signinViaCallback({
      provider,
      searchParams
    });

    if(response.data.status === 0) {
      document.cookie = `auth_token=${response.data.access_token};path=/;domain=.educat.courses;`;

      if (window.ym !== undefined) {
        window.ym(62295580, 'reachGoal', 'authorization')
      }

      options.onSuccess(response.data.access_token);
    }

    else if(response.data.status === 1) {
      throw new Error(response.data.message);
    }

    else {
      throw new Error(
        `Server error with http status ${response.status} and with response data message ${response.data.message}`
      );
    }
  } catch (error) {
    console.error('Error while signing in by code', error);
    options.onError();
  }
};

/** Hook to signin with params passed by search */
export const useSigninBySearch = (appDelegate: AppDelegateType): void => {
  const { provider } = useParams<{provider: string}>();
  const { search } = useLocation();
  const history = useHistory();

  const { authCompletion } = appDelegate;

  const onSuccess = React.useCallback((access_token: string) => {
    if(authCompletion !== undefined) {
      authCompletion({access_token});
      history.push("/");
    }
  }, [authCompletion, history]);

  const onError = React.useCallback(() => {
    history.push(RegistrationRoutes.SIGN_IN);
  }, [history]);
  
  React.useEffect(() => {
    if(search !== "") {
      signinBySearch(search, provider, {
        onSuccess,
        onError
      });
    }
  }, [search, provider, onSuccess, onError]);
};