import React from "react";
import config from "../../../etc/config";
import Loader from "../../Components/Loader";
import ErrorPage from "../ErrorPage";


const withHome = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                errorPage: null,
                section: this.section,
                category: Number(this.category),
                loading: true
            }

        }

        componentDidMount() {
            this.getData();
            window.scrollTo({top: 0, left: 0});
            if(this.props.location.pathname === "/helper"){
                document.title = "Консультация"
            } else if (this.props.location.pathname === "/"){
                document.title = "Все курсы"
            }
            else {
                document.title = "Мои курсы"
            }


            this.setState({section: this.section})

            if (window.jivo_is_initialize) {
                window.initChat()
            } else {
                document.addEventListener("jivoOnLoad", window.initChat)
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.props.location.search !== prevProps.location.search) {
                this.getData()
                this.setState({loading: true})
            }
        }

        componentWillUnmount() {
            document.removeEventListener("jivoOnLoad", window.initChat)
        }


        get section() {
            return this.props.location.search && this.props.location.search.includes("section") ?
                this.props.location.search
                    .slice(1)
                    .split("&")
                    .find(x => x.split("=")[0] === "section")
                    .split("=")[1]
                : "all-courses"
        }

        get category() {
            return this.props.location.search && this.props.location.search.includes("category") ?
                this.props.location.search
                    .slice(1)
                    .split("&")
                    .find(x => x.split("=")[0] === "category")
                    .split("=")[1]
                : -1
        }
        get currentCategory() {
            return this.props.location.search
                .slice(1)
                .split("&")
                .reduce((prev, x) => x.split("=").length === 2 && x.split("=")[0] === "category" ? x.split("=")[1] : prev, null) || 0
        }


        getQueryWithout = (key) => {
            return this.props.location.search ? this.props.location.search
                .slice(1)
                .split("&")
                .filter(x => !x.includes(`${key}=`))
                .reduce((accum, item) => `${accum}${item}&`, "?") : "?"
        }


        getData = () => {
            fetch(`${config.API_PREFIX}courses${this.props.location.search}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({data: res, loading: false})
                })
                .catch(err => {
                    console.log(err);
                })
        };


        render() {
            if (!this.state.data && !this.state.errorPage)
                return <Loader/>;
            else if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else
                return <Component homeDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withHome(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withHome;