import {CHANGE_LOADING_TO_ROULETTE, SET_DATA_FOR_ROULETTE} from '../constants';
import {RouletteType} from '../../types/roulette';
import {AppDispatch} from '../store';
import {rouletteAPI} from '../../api/api';

export const changeLoaderToRoulette = (isLoading: boolean) => ({
    type: CHANGE_LOADING_TO_ROULETTE,
    payload: isLoading
})

export const setDataForRoulette = ({date_competition, prev_date_competition, participants}: RouletteType) => ({
    type: SET_DATA_FOR_ROULETTE,
    payload: {date_competition, prev_date_competition, participants}
})

export const getDataForRoulette = (authToken: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeLoaderToRoulette(true))
        let {data} = await rouletteAPI.getAdminRoulette(authToken);
        try {
            dispatch(setDataForRoulette(data))
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(changeLoaderToRoulette(false))
        }
    }
}
