import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import {useDispatch, useSelector} from "react-redux"
import styled from './Discussion.module.scss'
import closeIcon from '../../Pages/Tasks/img/exit.png'
import arrowIcon from '../../Pages/Tasks/img/arrow.png'
import ladaIcon from '../../Pages/Tasks/img/lada.svg'
import CommentItem from "./components/CommentItem/CommentItem"
import {getCommentsThunk, sendCommentForTaskThunk} from "../../../redux/discussionReducer"
import {declOfNum} from "../../../helpers/declOfNum";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    dialogIcon: {
        position: 'relative'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Discussion = ({task, infoUser, showDiscussion, closeDiscussion}) => {
    const {comments, disabledButton} = useSelector(state => state.discussionReducer)
    const dispatch = useDispatch()
    const classes = useStyles();
    const [comment, setComment] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCommentsThunk(infoUser.authToken, task.id))
    }, [])

    const sendComment = () => {
        dispatch(sendCommentForTaskThunk(task.id, infoUser.authToken, comment))
        setComment('')
    }

    const handleReadComment = (e) => {
        setComment(e.target.value)
    }

    return <Dialog className={classes.dialogIcon} fullScreen open={showDiscussion} onClose={() => closeDiscussion(false)}
                   TransitionComponent={Transition}>
        <div className={styled.wrapper}>
            <div className={styled.content}>
                <div className={styled.title}>
                    <h2>
                        {t('discussion.title')}
                    </h2>
                    <div
                        dangerouslySetInnerHTML={{__html: task.question}}
                        className={"html-data"} />
                    <div className={styled.formComment}>
                        {infoUser.user.photo == null
                            ? <img src={ladaIcon} alt="Icon"/>
                            : <img src={infoUser.user.photo} alt="Avatar"/>
                        }
                        <div className={styled.textareaForm}>
                            <textarea placeholder={t('discussion.message')} onChange={handleReadComment}
                                      value={comment} />
                            <button onClick={sendComment} disabled={disabledButton || comment === ''}>
                                {t('btn.send')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styled.btnClose} onClick={() => closeDiscussion(false)}>
                    <img src={closeIcon} alt="x"/>
                </div>
                <div className={styled.wrapperComments}>
                    <div className={styled.titleComments}>
                        <h2>{comments.length === 0 ? t('task.comment.first') : `${comments.length} ${declOfNum(comments.length, [t('task.comment1'), t('task.comment2'), t('task.comment3')])}`}</h2>
                    </div>
                    {comments.map(({comment, avatar, icon, createdAt, fullName, id, isLiked, likes, replies}, index) =>
                        <div className={styled.commentItem} key={id}><CommentItem key={index}
                                                                                  comment={comment}
                                                                                  avatar={avatar}
                                                                                  createdAt={createdAt}
                                                                                  fullName={fullName}
                                                                                  idComment={id}
                                                                                  isLiked={isLiked}
                                                                                  likes={likes} replies={replies}
                                                                                  icon={ladaIcon}
                                                                                  authToken={infoUser.authToken}
                        /></div>)}
                </div>
            </div>
            <footer>
                <button onClick={() => closeDiscussion(false)}>
                    {t('discussion.back')} <img src={arrowIcon} alt="=>"/>
                </button>
            </footer>
        </div>
    </Dialog>
}

export default Discussion;
