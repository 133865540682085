import React from 'react';
import {Link} from 'react-router-dom';
import styled from './Header.module.scss';
import {useHistory} from "react-router-dom";
import Video from "../../../Tasks/components/Video/Video";
import {useTranslation} from "react-i18next";
import {questions} from "../../../Billing/components/FAQ/questions";

const Header = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return <div className={styled.wrapper}>
        <div className={styled.content}>
            <div className={styled.left}>
                <h3>{ t('main.title1') }</h3>
                <div className={styled.text}>
                    { t('main.text1') }
                    <br/><br/>
                    { t('main.text2') }
                    <br/><br/>
                    { t('main.text3') }
                    <br/><br/>
                    { t('main.text4') }
                    <br/><br/>
                    { t('main.text5') }
                    <br/><br/>
                    <Link to={'/all-courses'}>{ t('main.link.label1') }</Link> { t('main.link.text1') }<br/>
                    <br/>
                    <Link to={'/practices'}>{ t('main.link.label2') }</Link> { t('main.link.text2') }<br/>
                    <br/>
                    <Link to={'/tests'}>{ t('main.link.label3') }</Link> { t('main.link.text3') }<br/>
                    <br/>
                    <Link to={'/games'}>{ t('main.link.label4') }</Link> { t('main.link.text4') }<br/>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: t('main.text6')}}
                        className={"styled.text"}/>
                    <br/>
                    <strong>{ t('main.text7') }</strong><br/>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: t('main.text8')}}
                         className={"styled.text"}/>
                    <br/>
                    { t('main.text9') }
                </div>
                <button onClick={() => history.push('/')}>{ t('main.button.label') }</button>
            </div>
            <div className={styled.right}>
                <div className={styled.video}>
                    <Video link="https://vimeo.com/533988380"/>
                </div>
            </div>
        </div>
    </div>;
};

export default Header;
