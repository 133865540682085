import React, {useRef, useEffect, useState} from "react"
import config from "../../../../etc/config"
import moment from "moment"



const Comments = ({appDelegate, id, themeDelegate}) => {

    const textarea = useRef(null)

    const [hasFocused, setFocus] = useState(false)
    const [height, setHeight] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        textarea.current.addEventListener("input", handler)
        setHeight(textarea.current.clientHeight)

        return () => {
            textarea.current.removeEventListener("input", handler)
        }
    }, [appDelegate, themeDelegate])

    const handler = (e) => {
        if (!textarea.current)
            return
        textarea.current.rows = 1
        const rows = (textarea.current.scrollHeight - 24) / 24;
        textarea.current.rows = rows;
        setHeight(textarea.current.clientHeight)
    }

    const submit = (e) => {
        e.preventDefault()

        if (appDelegate.state.anonymous) {
            alert("Комментарии могут оставлять только авторизованные пользователи")
            return;
        }

        if (!textarea.current.value)
            return

        setLoading(true)

        fetch(`${config.API_PREFIX}themes/${id}/reviews`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${appDelegate.state.authToken}`
            },
            body: JSON.stringify({
                review: textarea.current.value
            })
        })
            .then(res => {
                if (res.status === 401)
                    appDelegate.logout();
                setLoading(false)
                return res.json();
            })
            .then(res => {
                textarea.current.rows = 1
                textarea.current.value = ""
                setFocus(false)
                themeDelegate.commentCompletion(res)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className={"comment-block"}>
            <h3>Комментарии {themeDelegate.state.data.reviews && themeDelegate.state.data.reviews.length > 0 ? `(${themeDelegate.state.data.reviews.length})` : ""}</h3>
            <p className={"have-question"}>Если у вас есть вопросы или комментарии к главе, то напишите нам здесь и получите ответ на email. Также можете посмотреть вопросы и отзывы других студентов. Будем рады, если вы найдете немного времени и оставите свой отзыв.<br/>
            Как задать вопрос и как получить ответ на него смотрите <a href="https://vimeo.com/428395844" rel="noopener noreferrer" target={"_blank"}>здесь</a></p>
            <form onSubmit={submit} style={{ maxHeight: `${hasFocused ? height + 48 : height}px` }} className={`textarea-container ${hasFocused ? "active" : ""}`}>
                <textarea placeholder={"Ваш комментарий..."} onFocus={() => setFocus(true)} onBlur={(e) => setFocus( (e.target.value && e.target.value.length > 0))} ref={textarea} name="comment" rows={1} />
                <button disabled={loading} type={"submit"}>
                    {loading ? (
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#ffffff" stroke="none">
                                <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"/>
                            </path>
                        </svg>
                    ) : "Отправить"}
                </button>
            </form>
            {themeDelegate.state.data.reviews.map((item, index) => (
                <div className={"comment"} key={index}>
                    <div className={"comment__user"}>
                        <div style={{ background: `#babbbf url(${item.photo})` }} className={"photo"}>{!item.photo ? item.user[0].toUpperCase() : ""}</div>
                        <div className={"info"}>
                            <h4>{item.user}</h4>
                            <p>{moment(item.created_at).format("MMM DD, YYYY")}</p>
                        </div>
                    </div>
                    <div className={"message"}>
                        {item.review.split("\n").map((p, idx) => (
                            <p key={idx}>{p}</p>
                        ))}
                    </div>
                    {item.answer ? (
                        <div className={"comment answer"}>
                            <div className={"comment__user"}>
                                <div style={{ background: `#babbbf url(/Images/edu.jpg)` }} className={"photo"} />
                                <div className={"info"}>
                                    <h4>Кот Эдью</h4>
                                    <p>{moment(item.update_at).format("MMM DD, YYYY")}</p>
                                </div>
                            </div>
                            <div className={"message"}>
                                {item.answer.split("\n").map((p, idx) => (
                                    <p key={idx}>{p}</p>
                                ))}
                            </div>
                        </div>
                    ) : ""}
                </div>
            ))}
        </div>
    )
}


export default Comments