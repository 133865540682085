import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Screen5.module.css';

const Screen5 = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Чтобы стать мастером, нужно тренироваться</h2>
            <Link className={styles.buySubscriptionLinkAccent}>Купить подписку</Link>
        </div>
    )
}

export default Screen5;