import React from 'react';
import s from './SwitcherComponent.module.scss'

const SwitcherComponent = ({subscriptions, changeTariff}) => {
    return <div className={s.wrapper}>
        <span className={subscriptions.currentTariff ? s.selected : ''}>
            {subscriptions.subscriptions[0].prices[1].title}
        </span>
        <div className={`${s.toggler} ${subscriptions.currentTariff ? s.firstOptionSelected : ''}`} onClick={changeTariff} />
        <span className={subscriptions.currentTariff ? s.selected : ''}>
            {subscriptions.subscriptions[0].prices[0].title}
        </span>
    </div>
}

export default SwitcherComponent;
