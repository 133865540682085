import React, {useState} from 'react'
import styled from './CancelBilling.module.scss'
import {useDispatch} from 'react-redux'
import {deleteAutoSubscriptionThunk} from '../../../../../redux/actions/subscription';
import {useTranslation} from "react-i18next";



const CancelBilling = ({appDelegate}) => {
    const [isAutoRenewal, setIsAutoRenewal] = useState(!appDelegate.state.user.subscriptions.scheduled)
    const [showCausesStopBilling, setShowCausesStopBilling] = useState(true)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const subscription = [t('subscription.monthly'), t('subscription.yearly')];
    const reasons = [t('subscription.reason4'), t('subscription.reason5')];

    // Выключить(включить) автопродление
    const handlerSwitcher = () => {
        setIsAutoRenewal(false)
        dispatch(deleteAutoSubscriptionThunk(appDelegate.state.authToken, appDelegate.state.user.subscriptions.id_sub))
        setShowCausesStopBilling(false)
    }

    // Показать(скрыть) окно "Причина отключение автопродления биллинга"
    const handlerCausesStopBilling = () => {
        showCausesStopBilling ? setShowCausesStopBilling(false) : setShowCausesStopBilling(true)
    }

    return <div className={styled.wrapper}>
        <div className={styled.container}>
            <h3>
                {t('account.nav.subscription')}
            </h3>
            <p>
                {t('subscription.plan.title')}
            </p>
            <div className={styled.title}>
                <span className={styled.price}>{subscription[appDelegate.state.user.subscriptions.id - 2]}</span>
                <span className={styled.validUntil}>
                    {t('subscription.plan.valid')} {appDelegate.state.user.subscriptions.end}
                </span>
            </div>
            <div className={!showCausesStopBilling ? styled.content : `${styled.content} ${styled.activeContent}`}>
                <div className={styled.statusAndSwitcher}>
                    <div>
                        <img
                            className={showCausesStopBilling && styled.activeCausesStopBilling}
                            src="/Images/arrow-cancel-billing.png" alt=""
                            onClick={handlerCausesStopBilling}
                        />
                        <span>
                            {t('subscription.auto')}
                        </span>
                    </div>
                    <div className={styled.switcher}>
                        <div
                            className={isAutoRenewal ? `${styled.wrapperSwitcher} ${styled.activeSwitcher}` : styled.wrapperSwitcher}
                            style={{justifyContent: isAutoRenewal ? 'flex-end' : 'flex-start'}}
                            onClick={appDelegate.state.user.subscriptions.status === 'active' && handlerSwitcher}
                        >
                            <div className={styled.icon} />
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        {t('subscription.reason1')}
                    </p>
                    <div className={styled.lines}>
                        {reasons.map((reason, index) => <div className={styled.line} key={index}>
                            <input type="checkbox" id={index}/>
                            <label htmlFor={index}>
                                <div className={styled.checkbox}/>
                                <div className={styled.label}>{reason}</div>
                            </label>
                        </div>)}
                        <p className={styled.label}>
                            {t('subscription.reason2')}
                        </p>
                        <textarea placeholder={t('subscription.reason3')}></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CancelBilling
