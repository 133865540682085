import React, {useState} from 'react'
import './faq-questions.scss';
import arrowIcon from '../../../Description/img/arrow.png'
import ReactYoutube from "../../../Tasks/components/YouTube/YouTube";
import {useTranslation} from "react-i18next";

type Props = {
    title: string;
    description: null | string;
    classQuestion: string;
    link?: null | string;
}

const FaqQuestions = ({title, description = null, classQuestion, link = null}: Props) => {
    const { t } = useTranslation();

    const [openQuestion, setOpenQuestion] = useState<boolean>(false)

    const handleOpenQuestion = () => {
        setOpenQuestion(prev => !prev)
    }

    return <div className={'wrapper-faq-question'} onClick={handleOpenQuestion}>
        <div className={'title-question'}>
            <span className={classQuestion}>{ t(title) }</span>
            <img className={openQuestion ? 'open-arrow' : 'close-arrow'} src={arrowIcon} alt=">"/>
        </div>
        {openQuestion && <div className={'content-question'}>
            {description !== null && <span className={openQuestion ? 'open-question' : 'close-question'}>{ t(description) }</span>}
            {link !== null && <ReactYoutube link={link} />}
        </div>}
    </div>
}

export default FaqQuestions
