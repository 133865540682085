import React from "react";


const withSelect = Component => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isOpen: false
            };
            this.toggle = this.toggle.bind(this);
            this.close = this.close.bind(this);
            this.body = React.createRef();
        }

        componentDidMount() {
            window.addEventListener("click", this.close);
            this.body.current.addEventListener("click", this.toggle);
        }

        componentWillUnmount() {
            window.removeEventListener("click", this.close);
            this.body.current.removeEventListener("click", this.toggle);
        }

        toggle(e) {
            e.stopPropagation();
            this.setState({isOpen: !this.state.isOpen});
        }

        close() {
            this.setState({isOpen: false});
        }

        changeItem(value) {
            this.setState({value: value, isOpen: false})
        }


        render() {
            return (
                <Component
                    selectDelegate={this}
                    {...this.props}
                />
            );
        }
    }

    HOC.displayName = `withSelect(${Component.displayName ||
    Component.name ||
    "Component"})`;
    return HOC;
};

export default withSelect;