import React from 'react'

const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null)
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true)
        }
    }, [])
    return (
        <div className={"trust-box"}>
            <div
                ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                className="trustpilot-widget" // Renamed this to className.
                data-locale="ru-RU"
                data-template-id="5613c9cde69ddc09340c6beb"
                data-businessunit-id="5f32462b6ebe7100015c02f3"
                data-style-height="52px"
                data-style-width="100%"
            >
                <a
                    href="https://uk.trustpilot.com/review/fromfirstprincipals.com"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div>
        </div>
    )
}
export default TrustBox
