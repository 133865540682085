import React from "react"
import {Link} from "react-router-dom"
import Course from "../Course"
import SelectWithRadio from "../../../../Components/SelectWithRadio";
import Loader from "../../../../Components/Loader";

const AllCourses = ({homeDelegate, appDelegate}) => (
    <section className={"all-courses"}>
        <h1>Все курсы</h1>
        {!appDelegate.state.user || !appDelegate.state.user.subscriptions ? (
            <div className={"top"}>
                <p>Тьюторское сопровождение позволяет максимально быстро продвигаться в изучении программирования и
                    решения
                    широкого круга задач.</p>
                <Link to={"/billing"} className={"button"}>Подписаться</Link>
            </div>
        ) : ""}
        {/*<div className={"special-offer"}>*/}
        {/*    <div className={"special-offer__left"}>*/}
        {/*        <p>Специальное предложение – Скидка 50% на пакет Educat Pro</p>*/}
        {/*        <p>Переключитесь на годовой план всего за 84 доллара в год. Меньше счетов, больше Marvel.</p>*/}
        {/*    </div>*/}
        {/*    <Link to={"/billing"} className={"button-sell"}>Получить скидку</Link>*/}
        {/*</div>*/}

        <div className={"choose-direction"}>
            {/*<p>Выберите направление:</p>*/}
            {/*<div className={"choose-direction__program"}>*/}
            {/*    <Link className={homeDelegate.state.category === -1 ? "active" : ""} to={"/"}>Все</Link>*/}
            {/*    {homeDelegate.state.data.categories.map((item, index) => (*/}
            {/*        <Link className={homeDelegate.state.category === item.id ? "active" : ""}*/}
            {/*              to={`/?category=${item.id}`} key={index}>{item.name}</Link>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <SelectWithRadio defaultSelected={homeDelegate.currentCategory || 0} title={"Все направления"} name={"type"}
                             handler={(name, value) => {
                                 homeDelegate.props.history.push(`/${homeDelegate.getQueryWithout("category")}category=${value.id}`)
                             }}
                             data={homeDelegate.state.data.categories}/>


        </div>

<div className={"container"}>
        {homeDelegate.state.loading ? (
            <Loader/>
        ) : (
            <React.Fragment>
                {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "profession" && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).length > 0 ? (
                    <div className={"profession"}>
                        <h3>Професии</h3>

                        {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "profession" && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).map((item, index) => (
                            <Course subscribed={appDelegate.state.user.subscriptions !== null}
                                    added={homeDelegate.state.data.addedCourses.includes(item.id)}
                                    categories={homeDelegate.state.data.categories} item={item} key={index}/>
                        ))}


                    </div>
                ) : ""}

                {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "course" && !x.free && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).length > 0 ? (
                    <div className={"profession"}>
                        <h3>Платные курсы</h3>
                        {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "course" && !x.free && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).map((item, index) => (
                            <Course subscribed={appDelegate.state.user.subscriptions !== null}
                                    added={homeDelegate.state.data.addedCourses.includes(item.id)}
                                    categories={homeDelegate.state.data.categories} item={item} key={index}/>
                        ))}
                    </div>
                ) : ""}

                {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "course" && x.free && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).length > 0 ? (
                    <div className={"profession"}>
                        <h3>Бесплатные курсы</h3>
                        {homeDelegate.state.data.courses.filter(x => x.type.toLowerCase() === "course" && x.free && !x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).map((item, index) => (
                            <Course subscribed={appDelegate.state.user.subscriptions !== null}
                                    added={homeDelegate.state.data.addedCourses.includes(item.id)}
                                    categories={homeDelegate.state.data.categories} item={item} key={index}/>
                        ))}
                    </div>
                ) : ""}
                {homeDelegate.state.data.courses.filter(x => x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).length > 0 ? (
                    <div className={"profession"}>
                        <h3>Курсы в разработке</h3>
                        {homeDelegate.state.data.courses.filter(x => x.enabled && (homeDelegate.state.category === -1 || homeDelegate.state.category === x.category)).map((item, index) => (
                            <Course subscribed={appDelegate.state.user.subscriptions !== null}
                                    categories={homeDelegate.state.data.categories} item={item} key={index}/>
                        ))}
                    </div>
                ) : ""}

            </React.Fragment>
        )}
</div>


    </section>
)
export default AllCourses