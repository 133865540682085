import React from 'react'
import styled from './style.module.scss'
import Header from "./components/Header/Header"
import QuestionsAndTariff from "../../Components/QuestionsAndTarif/QuestionsAndTarif"
import StepsLearnProgramming from "../../Components/StepsLearnProgramming"
import VideosMainPage from "./components/VideosMainPage"
import ReviewAboutUs from "./components/ReviewAboutUs/ReviewAboutUs"
import DevelopmentPlan from "./components/DevelopmentPlan/DevelopmentPlan"

const MainPage = () => {
    return <div className={styled.wrapper}>
            <div className={styled.container}>
                <Header/>
                <StepsLearnProgramming/>
                <VideosMainPage/>
                {/*<RoulettePart />*/}
                {/*<CertificatesMainPage/>*/}
                {/*<ReviewAboutUs/>*/}
                {/*<DevelopmentPlan/>*/}
                <QuestionsAndTariff purchasedTariff={null} />
            </div>
    </div>
}

export default MainPage
