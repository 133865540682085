import React from "react";
import {Link} from "react-router-dom"

const Question = ({appDelegate, allowed = true, subscribed = true}) => {
    return (<section className={"question"}>
        <div className={"question__content"}>
            <h2>Нужна помощь?</h2>
            <p className={"question-text"}><p> Если вы понимаете что без преподавателя не справитесь, и у вас есть
                платная подписка, вы можете записаться на онлайн созвон и совместно пройти и разобрать ваш проект в
                реальном времени прямо с нашими программистами. <br/>
                Как записаться на консультацию смотрите <a href="https://vimeo.com/428395844" rel="noopener noreferrer"
                                                           target={"_blank"}>здесь</a></p>
            </p>
            {/*<div className="consultation">{appDelegate.state.user.consultation === 1 ? `У вас ${appDelegate.state.user.consultation} доступная консультация` : appDelegate.state.user.consultation < 1 ? `У вас ${appDelegate.state.user.consultation} доступные консультации` : appDelegate.state.user.consultation < 4 ? `У вас ${appDelegate.state.user.consultation} доступных консультаций` : 'У вас нет доступных консультаций'}</div>*/}
            <div className={"question-box"}>
                <div className={"box-inside"}>
                    {/*<div className={"inside"}>*/}
                    {/*    <h4>Продвижение карьеры</h4>*/}
                    {/*    <p className={"choose"}>Выберете дату и время, чтобы получить руководство по своему продвижению*/}
                    {/*        и составить персональную карту развития</p>*/}
                    {/*    <div className={"inside-bottom"}>*/}
                    {/*        {allowed ? (*/}
                    {/*            <a href={"https://calendly.com/mazur-1/30min"}*/}
                    {/*               className={"sign-up"} target={"_blank"} rel="noopener noreferrer">*/}
                    {/*                <img src="/Images/calendar.svg" alt="calendar"/>*/}
                    {/*                <span>Записаться на консультацию</span>*/}
                    {/*                <span className={"mobile"}>Записаться</span>*/}
                    {/*            </a>*/}
                    {/*        ) : (*/}
                    {/*            <Link to={subscribed ? "/subscription" : "/billing"}*/}
                    {/*                  className={"sign-up"}>*/}
                    {/*                <img src="/Images/calendar.svg" alt="calendar"/>*/}
                    {/*                <span>Записаться на консультацию</span>*/}
                    {/*                <span className={"mobile"}>Записаться</span>*/}
                    {/*            </Link>*/}
                    {/*        )}*/}

                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"inside"}>
                        <h4>Связь с преподавателем</h4>
                        <p className={"choose"}>Запишитесь к преподавателю, чтобы разобрать вашу проблему в реальном времени (доступна только для студентов с подпиской)</p>
                        <div className={"inside-bottom"}>
                            {allowed ? (
                                <a href={"https://calendly.com/educat-courses"}
                                   className={"sign-up"} target={"_blank"} rel="noopener noreferrer">
                                    <img src="/Images/calendar.svg" alt="calendar"/>
                                    <span>Записаться на консультацию</span>
                                    <span className={"mobile"}>Записаться</span>
                                </a>
                            ) : (
                                // <Link to={subscribed ? "/subscription" : "/billing"}
                                <Link to={"/billing"}
                                      className={"sign-up"}>
                                    <img src="/Images/calendar.svg" alt="calendar"/>
                                    <span>Записаться на консультацию</span>
                                    <span className={"mobile"}>Записаться</span>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>)
}
export default Question