import {tasksAPI} from '../../api/api';
import {AppDispatch} from '../store';
import {TestItem} from '../../types/tests';

export const SET_ALL_TESTS = 'SET_ALL_TESTS';
export const SET_TEST = 'SET_TEST';
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME';
export const RESET_TEST = 'RESET_TEST';
export const SHOW_THEME = 'SHOW_THEME';
export const CLOSE_THEME = 'CLOSE_THEME';
export const SWITCH_NEXT_TASK = 'SWITCH_NEXT_TASK';
export const CHANGED_PROGRESS_THEME = 'CHANGED_PROGRESS_THEME';
export const CHECKED_THE_TASK = 'CHECKED_THE_TASK';
export const CLOSE_PAGE_SUCCESS_PASSED_THEME = 'CLOSE_PAGE_SUCCESS_PASSED_THEME';
export const SWITCH_ON_NEXT_SUCCESS_TASK = 'SWITCH_ON_NEXT_SUCCESS_TASK';
export const SWITCH_ON_BACK_SUCCESS_TASK = 'SWITCH_ON_BACK_SUCCESS_TASK';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
export const CLOSE_END_PAGE_TEST = 'CLOSE_END_PAGE_TEST';
export const CHANGE_IS_LOADING = 'CHANGE_IS_LOADING';

export const setCurrentTask = () => {
    return {
        type: SET_CURRENT_TASK
    }
}

export const closeEndPageTest = () => {
    return {
        type: CLOSE_END_PAGE_TEST
    }
}

const setAllTests = ({data, categories, total, perPage, currentPage}: any) => {
    return {
        type: SET_ALL_TESTS,
        payload: {data, categories, total, perPage, currentPage}
    }
}

const setTest = (test: TestItem) => {
    return {
        type: SET_TEST,
        payload: test
    }
}

export const showThemeTest = (idTheme: number, idTask: number) => {
    return {
        type: SHOW_THEME,
        payload: {idTheme, idTask}
    }
}

export const closeThemeTest = () => {
    return {
        type: CLOSE_THEME
    }
}

export const changeNextTask = (subscription: any) => {
    return {
        type: SWITCH_NEXT_TASK,
        payload: subscription
    }
}

export const setCurrentTheme = (theme: TestItem, subscription: any) => {
    return {
        type: SET_CURRENT_THEME,
        payload: {theme, subscription}
    }
}

export const resetTest = (isStatus: boolean) => {
    return {
        type: RESET_TEST,
        payload: isStatus
    }
}

const checkedTask = (is_correct: number, answer: string, id: number) => {
    return {
        type: CHECKED_THE_TASK,
        payload: {is_correct, answer, id}
    }
}

const changedProgressTheme = () => {
    return {
        type: CHANGED_PROGRESS_THEME
    }
}

export const switchOnNextSuccessTask = () => {
    return {
        type: SWITCH_ON_NEXT_SUCCESS_TASK
    }
}

export const switchOnBackSuccessTask = () => {
    return {
        type: SWITCH_ON_BACK_SUCCESS_TASK
    }
}


const changeIsLoading = (isLoading: boolean) => {
    return {
        type: CHANGE_IS_LOADING,
        payload: isLoading
    }
}
export const getAllTestsThunk = (authToken: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeIsLoading(true))
        let response = await tasksAPI.getAllTests(authToken)
        try {
            dispatch(setAllTests(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getPaginationPageTests = (authToken: string, page: number, category: number) => {
    return async (dispatch: AppDispatch) => {
        let response = await tasksAPI.getPaginationTest(authToken, page, category)
        try {
            dispatch(setAllTests(response.data))
        } catch (e) {
            console.log(e)
        }
    }
}

export const sortCategoryTests = (authToken: string, category: number) => {
    return async (dispatch: AppDispatch) => {
        let response;
        if (category === 0) {
            response = await tasksAPI.getAllTests(authToken)
        } else {
            response = await tasksAPI.sortCategoryTests(authToken, category)
        }
        try {
            dispatch(setAllTests(response.data))
        } catch (e) {
            console.log(e)
        }
    }
}

export const getTestThunk = (id: number, authToken: string) => {
    return async (dispatch: AppDispatch) => {
        let response = await tasksAPI.getTest(id, authToken)
        try {
            dispatch(setTest(response.data.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const closePageSuccessPassedTheme = () => {
    return {
        type: CLOSE_PAGE_SUCCESS_PASSED_THEME
    }
}

export const resetTestThunk = (id: number, authToken: string) => {
    return async (dispatch: AppDispatch) => {
        let response = await tasksAPI.resetTest(id, authToken)
        try {
            dispatch(resetTest(true))
        } catch (error) {
            console.log(error)
        }
    }
}

const checkedTaskAndChangeProgressTheme = (is_correct: number, answer: string, id: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(checkedTask(is_correct, answer, id))
        if (is_correct) {
            dispatch(changedProgressTheme())
        }
    }
}

export const checkTaskThunk = (id: number, answers: string[], authToken: string) => {
    const clearAnswers = answers.filter(function (x) {
        return x !== undefined && x !== null;
    });
    return async (dispatch: AppDispatch) => {
        dispatch(changeIsLoading(true));
        let response = await tasksAPI.checkTheTask(id, clearAnswers, authToken);
        const {is_correct, answer} = response.data;
        try {
            dispatch(checkedTaskAndChangeProgressTheme(is_correct, answer, id))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeIsLoading(false))
        }
    }
}
