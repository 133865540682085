import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const Policy = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('nav.policy');
        window.scrollTo({top: 0, left: 0});
    });
    return (
        <div className={"content-main"}>
            <div className={"html-data"}
                 style={{boxSizing: "border-box", width: "720px", maxWidth: "90%", margin: "auto", padding: "42px 0"}}>
                <h1>{ t('privacy.policy.h1') }</h1>
                <h2>{ t('privacy.policy.h2') }</h2>
                <h2>{ t('privacy.policy.item1') }</h2>
                <p>{ t('privacy.policy.subitem1.1') }</p>
                <p>{ t('privacy.policy.subitem1.2') }</p>
                <h2>{ t('privacy.policy.item2') }</h2>
                <p>{ t('privacy.policy.subitem2.1') }</p>
                <p>{ t('privacy.policy.subitem2.2') }</p>
                <ul>
                    <li>{ t('privacy.policy.subitem2.li1') }</li>
                    <li>{ t('privacy.policy.subitem2.li2') }</li>
                    <li>{ t('privacy.policy.subitem2.li3') }</li>
                </ul>
                <p>{ t('privacy.policy.subitem2.3') }</p>
                <p>{ t('privacy.policy.subitem2.4') }</p>
                <p>{ t('privacy.policy.subitem2.5') }</p>
                <h2>{ t('privacy.policy.item3') }</h2>
                <p>{ t('privacy.policy.subitem3.1') }</p>
                <p>{ t('privacy.policy.subitem3.2') }</p>
                <p>{ t('privacy.policy.subitem3.3') }</p>
                <p>{ t('privacy.policy.subitem3.4') }</p>
                <h2>{ t('privacy.policy.item4') }</h2>
                <p>{ t('privacy.policy.subitem4.1') }</p>
                <p>{ t('privacy.policy.subitem4.2') }</p>
                <p>{ t('privacy.policy.subitem4.3') }</p>
                <p>{ t('privacy.policy.subitem4.4') }</p>
                <h2>{ t('privacy.policy.item5') }</h2>
                <p>{ t('privacy.policy.subitem5.1') }</p>
                <p>{ t('privacy.policy.subitem5.2') }</p>
                <p>{ t('privacy.policy.subitem5.3') }</p>
                <h2>{ t('privacy.policy.item6') }</h2>
                <p>{ t('privacy.policy.subitem6.1') }</p>
                <p>{ t('privacy.policy.subitem6.2') }</p>
                <p>{ t('privacy.policy.subitem6.3') }</p>
                <ul>
                    <li>{ t('privacy.policy.subitem6.li1') }</li>
                    <li>{ t('privacy.policy.subitem6.li2') }</li>
                    <li>{ t('privacy.policy.subitem6.li3') }</li>
                    <li>{ t('privacy.policy.subitem6.li4') }</li>
                    <li>{ t('privacy.policy.subitem6.li5') }</li>
                    <li>{ t('privacy.policy.subitem6.li6') }</li>
                    <li>{ t('privacy.policy.subitem6.li7') }</li>
                    <li>{ t('privacy.policy.subitem6.li8') }</li>
                    <li>{ t('privacy.policy.subitem6.li9') }</li>
                    <li>{ t('privacy.policy.subitem6.li10') }</li>
                </ul>
                <h2>{ t('privacy.policy.item7') }</h2>
                <p>{ t('privacy.policy.subitem7.1') }</p>
                <p>{ t('privacy.policy.subitem7.2') }</p>
                <p>{ t('privacy.policy.subitem7.3') }</p>
                <p>{ t('privacy.policy.subitem7.4') }</p>
                <h2>{ t('privacy.policy.item8') }</h2>
                <p>{ t('privacy.policy.subitem8.1') }</p>
                <p>{ t('privacy.policy.subitem8.2') }</p>
                <ul>
                    <li>{ t('privacy.policy.subitem8.li1') }</li>
                    <li>{ t('privacy.policy.subitem8.li2') }</li>
                </ul>
                <p>{ t('privacy.policy.subitem8.3') }</p>
                <p>{ t('privacy.policy.subitem8.4') }</p>
                <p>{ t('privacy.policy.subitem8.5') }</p>
            </div>
        </div>
    )
};


export default Policy;