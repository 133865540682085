import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";

const Notifications = ({accountDelegate, appDelegate}) => {

    const [checked, toggleChecked] = useState(Object.keys(accountDelegate.state.notification).reduce((prev, key) => accountDelegate.state.notification[key].enabled ? [...prev, key] : prev, []));

    const [verified, toggleVerified] = useState(appDelegate.state.user && appDelegate.state.user.phone_verified === "Verified");
    const { t } = useTranslation();

    useEffect(() => {
        toggleChecked(Object.keys(accountDelegate.state.notification).reduce((prev, key) => accountDelegate.state.notification[key].enabled ? [...prev, key] : prev, []))
    }, []);

    const inputRef = useRef(null);



    return (
        <div className={"notification"}>
            <form className={"notification-form"}>
                <h3>
                    {t('account.notifications.sms')}
                </h3>
                <label htmlFor="tel">
                    {t('account.notifications.phone')}
                </label>
                <div className={"tel-box"}>
                    <div className={!verified && checked.includes("phone") ? "tel-input active" : "tel-input" }>

                        <input ref={inputRef}
                               disabled={!checked.includes("phone")}
                               className={checked.includes("phone") ? "tel" : "tel disable"} type="tel" id={"tel"} placeholder={"Телефон"}
                               value={accountDelegate.state.phone}
                               name={"phone"}
                               onChange={(e) => {
                                   accountDelegate.handlerChange(e)
                                   toggleVerified(false)
                               }}/>
                        {verified && accountDelegate.state.phone !== "Empty" ? (
                            <div onClick={() => {
                                toggleVerified(false)

                            }} className={checked.includes("phone") ? "change" : "change disable"}>{t('btn.change')}</div>
                        ) : ""}

                    </div>
                    <button className={!verified ? "" : "grey"}
                            onClick={e => {
                                e.preventDefault();
                        if (!verified) {
                            appDelegate.togglePhoneValidator();
                        }}}>
                        {t('account.notifications.verify')}
                    </button>
                </div>
                <div className={checked.includes("phone") ? "switch-box" : "switch-box disable"}>
                    <div className={"switch-box__top"}>
                        <label>
                            {t('account.notifications.sms1')}
                        </label>
                        <div className="switch">
                            <input checked={checked.includes("phone")} onChange={ e => {
                                if (e.target.checked) {
                                    toggleChecked([...checked, "phone"])
                                    inputRef.current.focus()
                                }
                                else
                                    toggleChecked(checked.filter(x => x !== "phone"))
                                accountDelegate.toggleNotification(accountDelegate.state.notification.phone.id, e.target.checked)
                            }
                            } type="checkbox" id={"switch-sms"}/>
                            <label htmlFor="switch-sms" className={"switch-label"}/>
                        </div>
                    </div>
                    <div className={"check-box"}>
                        {accountDelegate.state.notification.phone.events.map((item, index) => (
                            <div className="line" key={index}>
                                <input disabled={!checked.includes("phone")} checked={item.enabled} onChange={event => {
                                    accountDelegate.toggleNotificationEvents(accountDelegate.state.notification.phone.id, event.target.checked, item.id)
                                }} type="checkbox" id={`phone-${item.id}`}/>

                                <label htmlFor={`phone-${item.id}`}>
                                    <div className={"checkbox"}/>
                                    <div className={"label"}>{item.name}</div>
                                </label></div>
                        ))}

                    </div>

                </div>
                <div className={checked.includes("email") ? "switch-box" : "switch-box disable"}>
                    <h3>
                        {t('account.notifications.email')}
                    </h3>
                    <div className={"switch-box__top"}>
                        <label>
                            {t('account.notifications.email1')}
                        </label>
                        <div className="switch">
                            <input checked={checked.includes("email")} onChange={e => {
                                if(e.target.checked) {
                                    toggleChecked([...checked, "email"])
                                }
                                else
                                    toggleChecked(checked.filter(x => x !== "email"))
                                accountDelegate.toggleNotification(accountDelegate.state.notification.email.id, e.target.checked)

                            }} type="checkbox" id={"switch-email"}/>
                            <label htmlFor="switch-email" className={"switch-label"}/>
                        </div>
                    </div>
                    <div className={"check-box"}>
                        {accountDelegate.state.notification.email.events.map((item, index) => (
                        <div key={index} className="line">
                            <input disabled={!checked.includes("email")} checked={item.enabled} type="checkbox" id={`email-${item.id}`} onChange={event => {
                                accountDelegate.toggleNotificationEvents(accountDelegate.state.notification.email.id, event.target.checked, item.id)
                            }}/>
                            <label htmlFor={`email-${item.id}`}>
                                <div className={"checkbox"}/>
                                <div className={"label"}>{item.name}</div>
                            </label></div>
                            ))}

                    </div>
                </div>
                <div className={checked.includes("browser") ? "switch-box" : "switch-box disable"}>
                    <h3>
                        {t('account.notifications.push')}
                    </h3>
                    <div className={"switch-box__top"}>
                        <label>
                            {t('account.notifications.push1')}
                        </label>
                        <div className="switch">
                            <input checked={checked.includes("browser")} onChange={ e => {
                                if (e.target.checked)
                                    toggleChecked([...checked, "browser"])
                                else
                                    toggleChecked(checked.filter(x => x !== "browser"))
                                accountDelegate.toggleNotification(accountDelegate.state.notification.browser.id, e.target.checked)
                            }} type="checkbox" id={"switch-browser"}/>
                            <label htmlFor="switch-browser" className={"switch-label"}/>
                        </div>
                    </div>
                    <div className={"check-box"}>
                        {accountDelegate.state.notification.browser.events.map((item, index) => (
                        <div key={index} className="line">
                            <input disabled={!checked.includes("browser")}  checked={item.enabled} type="checkbox" id={`browser-${item.id}`} onChange={event => {
                                accountDelegate.toggleNotificationEvents(accountDelegate.state.notification.browser.id, event.target.checked, item.id)
                            }}/>
                            <label htmlFor={`browser-${item.id}`}>
                                <div className={"checkbox"}/>
                                <div className={"label"}>{item.name}</div>
                            </label></div>
                            ))}

                    </div>
                </div>
            </form>

        </div>
    )
}
export default Notifications