import React from "react"
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup"
import { AuthSubmitHandlerFactory } from "View/Pages/RegistrationAlternative/types";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";
import { useDocumentTitle } from "View/Pages/RegistrationAlternative/hooks/useDocumentTitle";
import { useDropPasswordInitialValues } from "./hooks/useDropPasswordInitialValue";
import { useDropPasswordApi } from "./hooks/useDropPasswordApi";
import { DropPasswordFormValues, DropPasswordProps, UseDropPasswordApiResult } from "./types";
import { isFieldInValid } from "../../utils/isFieldInValid";
import AuthForm from "../../components/AuthForm";
import {useTranslation} from "react-i18next";

const DropPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите правильный Email')
    .required('Обязательно для заполнения'),
});

const DropPasswordSubmitHandlerFactory: AuthSubmitHandlerFactory<DropPasswordFormValues, UseDropPasswordApiResult> = (
	appDelegate, 
	resetPasswordApiCallback,
	options = {}
) => async (
	values, 
	helpers
) => {
  try {
    const response = await resetPasswordApiCallback(values);
    if(response.data.status === 0 ) {
      alert("Перейдите по ссылке в письме, чтобы завершить изменение пароля."); // TODO: Should be added valid popup
      if(options.onSuccess) {
        options.onSuccess(values);
      }
    }
    if(response.data.status === 1 ) {
      helpers.setFieldError(
        "email", 
        response.data.message || "Что-то пошло не так, возможно вы указали неверный email."
      );
    }
    throw new Error("Ошибка на сервере");
  } catch (error) {
    console.error("Error while trying reset password by email", error);    
  }
};

function DropPassword({appDelegate}: DropPasswordProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const resetPasswordApiCallback = useDropPasswordApi();
  const resetPasswordInitialValues = useDropPasswordInitialValues();
  const onSuccess = React.useCallback(({email}: DropPasswordFormValues) => {
    history.push({
      pathname: RegistrationRoutes.SIGN_IN,
      search: `?email=${email}`
    });
  }, [history]);
  const formik = useFormik<DropPasswordFormValues>({
    initialValues: resetPasswordInitialValues,
    validationSchema: DropPasswordValidationSchema,
    onSubmit: DropPasswordSubmitHandlerFactory(
      appDelegate, 
      resetPasswordApiCallback,
      { onSuccess }
    )
  });

  const EMAIL_IS_INVALID = isFieldInValid(formik, "email");
  const FORM_IS_INVALID = EMAIL_IS_INVALID;

  useDocumentTitle(t('auth.reset'));
  
  return (
    <AuthForm
      isInValid={FORM_IS_INVALID}
      onSubmit={formik.handleSubmit}
    >
      <h4 className={"or"}>
        {t('auth.reset.title')}
      </h4>
        <label
          className={EMAIL_IS_INVALID ? "error" : ""}
          htmlFor="email"
        >
          { EMAIL_IS_INVALID ? formik.errors.email : "Email" }
        </label>
        <input name="email"
          className={EMAIL_IS_INVALID ? "error" : ""}
          value={formik.values.email}
          onChange={formik.handleChange}
          id={"email"} 
          type="email" 
          placeholder={t('auth.email')}
        />
        
        <button
          type={"submit"}
        >
          {t('btn.send')}
        </button>
          
        <span 
          className={"back"}
          onClick={() => history.goBack()}
        >
          {t('auth.back')}
        </span>
    </AuthForm>
  )
}

export default DropPassword;