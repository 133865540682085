import React from 'react'
import styled from './Header.module.scss'
import {CourseType} from '../../../../../types/course';

const HeaderCourse = ({type, image, name, short_description = ''}: CourseType) => {
    return <div className={styled.headerCourse}>
        <div className={styled.container}>
            <div className={styled.left}>
                <span className={styled.profession}>{type === "Course" ? "Курс" : "Профессия"}</span>
                <div className={styled.title}>
                    <img src={image} alt="Logo"/>
                    <h2 className={styled.titleCourse}>{name}</h2>
                </div>
                {short_description && <span className={styled.descriptionCourse}>{short_description}</span>}
                <button>Перейти к обучению</button>
            </div>
            <img className={styled.logoCourse} src={image} alt="Logo"/>
        </div>
    </div>
}

export default HeaderCourse
