import React, {useEffect} from 'react'
import {useHistory} from 'react-router'
import s from './Card.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {declOfNum} from '../../../../../helpers/declOfNum'
import {RootState} from '../../../../../redux/reducers';
import {resetTest, resetTestThunk} from '../../../../../redux/actions/tests';
import {useTranslation} from "react-i18next";

type Props = {
  id: number;
  image: string;
  name: string;
  isComplete: boolean;
  countTasks: number;
  description: string;
  authToken: string;
}

const Card = ({id, image, name, isComplete, countTasks, description, authToken}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {isResetTest} = useSelector((state: RootState) => state.tasksReducer)
  const { t } = useTranslation();

  const redirectToCourse = () => history.push(`/description/test/${id}`)

  const resetCourse = () => {
    dispatch(resetTestThunk(id, authToken))
  }

  useEffect(() => {
    if (isResetTest) {
      redirectToCourse()
      dispatch(resetTest(false))
    }
  }, [isResetTest])

  return <div className={s.wrapper}>
    <div className={s.iconAndHeader}>
      <div className={s.icon}
           style={{backgroundImage: `url(${image})`}}>
      </div>
      <p className={s.description}>
        <span className={s.title}>{name}</span>
        {description}
      </p>
    </div>

    <div className={s.iconAndInfoMobile}>
      <div className={s.icon}
           style={{backgroundImage: `url(${image})`}}>
      </div>
    </div>

    <div className={s.headerMobile}>
      <p className={s.description}>
        <span className={s.title}>{name}</span>
        {description}
      </p>
    </div>

    <div className={s.infoLinkAndButton}>
      <div className={s.info}>
        {countTasks ? `${countTasks} ${declOfNum(countTasks, [t('task.task1'), t('task.task2'), t('task.task3')])}` : t('task.task4')}
      </div>
      <div className={s.linkAndButton}>
        <Link to={`/about-test/${id}`}>
          {t('task.desc')}
        </Link>
        {isComplete
          ? <button onClick={resetCourse}>
            {t('task.btn.reset')}
        </button>
          : <button onClick={redirectToCourse}>
            {t('task.btn.start')}
          </button>}
      </div>
    </div>
  </div>
}

export default Card
