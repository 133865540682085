import React, {useEffect} from 'react'
import Loader from '../../Components/Loader'
import PaidSubscription from "./components/PaidSubscription";
import FreeSubscription from "./components/FreeSubscription";
import {useDispatch} from 'react-redux';
import {getSubscriptionThunk} from '../../../redux/actions/subscription';
import {AppDelegateType} from '../../../types/app-delegate'

type Props = {
    appDelegate: AppDelegateType
}

const Billing = ({appDelegate}: Props) => {
    const dispatch = useDispatch()

    // get tariffs for billing
    useEffect(() => {
        dispatch(getSubscriptionThunk(appDelegate.state?.authToken))
    }, []);

    return !!appDelegate.state?.user
        ? !Array.isArray(appDelegate.state?.user.subscriptions)
            ? <PaidSubscription subscriptions={appDelegate.state.user.subscriptions}/>
            : <FreeSubscription />
        : <Loader/>
}

export default Billing
