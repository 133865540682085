import React, {useEffect} from 'react';
import style from './MainPage.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {localStorageGetItemGameThunk} from "../../../../redux/gameReducer";
import Cards from "../Cards/Cards";
import StartGame from "../StartGame/StartGame";
import LevelsPage from "../LevelGame/LevelsPage";

const MainPage = props => {
    const token = props.token

    useEffect(() => {
        dispatch(localStorageGetItemGameThunk(token))
    }, [])

    let dispatch = useDispatch()

    const isFinishedButton = useSelector(state => state.gameReducer.isFinishedButton)
    const changeLevelGame = useSelector(state => state.gameReducer.isStartChangeLevelGame)

    return <div className={style.wrapperGame}>
        <div className={style.processGame}>
            {!isFinishedButton && <div className={style.buttonNewGame}>
                    {!changeLevelGame
                        ? <StartGame />
                        : <LevelsPage />}
                </div>
            }
        </div>

        {isFinishedButton && <Cards/>}
    </div>
}

export default MainPage;