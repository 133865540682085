import React from "react";
import config from "../../../../etc/config";
import Loader from "../../../Components/Loader";
import ErrorPage from "../../ErrorPage";

const withCourse = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                activeLevel: -1,
                activeStage: -1,
                errorPage: null
            }
        }


        get isPractice() {
            return this.props.location.pathname.includes("practices")
        }

        componentDidMount() {
            this.getData();
            document.title = "Courses";
            window.scrollTo({top: 0, left: 0});

            if (window.jivo_is_initialize) {
                window.destroyChat()
            } else {
                document.addEventListener("jivoOnLoad", window.destroyChat)
            }



            document.addEventListener("commentEvent", e => {
                console.log(e.data);
                const { stage_id} = e.data;
                const {data} = this.state;
                if (data && data.id && data.levels && data.levels.length > 0) {
                    for (let i = 0; i < data.levels.length; i++) {
                        const idx = data.levels[i].stages.findIndex(x => x.id === stage_id);
                        if (idx > -1) {
                            data.levels[i].stages[idx].notify = true;
                        }
                    }
                }
                this.setState({ data });
            });

            document.addEventListener("successEvent", e => {
                const { stage_id } = e.data;
                const {data} = this.state;
                if (data && data.id && data.levels && data.levels.length > 0) {
                    for (let i = 0; i < data.levels.length; i++) {
                        const idx = data.levels[i].stages.findIndex(x => x.id === stage_id);
                        if (idx > -1) {
                            data.levels[i].stages[idx].notify = true;
                            data.levels[i].stages[idx].done = true;
                        }
                    }
                }
                this.setState({ data });
            });
        }

        componentWillUnmount() {
            document.removeEventListener("jivoOnLoad", window.destroyChat)
        }

        goTo = () => {
            if (!this.props.match.params.level)
                return;

            this.setState({ activeLevel: this.props.match.params.level, activeStage: this.props.match.params.stage || -1 });
        };


        getData = () => {
            fetch(`${config.API_PREFIX}${this.props.location.pathname.includes("practices") ? "practics" : "courses"}/${this.props.match.params.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    // if (this.props.appDelegate.state.user.subscriptions === null && Number(res.free) === 0) {
                    if (this.props.appDelegate.state.user.subscriptions.length === 0 && Number(res.free) === 0) {
                        this.props.history.push("/billing")
                        return
                    }

                    this.setState({ data: res }, () => {
                        document.title = this.state.data.course_name
                        this.goTo();
                    })
                })
                .catch(err => {
                    console.log(err);
                })
        };

        render() {
            if (!this.state.data && !this.state.errorPage)
                return <Loader/>;
            else  if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage} />
            }
            else
                return <Component courseDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withCourse(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withCourse;