import React from 'react';
import styles from './NewBilling.module.css';
import ChoosePrice from './Components/Screen1/Screen1';
import WhatSubscribeGives from './Components/Screen2/Screen2';
import WhyTests from './Components/Screen3/Screen3';
import UsersAboutTests from './Components/Screen4/Screen4';
import WarriorCat from './Components/Screen5/Screen5';

const AvatarColors = {
    YELLOW: `yellow`,
    PINK: `pink`,
    DARK_BLUE: `dark_blue`,
    LIGHT_BLUE: `light_blue`,
    GREEN: 'green'
};

const getReviews = () => [
    {
        color: AvatarColors.YELLOW,
        name: `Иван Дубровский`,
        text: `Тесты для меня оказались очень эффективными, так как визуально я лучше усваиваю информацию.`
    },
    {
        color: AvatarColors.PINK,
        name: `Петр Вахмуров`,
        text: `Я был уверен, что знаю уже все о HTML. Но тесты показали, что я был не прав и мне есть чему научиться. Сейчас я купил подписку и периодически прохожу тесты для проверки своих знаний.`
    },
    {
        color: AvatarColors.DARK_BLUE,
        name: `Мария Григорович`,
        text: `Купила подписку и не парюсь. Занимаюсь когда и где мне удобно. Спасибо Educat!`
    },
    {
        color: AvatarColors.LIGHT_BLUE,
        name: `Анна Максименко`,
        text: `Я был уверен, что знаю уже все о HTML. Но тесты показали, что я был не прав и мне есть чему научиться. Сейчас я купил подписку и периодически прохожу тесты для проверки своих знаний.`
    },
    {
        color: AvatarColors.GREEN,
        name: `Никита Остапенко`,
        text: `Я был уверен, что знаю уже все о HTML. Но тесты показали, что я был не прав и мне есть чему научиться. Сейчас я купил подписку и периодически прохожу тесты для проверки своих знаний.`
    },
    {
        color: AvatarColors.YELLOW,
        name: `Наталья Воробей`,
        text: `Тесты для меня оказались очень эффективными, так как визуально я лучше усваиваю информацию.`
    }
];


const NewBilling = () => {
    return (
        <div className={styles.wrapper}>
            <ChoosePrice />
            <WhatSubscribeGives />
            <WhyTests />
            <UsersAboutTests reviews={getReviews()} />
            <WarriorCat />
        </div>
    )
}

export default NewBilling;