import React from 'react'
import withEvent from "./HOCs/withEvent"
import moment from "moment"



const Events = ({eventDelegate}) => (
    <section className={"event"}>
        <h2>{eventDelegate.state.title}</h2>
        <div className={"event-box"}>
            <div onClick={() => eventDelegate.getData(-1)} className={"arrow-left"}> <img src="/Images/arrow-events.svg" alt={"😼"}/></div>
            <div onClick={() => eventDelegate.getData(1)} className={"arrow-right"}> <img src="/Images/arrow-events.svg" alt={"😼"}/></div>
            {eventDelegate.state.events.map((item, index) => (
                <div key={index}
                     onClick={() => eventDelegate.changeBlock(index)}
                     className={eventDelegate.state.active[index] ? "content active" : "content"}>
                    <div className={"head"}>
                        <div className={"head__left"}>
                            <p className={"date"}>{moment(item.start_at).format("DD MMMM YY, HH:MM")}</p>
                            <h3>{item.title}</h3>
                        </div>
                        <img className={"more"} src="/Images/arrow-black.svg" alt={"😼"}/>
                    </div>
                    <div className={"info"}>
                        <div className={"info__text"} dangerouslySetInnerHTML={{__html: item.description}}/>
                        <a href={item.url} className={"button"}>
                            Смотреть видео
                        </a>
                    </div>
                </div>
            ))}
        </div>
    </section>
)
export default withEvent(Events)