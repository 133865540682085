import React from 'react'
import styled from './PostItem.module.scss'
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const PostItem = ({date, content, id, slug, image, categories, title, views}) => {
    const history = useHistory()
    const { t } = useTranslation();

    const openPost = () => {
        history.push(`blog/posts/${categories.slug}/${slug}`)
    }

    return <div className={styled.wrapperPost} onClick={openPost}>
        <img className={styled.iconPost} src={image} alt="Icon"/>
        <div className={styled.container}>
            <div className={styled.categoriesAndDate}>
                <span className={styled.category}>{categories.name}</span>
                <span className={styled.date}>{date}</span>
            </div>
            <h1>{title}</h1>
            <div
                dangerouslySetInnerHTML={{__html: content}}
                className={styled.content}/>
            <span className={styled.linkOpenPost}>
                {t('blog.read')}
            </span>
        </div>
    </div>
}

export default PostItem