import React from "react"
import Loader from "../View/Components/Loader";
import config from "../etc/config";
import ErrorPage from "../View/Pages/ErrorPage";
import moment from "moment";
import {withTranslation} from "react-i18next";

const withApp = Component => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                init: false,
                authToken: null,
                user: null,
                errorPage: null,
                notify: null,
                anonymous: false,
                message: null,
                registrationPopup: false,
                isNew: false,
                alert: null,
                MobileMenu: false,
                showPhoneValidator: false,
                userCount: 0,
                MobileMenuAccount: false
            };

            this.openAlert = null;
            this.toggleMenu= this.toggleMenu.bind(this);
            this.getStudentsCount = this.getStudentsCount.bind(this);
            this.toggleMenuAccount = this.toggleMenuAccount.bind(this);

        }
        toggleMenu() {
            this.setState({ MobileMenu: !this.state.MobileMenu, MobileMenuAccount: false });
        }
        toggleMenuAccount() {
            this.setState({ MobileMenuAccount: !this.state.MobileMenuAccount, MobileMenu: false });
        }

        setPopupAlert = (alert) => {
            document.body.style.overflowY = alert ? "hidden" : "auto"
            this.setState({alert});
        };

        phoneValidationCompletion = () => {
            const user = JSON.parse(JSON.stringify(this.state.user))
            user.phone_verified = "Verified"
            this.setState({user})
        }


        togglePhoneValidator = () => {
            this.setState({showPhoneValidator: !this.state.showPhoneValidator}, () => {
                document.body.style.overflowY = this.state.showPhoneValidator ? 'hidden' : 'auto'
            })
        }
        getStudentsCount= () => {
            fetch(`${config.API_PREFIX}users/count`)
                .then(res => res.json())
                .then(res => {
                    this.setState({userCount: res.count})
                })
                .catch(err => {
                    console.log(err)
                })
        }


        componentDidMount() {
            this.getStudentsCount()

            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);

            this.openAlert = document.createEvent("Event");
            this.openAlert.initEvent("openAlert", true, true);

            let authToken = this.readCookie("auth_token");
            document.addEventListener("commentEvent", e => {
                this.setState({ notify: { title: `Новый комментарий`, message: `Новый комментарий по практическому заданию.`, link: `/practical-task/${e.data.stage_id}` } })
            });

            document.addEventListener("successEvent", e => {
                this.setState({ notify: { title: `Практическое задание`, message: `Ваше практическое задание принято преподавателем.`, link: `/practical-task/${e.data.stage_id}` } })
            });

            if (!authToken) {
                authToken = this.getReqParam("auth_token");
                if (authToken)
                    document.cookie = `auth_token=${authToken};path=/;domain=.educat.courses;`;
            }


            if (authToken) {
                this.setState({authToken}, this.getData);
            } else {
                this.setState({ init: true })
            }

            const cookieLocale = this.readCookie("locale");
            if(cookieLocale) {
                this.props.i18n.changeLanguage(cookieLocale);
            } else {
                this.props.i18n.changeLanguage('ru');
            }
        }


        setAlert = () => {
            document.dispatchEvent(this.openAlert);
        };

        removeMessage = () => {
            this.setState({ message: null })
        };


        getReqParam = (name) => {
            if (!window.location.search || window.location.search.length < 1)
                return null;
            const search = window.location.search.slice(1);
            const params = search.split("&");
            for (const param of params) {
                if (param.split("=")[0] === name) {
                    return param.split("=")[1];
                }
            }
            return null
        };

        componentWillUnmount() {
            document.removeEventListener("commentEvent", e => {
                this.setState({ notify: { title: `Новый комментарий`, message: `${e.data.comment}`, link: `/practical-task/${e.data.stage_id}` } })
            });
            document.removeEventListener("successEvent", e => {
                this.setState({ notify: { title: `Практическое задание`, message: `Ваше практическое задание принято преподавателем.`, link: `/practical-task/${e.data.stage_id}` } })
            });
        }


        showNotify = notify => {
            this.setState({notify});
        };


        logout = () => {
            this.setState({
                authToken: "",
                init: true
            });
            this.clearCookies()
        };

        getData = () => {
            fetch(`${config.API_PREFIX}user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401) {
                        this.logout();
                        throw new Error(res.statusText)
                    }


                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    if (!res.email) {
                        this.logout()
                        this.setState({init: true})
                        return
                    }
                    this.setState({
                        user: res,
                        init: true,
                        anonymous: res.email === null
                    }, () => {
                        // if (this.state.user && this.state.user.subscriptions && this.state.user.subscriptions.date && moment(this.state.user.subscriptions.date).isAfter(moment()) && (moment(this.state.user.subscriptions.date).diff(moment(), "days") < 2))
                        //     this.setPopupAlert({ message: `Напоминаем, что срок вашей подписки заканчивается ${moment(this.state.user.subscriptions.date).format("DD.MM.YYYY")}. После этого вы не будете иметь премиум доступ к материалам курса.`, title: "Истекает срок подписки.", handler: () => this.setPopupAlert(null), buttonText: "Продлить подписку", close: () => this.setPopupAlert(null), link: "/billing"  })
                        // else if (this.state.user && this.state.user.subscriptions && this.state.user.subscriptions.date && moment(this.state.user.subscriptions.date).isBefore(moment()))
                        //     this.setPopupAlert({ message: `Срок вашей подписки закончился ${moment(this.state.user.subscriptions.date).format("DD.MM.YYYY")}. Продлите подписку чтобы получить премиум доступ к курсу.`, title: "Истек срок подписки.", handler: () => this.setPopupAlert(null), buttonText: "Продлить подписку", close: () => this.setPopupAlert(null), link: "/billing" })
                    })
                })
                .catch(err => {
                    console.log(err);
                })
        };

        toggleRegistration = (isNew = false) => {
            this.setState({ registrationPopup: !this.state.registrationPopup, isNew })
        };

        logoutWitMessage = (email) => {
            this.logout();
            this.setState({message: `Спасибо за регистрацию на портале educat.courses. Ваши регистрационные данные отправлены на почту ${email}.`})
        };

        readCookie = (name) => {
            let name_cook = name + "=";
            let spl = document.cookie.split(";");
            for (let i = 0; i < spl.length; i++) {
                let c = spl[i];
                while (c.charAt(0) === " ") {
                    c = c.substring(1, c.length)
                }
                if (c.indexOf(name_cook) === 0) {
                    return c.substring(name_cook.length, c.length)
                }
            }
            return null
        };

        clearCookies = () => {
            let cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i]
                let eqPos = cookie.indexOf("=")
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${
                    process.env.NODE_ENV !== "production" ? "localhost" : ".educat.courses" 
                };`
            }
        };


        changeUserInfo = target => {
            const {user} = this.state;
            const {name, value} = target;
            user[name] = value;
            this.setState({ user })
        };

        changeLocale = (locale) => {
            const previousState = this.state.user;
            previousState.locale = locale.locale;
            this.setState({ user: previousState});
            locale.is_active = true;
            fetch(`${config.API_PREFIX}locales`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.state.authToken}`
                },
                body: JSON.stringify(locale),
            }).catch(err => {
                    console.log(err)
            })
        }


        authCompletion = (user) => {
            const domain = window.location.hostname === 'localhost' ? window.location.hostname : '.educat.courses'
            const data = JSON.parse(JSON.stringify(user));
            delete data.access_token;
            delete data.status;
            this.setState({user: data, authToken: user.access_token}, () => {
                // document.cookie = `auth_token=${user.access_token};path=/;domain=${'.educat.courses'}`;
                document.cookie = `auth_token=${user.access_token};path=/;domain=${domain}`;
                this.getData();
            })
        };


        render() {

            if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else if (!this.state.init) {
                return <Loader/>
            } else {
                return <Component
                    appDelegate={this}
                    {...this.props}
                />
            }


        }
    }

    HOC.displayName = `withApp(${Component.displayName || Component.name || "Component"})`

    return withTranslation()(HOC);
};

export default withApp
