import React from 'react';
import {Link} from 'react-router-dom';
import s from './NotSuccessfulBiling.module.scss';
import {useTranslation} from "react-i18next";

const NotSuccessfulBilling = () => {
    const { t } = useTranslation();
    return <div className={s.wrapper}>
        <h2>
            {t('billing.fail.title')}
        </h2>
        <div className={s.title}>
            {t('billing.fail.desc')}<br/>
            <a href="/billing/1">
                {t('')}Вернуться на начало
            </a>
        </div>
        <div className={s.info}>
            <p>
                {t('billing.fail.problem')} <a href="/helper">{t('billing.fail.form')}</a> {t('billing.fail.email')}
                <a href="mailto: info@educat.courses">info@educat.courses</a></p>
            <Link to="/all-courses" className={s.chooseCourses}>
                {t('billing.success.choose')}
            </Link>
        </div>
        <a href="/" className={s.linkToMainPage}>
            {t('billing.success.main')}
        </a>
        <div className={s.cat}></div>
    </div>
};

export default NotSuccessfulBilling;
