import React, {useEffect} from 'react'
import QuestionsAndTarif from '../../Components/QuestionsAndTarif/QuestionsAndTarif'
import Card from './Card/Card'
import styled from './AllCourses.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {getCoursesThunk} from '../../../redux/allCoursesReducer'
import Loader from '../../Components/Loader'
import HeaderAndCategory from '../../Components/HeaderAndCategory/HeaderAndCategory'
import {useTranslation} from "react-i18next";

const AllCourses = ({appDelegate}) => {

    const dispatch = useDispatch();
    const coursesData = useSelector((state) => state.allCoursesReducer);
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(getCoursesThunk(appDelegate.state.authToken));
    }, []);

    return coursesData.courses !== null ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <HeaderAndCategory
                categories={coursesData.categories}
                complexities={coursesData.complexities}
                title={t('course.choose')}
                type="courses"
            />
            <ul className={styled.list}>
                {
                    coursesData.courses.map((course) => <li key={`course${course.id}`}>
                        <Card course={course} />
                    </li>)
                }
            </ul>
        </div>
        <QuestionsAndTarif/>
    </div> : <Loader/>
}

export default AllCourses;
