import React from "react"

const withScrollWrapper = Component => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                scrollAxis: this.props.axis && this.props.axis.toUpperCase() === "X" ? "Left" : "Top",
                size: this.props.axis && this.props.axis.toUpperCase() === "X" ? "Width" : "Height",
                axis: this.props.axis && (this.props.axis.toUpperCase() === "X" || this.props.axis.toUpperCase() === "Y") ? this.props.axis.toUpperCase() : "Y",
                overflow: "hidden",
                scrollBarHeight: 0
            };
            this.wrapperRef = React.createRef();
            this.scrollBar = React.createRef();

            this.startPosition = 0;
            this.diff = 0;

            this.listenerWithMouse = this.listenerWithMouse.bind(this);
            this.scrollListener = this.scrollListener.bind(this);
            this.createScrollBar = this.createScrollBar.bind(this);
        }

        componentDidMount() {
            this.wrapperRef.current.scrollTo({ top: this.wrapperRef.current.scrollHeight })

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                this.setState({overflow: "auto"})
            } else {
                this.listenerWithMouse();
                this.scrollListener();
                this.createScrollBar();
            }
        }

        componentWillReceiveProps(nextProps, nextContext) {
            if (this.props.update !== nextProps.update) {
                setTimeout(() => {
                    this.wrapperRef.current.scrollTo({ top: this.wrapperRef.current.scrollHeight });
                }, 100);

            }
        }

        createScrollBar() {
            const { scrollHeight, clientHeight } = this.wrapperRef.current;
            const percent = clientHeight * 100 / scrollHeight;
            this.scrollBar.current.style.height = `${percent / 100 * clientHeight}px`;
            this.setState({ scrollBarHeight: percent / 100 * clientHeight });

        }


        scrollListener() {

            this.wrapperRef.current.onwheel = event => {

                this.wrapperRef.current.scrollTop += event.deltaY;

                if (event.deltaY < 0 && this.wrapperRef.current.scrollTop > 0) {
                    event.preventDefault();
                }

                if (event.deltaY > 0 && this.wrapperRef.current.scrollTop < this.wrapperRef.current.scrollHeight - this.wrapperRef.current.clientHeight) {
                    event.preventDefault();
                }
                const percent = this.wrapperRef.current.scrollTop * 100 / (this.wrapperRef.current.scrollHeight  - this.wrapperRef.current.clientHeight);
                const px = percent / 100 * (this.wrapperRef.current.clientHeight - this.state.scrollBarHeight);

                this.scrollBar.current.style.transform = `translateY(${this.wrapperRef.current.scrollTop + px}px)`;
                this.scrollBar.current.style.opacity = 1;
                let start = 1;
                const animate = () => {
                    let step = Math.sin(start);
                    if (step <= 0) {
                        window.cancelAnimationFrame(animate);
                        if (this.scrollBar.current)
                            this.scrollBar.current.style.opacity = 0;
                    } else {
                        if (this.wrapperRef.current && this.scrollBar.current) {
                            this.wrapperRef.current.scrollTop += this.diff * step;
                            const percent = this.wrapperRef.current.scrollTop * 100 / (this.wrapperRef.current.scrollHeight  - this.wrapperRef.current.clientHeight);
                            const px = percent / 100 * (this.wrapperRef.current.clientHeight - this.state.scrollBarHeight);
                            this.scrollBar.current.style.transform = `translateY(${this.wrapperRef.current.scrollTop + px}px)`;
                            start -= 0.02;
                        }

                        window.requestAnimationFrame(animate);
                    }
                };
                animate();
            }
        }


        listenerWithMouse() {

            this.wrapperRef.current.onmousedown = downEvent => {
                if (downEvent.button !== 0)
                    return;
                if (!downEvent) {
                    downEvent = window.event;
                }
                if (downEvent.target && downEvent.target.nodeName === 'IMG') {
                    downEvent.preventDefault();
                } else if (downEvent.srcElement && downEvent.srcElement.nodeName === 'IMG') {
                    downEvent.returnValue = false;
                }

                this.startPosition = downEvent[`client${this.state.axis}`] + this.wrapperRef.current[`scroll${this.state.scrollAxis}`];
                this.diff = 0;
                this.wrapperRef.current.onmousemove = e => {
                    if (!e) {
                        e = window.event;
                    }
                    this.diff = (this.startPosition - (e.clientY + this.wrapperRef.current.scrollTop));
                    this.wrapperRef.current.scrollTop += this.diff;
                    const percent = this.wrapperRef.current.scrollTop * 100 / (this.wrapperRef.current.scrollHeight  - this.wrapperRef.current.clientHeight);
                    const px = percent / 100 * (this.wrapperRef.current.clientHeight - this.state.scrollBarHeight);
                    this.scrollBar.current.style.transform = `translateY(${this.wrapperRef.current.scrollTop + px}px)`;
                    this.scrollBar.current.style.opacity = 1;
                };
                this.wrapperRef.current.onmouseup = e => {
                    if (!e) {
                        e = window.event;
                    }
                    this.wrapperRef.current.onmousemove = null;
                    this.wrapperRef.current.onmouseup = null;
                    let start = 1;
                    const animate = () => {
                        let step = Math.sin(start);
                        if (step <= 0) {
                            window.cancelAnimationFrame(animate);
                            if (this.scrollBar.current)
                                this.scrollBar.current.style.opacity = 0;
                        } else {
                            if (this.wrapperRef.current && this.scrollBar.current) {
                                this.wrapperRef.current.scrollTop += this.diff * step;
                                const percent = this.wrapperRef.current.scrollTop * 100 / (this.wrapperRef.current.scrollHeight  - this.wrapperRef.current.clientHeight);
                                const px = percent / 100 * (this.wrapperRef.current.clientHeight - this.state.scrollBarHeight);
                                this.scrollBar.current.style.transform = `translateY(${this.wrapperRef.current.scrollTop + px}px)`;
                                start -= 0.02;
                            }

                            window.requestAnimationFrame(animate);
                        }
                    };
                    animate();
                };

            }
        }


        componentWillUnmount() {
            if (this.wrapperRef.current) {
                this.wrapperRef.current.onwheel = null;
                this.wrapperRef.current.onmousedown = null;
            }

        }


        render() {
            return (
                <Component
                    wrapperRef={this.wrapperRef}
                    position={this.state.position}
                    overflow={this.state.overflow}
                    scrollBar={this.scrollBar}
                    {...this.props}
                />
            )
        }
    }

    HOC.displayName = `withScrollWrapper(${Component.displayName || Component.name || "Component"})`;
    return HOC;
};

export default withScrollWrapper