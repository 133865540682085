import React from 'react'
import styled from './FAQ.module.scss'
import FaqQuestions from './components/FaqQuestions'
import {questions} from '../Billing/components/FAQ/questions'
import {useTranslation} from "react-i18next";


const FAQ = () => {
  const { t } = useTranslation();

  const mainQuestions = {
    title: t('faq.page.main'),
    questions: questions
  }

  const studentsQuestions = {
    title: t('faq.page.profile'),
    // questions: [
    //     {
    //         title: 'Аккаунт (учётная запись)'
    //     },
    //     {
    //         title: 'Профиль пользователя'
    //     },
    //     {
    //         title: 'Подписка'
    //     },
    //     {
    //         title: 'Курсы и профессии'
    //     }
    // ]
    questions: [
      // {
      //   title: 'Обзор раздела ВИДЕО',
      //   link: 'https://www.youtube.com/watch?v=6Ooat12FEjU'
      // },
      // {
      //   title: 'Обзор раздела ВСЕ КУРСЫ',
      //   link: 'https://www.youtube.com/watch?v=dPKAkZ3otJQ'
      // },
      {
        title: t('faq.page.profile1'),
        link: 'https://www.youtube.com/watch?v=Jx7w71k7FT4'
      },
      // {
      //   title: 'Портфолио разработчика',
      //   link: 'https://www.youtube.com/watch?v=rcdA69cEo9E'
      // },
      // {
      //   title: 'Вакансии для студентов',
      //   link: 'https://www.youtube.com/watch?v=vMM6aB2MSy4'
      // },
      // {
      //   title: 'Подтверждение навыков специалиста',
      //   link: 'https://www.youtube.com/watch?v=DQxYKic6ZQo'
      // },
      // {
      //   title: 'Углубленное изучение всех необходимых навыков',
      //   link: 'https://www.youtube.com/watch?v=1o_3T5Zwvr0'
      // },
      {
        title: t('faq.page.profile2'),
        link: 'https://www.youtube.com/watch?v=IqiS9szT1-Q'
      },
      {
        title: t('faq.page.profile3'),
        link: 'https://www.youtube.com/watch?v=lod9vmXcoQM'
      }
    ]
  }
  return <div className={styled.faqWrapper}>
    <div className={styled.container}>
      <h1>
        {t('faq.page.title')}
      </h1>
      <div className={styled.content}>
        <h4>{mainQuestions.title}</h4>
        <div className={styled.questions}>
          {mainQuestions.questions.map(({title, descriptionHTML}, index) =>
            <FaqQuestions key={index}
                          title={title}
                          description={descriptionHTML}
                          classQuestion={'main-question'}
            />)}
        </div>
        <h4>{studentsQuestions.title}</h4>
        <div className={styled.questions}>
          {studentsQuestions.questions.map(({title, link}, index) =>
            <FaqQuestions key={index} title={title} link={link}
                          classQuestion={'student-question'}/>)}
        </div>
      </div>
    </div>
  </div>
}

export default FAQ
