import React, {useEffect, useState} from "react";
import moment from "moment";




const months = [
    "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"
];


const ADS = ({ handler }) => {

    const [date, setDate] = useState("");

    useEffect(() => {
        moment().isoWeekday(7);
        const dayINeed = 6; // for Thursday
        const today = moment().isoWeekday();
        if (today <= dayINeed) {
            const date = moment().isoWeekday(dayINeed);
            setDate(`${date.date()} ${months[date.month()]}`)
        }
    }, []);

    return (
        <div className={"ads"}>
            <div className={"ads__text"}>
                <h4>Не пропусти бесплатный вебинар {date} (в субботу)</h4>
                <p>Путь «от нуля до профи» в web-разработке</p>
            </div>
            <button onClick={() => handler(false)} className={"ads__button"}>Участвовать</button>
        </div>
    )
};

export default ADS;