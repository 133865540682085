import React from 'react'
import withOfferDetail from "./HOCs/init"
import moment from "moment"
import {days} from "../Orders"
import Popup from "./Components/Popup"

const OrderDetail = ({offerDetailDelegate}) => (
    <main className={"order-detail content-main "}>
        <div className={"order-detail__content"}>
            <h2>Детали заказа</h2>
            <div className={"order-detail-container"}>
                {offerDetailDelegate.state.data.status_id === 2 || offerDetailDelegate.state.data.status_id === 3 ? (
                    <div className={"progress"}>
                        <div style={{width: `${offerDetailDelegate.state.data.time}%`}} className={"progress__active"}/>
                    </div>
                ) : ""}
                <div className={"all-container"}>
                    <div className={"container-left"}>
                        <div className={"title"}>{offerDetailDelegate.state.data.name}</div>
                        <div className={"vacancy-cell"}>
                            <div className={"status"}>
                                {offerDetailDelegate.state.data.status_id !== 1 ?
                                    <div className={"status__word"}>Статус: </div> : ""}
                                <p className={`vacancy ${offerDetailDelegate.state.data.status_id === 4 ? "done" : ""}`}>{offerDetailDelegate.state.data.status_id === 1 ? (
                                    <div className={"open"}><img src={"/Images/eye.svg"}/>
                                        {offerDetailDelegate.state.data.views}</div>
                                ) : offerDetailDelegate.state.data.status_id === 2 ? "В работе" : offerDetailDelegate.state.data.status_id === 3 ? "На проверке" : "Проверено и принято"}</p>
                            </div>
                            <p className={"published"}>{offerDetailDelegate.state.data.status_id === 1 ? `Опубликовано: ${moment(offerDetailDelegate.state.data.published).format("DD.MM.YYYY")}` : offerDetailDelegate.state.data.status_id === 2 || offerDetailDelegate.state.data.status_id === 4 ? "" : offerDetailDelegate.state.data.status_id === 3 ? `Отправленно: ${moment(offerDetailDelegate.state.data.time_complete).format("DD.MM.YYYY")}` : ""}</p>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: offerDetailDelegate.state.data.description}}
                             className={"description"}/>
                        {/*<p className={"file"}>Файлы</p>*/}
                        {/*<div className={"downloand-cell"}>*/}
                        {/*    <p className={"name-file"}>name file</p>*/}
                        {/*    <img src="./Images/download.svg" alt="download"/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"container-right"}>
                        <div className={"container-right__top"}>
                            <button
                                onClick={() => {
                                    if (offerDetailDelegate.state.data.status_id === 1)
                                        return offerDetailDelegate.togglePopup(true)
                                    if (offerDetailDelegate.state.data.status_id === 2)
                                        return offerDetailDelegate.completeProject()
                                }}
                                className={`button ${offerDetailDelegate.state.data.status_id === 1 ? "blue" : ""}`}>{offerDetailDelegate.state.loading.includes("complete") ? "Загрузка..." : offerDetailDelegate.state.data.status_id === 1 ? "Взять заказ" : offerDetailDelegate.state.data.status_id === 2 ? "Завершить" : offerDetailDelegate.state.data.status_id === 3 ? "Ожидает проверки" : "Завершено"}</button>
                            <div className={"descrip"}>
                                <p className={"label"}>Компания</p>
                                <p className={"data"}>{offerDetailDelegate.state.data.company.name}</p>
                            </div>
                            <div className={"descrip"}>
                                <p className={"label"}>Оплата за работу</p>
                                <p className={"data"}>{offerDetailDelegate.state.data.price} RUR</p>
                            </div>
                            {offerDetailDelegate.state.data.status_id === 2 || offerDetailDelegate.state.data.status_id === 3 ? (
                                <div className={"descrip"}>
                                    <p className={"label"}>Старт</p>
                                    <p className={"data"}>{moment(offerDetailDelegate.state.data.time_start).format("DD.MM.YYYY")}</p>
                                </div>
                            ) : ""}
                            <div className={"descrip"}>
                                <p className={"label"}>Дедлайн</p>
                                {offerDetailDelegate.state.data.status_id === 2 || offerDetailDelegate.state.data.status_id === 3 ? (
                                    <p className={"data"}>{moment(offerDetailDelegate.state.data.time_start).add(offerDetailDelegate.state.data.days, "days").format("DD.MM.YYYY")}</p>
                                ) : (
                                    <p className={"data"}>{offerDetailDelegate.state.data.days} {days(offerDetailDelegate.state.data.days)}</p>
                                )}

                            </div>
                            <div className={"descrip"}>
                                <p className={"label"}>Ключевые навыки</p>
                                <p className={"technology"}>
                                    {offerDetailDelegate.state.data.technology.map((item, index) => (
                                        <span key={index}>{item}</span>
                                    ))}
                                </p>
                            </div>
                        </div>
                        {offerDetailDelegate.state.data.status_id !== 1 ? (
                            <div className={"container-right__bottom"}>
                                <p className={"can-not"}>Если вы не можете завершить заказ, обратитесь в службу
                                    поддержки и
                                    скажите свой номер заказа:</p>
                                <div onClick={offerDetailDelegate.copyToClipboard}
                                     className={`copy ${offerDetailDelegate.state.copied ? "active" : ""}`}>
                                    #{offerDetailDelegate.state.data.id}
                                    <span className={"copied"}>Copied!</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M0 0L24 0 24 24 0 24z" transform="translate(-2 -1)"/>
                                            <path fill="currentColor" fillRule="nonzero"
                                                  d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
                                                  transform="translate(-2 -1)"/>
                                        </g>
                                    </svg>
                                </div>
                                <a href={"mailto:help@educat.courses"} className={"email"}>help@educat.courses</a>
                                <textarea style={{position: "absolute", left: "-9999px"}}
                                          value={offerDetailDelegate.state.data.id}
                                          ref={offerDetailDelegate.clipboardRef}
                                          readOnly={true}/>
                            </div>
                        ) : ""}


                    </div>
                </div>

            </div>
        </div>
        {offerDetailDelegate.state.popup ? (
            <Popup offerDetailDelegate={offerDetailDelegate}/>
        ) : ""}
    </main>
)
export default withOfferDetail(OrderDetail)