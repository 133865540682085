import React from 'react'
import styled from "../Task/styled.module.scss"
import arrowImg from "../../img/arrow-step.png"
import discussionIcon from "../../img/discussion-icon.svg"
import {useDispatch, useSelector} from "react-redux"
import {switchOnBackSuccessTask, switchOnNextSuccessTask} from '../../../../../redux/actions/tests';
import {useTranslation} from "react-i18next";

const Footer = ({verifyAnswerUser, showDiscussion, showHelpTask, task, isViewAnswer, arrayTasks, isDisabled}) => {
    const indexTask = arrayTasks.tasks.findIndex(i => i.id === task.id)
    const {isLoading} = useSelector(state => state.tasksReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const changeBackTask = () => {
        dispatch(switchOnBackSuccessTask())
    }

    const changeNextTask = () => {
        dispatch(switchOnNextSuccessTask())
    }

    return <footer>
        {isViewAnswer && <button className={styled.switchTask} disabled={indexTask === 0} onClick={changeBackTask}>
            <img className={styled.backStep} src={arrowImg} alt="<="/>
        </button>}
        <div className={styled.menuFooter}>
            <div className={styled.promptTask} onClick={() => showDiscussion(true)}>
                <img src={discussionIcon} alt="!"/>
            </div>
            {task.theory && <div className={styled.promptTask} onClick={() => showHelpTask(true)}>?</div>}
            {!isViewAnswer && <div onClick={verifyAnswerUser} disabled={isDisabled || isLoading || task.is_correct} className={styled.enterTask}>
                <span>
                    {t('task.single.verify')}
                </span>
            </div>}
        </div>
        {isViewAnswer && <button className={styled.switchTask} disabled={indexTask === arrayTasks.tasks.length - 1} onClick={changeNextTask}>
            <img src={arrowImg} alt="=>"/>
        </button>}
    </footer>
}

export default Footer;
