import React, {Fragment} from 'react';
import "./index.scss"
import 'highlight.js/styles/github.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from "./View/Components/Header";
import Footer from "./View/Components/Footer";
import withApp from "./HOCs/withApp";
import ErrorPage from "./View/Pages/ErrorPage";
import withSocket from "./HOCs/withSocket";
import NotifyBadge from "./View/Components/NotifyBadge";
import RegistrationPopup from "./View/Components/RegistrationPopup";
import MenuMobile from "./View/Components/MenuMobile"
import PhoneValidator from "./View/Components/PhoneValidator"
import MenuAccount from "./View/Components/MenuAccount";
import MenuMobileAccount from "./View/Components/MenuMobileAccount";
import ReportABag from "./View/Components/ReportABag/ReportAbag";
import routes from './routes';
// import Registration from "./View/Pages/Registration";
import RegistrationAlternative from 'View/Pages/RegistrationAlternative';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const App = ({appDelegate}) => {
    return (<div>
        <Router>
            {appDelegate.state.authToken ? (
                <Fragment>
                    <Header appDelegate={appDelegate}/>
                    <Route render={(props) => <MenuAccount appDelegate={appDelegate} {...props}/>}/>
                </Fragment>

            ) : ""}
            {appDelegate.state.authToken ? (
                <div className="App">
                    <Switch>
                        {routes.map(({path, title, exact, component: C, ...rest}) => (
                            <Route
                                key={path}
                                path={path}
                                exact={exact}
                                render={props => (
                                    <C
                                        title={title}
                                        appDelegate={appDelegate}
                                        {...props}
                                        {...rest}
                                    />
                                )}
                            />
                        ))}
                        <Route render={props => <ErrorPage code={404} {...props} />}/>
                        {/*<Route render={props => <TechnicalWork {...props} />}/>*/}
                    </Switch>
                    {appDelegate.state.notify ? (
                        <NotifyBadge close={() => appDelegate.showNotify(null)} {...appDelegate.state.notify} />
                    ) : ""}
                </div>
            ) : (
                <Fragment>
                    {appDelegate.state.message ? (
                        <div className={"alert-message"}>
                            <div className={"alert-message__container"}>
                                <span>{appDelegate.state.message}</span>
                                <div onClick={appDelegate.removeMessage}>Понятно</div>
                            </div>
                        </div>
                    ) : ""}
                    <GoogleReCaptchaProvider reCaptchaKey="6LejIvgkAAAAAAAw9pGEpJQcihbWrlPdhb7FbRXj">
                        <RegistrationAlternative appDelegate={appDelegate}/>
                    </GoogleReCaptchaProvider>
                </Fragment>

            )}
            {/*{appDelegate.state.alert ? (*/}
            {/*    <Alert {...appDelegate.state.alert}/>*/}
            {/*) : ""}*/}
            {appDelegate.state.user ? (
                <Footer appDelegate={appDelegate}/>
            ) : ""}
            {appDelegate.state.registrationPopup ? (
                <Route
                    component={() => <RegistrationPopup isNew={appDelegate.state.isNew} appDelegate={appDelegate}/>}/>
            ) : ""}
            {appDelegate.state.MobileMenu ? (
                <MenuMobile
                    appDelegate={appDelegate}
                />
            ) : (
                ""
            )}
            {appDelegate.state.MobileMenuAccount ? (
                <MenuMobileAccount
                    appDelegate={appDelegate}
                />
            ) : (
                ""
            )}
            {appDelegate.state.showPhoneValidator ? (
                <PhoneValidator appDelegate={appDelegate}/>
            ) : ""}

        </Router>
        {appDelegate.state.authToken && <div className={"window-error"}>
            <ReportABag appDelegate={appDelegate} />
        </div>}
        {/*{appDelegate.state.authToken && <div className={"roulette-window"}>*/}
        {/*    <RouletteWindow  />*/}
        {/*</div>}*/}
    </div>)
};

export default withApp(withSocket(App));
