import React, {Fragment} from 'react';

import withCourse from "./HOCs/init";
import Levels from "./Components/Levels";
import {Link} from "react-router-dom";
import Messenger from "../../Components/Messanger";
import moment from "moment";

const Course = ({courseDelegate, appDelegate}) => {
    return <div className="content-main course-page">
        <div className={"course-top"}>
            <div className={"top-content"}>
                <div
                    className={"course-name"}> {courseDelegate.state.data ? courseDelegate.state.data.course_name : ""}
                </div>
                <a rel="noopener noreferrer" href={courseDelegate.state.data.project} target={"_blank"}
                   className={"design-project"}>Дизайн-проект
                    <img src={"/Images/link.svg"} alt={"😼"}/>
                </a>
            </div>
        </div>
        <div className={"course-content"}>
            {courseDelegate.state.data && courseDelegate.state.data.levels ? courseDelegate.state.data.levels.map((level, index) => (
                <Fragment key={index}>
                    <Levels levelIndex={index} appDelegate={appDelegate}
                            available={(appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date && !moment(appDelegate.state.user.subscriptions.date).isBefore(moment())) || index === 0}
                            level={level} courseDelegate={courseDelegate}/>
                    {
                        (!appDelegate.state.user || !appDelegate.state.user.subscriptions) && index === 0 ? (
                            <div className={"bottom-buy"}>
                                <img src="/Images/pro-white.svg" alt=""/>
                                <p>Получить доступ ко всему курсу от 500 рублей</p>
                                <Link to={`/billing`} className={"button-buy"}>Получить доступ</Link>
                            </div>
                        ) : ""
                    }
                </Fragment>

            )) : ""}
        </div>
        {(!appDelegate.state.user || !appDelegate.state.user.subscriptions) ? (
            <div className={"bottom-buy"}>
                <img src="/Images/pro-white.svg" alt=""/>
                <p>Получить доступ ко всему курсу от 500 рублей</p>
                <Link to={`/billing`} className={"button-buy"}>Получить доступ</Link>
            </div>

        ) : ""}

        {!appDelegate.state.anonymous && courseDelegate.state.data && courseDelegate.state.data.id ? (
            <Messenger appDelegate={appDelegate} id={courseDelegate.state.data.id}/>
        ) : ""}
    </div>
};


export default withCourse(Course);
