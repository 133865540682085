import React from "react"
import {Link} from "react-router-dom"
import config from "../../../../etc/config"


const Course = ({item, categories, subscribed, added = false}) => {

    if (added && (subscribed || item.free)) {
        return (
            <Link to={`/course/${item.id}`} className={"profession__item"} style={{background: `url(${item.image})`}}>
                <div className={"item-top"}>
                    <p>
                        <span className={"left"}>{item.type.toLowerCase() === "course" ? "Курс" : "Профессия"}</span>
                        <span className={"right"}>{categories.find(x => x.id === item.category)?.name}</span>
                    </p>
                    <h3>{item.name}</h3>
                    <div className={"description"}>
                        {item.description.length > 80 ? `${item.description.slice(0, 77)}...` : item.description}
                    </div>
                    <div className={"info"}>
                        <span>{item.duration} мес.</span>
                        <span>{item.taskCount} заданий</span>
                        <span>{item.videosCount} видео</span>
                    </div>
                </div>
            </Link>
        )
    }


    if (added) {
        return (
            <Link to={`/billing`} className={"profession__item"} style={{background: `url(${item.image})`}}>
                <div className={"item-top"}>
                    <p>
                        <span className={"left"}>{item.type.toLowerCase() === "course" ? "Курс" : "Профессия"}</span>
                        <span className={"right"}>{categories.find(x => x.id === item.category)?.name}</span>
                    </p>
                    <h3>{item.name}</h3>
                    <div className={"description"}>
                        {item.description.length > 80 ? `${item.description.slice(0, 77)}...` : item.description}
                    </div>
                    <div className={"info"}>
                        <span>{item.duration} мес.</span>
                        <span>{item.taskCount} заданий</span>
                        <span>{item.videosCount} видео</span>
                    </div>
                </div>
            </Link>
        )
    }

    if (item.enabled === 1) {
        return (
            <div className={"profession__item"} style={{background: `url(${item.image})`}}>
                <div className={"item-top"}>
                    <p>
                        <span className={"left"}>{item.type.toLowerCase() === "course" ? "Курс" : "Профессия"}</span>
                        <span className={"right"}>{categories.find(x => x.id === item.category)?.name}</span>
                    </p>
                    <h3>{item.name}</h3>
                    <div className={"description"}>
                        {item.description.length > 80 ? `${item.description.slice(0, 77)}...` : item.description}
                    </div>
                    <div className={"info"}>
                        <span>{item.duration} мес.</span>
                        <span>{item.taskCount} заданий</span>
                        <span>{item.videosCount} видео</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <a href={`${config.MAIN_URL}/about-course/${item.id}`} className={"profession__item"}
           style={{background: `url(${item.image})`}}>
            <div className={"item-top"}>
                <p>
                    <span className={"left"}>{item.type.toLowerCase() === "course" ? "Курс" : "Профессия"}</span>
                    <span className={"right"}>{categories.find(x => x.id === item.category)?.name}</span>
                </p>
                <h3>{item.name}</h3>
                <div className={"description"}>
                    {item.description.length > 80 ? `${item.description.slice(0, 77)}...` : item.description}
                </div>
                <div className={"info"}>
                    <span>{item.duration} мес.</span>
                    <span>{item.taskCount} заданий</span>
                    <span>{item.videosCount} видео</span>
                </div>
            </div>
        </a>
    )
}


export default Course