import React from "react";
import config from "../../../etc/config";


const withFileLoader = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
            this.state = {
                formData: null,
                fileName: "",
                loading: false
            }
        }

        componentDidMount() {

        }

        loadFile = () => {
            const {formData} = this.state;

            if (!formData) {
                alert("Select file, please");
                return
            }

            this.setState({ loading: true })

            fetch(`${config.API_PREFIX}${this.props.resource}`, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.token}`
                },
                body: formData
            })
                .then(res => res.json())
                .then(res => {
                    this.setState({ fileName: "", formData: null, loading: false });
                    if (res.status === 0) {
                        this.props.completion(res);
                    } else {
                        alert(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        };

        handlerChange = (event) => {
            const formData = new FormData();
            const file = event.target.files[0];
            formData.append("practice", file, file.name);
            this.setState({ formData, fileName: file.name });
        };



        render() {
            return <Component fileLoaderDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withFileLoader(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withFileLoader;