import React from "react";
import config from "../../../etc/config";


const withMessenger = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
            this.state = {
                messages: [],
                count: 0,
                newData: false,
                show: false,
                text: "",
                inputHeight: 100,
                update: null,
                ctxMenu: null,
                quote: null,
                printNow: [],
                loading: []
            };
            this.printEvent = null;
            this.player = React.createRef();
        }

        componentDidMount() {
            document.addEventListener("Message", this.messageListener);
            document.addEventListener("userPrint", this.userPrintDetect);
            this.printEvent = document.createEvent("Event");
            this.printEvent.initEvent("printMessage", true, true);
            this.getData();
        }

        componentWillUnmount() {
            document.removeEventListener("Message", this.messageListener);
            document.removeEventListener("userPrint", this.userPrintDetect);
        }


        userPrintDetect = (e) => {
            const {printNow} = this.state;
            if (printNow.findIndex(x => x.id === e.data.id) === -1) {
                printNow.push(e.data);
                this.setState({printNow}, () => {
                    this.removeInPrintNow(e.data.id);
                })
            }
        };

        removeInPrintNow = (id) => {
            const {printNow} = this.state;
            const idx = printNow.findIndex(x => x.id === id);
            if (idx > -1) {
                setTimeout(() => {
                    printNow.splice(idx, 1);
                    this.setState({ printNow })
                }, 5000);
            }
        };


        getData = () => {
            fetch(`${config.API_PREFIX}messages/${this.props.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status >= 200 && res.status < 300)
                        return res.json();
                    else
                        throw new Error(res.statusText);
                })
                .then(res => {
                    this.setState({ messages: [...this.state.messages, ...res.messages.reverse()], count: res.count, update: new Date() })
                })
                .catch(err => {
                    console.log(err);
                })
        };


        toggleCTXMenu = (e, ctxMenu) => {
            e.stopPropagation();
            this.setState({ctxMenu});
        };

        setQuote = quote => {
            this.setState({quote})
        };


        messageListener = e => {
            if (e.data.group_id === this.props.id && e.data.sender.id !== this.props.appDelegate.state.user.id){
                this.setState({messages: [...this.state.messages, e.data], newData: true, update: new Date()});
                this.player.current.play();
            }
        };

        toggleContainer = () => {
            this.setState({show: !this.state.show, newData: false}, () => {
                document.body.style.overflowY = this.state.show ? "hidden" : "auto";
            })
        };

        changeMessage = (e) => {
            this.setState({text: e.target.value});
            this.printEvent.data = {group_id: this.props.id}
            document.dispatchEvent(this.printEvent);
        };


        heightDetect = (inputHeight) => {
            this.setState({inputHeight})
        };


        pushMessage = () => {
            if (!this.state.text)
                return;

            const parent = this.state.quote ? this.state.quote.id : null;

            this.setState({
                messages: [
                    ...this.state.messages,
                    {
                        message: this.state.text,
                        sender: {
                            name: `${this.props.appDelegate.state.user.name || ""}`,
                            id: this.props.appDelegate.state.user.id,
                            itsMe: true
                        },
                        parent: JSON.parse(JSON.stringify(this.state.quote))
                    }],
                update: new Date(),
                quote: null
            });



            fetch(`${config.API_PREFIX}messages/${this.props.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    message: this.state.text,
                    parent: parent
                })
            })
                .then(res => {
                    if (Number(res.status) >= 200 && Number(res.status) <= 300)
                    {
                        return res.json();
                    }

                    else
                        throw new Error(res.statusText);
                })
                .then(res => {
                    const {messages} = this.state;
                    messages[messages.length - 1].id = res.id;
                    this.setState({ text: "", messages });
                })
                .catch(err => {
                    console.log(err);
                })
        };

        render() {
            return <Component messengerDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withMessenger(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withMessenger;