import React, {FC} from 'react';
import styled from '../AllCourses/AllCourses.module.scss';
import TableCareer from "../TableHOC/TableCareer";
import checkIcon from "../../img/check-icon.png";
import Loader from "../../../../Components/Loader";
import {CareersType} from "../../../../../redux/dashboardReducer";
import {useTranslation} from "react-i18next";

type PropsType = {
    careers: CareersType[] | null
}

const Careers: FC<PropsType> = ({careers}) => {
    const { t } = useTranslation();
    return careers !== null ? <div className={styled.wrapper}>
        <div className={styled.title}>
            <div className={styled.left}>
                <div className={styled.iconTitle}><img src={checkIcon} alt='v'/></div>
                <h2>
                    {t('careers.title')}
                </h2>
            </div>
        </div>
        <TableCareer careers={careers}/>
    </div> : <Loader/>
};

export default Careers;