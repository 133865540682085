export const questions = [
    {
        title: 'questions.title1',
        descriptionHTML: 'questions.description1'
    },
    {
        title: 'questions.title2',
        descriptionHTML: 'questions.description2'
    },
    {
        title:'questions.title3',
        descriptionHTML: 'questions.description3'
    },
    {
        title: 'questions.title4',
        descriptionHTML: 'questions.description4'
    },
    {
        title: 'questions.title5',
        descriptionHTML: 'questions.description5'
    },
    {
        title: 'questions.title6',
        descriptionHTML:'questions.description6'
    }
];

export const questionsBilling = [
    {
        title:    'questions.billing.title1',
        descriptionHTML: 'questions.billing.description1'
    },
    {
        title: 'questions.billing.title2',
        descriptionHTML: 'questions.billing.description2'
    },
    {
        title: 'questions.billing.title3',
        descriptionHTML: 'questions.billing.description3'
    },
    {
        title: 'questions.billing.title4',
        descriptionHTML: 'questions.billing.description4'
    },
]
