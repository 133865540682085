import React from 'react'
import backgroundImg from '../../../../img/background-level-passed.png'
import badResultProgress from '../../../../img/bad-result.png'
import styled from './style.module.scss'
import ProgressCourse from "../../../ProgressCourse"
import {useTranslation} from "react-i18next";

type Props = {
    progressTheme: number;
    closePassed: () => void;
    title: string;
}

const LevelPassed = ({progressTheme, closePassed, title}: Props) => {
    const { t } = useTranslation();
    return <div className={styled.wrapper}>
        <div className={styled.container}>
            {progressTheme === 100
                ? <div>
                    <h4>
                        {t('task.level.passed')}
                    </h4>
                    <p>
                        {t('Поздравляю!')}
                    </p>
                    <p>
                        {t('task.level.end')} <span>"{title}"</span>
                    </p>
                    <img className={styled.mainImg} src={backgroundImg} alt="Background"/>
                </div>
                : <div>
                    <h4>
                        {t('task.level.better')}
                    </h4>
                    <p>
                        {t('task.level.toend')} <span>"{title}"</span>
                    </p>
                    <p>
                        {t('task.level.comfort')}
                    </p>
                    <img className={styled.mainImg} src={badResultProgress} alt="Bad"/>
                </div>}
            <div>
                <ProgressCourse progressTheme={progressTheme}/>
                <div className={styled.percentageSuccess}>
                    <span>
                        {t('task.level.result')} {Math.floor(progressTheme)}%
                    </span>
                </div>
                {progressTheme !== 100 &&
                <p>
                    {t('task.level.desc')}
                </p>}
            </div>
        </div>
        <footer>

            {progressTheme === 100
                ? <button onClick={closePassed}>
                  {t('task.btn.continue')}
                </button>
                : <button onClick={closePassed}>
                  {t('task.btn.improve')}
                </button>}
        </footer>
    </div>
}

export default LevelPassed;
