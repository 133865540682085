import React, {useEffect, useState, useRef, Fragment} from "react";
import {Link} from "react-router-dom";

const Video = ({themeDelegate, available, isSubscribed}) => {
    const [width, setWidth] = useState(0);
    const container = useRef();

    useEffect(() => {
        setWidth(container.current.clientWidth)
    }, []);

    return (
        <div className={"video-container"}>
            <div ref={container} className={"box"}>
                {themeDelegate.state.data.lecture.map((item, index) => (
                    <Fragment key={index}>
                        <h3>Видеолекция: {item.name}</h3>
                        <div className={"video"}>
                            {available ? (
                                <iframe
                                    title={item.name}
                                    src={`https://player.vimeo.com/video/${item.id_video}`}
                                    width={width}
                                    height={width * 9 / 16}
                                    frameBorder="0" allow="autoplay; fullscreen"
                                    allowFullScreen />
                            ) : (
                                <div className={"video-non"}>
                                    <img src={"/Images/video.svg"} alt={"😼"}/>
                                    <p>Включите опцию, чтобы смотреть видеоурок </p>
                                    {/*<Link to={isSubscribed ? '/subscription' : `/billing/${themeDelegate.state.data.stage.level.course.id}`}> <button>Включить</button></Link>*/}
                                    <Link to={`/billing`}> <button>Включить</button></Link>

                                </div>
                            )}



                        </div>
                    </Fragment>
                ))}

            </div>
        </div>
    )
}
export default Video;