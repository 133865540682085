import React, {useState} from 'react';
import styled from "../Videos.module.scss";
import classnames from 'classnames';
import {useDispatch} from "react-redux";
import {sortWebinars} from "../../../../redux/videosReducer";
import {useTranslation} from "react-i18next";

type SortVideosType = {
    categories: Array<string>
}

const SortVideos: React.FC<SortVideosType> = ({categories}) => {
    const [indexCategory, setIndexCategory] = useState(0)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const changeActiveCategory = (index: number, item: string) => () => {
        setIndexCategory(index)
        dispatch(sortWebinars(item))
    }

    return <div className={styled.sort}>
        <h4>
            {t('videos.sort')}
        </h4>
        <div className={styled.categories}>
            {categories.map((item, index) => <span onClick={changeActiveCategory(index, item)} className={classnames({
                [styled.active]: index === indexCategory
            })} key={index}>{item}</span>)}
        </div>
    </div>
}

export default SortVideos;