import React, {  useState } from "react";


export const Checkbox = ({ checked, ...inputProps}) => {
    return (
        <div style={
            { boxSizing: "border-box", width: "24px", height: "24px", minWidth: "24px", minHeight: "24px", borderRadius: "4px", borderColor: checked ? "#024feb" : "#f0f1f2", borderWidth: "2px", borderStyle: "solid", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }
        }>
            {checked ? <img alt={"😼"} style={{ width: "16px", height: "16px", margin: "0" }} src={"/Images/check_yes.svg"} />: ""}
            <input style={{opacity: 0, width: "100%", height: "100%", position: "absolute", left: 0, top: 0, margin: 0, padding: 0}} type="checkbox" {...inputProps}/>
        </div>
    )
};




export const RadioGroup = ({ options, handler, name, value, disabled }) => {
    const [activeName, changeActive] = useState(null);
    return (
        <div className={"radio-group"}>
            {options.map((option, idx) => (
                <div className={"box-group"} key={idx}>
                    <div style={
                        { boxSizing: "border-box",  width: "24px", height: "24px", minWidth: "24px", minHeight: "24px", borderRadius: "50%", borderColor: activeName === option.id || value === option.id  ? "#024feb" : "#f0f1f2", borderWidth: "2px", borderStyle: "solid", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }
                    }>
                        {activeName === option.id || value === option.id ? <div style={{ backgroundColor: "#024feb", width: "10px", height: "10px", borderRadius: "50%" }} /> : ""}
                        <input disabled={disabled} value={option.id} style={{opacity: 0, width: "100%", height: "100%", position: "absolute", left: 0, top: 0, margin: 0, padding: 0}} type="radio" id={`q-${name}-oid-${option.id}`} name={name} onChange={(e) => {
                            changeActive(option.id);
                            handler(e)
                        }}/>
                    </div>
                    <label htmlFor={`q-${name}-oid-${option.id}`}>{option.value}</label>
                </div>
            ))}
        </div>
    )
};


export const CheckboxSlider = ({ checked, ...inputProps}) => {
    return (
        <div className={"checkbox-slider"} onClick={e => e.stopPropagation()} style={
            { opacity: inputProps.disabled ? 0.5 : 1, transition: "all 0.1s linear", cursor: "pointer", boxSizing: "border-box", width: "60px", height: "32px", minWidth: "60px", minHeight: "32px", borderRadius: "16px", backgroundColor: checked ? "#024feb" : "#e3e4e6", position: "relative" }
        }>
            <div style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "4px",
                left: "4px",
                transition: "all 0.2s linear",
                transform: `translateX(${ checked ? 27 : 0 }px)`
            }}>
                <img src={`/Images/checker-${checked ? "yes" : "no"}.svg`} alt="" style={{width: "16px", height: "16px", margin: 0}}/>
            </div>
            <input style={{ cursor: "pointer", opacity: 0, width: "100%", height: "100%", position: "absolute", left: 0, top: 0, margin: 0, padding: 0}} type="checkbox" {...inputProps}/>
        </div>
    )
};