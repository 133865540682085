import React from "react";
import config from "../../../../etc/config"
import Loader from "../../Loader";

const withEvent = Component => {
        class HOC extends React.Component {
            constructor(props) {
                super(props);

                this.state = {
                    active: [],
                    events: [],
                    prev: null,
                    next: null,
                    title: "События за эту неделю",
                    loading: true

                };
                this.changeBlock = this.changeBlock.bind(this);
                this.getData = this.getData.bind(this)

            }

            componentDidMount() {
                this.getData()

            }







            changeBlock(idx) {
                let {active} = this.state;
                active[idx] = !active[idx];
                this.setState({active});



            }


            getData(direction = 0) {
                let weekNumber = 0
                let year = 0
                fetch(direction === 0 ? `${config.API_PREFIX}events` : direction === 1 ? `${this.state.next}` : `${this.state.prev}`)
                    .then(res => {
                        if (res.status >= 200 && res.status < 300) {
                            const arr = res.url.split("?")
                            if (arr[1]) {
                                let queries = arr[1].split("&")
                                for (let item of queries) {
                                    let qArr = item.split("=")
                                    if (qArr.length !== 2) {
                                        continue
                                    }
                                    if (qArr[0] === "week")
                                        weekNumber = qArr[1]
                                    else if (qArr[0] === "year")
                                        year = qArr[1]
                                }
                            }
                            return res.json()
                        }
                        throw new Error(res.statusText)
                    })
                    .then(res => {

                        const active = [...Object.keys(res.data.current).map(() => false)]


                        this.setState({
                            events: res.data.current,
                            prev: res.data.prev,
                            next: res.data.next,
                            title: direction === 0 ? "События за эту неделю" : `События за неделю ${weekNumber} ${year} года`,
                            active,
                            loading: false
                        })

                    })
                    .catch(err => {
                        console.log(err)
                    })
            }



            render() {
                if (this.state.loading)
                    return <Loader/>
                return (
                    <Component
                        eventDelegate={this}
                        {...this.props}
                    />
                );
            }
        }

        HOC.displayName = `withEvent(${Component.displayName ||
        Component.name ||
        "Component"})`;
        return HOC;
    }
;

export default withEvent;