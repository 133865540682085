import React from "react";
import { authApi } from "api/api";
import { DropPasswordFormValues, UseDropPasswordApiResult } from "../types";

export const useDropPasswordApi = (): UseDropPasswordApiResult => {
  const authSocialMediaApi = React.useCallback( async (payload: DropPasswordFormValues) => {
		return await authApi.dropPassword(payload);
	}, []);

	return authSocialMediaApi;
}