import React, {useCallback} from 'react'
import Task from './Task/Task'
import checkIcon from '../../img/check-icon.png'
import s from '../AllCourses/AllCourses.module.scss'
import Loader from '../../../../Components/Loader'
import {useHistory} from 'react-router'
import {useTranslation} from "react-i18next";

const sortArray = ["Завершенныи задачам", "Направлениям", "Сложности"]

const ChooseNewTask = ({tests}) => {
    const history = useHistory()

    const changePathToTests = useCallback(() => {
        let path = '/tests'
        history.push(path)
    }, []);

    const { t } = useTranslation();

    return tests !== null ? <div className={s.wrapper}>
        <div className={s.title}>
            <div className={s.left}>
                <div className={s.iconTitle}><img src={checkIcon} alt='v'/></div>
                <h2>
                    {t('task.choose')}
                </h2>
            </div>
            {/*<SortBy arraySort={sortArray}/>*/}
        </div>
        <div className={s.content}>
            <div className={s.wrapperCourses}>
                {tests.map(({id, title, participants, icon, questions}, index) => <Task
                    icon={icon}
                    key={index}
                    id={id}
                    title={title}
                    participants={participants}
                    questions={questions}
                />)}
            </div>
            <div className={s.right}>
                <button onClick={changePathToTests}>
                    {t('task.btn.all')}
                </button>
            </div>
        </div>
    </div> : <Loader/>
};

export default ChooseNewTask;
