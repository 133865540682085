import React, {useState} from 'react';
import s from './DropAndDrag.module.scss';

const DropAndDragRow = ({variants, result, changeItem, deleteItem, isCorrectTask, isViewAnswer, passedRightAnswer}) => {
    const [variantsDropAndDrag, setDropAndDragVariants] = useState(variants)
    const answers = isViewAnswer ? passedRightAnswer : result

    const addWordInRow = (item, index) => {
        if(!isViewAnswer && isCorrectTask === 0) {
            changeItem(item)
            setDropAndDragVariants(variantsDropAndDrag.filter((item, ind) => index !== ind))
        }
    }

    const deleteWordInRow = (item) => {
        if(!isViewAnswer && isCorrectTask === 0) {
            setDropAndDragVariants([...variantsDropAndDrag, item])
            deleteItem(item)
        }
    }

    return <div className={s.contentDragAndDrop}>
        <div className={isCorrectTask === 0
            ? s.resultDragAndDrop
            : isCorrectTask
                ? s.resultDragAndDrop + " " + s.isRight
                : s.resultDragAndDrop + " " + s.isFalse}>
                {answers.map((item, index) => (
                    <div className={s.dragAndDropResultItem} key={index}
                         onClick={() => deleteWordInRow(item)}>{item}</div>
                ))}
        </div>
        <div className={s.dragAndDropVariants}>
            {variantsDropAndDrag.map((answer, index) => (
                <div className={isViewAnswer ? s.dragAndDropItem + ' ' + s.isShowAnswer : s.dragAndDropItem} key={index}
                     onClick={() => addWordInRow(answer, index)}>{answer}</div>))}
        </div>
    </div>
}

export default DropAndDragRow;