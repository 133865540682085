import React from "react"
import Loader from "../../Components/Loader"
import config from "../../../etc/config"
import ErrorPage from "../ErrorPage"
import {Redirect} from "react-router"

const withReview = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props)

            this.state = {
                data: null,
                errorPage: null,
                doneTests: [],
                showMy: true,
                review: "",
                advice: "",
                mark_1: 5,
                mark_2: 5,
                mark_3: 5,
                mark_4: 5,
                loadButton: false,
                redirect: false
            }
        }

        componentDidMount() {
            this.getData()
            document.title = "Review"
            window.scrollTo({top: 0, left: 0})
        }


        changeSwitcher = (showMy) => {
            this.setState({showMy})
        }

        handlerChange = (e) => {
            const {name, value} = e.target
            this.setState({[name]: value})
        }


        getData = () => {
            fetch(`${config.API_PREFIX}stages/${this.props.match.params.id}${this.props.query}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout()

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status})
                        throw new Error(res.statusText)
                    }

                    return res.json()
                })
                .then(res => {
                    this.setState({data: res})
                })
                .catch(err => {
                    console.log(err)
                })
        }


        submit = (e) => {
            e.preventDefault()
            this.setState({loadButton: true})
            const {review, advice, mark_1, mark_2, mark_3, mark_4} = this.state
            fetch(`${config.API_PREFIX}stages/${this.state.data.stage.id}/reviews`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    review, advice, mark_1, mark_2, mark_3, mark_4
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout()

                    return res.json()
                })
                .then(res => {
                    this.setState({
                        loadButton: false,
                        review: "",
                        advice: "",
                        mark_1: 5,
                        mark_2: 5,
                        mark_3: 5,
                        mark_4: 5,
                    })
                    this.props.practicalDelegate.showNotify("Ваш комментарий отправлен")
                })
                .catch(err => {
                    console.log(err)
                })
        }

        render() {
            if (this.state.redirect) {
                return <Redirect to={`/course/${this.state.data.stage.level.course.id}`}/>
            } else if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else if (this.state.data && !this.state.errorPage) {
                return <Component reviewDelegate={this} {...this.props} />
            } else {
                return <Loader/>
            }

        }
    }

    HOC.displayName = `withReview(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withReview