import React from "react";
import ComponentLoader from "View/Components/ComponentLoader/ComponentLoader";
import { useDocumentTitle } from "View/Pages/RegistrationAlternative/hooks/useDocumentTitle";
import { useRedirectToProvider } from "./hooks/useRedirectToProvider";
import { useSigninBySearch } from "./hooks/useSigninBySearch";
import { SigninSocialProps } from "./types";
import classes from "./SigninSocial.module.scss"

function SigninSocial({ appDelegate }: SigninSocialProps): JSX.Element {
  useRedirectToProvider();
  useSigninBySearch(appDelegate);

  useDocumentTitle("Вход через соц. сети");

  return (
    <div
      className={classes["signin-social"]}
    >
      <ComponentLoader/>
    </div>
    
  );
}

export default SigninSocial;