import React from 'react';
import {useSelector} from "react-redux";
import LevelGame from "./LevelGame";
import style from "./LevelGame.module.scss";
import {useTranslation} from "react-i18next";

const LevelsPage = () => {
    const { t } = useTranslation();
    const levels = useSelector(state => state.gameReducer.levels);

    let levelGame = levels.map((item, index) => <LevelGame
        key={index}
        item={item}
        level={item.levelText}
        tags={item.tags}
        column={item.column}
        levelGame={item.levelGame}
        color={item.color}
        cards={item.cards}
        img={item.img}
    />)

    return <div className={style.wrapper}>
        <h1>
            {t('game.level.title')}
        </h1>
        <p className={style.infoLevel}>
            {t('game.level.desc')}
        </p>
        <hr/>
        <div className={style.wrapperContent}>{levelGame}</div>
    </div>
}

export default LevelsPage;