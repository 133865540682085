import React, { useEffect, useRef, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import useCheckAdmin from "../../../hooks/useCheckAdmin";
import LangSwitcher from "../LangSwitcher";
import {useTranslation} from "react-i18next";

const Header = ({appDelegate}) => {
    const [withMenu, toggleMenu] = useState(false);
    const role = useCheckAdmin(appDelegate.state.user.email)
    const menuButton = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        document.addEventListener("click", (e) => {
            toggleMenu(false);
        });
        menuButton.current.addEventListener("click", (e) => {
            e.stopPropagation();
            toggleMenu(true);
        });
    });

    return (
        <div className="header">
            <div className={"header__content"}>
                {appDelegate.state.userCount ? (
                    <div className={"us"}>{t('header.us')} {appDelegate.state.userCount} {t('header.people')}</div>
                ) : ""}
                <div className={withMenu ? "menu active" : "menu"}>
                    <NavLink
                        to={`/billing`}
                        // to={appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date ? "/subscription" : "/billing"}
                        className={"program"}>
                        {t('header.signup')}
                    </NavLink>
                    <NavLink to={"/helper"} className={"program"}>
                        {t('nav.contacts')}
                    </NavLink>
                    <NavLink to={"/blog"} className={"program"}>
                        {t('nav.blog')}
                    </NavLink>
                    <a href={"https://educat.courses/about"} className={"program"}>
                        {t('nav.about')}
                    </a>
                    {role && <Link to={{
                        pathname: '/roulette',
                        state: { admin: true }
                    }} className={"program"}>
                        {t('nav.roulette')}
                    </Link>}
                    <div ref={menuButton} className={"menu__open-button"}>
                        <div className={"header-photo"}
                             style={{backgroundImage: appDelegate.state.user.photo ? `url(${appDelegate.state.user.photo})` : "url(/Images/h-no-photo.svg)"}}/>
                        <div className={"name"}>
                            {appDelegate.state.user.name}
                            {appDelegate.state.user && appDelegate.state.user.subscriptions ? (
                                <div className={"info"}>
                                    <span>{appDelegate.state.user.subscriptions.name}</span> {appDelegate.state.user.subscriptions.length !== 0 && <div>до {appDelegate.state.user.subscriptions.date}</div>}
                                </div>
                            ) : ""}
                        </div>
                    </div>

                    {withMenu ? (
                        <div className={"menu__ctx"}>
                            <Link className={"item"} to={"/account"}>
                                <div className={"item__image photo"}
                                     style={{backgroundImage: appDelegate.state.user.photo ? `url(${appDelegate.state.user.photo})` : "url(/Images/h-no-photo.svg)"}}/>
                                <span className={"text"}>{appDelegate.state.user.name}</span>

                            </Link>
                            <Link className={"item"}
                                  to={"/billing"}>
                            {/*to={appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.date ? "/subscription" : "/billing"}>*/}
                                <div className={"item__image"} style={{backgroundImage: "url(/Images/purch.svg)"}}/>
                                <span className={"text"}>
                                    {t('header.signup')}
                                </span>
                            </Link>

                            <div className={"item"} onMouseDown={appDelegate.logout}>
                                <div className={"item__image"} style={{backgroundImage: "url(/Images/logout.svg)"}}/>
                                <span className={"text"}>
                                    {t('header.exit')}
                                </span>
                            </div>
                        </div>
                    ) : ""}
                </div>
                <div className={"block-mobile"}>
                    <div className={"header-photo"}
                         style={{backgroundImage: appDelegate.state.user.photo ? `url(${appDelegate.state.user.photo})` : "url(/Images/h-no-photo.svg)"}}
                         onClick={appDelegate.toggleMenuAccount}/>
                    <a className={"menu-logo"} href={"/main"}><img src={"/Images/logo.svg"} alt={"😼"}/></a>
                    <div onClick={appDelegate.toggleMenu} className={"burger"}>
                        <img src="/Images/burger.svg" alt="menu"/>
                    </div>
                </div>
                <LangSwitcher appDelegate={appDelegate}/>
            </div>
        </div>
    )
};
export default Header;
