import React from 'react'
import moment from "moment"
import {useTranslation} from "react-i18next";

const PopUp = ({vacancyDelegate}) => {
    const { t } = useTranslation();
    return (<div onClick={() => vacancyDelegate.setPopup(null)} className={"pop-up-vacancy"}>
        <div onClick={e => e.stopPropagation()} className={"window"}>
            <img onClick={() => vacancyDelegate.setPopup(null)}  className={'close'} src="/Images/close-wight.svg" alt="close"/>
            {vacancyDelegate.state.popup === "info" ? (
                <div className={"content"}>
                    <h2>
                        {t('vacancy.popup.title')}
                    </h2>
                    <p className={""}>
                        {vacancyDelegate.state.data.position}
                        <span> {t('vacancy.popup.company')} </span> {vacancyDelegate.state.data.company.name}
                    </p>
                    <p>{vacancyDelegate.state.data.salary} RUR</p>
                    <p className={"responded"}>
                        {t('vacancy.popup.responded')} {moment(vacancyDelegate.state.data.requests[0].date).format("DD.MM.YYYY")}
                    </p>
                    <button onClick={() => vacancyDelegate.setPopup("message")} >
                        {t('vacancy.popup.btn')}
                    </button>
                </div>
            ) : (
                <div className={"letter"}>
                <textarea onChange={vacancyDelegate.changeMessage} value={vacancyDelegate.state.message || vacancyDelegate.state.data.requests[0].text || ""} placeholder={t('vacancy.popup.msg')}/>
                    <div className={"bottom"}>
                        <button onClick={() => vacancyDelegate.setPopup("info")}>
                            {t('btn.cancel')}
                        </button>
                        <button onClick={() => {
                            if (vacancyDelegate.state.successMessageSend)
                                return vacancyDelegate.setPopup(null)

                            vacancyDelegate.setMessage()
                        }} className={"send"}>{vacancyDelegate.state.loading ? t('vacancy.popup.sending') : vacancyDelegate.state.successMessageSend ? t('vacancy.popup.success') : t('btn.send')}</button>
                    </div>
                </div>
            )}


        </div>
    </div>);
}
export default PopUp