import React from "react"


const withResizableTextField = Component => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                rows: 1
            };
            this.handler = this.handler.bind(this);
            this.ref = React.createRef();
            this.container = React.createRef();
        }

        componentDidMount() {
            this.ref.current.addEventListener("input", this.handler);
            this.ref.current.addEventListener("keydown", e => {
                if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    this.props.pushMessage();
                    this.ref.current.value = "";
                }
            });
        }

        componentWillUnmount() {
            this.ref.current.removeEventListener("input", this.handler);
        }


        handler(e) {
            this.ref.current.rows = 1;
            const rows = (e.target.scrollHeight - 12) / 24;
            this.ref.current.rows = rows;
            this.props.heightDetect(this.container.current.clientHeight);
            this.props.handlerChange(e);
        }

        render() {
            return <Component delegate={this} {...this.props}/>
        }
    }

    HOC.displayName = `withResizableTextField(${Component.displayName || Component.name || "Component"})`;
    return HOC;
};


export default withResizableTextField;