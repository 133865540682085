import React, {Fragment} from "react";
import withSelect from "./HOCs/withSelect";

const Select = ({value, selectDelegate, list, name, handler, label, showField}) => (
    <div className="accordion" style={{position: "relative"}}>
        <div ref={selectDelegate.body} className="accordion__close">
      <span className="text">{value ? (
          <Fragment>
              {showField.split(",").map((field, idx) => (
                  <Fragment key={idx}>{value[field]}</Fragment>
              ))}
          </Fragment>
      ) : label}</span>
            <img className={selectDelegate.state.isOpen ? "icon active" : "icon"}
                 alt={""}
                 src={"/Images/arrow-down.svg"}/>
        </div>
        {selectDelegate.state.isOpen ? (
            <div className="accordion__open" style={{position: "absolute", top: "100%", left: 0}}>
                {list.map((item, index) => (
                    <React.Fragment key={index}>
                        {!value || item.id !== value.id ? (
                            <p className="text" onClick={() => handler(name, item)}>{
                                showField.split(",").map((field, idx) => (
                                    <span key={idx}>{item[field]}</span>
                                ))
                            }</p>
                        ) : ""}
                    </React.Fragment>
                ))}
            </div>
        ) : ""}
    </div>


)
export default withSelect(Select);