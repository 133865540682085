import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";

const SupportBoxSms =({accountDelegate, data}) => {

    const [active, toggleActive] = useState(data.enabled)
    const [checked, toggleChecked] = useState(data.enabled)
    const { t } = useTranslation();

    useEffect(() => {
        // Надо проверить глобально активный или нет чекбокс
        toggleChecked(data.enabled)
    }, [])

    return(
        <div onClick={() => toggleActive(!active)} className={active ? "support-box active" : "support-box"}>
            <div className={"support-box__head"}>
                <div className="head-left">
                    <img src="./Images/arrow-black.svg"/>
                    <div className="name-support">
                        {t('support.sms.title')}
                    </div>
                </div>
                <div onClick={e => e.stopPropagation()} className="switch">
                    <input checked={checked} onChange={e => {
                        toggleActive(e.target.checked)
                        toggleChecked(e.target.checked)
                        accountDelegate.toggleSupportItems(data.id, e.target.checked)
                    }} type="checkbox" id={"support-sms"}/>
                    <label htmlFor="support-sms" className={"switch-label"}/>
                </div>
            </div>
            <div onClick={e => e.stopPropagation()} className={"support-box__info"}>
                <label className={!checked ? "disabled" : ""} htmlFor="tel">
                    {t('account.phone')}
                </label>
                <input disabled={true} type="tel" id={"tel"} value={accountDelegate.state.phone}/>
            </div>

        </div>
    )
}
export default SupportBoxSms