import React, {useCallback, useMemo, useState} from 'react';
import styled from './TableHOC.module.scss';
import showImg from '../../img/plus-icon.png';
import arrowImg from '../../img/arrow-more.png';
import {useHistory} from "react-router";
import {declOfNum} from "../../../../../helpers/declOfNum";
import {useTranslation} from "react-i18next";

const TablePractices = ({practices}) => {
    const history = useHistory();
    const [countShow, setCountShow] = useState(4);
    const { t } = useTranslation();

    const difficultyPractice = [
        {level: t('practices.level1'), color: "#28C38A"},
        {level: t('practices.level2'), color: "#FECD40"},
        {level: t('practices.level3'), color: "#FE9B40"},
        {level: t('practices.level4'), color: "#EF613A"}
    ]

    const changePathToPractices = (id) => {
        let path = `/description/practices/${id}`
        history.push(path)
    }

    const handlerCountShow = useCallback(() => {
        setCountShow((prev) => prev + 4)
    }, [])

    const arrayPractices = useMemo(() => {
        return practices.slice(0, countShow)
    }, [countShow])

    return <div>
        <div className={styled.wrapper}>
            <div className={styled.header}>
                <div className={styled.columnOne}>
                    {t('practices.table.course')}
                </div>
                <div className={styled.columnTwo}>
                    {t('practices.table.format')}
                </div>
                <div className={styled.columnThree}>
                    {t('practices.table.progress')}
                </div>
                <div className={styled.columnFour}>
                    {t('practices.table.program')}
                </div>
                <div className={styled.columnFive}>
                    {t('practices.table.link')}
                </div>
            </div>
            <div className={styled.rows}>
                {arrayPractices.map(({icon, id, levels, progress, stack, tasks, themes, title}, index) =>
                    <div key={index} className={styled.rowTable}>
                        <div className={styled.columnOne + ' ' + styled.cell}>
                            <div className={styled.wrapperImage}>
                                <img src={icon} alt="Icon"/>
                            </div>
                            <div className={styled.infoCourse}>
                                <h4>{title}</h4>
                                <span>
                                    {t('practices.tech')} {!stack ? t('practices.base.knowledge') : stack}
                                </span>
                            </div>
                        </div>
                        <div className={styled.columnTwo + ' ' + styled.cell}>
                            <div className={styled.descriptionCourse}>{tasks} {declOfNum(tasks, [t('practices.task1'), t('practices.task2'), t('practices.task3')])} <div className={styled.blockLevel}
                                                                                           style={{
                                                                                               background: difficultyPractice[levels - 1].color,
                                                                                               width: levels !== 4 ? '98px' : '130px'
                                                                                           }}>{difficultyPractice[levels - 1].level}</div>
                            </div>
                        </div>
                        <div className={styled.columnThree + ' ' + styled.cell}>
                            <div className={styled.wrapperProgress}>
                                <div className={styled.currentProgress} style={{
                                    width: progress + '%',
                                    background: progress === 100
                                        ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                        : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                                }} />
                            </div>
                            <span style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                                {t('practices.progress')} {progress}%
                            </span>
                        </div>
                        <div className={styled.columnFour + ' ' + styled.cell}>
                            {t('practices.theory')}
                            <img src={showImg} alt=""/>
                        </div>
                        <div className={styled.columnFive + ' ' + styled.cell}>
                            <button onClick={() => changePathToPractices(id)}>
                                {t('btn.pass')}
                            </button>
                        </div>
                    </div>)}
                {countShow < practices.length && <footer onClick={handlerCountShow}>
                    {t('btn.more')}
                    <img src={arrowImg} alt="=>"/>
                </footer>}
            </div>
        </div>

        <div className={`${styled.wrapper} ${styled.mobileWrapper}`}>
            <div className={styled.header}>
                <div className={styled.columnOne}>
                    {t('practices.table.course')}
                </div>
                <div className={styled.columnTwo}>
                    {t('practices.table.format')}
                </div>
            </div>
            <div className={styled.rows}>
                {arrayPractices.map(({icon, id, levels, progress, stack, tasks, themes, title}, index) => <div
                    key={index} className={styled.rowTable}>
                    <div className={styled.columnOne}>
                        <div className={styled.titleCourse}>
                            <div className={styled.wrapperImage}>
                                <img src={icon} alt="Icon"/>
                            </div>
                            <div className={styled.nameCourse}>
                                <h3>{title}</h3>
                                <span>
                                    {t('practices.profession')}
                                </span>
                            </div>
                        </div>
                        <div className={styled.progressCourse}>
                            <div className={styled.wrapperProgress}>
                                <div className={styled.currentProgress} style={{
                                    width: progress + '%',
                                    background: progress === 100
                                        ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                        : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                                }} />
                            </div>
                            <span style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                                {t('practices.progress')} {progress}%
                            </span>
                        </div>
                        <div className={styled.themeAndPractice}>
                            {t('practices.theory')} <img src={showImg} alt=""/>
                        </div>
                    </div>
                    <div className={styled.columnTwo}>
                        <div className={styled.descriptionCourse}>
                            <span>{tasks} {declOfNum(tasks, [t('practices.task1'), t('practices.task2'), t('practices.task3')])}</span>
                            <div className={styled.blockLevel}
                                 style={{
                                     background: difficultyPractice[levels - 1].color,
                                     width: levels !== 4 ? '98px' : '130px'
                                 }}>{difficultyPractice[levels - 1].level}</div>
                        </div>
                        <button onClick={() => changePathToPractices(id)}>
                            {t('btn.pass')}
                        </button>
                    </div>
                </div>)}
            </div>

            <div className={`${styled.rows} ${styled.rowsMobile}`}>
                {practices.map(({icon, id, levels, progress, stack, tasks, themes, title}, index) => <div
                    key={index} className={styled.rowTable}>
                    <div className={styled.columnOne}>
                        <div className={styled.titleCourse}>
                            <div className={styled.nameCourse}>
                                <img src={icon} alt="Icon"/>
                                <h4>{title}</h4>
                            </div>
                            <span>
                                {t('practices.profession')}
                            </span>
                        </div>
                        <div className={styled.descriptionCourse}>
                            <span>{tasks} {declOfNum(tasks, [t('practices.task1'), t('practices.task2'), t('practices.task3')])}</span>
                            <div className={styled.blockLevel}
                                 style={{
                                     background: difficultyPractice[levels - 1].color,
                                     width: levels !== 4 ? '98px' : '130px'
                                 }}>{difficultyPractice[levels - 1].level}</div>
                        </div>
                        <div className={styled.progressCourse}>
                            <div className={styled.wrapperProgress}>
                                <div className={styled.currentProgress} style={{
                                    width: progress + '%',
                                    background: progress === 100
                                        ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                        : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                                }} />
                            </div>
                            <span style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                                {t('practices.progress')} {progress}%
                            </span>
                        </div>
                        <div className={styled.themeAndPractice}>
                            {t('practices.theory')} <img src={showImg} alt=""/>
                        </div>
                        <button  onClick={() => changePathToPractices(id)}>
                            {t('btn.pass')}
                        </button>
                    </div>
                </div>)}
            </div>
            {countShow < practices.length && <footer onClick={handlerCountShow}>
                {t('btn.more')} <img src={arrowImg} alt="=>"/>
            </footer>}
        </div>
    </div>
}

export default TablePractices;