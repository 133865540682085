import React from "react";
import Loader from "../../../Components/Loader";
import config from "../../../../etc/config";
import moment from "moment";
import Error from "../../../Components/ErrorPage";
import ErrorPage from "../../ErrorPage";
import {Redirect} from "react-router"

const withPracticalTask = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
            this.state = {
                data: null,
                err: "",
                errorPage: null,
                redirect: false,
                notify: null
            }

            this._isMounted = false
        }

        get isPractice() {
            return this.props.location.pathname.includes("project-practical-task")
        }

        componentDidMount() {
            this.getData();
            document.title = "Practice...";
            window.scrollTo({top: 0, left: 0});
            this._isMounted = true

            document.addEventListener("commentEvent", e => {
                const { stage_id, comment, evaluation_id} = e.data;
                const {data} = this.state;
                if (data && data.id === stage_id) {
                    const idx = data.evaluation.findIndex(x => x.id === evaluation_id);
                    if (idx > -1) {
                        data.evaluation[idx].comment = comment;
                        data.evaluation[idx].state = "reviewed";
                    }

                }
                this.setState({ data });
            });
        }


        componentWillUnmount() {
            this._isMounted = false
            document.removeEventListener("commentEvent", e => {
                const { stage_id, comment, evaluation_id} = e.data;
                const {data} = this.state;
                if (data && data.id === stage_id) {
                    const idx = data.evaluation.findIndex(x => x.id === evaluation_id);
                    if (idx > -1) {
                        data.evaluation[idx].comment = comment;
                        data.evaluation[idx].state = "reviewed";
                    }

                }
                this.setState({ data });
            });
        }

        getData = () => {
            fetch(`${config.API_PREFIX}stages/${this.props.match.params.id}${this.props.location.search || ""}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({ data: res })
                    document.title = res.name
                })
                .catch(err => {
                    this.setState({ err: err.toString()});
                    console.log(err);
                })
        };

        completion = (res) => {
            const evaluation = {
                comment: null,
                date: moment().format("YYYY-MM-DD"),
                file: res.url,
                state: "pending"
            };

            const {data} = this.state;
            if (!data.evaluation)
                data.evaluation = [];

            data.evaluation.unshift(evaluation);

            this.setState({data});
            this.props.appDelegate.setPopupAlert({ message: "В ближайшее время мы его проверим и отправим уведомление вам на почту.", title: "Домашнее задание отправленно на проверку", handler: () => this.props.appDelegate.setPopupAlert(null), buttonText: "Отлично", close: () => this.props.appDelegate.setPopupAlert(null) })
        };

        showNotify = (notify) => {
            this.setState({notify}, () => {
                setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({notify: null})
                    }
                }, 3000)
            })
        }

        render() {
            if (this.state.redirect) {
              return <Redirect to={`/review/${this.props.match.params.id}`}/>
            } else if (this.state.data && !this.state.errorPage) {
                return <Component practicalDelegate={this} {...this.props} />
            } else  if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage} />
            }  else if (this.state.err) {
                return <Error error={this.state.err} />
            } else {
                return <Loader />
            }

        }
    }

    HOC.displayName = `withPracticalTask(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withPracticalTask;