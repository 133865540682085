import {blogApi} from "../api/api";

const SET_POSTS = 'SET_POSTS'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_POST_FOR_BLOG = 'SET_POST_FOR_BLOG'
const SWITCH_NEXT_POST = 'SWITCH_NEXT_POST'
const SWITCH_BACK_POST = 'SWITCH_BACK_POST'
const CHANGED_IS_LOADING = 'CHANGED_IS_LOADING'

let initialState = {
    posts: [],
    categories: [],
    currentPost: null,
    isLoading: false
}

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POSTS: {
            return {
                ...state,
                posts: action.posts
            }
        }
        case SET_CATEGORIES: {
            return {
                ...state,
                categories: [
                    {
                        id: 0,
                        name: "Все",
                        slug: null
                    }, ...action.categories
                ]
            }
        }
        case SET_POST_FOR_BLOG: {
            return {
                ...state,
                currentPost: action.post,
            }
        }
        case SWITCH_NEXT_POST: {
            let currentIndex = state.posts.findIndex(({id}) => id === action.idPost)
            if (currentIndex !== state.posts.length - 1) {
                return {
                    ...state,
                    currentPost: state.posts[currentIndex + 1]
                }
            } else {
                return {
                    ...state,
                    currentPost: state.posts[0]
                }
            }
        }
        case SWITCH_BACK_POST: {
            let currentIndex = state.posts.findIndex(({id}) => id === action.idPost)
            if (currentIndex !== 0 && state.posts.length !== 0) {
                return {
                    ...state,
                    currentPost: state.posts[currentIndex - 1]
                }
            } else if (currentIndex === 0) {
                return {
                    ...state,
                    currentPost: state.posts[state.posts.length - 1]
                }
            }
        }
        case CHANGED_IS_LOADING: {
            return {
                ...state,
                isLoading: action.status
            }
        }
        default:
            return state;
    }
}

const setPosts = (posts) => {
    return {
        type: SET_POSTS,
        posts
    }
}

const changedIsLoading = (status) => {
    return {
        type: CHANGED_IS_LOADING,
        status
    }
}

const setCategories = (categories) => {
    return {
        type: SET_CATEGORIES,
        categories
    }
}

const setPostForBlog = ({post}) => {
    return {
        type: SET_POST_FOR_BLOG,
        post
    }
}

export const switchNextPost = (idPost) => {
    return {
        type: SWITCH_NEXT_POST,
        idPost
    }
}

export const switchBackPost = (idPost) => {
    return {
        type: SWITCH_BACK_POST,
        idPost
    }
}

export const getBlogThunk = () => {
    return async (dispatch) => {
        dispatch(changedIsLoading(true))
        let response = await blogApi.getBlog()
        try {
            dispatch(setPosts(response.data.posts))
            dispatch(setCategories(response.data.categories))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changedIsLoading(false))
        }
    }
}

export const getPostForBlogThunk = (category, post) => {
    return async (dispatch) => {
        let response = await blogApi.getPostForBlog(category, post)
        try {
            dispatch(setPostForBlog(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const changeCategory = (category_slug) => {
    return async (dispatch) => {
        let response = await blogApi.changeCategory(category_slug)
        try {
            dispatch(setPosts(response.data.posts))
        } catch (error) {
            console.log(error)
        }
    }
}

export default blogReducer;
