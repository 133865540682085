import React from "react";
import withMessenger from "./withMessenger";
import ResizableTextField from "../ResizableTextField";
import ScrollWrapper from "../ScrollWrapper";
import moment from "moment";



const Messenger = ({ messengerDelegate }) => (
    <div className={"messenger"}>
        <audio ref={messengerDelegate.player} src={"/Sounds/message.mp3"} controls={false} />
        <div onClick={messengerDelegate.toggleContainer} className={"messenger__button"}>
            {messengerDelegate.state.newData ? (
                <img src="/Images/chat-notify.svg" alt={"😼"} />
            ) : (
                <img src="/Images/chat.svg" alt={"😼"} />
            )}

        </div>
        {messengerDelegate.state.show ? <div onClick={messengerDelegate.toggleContainer} className={"messenger__close"}/> : ""}
        <div onClick={e => messengerDelegate.toggleCTXMenu(e, null)} style={{transform: `translateX(${messengerDelegate.state.show ? 0 : 100}%)`}} className={"messenger__container"}>
            <div onClick={messengerDelegate.toggleContainer} className={"top-close"}>
                <img  src="/Images/arrow-close.svg" alt={"😼"}/></div>
            <ScrollWrapper update={messengerDelegate.state.update} classes={"messages"} styles={{height: `calc(100% - ${messengerDelegate.state.inputHeight}px - 50px)`}}>
                <div>
                    {messengerDelegate.state.messages.map((message, index) => (
                        <div className={message.sender.itsMe ? "container me" : "container"} key={index}>

                            {!message.sender.itsMe ? (<div className={"container__avatar"}>{message.sender.name ? message.sender.name[0] : "😎"}</div>) : ""}
                            <div className={"container__message"}>
                                {!message.id ? (
                                    <img src="/Images/message-load.svg" alt={"😼"} className={"message-loader"}/>
                                ) : (
                                    <img className={"message-detail"} onClick={e => messengerDelegate.toggleCTXMenu(e, message.id)} src="/Images/detail-message.svg" alt={"😼"}/>
                                )}
                                {messengerDelegate.state.ctxMenu === message.id ? (
                                    <div className={"ctx-menu"}>
                                        <div onClick={() => messengerDelegate.setQuote(message)} className={"ctx-menu__item"}>Цитировать</div>
                                        {message.sender.itsMe ? <div className={"ctx-menu__item"}>Удалить</div> : ""}
                                    </div>
                                ) : ""}
                                <h5>{message.sender.name || `Гость - ${message.sender.id}`}</h5>
                                {message.parent ? (
                                    <div className={"parent"}>
                                        <h5>{message.parent.sender.name}</h5>
                                        <p>{message.parent.message}</p>
                                    </div>
                                ) : ""}
                                <p>{message.message}</p>
                                <span>{moment(message.created_at).isSameOrAfter(moment().set({hour:0,minute:0,second:0,millisecond:0})) ? moment(message.created_at).format("hh:mm") : moment(message.created_at).format("DD MMM, hh:mm")}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </ScrollWrapper>
            {messengerDelegate.state.quote ? (
                <div style={{bottom: `${messengerDelegate.state.inputHeight}px`}} className={"quote"}>
                    <div className={"quote__container"}>
                        <h5>{messengerDelegate.state.quote.sender.name}</h5>
                        <p>{messengerDelegate.state.quote.message}</p>
                        <img onClick={() => messengerDelegate.setQuote(null)} src="/Images/delete-quote.svg" alt=""/>
                    </div>
                </div>
            ) : ""}
            {messengerDelegate.state.printNow.length > 0 ? (
                <div style={{bottom: `${messengerDelegate.state.inputHeight + 8}px`}} className={"print-now"}>
                    {messengerDelegate.state.printNow.map((item, index) => (
                        <span key={index}>{item.name} {index < messengerDelegate.state.printNow.length - 1 ? ", " : " "}</span>
                    ))}
                    <span>{messengerDelegate.state.printNow.length > 1 ? "пишут" : "пишет"} сообщение</span>
                </div>
            ) : ""}
            <ResizableTextField pushMessage={messengerDelegate.pushMessage} heightDetect={messengerDelegate.heightDetect} name={"text"} handlerChange={messengerDelegate.changeMessage} placeholder={"Сообщение..."} value={messengerDelegate.state.text} />
        </div>
    </div>
);


export default withMessenger(Messenger);