import React, {useEffect, useMemo} from 'react';
import {renderApp} from '../../../roulette/user/roulette';
import {_renderCompetition} from '../../../roulette/admin/admin-roulette';
import lotteryImg from '../../../roulette/images/lottery.png';
import shareImg from '../../../roulette/images/share.png';
import listImg from '../../../roulette/images/list.png';
import rouletteImg from '../../../roulette/images/roulette.png';
import logoIcon from '../../../roulette/images/cat-logo.png';
import instaIcon from '../../../roulette/images/insta.png';
import facebookIcon from '../../../roulette/images/facebook.png';
import vkIcon from '../../../roulette/images/vk.png';
import twitterIcon from '../../../roulette/images/twitter.png';
import arrowRoulette from '../../../roulette/images/arrow-roulette.png';
import leftIcon from '../../../roulette/images/cat_icon.png';
import rightIcon from '../../../roulette/images/money_icon.png';
import confettiImg from '../../../roulette/images/confetti.png';
import backgroundImg from '../../../roulette/images/background-image.png';
import moneyImg from '../../../roulette/images/money.png';
import prevPage from '../../../roulette/images/prev-page.png';
import closeIcon from '../../../roulette/images/close-icon.png';
import Loader from '../../Components/Loader';
import {_dataRoulette} from '../../../roulette/_data-roulette';
import {useDispatch, useSelector} from 'react-redux';
import {getDataForRoulette} from '../../../redux/actions/roulette';
import {RootState} from '../../../redux/reducers';
import {AppDelegateType} from '../../../types/app-delegate';

type Props = {
    appDelegate: AppDelegateType;
    location: any;
}

const Roulette = ({appDelegate, location}: Props) => {
    const dispatch = useDispatch();
    const roulette = useSelector((state: RootState) => state.rouletteReducer);

    useEffect(() => {
        dispatch(getDataForRoulette(appDelegate?.state?.authToken));
    }, []);

    const _data = useMemo(() => {
        return {
            date_competition: roulette.date_competition,
            last_competition: roulette.prev_date_competition,
            count_competition: _dataRoulette.count_competition,
            progress: _dataRoulette.completed,
            subscription: !!appDelegate?.state?.user?.subscriptions,
            path_billing: 'https://cabinet.educat.courses/billing',
            list_winners: [
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
                {
                    prize: 'Денежный приз',
                    date_competition: '15.04.2020',
                    user: {
                        fullName: 'Pavlo Tsimbota',
                        image: 'https://image.freepik.com/free-vector/cartoon-thief-walking-carefully_29190-4247.jpg'
                    },
                    passed: {
                        tasks: 28,
                        courses: 12
                    }
                },
            ],
            users_participants: roulette.participants,
            prizes: roulette.prizes,
            colors_confetti: roulette.colors_confetti,
            text_page: {
                button: !!appDelegate?.state?.user?.subscriptions ? 'вы уже участвуете' : 'участвовать',
                prev_page: 'Вернуться назад',
                menu: [
                    {
                        title: 'Условия',
                        id: '#conditions'
                    },
                    {
                        title: 'Розыгрыш',
                        id: '#competition'
                    },
                    {
                        title: 'Победители',
                        id: '#winners'
                    }
                ],
                mainLayout: {
                    title: 'Участвуй в розыгрышах Educat',
                    description: 'Подарки для всех, кто развивает свои навыки. Вы учитесь и решаете задачи — мы дарим призы за активность.',
                    condition: 'Ваша подписка даёт вам право участвовать в розыгрыше призов',
                    text_subscription: 'Я хочу оплатить подписку <br /> и стать участником конкурса розыгрыша призов'
                },
                main_admin: {
                    title: 'Розыгрыш в реальном времени от компании Educat',
                    date_competition: `Дата проведения ${roulette.date_competition}`,
                    btn_title: 'Вращать',
                    winner_text: `Победителем ${_dataRoulette.count_competition} розыгрыша Educut становится`,
                    administration: 'Администрация сайта свяжется с победителем в ближайшее время',
                    btn_next_step: 'Запустить снова',
                    btn_end_roulette: 'Розыгрыш окончен',
                    users: 'Участники',
                    users_competition: 'Участники розыгрыша',
                    participant: 'Участник'
                },
                condition: {
                    title: 'Условия розыгрыша',
                    steps: [
                        {
                            title: 'Оформите подписку в Educat на 1 месяц или 1 год',
                            description: 'К участию в розыгрыше допускаются пользователи, обучающиеся на курсах Educat.',
                            text: `У вас приобретена подписка на ${_dataRoulette.subscriptions.period}. Действует ${_dataRoulette.subscriptions.end} г.`
                        },
                        {
                            title: 'Проходите курсы, выполняйте практику и решайте задачи',
                            description: 'Будьте увлечены и активны обучением на платформе, мы обязательно оценим ваши старания и отметим их призами.',
                            text: `У вас пройдено ${_dataRoulette.completed}`
                        },
                        {
                            title: 'Выигрывайте подарки от Educat',
                            description: 'Оформляя подписку в Educat, вы автоматически становитесь участником наших розыгрышей. Розыгрыш проводится 2 раза за месяц. Для тех, кто приобрел подписку - 1го числа и для тех, кто достиг прогресса в учёбе - 15го числа каждого месяца. ',
                            text: null
                        }
                    ]
                },
                winners: {
                    title: 'Наши победители',
                    last_month: 'Розыгрыш призов за последний месяц',
                    interview: [
                        'https://www.youtube.com/embed/todcApS3BIY',
                        'https://www.youtube.com/embed/todcApS3BIY',
                        'https://www.youtube.com/embed/todcApS3BIY',
                        'https://www.youtube.com/embed/todcApS3BIY',
                        'https://www.youtube.com/embed/todcApS3BIY',
                        'https://www.youtube.com/embed/todcApS3BIY'
                    ],
                    next_competition: 'Следующий розыгрыш состоится',
                    date_next_competition: roulette.date_competition,
                    paid_subscription: 'среди тех, кто достиг прогресса в учёбе',
                    free_subscription: 'среди тех, кто приобрел подписку',
                    list_winner: `Список победителей розыгрыша от ${roulette.prev_date_competition} года`,
                    placeholder: 'Найди себя в списке победителей'
                },
                roulette: {
                    title: 'Ещё нет подписки?',
                    description: 'Получите дополнительный бонус за обучение, спешите принять участие',
                    text: [
                        'Оплатите подписку',
                        'и станьте участником конкурса розыгрыша призов'
                    ],
                    rules: [
                        'Победитель определяется генератором случайных чисел',
                        'Наш менеджер в прямом эфире свяжется с победителем для вручения приза'
                    ]
                },
                table_winners: {
                    date_competition: 'Дата розыгрыша',
                    winner: 'Победитель',
                    passed: 'Подписка:'
                },
                info_lottery: [
                    {
                        img: lotteryImg,
                        title: '100 призов',
                        description: 'разыгрываем каждую неделю случайным образом'
                    },
                    {
                        img: shareImg,
                        title: 'Поделись',
                        description: 'информацией о розыгрыше в своём аккаунте'
                    },
                    {
                        img: listImg,
                        title: 'Увеличь шансы',
                        description: 'на победу, выполняя ещё больше задач и проходя курсы'
                    }
                ],
                footer: {
                    links: ['Политика конфиденциальности', 'Правила акции', 'Пользовательское соглашение'],
                    text: 'Акция проводится на территории Российской Федерации и распространяется только на продукцию, купленную на территории Российской Федерации. Общие сроки проведения акции с 01.04.2021 г. по 30.11.2021 г. включительно, включая срок выдачи призов. Сроки регистрации кодов с 01.04.2021 г. по 31.07.2021 г., сроки активации QR-кодов и подачи конкурсных работ с 01.04.2021 г. по 15.07.2021 г. Количество призов ограничено. Полную информацию об организаторе Акции, о Правилах ее проведения, количестве Призов, сроках, месте и порядке их получения можно получить на сайте /название сайта/.',
                    email: 'info@educat.courses',
                    rightsReserved: 'Все права защищены',
                    icons: [instaIcon, facebookIcon, vkIcon, twitterIcon]
                }
            },
            style: {
                roulette_img: rouletteImg,
                logo: logoIcon,
                arrow_roulette: arrowRoulette,
                prev_page: prevPage,
                icon_left: leftIcon,
                icon_right: rightIcon,
                icon_close: closeIcon,
                color_title: '#ffffff',
                color_text: '#ffffff',
                color_count_steps: '#ffffff',
                color_next_competition: '#FFBC11',
                color_input: '#0038C1',
                bcg_roulette: '#1f67f3',
                bcg_confetti: confettiImg,
                bcg_image: backgroundImg,
                bcg_money: moneyImg,
                bcg_winner: '#0A48DC',
                bcg_info_winner: 'linear-gradient(0deg, #003CCA, #003CCA), #0C4DEA',
                bcg_button: '#FF9D11',
                bcg_condition_item: '#0A48DC',
                bcg_no_subscription: 'linear-gradient(270deg, #0F49D4 0%, #0A48DC 18.23%, #0A48DC 83.85%, #0F49D5 100%), #0A48DC',
                bcg_table: 'rgba(10, 72, 220, 0.5)',
                bcg_footer: '#0A48DC',
                border_condition_item: '#3873FF',
                border_input: '#3772FD',
                border_table: '#467DFF',
                border_winner: '#3873ff',
                shadow_condition: 'inset 0 20px 35px rgba(11, 62, 183, 0.92)',
                shadow_no_subscription: '0 18px 22px rgba(10, 72, 220, 0.4)',
                shadow_winner: '0 20px 52px -10px #023BB5',
                shadow_footer: 'inset 0 20px 35px #0B3EB8',
                shadow_btn: '0 1px 2px #DE6F0D'
            },
            roulette_style: {
                main_border: '#4179FF',
                second_border: '#2759d4',
                main_arc: '#ffffff',
                second_arc: '#0f78d7',
                color_text: '#ffffff'
            }
        };
    }, [roulette]);

    useEffect(() => {
        if (roulette.participants.length) {
            location.state?.admin ? _renderCompetition(_data) : renderApp(_data);
        }
    }, [roulette.participants]);

    return <Loader/>;
};

export default Roulette;
