import {combineReducers} from "redux";
import gameReducer from '../gameReducer'
import dashboardReducer from '../dashboardReducer'
import descriptionReducer from './description'
import subscriptionReducer from './subscription'
import discussionReducer from '../discussionReducer'
import allCoursesReducer from '../allCoursesReducer'
import practicesReducer from '../practicesReducer'
import videosReducer from '../videosReducer'
import certificatesReducer from '../certificatesReducer'
import aboutCourseReducer from '../aboutCourseReducer'
import tasksReducer from './tests';
import blogReducer from '../blogReducer'
import rouletteReducer from './roulette';
import localesReducer from '../localesReducer';

const rootReducer = combineReducers({
    gameReducer,
    dashboardReducer,
    descriptionReducer,
    subscriptionReducer,
    discussionReducer,
    allCoursesReducer,
    practicesReducer,
    videosReducer,
    certificatesReducer,
    aboutCourseReducer,
    blogReducer,
    tasksReducer,
    rouletteReducer,
    localesReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
