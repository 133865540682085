import React, { useState } from 'react';
import s from './SelectCountry.module.scss'

const Countries = [
    {
        id: 0,
        name: `Россия`
    },
    {
        id: 1,
        name: `Уругвай`
    },
    {
        id: 2,
        name: `Мозамбик`
    }
];

const SelectCountry = () => {
    const [selectedCountry, setSelectedCountry] = useState(0);

    return (
        <div className={s.wrapper}>
            <div>Укажите свою страну:</div>
            <div className={s.chooseYourCountry}>
                <div className={`${s.flag} ${s[`id${selectedCountry}`]}`} ></div>
                <select onChange={(evt) => setSelectedCountry(evt.target.value)}>
                    {
                        Countries.map((c, i) => <option value={c.id} key={`option${i}`}>{c.name}</option>)
                    }
                </select>
            </div>
        </div>
    );
};

export default SelectCountry;
