import React from 'react'
import {Link} from "react-router-dom";
import withCareer from "./HOCs/init";
import {useTranslation} from "react-i18next";

const Career = ({careerDelegate}) => {
    const { t } = useTranslation();
    return (<main className={"practice-home content-main"}>
        <div className={"all-courses"}>
            <h1>
                {t('career.title')}
            </h1>
            <div className={"top"}>
                <p>
                    {t('career.desc')}
                </p>
            </div>
            {careerDelegate.state.data.my.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>
                        {t('career.responses')}
                    </h4>
                    <div className={"profession"}>
                        {careerDelegate.state.data.my.map((item, index) => (
                            <Link to={`/jobs/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>
                                            {t('career.vacancy')}
                                        </span>
                                    </p>
                                    <h3>{item.position}</h3>
                                    <div className={"info"}>
                                        <span> {item.company.name}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}

            {careerDelegate.state.data.jobs.length > 0 ? (
                <React.Fragment>
                    <h4 className={"all-courses__subtitle"}>
                        {t('career.all')}
                    </h4>
                    <div className={"profession"}>
                        {careerDelegate.state.data.jobs.map((item, index) => (
                            <Link to={`/jobs/${item.id}`} key={index}  className={"profession__item"}
                                  style={{background: `url(${item.company.logo})`, backgroundSize: 'content'}}>
                                <div className={"item-top"}>
                                    <p>
                                        <span>
                                            {t('career.vacancy')}
                                        </span>
                                        <span className="salary">
                                            {t('career.from')} {item.salary}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {t('career.exp')} {item.experience}
                                        </span>
                                    </p>
                                    <h3>{item.position}</h3>
                                    <div className={"info"}>
                                        <span> {item.company.name}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </React.Fragment>
            ) : ""}


        </div>
    </main>)
}

export default withCareer(Career)