import React from "react";
import config from "../../../etc/config";



const withRegPopup = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
            this.state = {
                name: "",
                email: "",
                phone: "",
                password: "",
                error: [],
                buttonState: "normal",
                formState: "registration"
            }
        }

        toggleState = (formState) => {
            this.setState({ formState })
        };


        submit = e => {
            e.preventDefault();
            const {formState} = this.state;
            if (formState === "registration") {
                this.registration()
            } else if (formState === "authorization") {
                this.authorization();
            } else {
                this.reset();
            }
        };


        authorization = () => {
            let error = [];
            const { email, password} = this.state;


            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (email.length < 1 || !re.test(email)) {
                error.push("email")
            }

            if (password.length < 1) {
                error.push("password")
            }


            if (error.length > 0) {
                this.setState({ error });
                return
            }

            this.setState({ buttonState: "pending" });

            fetch(`${config.API_PREFIX}oauth/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "email": email,
                    "password": password
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 0) {
                        this.props.appDelegate.authCompletion(res);
                        this.props.appDelegate.toggleRegistration();
                        this.setState({ buttonState: "success" });
                    } else if (res.status === 1) {
                        alert("Email или пароль указанны неверно!")
                        this.setState({ buttonState: "normal" });
                    } else {
                        alert(res.message || "Server error!");
                        this.setState({ buttonState: "normal" });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        };

        reset = () => {

            let error = [];
            const { email } = this.state;


            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (email.length < 1 || !re.test(email)) {
                error.push("email")
            }



            if (error.length > 0) {
                this.setState({ error });
                return
            }

            this.setState({ buttonState: "pending" });

            fetch(`${config.API_PREFIX}password/email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "email": this.state.email
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 0) {
                        this.setState({ buttonState: "success" });
                    } else if (res.status === 1) {
                        this.setState({ error: res.message || "Что-то пошло не так, возможно вы указали неверный email." })
                    } else {
                        this.setState({ error: res.message || "Server error!" })
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        };


        registration = () => {

            let error = [];
            const {name, email, phone} = this.state;


            if (name.length < 1) {
                error.push("name")
            }

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (email.length < 1 || !re.test(email)) {
                error.push("email")
            }



            if (error.length > 0) {
                this.setState({ error });
                return
            }

            this.setState({ buttonState: "pending" });


            fetch(`${config.API_PREFIX}${this.props.isNew ? "register" : "user"}`, {
                method: this.props.isNew ? "POST" : "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken || ""}`
                },
                body: JSON.stringify({
                    email,
                    name,
                    phone
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();
                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status, buttonState: "normal"});
                        throw new Error(res.statusText);
                    }
                    return res.json();
                })
                .then(res => {
                    if (res.status === 0){
                        this.setState({ buttonState: "success" });
                        if (this.props.isNew) {
                            this.props.appDelegate.logout();
                        } else {
                            this.props.appDelegate.getData();
                        }
                    } else if (res.status === 1) {
                        this.setState({ buttonState: "normal" });
                        let message = "";
                        for (let i = 0; i < res.data.length; i++) {
                            message += `\n${res.data[i]}`;
                        }
                        alert(message);
                    } else {
                        alert("Server error!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        };

        handler = e => {
            const {error, phone} = this.state;
            let {name, value} = e.target;
            if (error.indexOf(name) > -1) {
                error.splice(error.indexOf(name), 1)
            }
            if (name === "phone" && value.length === 1 && phone.length === 0) {
                value = `+${value}`
            }

            this.setState({[name]: value, error})
        };

        render() {
            return <Component regDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withRegPopup(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withRegPopup;
