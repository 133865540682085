import React from "react";
import withScrollWrapper from "./withScrollWrapper";


const ScrollWrapper = ({ wrapperRef, children, classes, overflow, scrollBar, styles = {} }) => (
    <div draggable={false} style={{overflow: overflow, position: "relative", ...styles}} className={classes} ref={wrapperRef}>
        <div style={{display: "inline"}}>
            {children}
        </div>

        <div style={{height: "100%", width: "4px", position: "absolute", top: 0, right: 0, background: "none"}}>
            <div ref={scrollBar} style={{opacity: 0, width: "100%", position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.3)", left: 0, borderRadius: "2px"}}/>
        </div>
    </div>
);


export default withScrollWrapper(ScrollWrapper);