import React from 'react'
import styled from './PromptTask.module.scss'
import Dialog from '@material-ui/core/Dialog'
import exitImg from "../../img/exit.png"
import Video from "../Video/Video";

const PromptTask = ({task, helpTask, closeHelpTask}) => {
    const videoPlayer = task.video_link?.split('/').includes('www.youtube.com')
    return (
        <div>
            <Dialog onClose={() => closeHelpTask(false)} aria-labelledby="customized-dialog-title" open={helpTask}>
                <div className={styled.wrapper}>
                    <div className={styled.imgBlock} onClick={() => closeHelpTask(false)}>
                        <img src={exitImg} alt="x"/>
                    </div>
                    <div className={styled.content}>
                        <div className={styled.video}>
                            {!videoPlayer
                                ? <Video link={task.video_link}/>
                                : null
                            }
                        </div>
                        <h2>{task.title}</h2>
                        <p>{task.theory}</p>
                        {task.code_example !== null && <div className={styled.exampleCode}>
                            <div
                                dangerouslySetInnerHTML={{__html: task.code_example}}
                                className={"html-data"}/>
                        </div>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default PromptTask;