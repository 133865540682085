import React from "react";
import config from "../../../../etc/config";
import Loader from "../../../Components/Loader";
import ErrorPage from "../../ErrorPage";


const withCareer = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                errorPage: null,
            }
        }

        componentDidMount() {
            this.getData();
            window.scrollTo({top: 0, left: 0});
            document.title = "Карьера"
        }

        getData = () => {
            fetch(`${config.API_PREFIX}jobs`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({data: res})
                })
                .catch(err => {
                    console.log(err);
                })
        };


        render() {
            if (!this.state.data && !this.state.errorPage)
                return <Loader/>;
            else if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else
                return <Component careerDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withCareer(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withCareer;