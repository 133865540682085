import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { RegistrationRoutes } from "./assets/RegistrationRoutes";
import ResetPassword from "./pages/ResetPassword";
import DropPassword from "./pages/DropPassword";
import Signin from "./pages/Signin";
import SigninSocial from "./pages/SigninSocial";
import Signup from "./pages/Signup";
import { AppDelgatedProps } from "./types";

function RegistrationAlternative({ appDelegate }: AppDelgatedProps): JSX.Element {
  return (
    <Switch>
      <Route path={RegistrationRoutes.SIGN_IN}>
        <Signin appDelegate={appDelegate}/>
      </Route>

      <Route path={RegistrationRoutes.SIGN_IN_SOCIAL.concat("/:provider")}>
        <SigninSocial appDelegate={appDelegate}/>
      </Route>

      <Route path={RegistrationRoutes.SIGN_UP}>
        <Signup appDelegate={appDelegate}/>
      </Route>

      <Route path={RegistrationRoutes.DROP_PASSWORD}>
        <DropPassword appDelegate={appDelegate}/>
      </Route>
      
      <Route path={RegistrationRoutes.RESET_PASSWORD}>
        <ResetPassword appDelegate={appDelegate}/>
      </Route>

      <Redirect to={RegistrationRoutes.SIGN_IN}/>
    </Switch>
  );
}

export default RegistrationAlternative;