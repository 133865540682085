import React, {Fragment} from "react"
import withTheme from "./HOCs/init"
import {Link} from "react-router-dom"
import Video from "./Component/Video"
import Test from "./Component/Test"
import ThemePractice from "./Component/ThemePractice"
import Exercise from "./Component/Exercise"
import Messenger from "../../Components/Messanger"
import Navigator from "./Component/Navigator"
import ADS from "../../Components/ADS"
import FileLoader from "../../Components/FileLoader"
import Evaluation from "../PracticalTask/Components/Evaluation"
import Comments from "./Component/Comments"
import Events from "../../Components/Events"
import TrustBox from "../../Components/TrustBox";

const Theme = ({themeDelegate, appDelegate}) => {
    return (<div className={"content-main theme-page"}>
        <div className={"theme-top"}>
            <div className={"top-content"}>
                <div className={"top-left"}>
                    <Link className={"left-a"}
                          to={`/${themeDelegate.isPractice 
                              // ? "practices" : "course"}/${themeDelegate.state.data.stage.level.course.id}/${themeDelegate.state.data.stage.level.id}`}>
                              ? "description/practices" : "description/course"}/${themeDelegate.state.data.stage.level.course.id}`}>
                        {themeDelegate.state.data && themeDelegate.state.data.stage
                            ? themeDelegate.state.data.stage.level.name
                            : ""}
                    </Link>
                    <img src={"/Images/arrow-right.svg"} alt={"😼"}/>
                    <Link className={"right-a"}
                          // to={`/${themeDelegate.isPractice ? "practices" : "course"}/${themeDelegate.state.data.stage.level.course.id}/${themeDelegate.state.data.stage.level.id}/${themeDelegate.state.data.stage.id}`}
                          to={`/${themeDelegate.isPractice ? "description/practices" : "description/course"}/${themeDelegate.state.data.stage.level.course.id}`}
                    >{themeDelegate.state.data && themeDelegate.state.data.stage ? themeDelegate.state.data.stage.name : ""}
                    </Link>
                </div>
            </div>
        </div>
        {themeDelegate.state.data.html.includes("#emty-theme#") ? (
            <div className={"empty-theme"}>
                <h3>Тема "{themeDelegate.state.data.name}" еще не доступна</h3>
                <a target={"_blank"}
                   rel="noopener noreferrer"
                   href={themeDelegate.state.data.html.slice(themeDelegate.state.data.html.indexOf("#emty-theme#") + 12, themeDelegate.state.data.html.lastIndexOf("#emty-theme#"))}>
                    <button type={"button"}>Записаться на вебинар</button>
                </a>
            </div>
        ) : (
            <Fragment>
                <div className={"theme-content"}>
                    <h1 className={"title"}>{themeDelegate.state.data ? themeDelegate.state.data.name : ""}</h1>
                    {themeDelegate.state.data && themeDelegate.state.data.html ? (
                        <div
                            dangerouslySetInnerHTML={{__html: themeDelegate.state.data.html.replace("#name#", appDelegate.state.user.name || "John").replace("#lastname#", appDelegate.state.user.last_name || "Smith")}}
                            className={"html-data"}/>
                    ) : ""}
                    {themeDelegate.state.data.exercise && themeDelegate.state.data.exercise.length > 0 ? (
                        <Fragment>
                            <h2>Задания(<span style={{color: "#024feb", cursor: "help"}}
                                              title={"Раздел находится в бета-тестировании. О всех неисправностях работы раздела сообщайте в slack."}>β</span>):
                            </h2>
                            {themeDelegate.state.data.exercise.map((exercise, i) => (
                                <Exercise themeDelegate={themeDelegate} index={i} key={i} exercise={exercise}/>
                            ))}
                        </Fragment>
                    ) : ""}
                </div>

                {themeDelegate.state.practice !== null ? (
                    <ThemePractice exercise={themeDelegate.state.data.exercise[themeDelegate.state.practice]}
                                   themeDelegate={themeDelegate}/>
                ) : ""}

                {themeDelegate.state.data.lecture && themeDelegate.state.data.lecture.length > 0 ?
                    <Video
                        isSubscribed={appDelegate.state.user.subscriptions.length !== 0 && appDelegate.state.user.subscriptions.date}
                        // available={(themeDelegate.state.data && themeDelegate.state.data.free) || (appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.items && appDelegate.state.user.subscriptions.items.includes("videos"))}
                        available={(themeDelegate.state.data && themeDelegate.state.data.free) || appDelegate.state.user.subscriptions !== 0}
                        themeDelegate={themeDelegate}/> : ""}
                {appDelegate.state.anonymous ? (
                    <ADS handler={appDelegate.toggleRegistration}/>
                ) : ""}
                {themeDelegate.state.data.test.length ? <Test isSubscribed={appDelegate.state.user.subscriptions.length !== 0 && appDelegate.state.user.subscriptions.date}
                      appDelegate={appDelegate} themeDelegate={themeDelegate}/> : null}
                {!appDelegate.state.anonymous && themeDelegate.state.data && themeDelegate.state.data.stage && themeDelegate.state.data.stage.level && themeDelegate.state.data.stage.level.course ? (
                    <Messenger appDelegate={appDelegate} id={themeDelegate.state.data.stage.level.course.id}/>
                ) : ""}

                {themeDelegate.state.data && themeDelegate.state.data.practice && themeDelegate.state.data.practice.html ? (
                    <div className={"theme-content"}>
                        <h1 className={"practice-title"}>Практическое задание</h1>
                        <div
                            dangerouslySetInnerHTML={{__html: themeDelegate.state.data.practice.html.replace("#name#", appDelegate.state.user.name || "John").replace("#lastname#", appDelegate.state.user.last_name || "Smith").replace("#id#", appDelegate.state.user.id)}}
                            className={"html-data"}/>
                        <div className={"mission"}>
                            <FileLoader appDelegate={appDelegate} completion={themeDelegate.completion}
                                        token={appDelegate.state.authToken}
                                        resource={`stages/${themeDelegate.state.data.stage.id}/themes/${themeDelegate.state.data.id}/upload?stage_id=${themeDelegate.state.data.stage.id}&level_id=${themeDelegate.state.data.stage.level.id}&course_id=${themeDelegate.state.data.stage.level.course.id}`}/>
                            {themeDelegate.state.data.practice.evaluation && themeDelegate.state.data.practice.evaluation.length > 0 ? (
                                <Fragment>
                                    {themeDelegate.state.data.practice.evaluation.map((evaluation, index) => (
                                        <Evaluation key={index} index={index} evaluation={evaluation}/>
                                    ))}
                                </Fragment>
                            ) : ""}

                        </div>
                    </div>
                ) : ""}

                <Comments themeDelegate={themeDelegate} id={themeDelegate.state.data.id} appDelegate={appDelegate}/>
                {/*<Question*/}
                {/*    appDelegate={appDelegate}*/}
                {/*    allowed={appDelegate.state.user && appDelegate.state.user.subscriptions && appDelegate.state.user.subscriptions.items && appDelegate.state.user.subscriptions.items.includes("tutor")}*/}
                {/*    subscribed={appDelegate.state.user && appDelegate.state.user.subscriptions}/>*/}
                <Navigator query={themeDelegate.props.location.search || ""}
                           isPractice={themeDelegate.isPractice}
                           is_open={themeDelegate.state.data.is_open || !themeDelegate.state.data.test.length}
                           stage_id={themeDelegate.state.data.stage.id}
                           done={themeDelegate.state.data.done || !themeDelegate.state.data.test.length}
                           next={themeDelegate.state.data.next}
                           prev={themeDelegate.state.data.prev}/>
                <Events/>
                <TrustBox/>
            </Fragment>
        )}

    </div>)
}

export default withTheme(Theme)
