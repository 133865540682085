import React, {useEffect} from 'react';
import HeaderCourse from "./Components/HeaderCourse/HeaderCourse";
import WhoIsDeveloper from "./Components/WhoIsDeveloper/WhoIsDeveloper";
import Index from "../../Components/StepsLearnProgramming";
import ProgramLearn from "./Components/ProgramLearn/ProgramLearn";
import CertificatesMainPage from "../MainPage/components/CertificatesMainPage/CertificatesMainPage";
import WhoIsThisProfession from "./Components/WhoIsThisProfession/WhoIsThisProfession";
import {useDispatch, useSelector} from "react-redux";
import {getInfoCourseThunk, getInfoPracticeThunk, getInfoTestThunk} from "../../../redux/aboutCourseReducer";
import Loader from "../../Components/Loader";
import styled from './AboutCourse.module.scss';
import QuestionsAndTariff from "../../Components/QuestionsAndTarif/QuestionsAndTarif";
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import {RootState} from '../../../redux/reducers';
import StepsLearnProgramming from '../../Components/StepsLearnProgramming';

type Props = {
    match: any;
}

const AboutCourse = ({match}: Props) => {
    const dispatch = useDispatch();
    const {
        type,
        image,
        name,
        pre_requirements,
        description,
        main_image,
        theories
    } = useSelector((state: RootState) => state.aboutCourseReducer.infoCourse);

    useEffect(() => {
        if (window.location.href.includes("/about-course")) {
            dispatch(getInfoCourseThunk(match?.params?.id));
        } else if (window.location.href.includes("/about-test")) {
            dispatch(getInfoTestThunk(match?.params?.id));
        } else if (window.location.href.includes("/about-practice")) {
            dispatch(getInfoPracticeThunk(match?.params?.id));
        }
    }, []);

    return <div className={styled.wrapperAboutCourse}>
        <ScrollToTop/>
        <HeaderCourse type={type} image={image} name={name}/>
        <WhoIsDeveloper pre_requirements={pre_requirements} description={description} main_image={main_image}/>
        <StepsLearnProgramming/>
        <ProgramLearn theories={theories}/>
        <CertificatesMainPage/>
        <WhoIsThisProfession/>
        <QuestionsAndTariff/>
    </div>;
};
export default AboutCourse;
