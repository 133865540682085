import React, {Fragment} from "react"
import withReview from "./init"
import Range from "../../Components/Range"
import moment from "moment"



const Review = ({reviewDelegate}) => (
    <div className={"content-main review-page"}>
        <section className={"review-page__reviews"}>

            <div className={"switcher"}>
                <div onClick={() => reviewDelegate.changeSwitcher(true)} className={`item ${reviewDelegate.state.showMy ? "active" : ""}`}>Ваш отзыв</div>
                <div onClick={() => reviewDelegate.changeSwitcher(false)} className={`item ${!reviewDelegate.state.showMy ? "active" : ""}`}>Все отзывы</div>
            </div>
            {reviewDelegate.state.showMy ? (
                <form onSubmit={reviewDelegate.submit} className={"form"}>
                    <div className={"form__item"}>
                        <p>1. Насколько вы удовлетворены этой главой?</p>
                        <Range defaultValue={reviewDelegate.state.mark_1} name={"mark_1"} onChange={reviewDelegate.handlerChange} step={1} min={1} max={10}/>
                    </div>
                    <div className={"form__item"}>
                        <p>2. Насколько материал был полезен?</p>
                        <Range defaultValue={reviewDelegate.state.mark_2} name={"mark_2"} onChange={reviewDelegate.handlerChange}  step={1} min={1} max={10}/>
                    </div>
                    <div className={"form__item"}>
                        <p>3. Насколько материал был понятен?</p>
                        <Range defaultValue={reviewDelegate.state.mark_3} name={"mark_3"} onChange={reviewDelegate.handlerChange} step={1} min={1} max={10}/>
                    </div>
                    <div className={"form__item"}>
                        <p>4. Оцените обратную связь преподавателя</p>
                        <Range defaultValue={reviewDelegate.state.mark_4} name={"mark_4"} onChange={reviewDelegate.handlerChange} step={1} min={1} max={10}/>
                    </div>
                    <div className={"form__item"}>
                        <p>Если у вас есть вопросы или комментарии к главе, то напишите нам здесь и получите ответ на email. Также можете <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => reviewDelegate.changeSwitcher(false)} >посмотреть вопросы и отзывы</span> других студентов. Будем рады, если вы найдете немного времени и оставите свой отзыв.</p>
                        <textarea value={reviewDelegate.state.review} name={"review"} onChange={reviewDelegate.handlerChange} placeholder={"Ваш комментарий увидят все пользователи"} />
                        <button disabled={reviewDelegate.state.loadButton} type={"submit"}>
                            {reviewDelegate.state.loadButton ? (
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48px" height="48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#ffffff" stroke="none">
                                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"/>
                                    </path>
                                </svg>
                            ) : "Отправить"}
                        </button>
                    </div>
                </form>
            ) : (
                <div className={"all-reviews"}>
                    {reviewDelegate.state.data.reviews.length > 0 ? (
                        <Fragment>
                            {reviewDelegate.state.data.reviews.map((item, index) => (
                                <Fragment key={index}>
                                    {item.review ? (
                                        <div className={"comment"} >
                                            <div className={"comment__user"}>
                                                <div style={{ background: `#babbbf url(${item.photo})` }} className={"photo"}>{!item.photo ? item.user[0].toUpperCase() : ""}</div>
                                                <div className={"info"}>
                                                    <h4>{item.user}</h4>
                                                    <p>{moment(item.created_at).format("MMM DD, YYYY")}</p>
                                                </div>
                                            </div>
                                            <div className={"message"}>
                                                {item.review.split("\n").map((p, idx) => (
                                                    <p key={idx}>{p}</p>
                                                ))}
                                            </div>
                                            {item.answer ? (
                                                <div className={"comment answer"}>
                                                    <div className={"comment__user"}>
                                                        <div style={{ background: `#babbbf url(/Images/edu.jpg)` }} className={"photo"} />
                                                        <div className={"info"}>
                                                            <h4>Кот Эдью</h4>
                                                            <p>{moment(item.update_at).format("MMM DD, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"message"}>
                                                        {item.answer.split("\n").map((p, idx) => (
                                                            <p key={idx}>{p}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : ""}
                                        </div>
                                    ) : ""}
                                </Fragment>

                            ))}
                        </Fragment>
                    ) : (
                        <p className={"empty"}>Пока нет ни одного отзыва.</p>
                    )}
                </div>
            )}


            {/*<div className={"bottom-menu"}>*/}
            {/*    <Link to={`/course/${reviewDelegate.state.data.stage.level.course.id}`}>*/}
            {/*        Продолжить обучение*/}
            {/*        <img src="/Images/next-review.svg" alt=""/>*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </section>
    </div>
)


export default withReview(Review)