import React from "react";
import withImageLoader from "./withImageLoader";



const ImageLoader = ({className, imageLoaderDelegate, value  }) => {

    if (value) {
        return (
            <label style={{ backgroundImage: `url(${value})` }} className={`image-loader ${className}`}>
                <input type={"file"} onChange={imageLoaderDelegate.load}/>
                <div className={"image-loader__background"}>
                    <img src="/Images/camera.svg" alt=""/>
                </div>
            </label>
        )
    } else {
        return (
            <label style={{ backgroundColor: `#3c4043`, display: "flex", alignItems: "center", justifyContent: "center" }} className={`image-loader ${className}`}>
                <input type={"file"} onChange={imageLoaderDelegate.load}/>
                <img className={"no-photo"} style={{ width: "46px", margin: 0 }} src="/Images/lapa-yellow.svg" alt=""/>
                <div className={"image-loader__background"}>
                    <img src="/Images/camera.svg" alt=""/>
                </div>
            </label>
        )
    }


};



export default withImageLoader(ImageLoader);