import React from 'react'
import styled from "../AddText.module.scss";

const Input = ({isCorrectTask, changeText, isViewAnswer, passedRightAnswer, index, rightAnswer, addText}) => {
    const handleInputText = (e) => {
        changeText(e.target.textContent)
    }

    return <div contentEditable={!isViewAnswer && isCorrectTask === 0 ? 'true' : 'false'} suppressContentEditableWarning="true"
                className={isCorrectTask === 0
                    ? styled.inputText
                    : isCorrectTask
                        ? styled.inputText + ' ' + styled.isRight
                        : addText[index] !== rightAnswer[index] && styled.inputText + ' ' + styled.isFalse} onInput={handleInputText} placeholder="Введите">
        {isViewAnswer && passedRightAnswer[index]}
    </div>
}

export default Input