import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";


const OfferContract = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('nav.offer');
        window.scrollTo({top: 0, left: 0});
    });
    return (
        <div className={"content-main"}>
            <div className={"html-data"}
                 style={{boxSizing: "border-box", width: "720px", maxWidth: "90%", margin: "auto", padding: "42px 0"}}>
                <h1>{ t('offer.h1') }</h1>
                <p>{ t('offer.intro') }</p>
                <h2>{ t('offer.item1') }</h2>
                <p dangerouslySetInnerHTML={{__html: t('offer.subitem1.1')}}/>
                <p>{ t('offer.subitem1.2') }</p>
                <p>{ t('offer.subitem1.3') }</p>
                <h2>{ t('offer.item2') }</h2>
                <p>{ t('offer.subitem2.1') }</p>
                <p>{ t('offer.subitem2.2') }</p>
                <p>{ t('offer.subitem2.3') }</p>
                <p>{ t('offer.subitem2.4') }</p>
                <p>{ t('offer.subitem2.5') }</p>
                <p>{ t('offer.subitem2.6') }</p>
                <p>{ t('offer.subitem2.7') }</p>
                <p>{ t('offer.subitem2.8') }</p>
                <h2>{ t('offer.item3') }</h2>
                <p>{ t('offer.subitem3.1') }</p>
                <p>{ t('offer.subitem3.2') }</p>
                <p>{ t('offer.subitem3.3') }</p>
                <p>{ t('offer.subitem3.4') }</p>
                <p>{ t('offer.subitem3.5') }</p>
                <h2>{ t('offer.item4') }</h2>
                <p>{ t('offer.subitem4.1') }</p>
                <p>{ t('offer.subitem4.2') }</p>
                <p>{ t('offer.subitem4.3') }</p>
                <h2>{ t('offer.item5') }</h2>
                <p>{ t('offer.subitem5.1') }</p>
                <h2>{ t('offer.item6') }</h2>
                <p>{ t('offer.subitem6.1') }</p>
                <h2>{ t('offer.item7') }</h2>
                <p>{ t('offer.subitem7.1') }</p>
                <h2>{ t('offer.item8') }</h2>
                <p>{ t('offer.company.name') }</p>
                <p>{ t('offer.company.address') }</p>
                <p>{ t('offer.company.inn') }</p>
            </div>
        </div>
    )
};


export default OfferContract;