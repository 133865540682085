import React from "react";
import {Link} from "react-router-dom";



const NotifyBadge = ({ link, title, message, close }) => {
    return (
        <Link onClick={close} to={link} className={"notify-badge"}>
            <div className={"notify-badge__title"}>{title}</div>
            <p>{message.length > 60 ? `${message.slice(0, 60)}...` : message}</p>
        </Link>
    )
};


export default NotifyBadge;