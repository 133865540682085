import React from "react"

const TechnicalWork = () => (
    <div className={"technical-work"}>
    <div className={"technical"}>
        <p>
            Ведутся плановые технические работы до 17:00 по Московскому времени
        </p>
    </div>
    </div>
)
export default TechnicalWork