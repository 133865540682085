import React from "react";
import { authApi } from "api/api";
import { SigninFormValues, UseSigninApiResult } from "../types";

export const useSigninApi = (): UseSigninApiResult => {
  const authSocialMediaApi = React.useCallback( async (payload: SigninFormValues) => {
		return await authApi.signin(payload);
	}, []);

	return authSocialMediaApi;
}