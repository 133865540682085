import React from "react"
import { useLocation, useHistory } from "react-router";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";
import {useTranslation} from "react-i18next";

/**
 * Component that provides authentication via social media providers 
 * such as `google` or `github` to use within `RegistrationRoutes.SIGN_UP` and 
 * `RegistrationRoutes.SIGN_IN` sub-routes
 * @returns Elements to mount in react render engine
 */
function AuthViaSocialMedia(): JSX.Element {
	const { pathname } = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<>
			<h4>
				{ pathname === RegistrationRoutes.SIGN_IN ? `${t('sign.in')} ` : `${t('sign.up')} ` }
				{t('auth.socials')}
			</h4>

			<div className={"social"}>
				<button 
					onClick={() => history.push(`${RegistrationRoutes.SIGN_IN_SOCIAL}/google`)}
					className={"menu-social google"}
				/>
		
				<button 
					onClick={() => history.push(`${RegistrationRoutes.SIGN_IN_SOCIAL}/github`)}
					className={"menu-social git"}
				/>
			</div>

			<h4 className={"or"}>
				{t('auth.or')}
			</h4>
		</>
	);
}

export default AuthViaSocialMedia;