import React, {useEffect} from 'react'
import {useParams} from 'react-router'
import FAQ from '../FAQ/FAQ'
import Payment from '../Payment'
import SwitcherComponent from '../SwitcherComponent/SwitcherComponent'
import Tarif from '../Tarif/Tarif'
import styled from './style.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../../../Components/Loader'
import {questions} from '../FAQ/questions'
import NotSuccessfulBilling from '../NotSuccessfulBilling/NotSuccessfulBilling'
import Congratulations from "../Congratulations/Congratulations";
import {changeTariff, getSubscriptionThunk} from '../../../../../redux/actions/subscription';
import {AppDelegateType} from '../../../../../types/app-delegate'
import {RootState} from '../../../../../redux/reducers'
import {useTranslation} from "react-i18next";

const mockFeatures = {
    title: 'Условия розыгрыша',
    features: [
        `Учебный контент (видео и текстовый)`,
        `Реальные проекты`,
        `Возможность получать ответы`,
        `Доступ к пройденным материалам`,
        `Обучение через тестирование`
    ],
    steps: [
        {
            title: 'Оформите подписку в Educat на 1 месяц или 1 год',
            description: 'К участию в розыгрыше допускаются пользователи, обучающиеся на курсах Educat.',
            description1: '',
            info: {
                text: 'Оформите тарифный план, чтобы участвовать в розыгрыше',
                className: 'linkDescription'
            }
        },
        {
            title: 'Проходите курсы, выполняйте практику и решайте задачи',
            description: 'Будьте увлечены и активны обучением на платформе, мы обязательно оценим ваши старания и отметим их призами.',
            description1: null,
            info: {
                text: 'У вас пройдено _XXX_ курсов и _XXX_ задач',
                className: null
            }
        },
        {
            title: 'Выигрывайте подарки от Educat',
            description: 'Оформляя подписку в Educat, вы автоматически становитесь участником наших розыгрышей.',
            description1: '*Розыгрыш проводится 2 раза за месяц. Для тех, кто приобрел подписку - 1-го числа и для тех, кто достиг прогресса в учёбе - 15-го числа каждого месяца.',
            info: null
        }
    ]
};

type Props = {
    appDelegate: AppDelegateType;
}

const PaymentCard = ({appDelegate}: Props ) => {
    const subscriptions = useSelector((state: RootState) => state.subscriptionReducer);
    const authToken = appDelegate.state?.authToken;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getSubscriptionThunk(authToken))
    }, []);

    const handleTariff = () => {
        dispatch(changeTariff())
    }

    const {step} = useParams<{step?: string}>();


    const renderBlock = () => {
        if (!subscriptions) return <Loader/>
        switch (step) {
            // case `2`: {
                // return <Payment step="2" invertSelection={invertSelection}
                //                 selectedTarif={selectedTarif}
                //                 isSecondSelected={isSecondSelected}
                //                 tarifs={subscriptions.subscriptions}
                //                 authToken={authToken}
                // />;
            // }
            case `3`: {
                return <Payment step="3"
                                authToken={authToken}
                                subscriptions={subscriptions}
                                changeTariff={handleTariff}
                />;
            }

            case `4`: {
                return <Congratulations
                    subscriptions={subscriptions}
                    appDelegate={appDelegate}
                />
            }

            case `5`: {
                return <NotSuccessfulBilling/>
            }

            default: {
                return <>
                    <SwitcherComponent
                        subscriptions={subscriptions}
                        changeTariff={handleTariff}
                    />
                    <Tarif features={mockFeatures.features}
                           subscriptions={subscriptions}
                    />
                </>
            }
        }
    };

    return <div className={styled.wrapper}>
        <h1>
            {t('account.nav.subscription')}
        </h1>
        <p className={styled.parOne}>
            {t('subscription.payed.desc1')}
        </p>
        <p className={styled.parTwo}>
            {t('subscription.payed.desc2')}
            <br/><br/>
            {t('subscription.payed.desc3')}
            <br/><br/>
            {t('subscription.payed.desc4')}
            <br/><br/>
            {t('subscription.payed.desc5')}
        </p>
        {subscriptions.tariff !== null && renderBlock()}

        <FAQ questions={questions}/>
    </div>
}

export default PaymentCard;
