import React, { useState } from 'react';
import s from './FAQ.module.scss'
import {useTranslation} from "react-i18next";

const FAQ = (props) => {
    const [numberOfSelectedItem, setSelectedItem] = useState(null);
    const { t } = useTranslation();
    const createMarkup = (html) => {
        return { __html: html };
    }

    const onClickHandler = (indexOfItem) => {
        numberOfSelectedItem === indexOfItem
        ? setSelectedItem(null)
        : setSelectedItem(indexOfItem)
    };

    return <div className={s.wrapper}>
        <h2>
            {t('faq.title')}
        </h2>
        <ul>
            {props.questions.map((q, i) => <li
                                            key={`FAQ#${i}`}
                                            className={numberOfSelectedItem === i ? '' : s.hidden}>
                <button
                    className={s.expandButton}
                    onClick={() => onClickHandler(i)}>
                </button>

                <h3 onClick={() => onClickHandler(i)}>{ t(q.title) }</h3>

                <div className={s.description}
                    dangerouslySetInnerHTML={createMarkup(t(q.descriptionHTML))} ></div>
            </li>)}
        </ul>
    </div>
}

export default FAQ;
