import React from 'react';
import './Card.css'
import {useDispatch, useSelector} from "react-redux";
import {setActiveThunk} from "../../../../../redux/gameReducer";
import imgCard from '../../img/cat_image.png';

const Card = (props) => {
    const activeCard = useSelector(state => state.gameReducer.activeCard)
    let dispatch = useDispatch()


    const setClassNameCard = () => {
        dispatch(setActiveThunk(props.index, props.id))
    }

    return (
        <div className="scene sceneCard">
            <div className={props.isActive ? "card isFlipped" : "card"} onClick={() => (!props.isActive && (activeCard !== 2)) && setClassNameCard()} aria-disabled={props.isActive && (activeCard !== 2)} >
                <div className="cardFace cardFaceFront">
                    <img className="imgCard" src={imgCard} alt="Угадай-ка!"/>
                </div>
                <div className={props.isGuessed
                    ? "cardFace cardGuessed"
                    :  `cardFace cardFaceBack ${props.classListCard}` }
                ><p className='tags'>{props.tag}</p></div>
            </div>
        </div>)
}

export default Card;