import React from "react";
import { useLocation } from "react-router"

export const useResetPasswordInitialValues = () => {
  const { search } = useLocation();
  const initialValues = React.useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      password: params.get("password") || "",
      repeat : params.get("repeat") || ""
    }
  }, [search]);

  return initialValues;
}