import React from "react";
import config from "../../../etc/config";
import Loader from "../../Components/Loader";
import ErrorPage from "../ErrorPage";

const withResultPurchase = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                result: null,
                errorPage: null
            };
        }

        componentDidMount() {
            document.title = "Purchase course";
            window.scrollTo({top: 0, left: 0});
            console.log(this.getQueryParams());

            this.setState({ result: this.props.match.params.result });

            if (this.props.match.params.result !== "pending") {
                this.getData()
            }

        }


        getData = () => {
            fetch(`${config.API_PREFIX}purchased/${this.getQueryParams().ik_pm_no}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500){
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    this.setState({ data: res })
                })
                .catch(err => {
                    console.log(err);
                })
        };


        getQueryParams = () => {
            const params = {};
            const  array = this.props.location.search.substr(1).split("&");

            for (let i = 0; i < array.length; i++) {
                params[array[i].split("=")[0]] = array[i].split("=")[1];
            }

            return params;
        };


        render() {
            if ((!this.state.data || this.state.result === "pending") && !this.state.errorPage)
                return <Loader/>
            else  if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage} />
            }
            else
                return <Component resultDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withResultPurchase(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withResultPurchase;