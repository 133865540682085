import React from "react"
import config from "etc/config"

type AuthFormProps = React.PropsWithChildren<{
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isInValid: boolean;
}>;

function AuthForm({ children, isInValid, onSubmit }: AuthFormProps): JSX.Element {
  return (
    <div className={"registration-page"}>
      <div className={"registration-page__content"}>
        <a href={`${config.MAIN_URL}`} className={"picture"}>
          <img src="/Images/cat-tmt.png" alt={"😼"}/>
        </a>
        <form
          className={isInValid ? "unauthorized" : ""}
          onSubmit={onSubmit}
        >	
          { children }
        </form>
      </div>
    </div>
  );
}

export default AuthForm