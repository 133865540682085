import {subscriptionsAPI} from '../../api/api'
import {AppDispatch} from '../store';

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const PROGRESS_BILLING = 'PROGRESS_BILLING';
export const CHANGE_IS_LOADING_FEEDBACK = 'CHANGE_IS_LOADING_FEEDBACK';
export const SET_STATUS_FEEDBACK = 'SET_STATUS_FEEDBACK';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_CARDS = 'SET_CARDS';
export const DELETE_AUTO_SUBSCRIPTION = 'DELETE_AUTO_SUBSCRIPTION';
export const CHANGE_TARIFF = 'CHANGE_TARIFF';

const setSubscriptions = (data: Array<object>) => {
    return {
        type: SET_SUBSCRIPTIONS,
        payload: {...data}
    }
}

export const changeTariff = () => {
    return {
        type: CHANGE_TARIFF
    }
}

const changeProgressBilling = (isStatus: boolean) => {
    return {
        type: PROGRESS_BILLING,
        payload: isStatus
    }
}

export const setIsStatusFeedback = (status: number) => {
    return {
        type: SET_STATUS_FEEDBACK,
        payload: status
    }
}

export const changeLoadingFeedback = (isProgress: boolean) => {
    return {
        type: CHANGE_IS_LOADING_FEEDBACK,
        payload: isProgress
    }
}

const setInvoices = (data: object) => {
    return {
        type: SET_INVOICES,
        payload: {...data}
    }
}

const setCards = (cards: Array<object>) => {
    return {
        type: SET_CARDS,
        payload: cards
    }
}

const deleteAutoSubscription = () => {
    return {
        type: DELETE_AUTO_SUBSCRIPTION
    }
}


export const getSubscriptionThunk = (authToken: string) => {
    return async (dispatch: AppDispatch) => {
        let response = await subscriptionsAPI.getSubscriptions(authToken)
        try {
            dispatch(setSubscriptions(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const billingStepProcess = (status: boolean) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeLoadingFeedback(true));
        try {
            dispatch(changeProgressBilling(status))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoadingFeedback(false))
        }
    }
}

export const sendStripeThunk = (authToken: string, token_id: string, product_id: number, price_id: number) => {
    return async (dispatch: AppDispatch) => {

        dispatch(changeLoadingFeedback(true))
        let response = await subscriptionsAPI.sendStripe(authToken, token_id, product_id, price_id)

        try {
            dispatch(changeProgressBilling(response.data.status))
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(changeLoadingFeedback(false));
        }
    }
}

export const sendDataFeedbackThunk = (authToken: string, name: string, phone: string, email: string, message: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(changeLoadingFeedback(true))
        let response = await subscriptionsAPI.sendDataFeedback(authToken, name, phone, email, message)
        try {
            dispatch(setIsStatusFeedback(response.data.status))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getInvoicesThunk = (authToken: string) => {
    return async (dispatch: AppDispatch) => {
        let response = await subscriptionsAPI.getInvoices(authToken)
        try {
            dispatch(setInvoices(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getCardsThunk = (authToken: string) => {
    return async (dispatch: AppDispatch) => {
        let response = await subscriptionsAPI.getCards(authToken)
        try {
            dispatch(setCards(response.data.cards))
        } catch (error) {
            console.log(error)
        }
    }
}

export const deleteAutoSubscriptionThunk = (authToken: string, id_card: string) => {
    return async () => {
        await subscriptionsAPI.deleteAutoSubscriptions(authToken, id_card)
        try {
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
}
