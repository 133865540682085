import React from 'react'
import styled from './WhoIsDeveloper.module.scss'
import {CourseType} from '../../../../../types/course';

const WhoIsDeveloper = ({pre_requirements, description, main_image}: CourseType) => {
    return <div className={styled.wrapperWhoIsDeveloper}>
        {pre_requirements && <div className={styled.requirements}>
            Необходимые навыки: <span>{pre_requirements}</span>
        </div>}
        <div className={styled.content}>
            <div className={styled.left}>
                <h2>Описание:</h2>
                <p>{description}</p>
            </div>
            <div className={styled.right}>
                <img src={main_image} alt="logo"/>
            </div>
        </div>
    </div>
}

export default WhoIsDeveloper
