import React from 'react';
import style from './styled.module.scss';
import catLogo from '../../img/catLogo.png';

type Props = {
    progressTheme?: number;
}

const ProgressCourse = ({progressTheme}: Props) => {
    return (<div className={style.wrapperProgress}>
        <div className={style.levelProgress} style={{width: progressTheme + '%'}}>
            <img src={catLogo} alt="Cat"/>
        </div>
    </div>);
};

export default ProgressCourse;
