import React, {useEffect} from 'react'
import styled from '../Payment/style.module.scss'
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import s from './style.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import PATH from '../pathConst'
import {useHistory} from 'react-router'
import Loader from "../../../../Components/Loader";
import {billingStepProcess, sendStripeThunk} from '../../../../../redux/actions/subscription';
import {RootState} from '../../../../../redux/reducers'
import {subscriptionsAPI} from "../../../../../api/api";
type Props = {
    tariff: any;
    authToken: string;
}

const ContactInfo = ({tariff, authToken}: Props) => {

    const stripe: any = useStripe()
    const elements: any = useElements()
    const statusBilling = useSelector((state: RootState) => state.subscriptionReducer.isStatus)
    const isLoading = useSelector((state: RootState) => state.subscriptionReducer.isLoadingFeedback)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (statusBilling === 0) {
            let path = `4`
            history.push(path)
        } else if (statusBilling === 1) {
            let path = '5'
            history.push(path)
        }
    }, [statusBilling])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        let status: any = 1;
        const cardElement = elements.getElement(CardNumberElement);
        const payload = await stripe.createToken(cardElement);
        let response = await subscriptionsAPI.sendStripe(authToken, payload.token.id, tariff.id, tariff.id);
        if (response.data.data.latest_invoice.payment_intent.status == 'requires_action') {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                response.data.data.latest_invoice.payment_intent.client_secret
            );
            status = (paymentIntent && paymentIntent.status == 'succeeded') ? 0:1;
        } else {
            status = 0;
        }
        dispatch(billingStepProcess(status));
    }

    return !isLoading ? <>
        <div className={s.cardWrapper}>
            <form>
                ????? ?????
                <CardNumberElement className={s.cardNumber}/>
                <div className={s.expiryAndCvc}>
                    <div>
                        ???? ????????
                        <CardExpiryElement className={s.cardExpiry}/>
                    </div>
                    <div>
                        CVC
                        <CardCvcElement className={s.cardCVC}/>
                    </div>
                </div>
                <div>
                    ??? ???????
                    <input type="text" placeholder="???? ??????" className={s.cardPerson}/>
                </div>
            </form>

            <div className={s.checkout}>
                <div className={s.checkoutTitle}>? ??????<br/>
                    <span>????? {tariff.title}</span>
                </div>
                <div className={s.price}>{tariff.price} $</div>
            </div>
        </div>
        <div className={styled.bottomButtons}>
            <div className={styled.buttonBack}><Link to={`${PATH}`}>?????????</Link></div>
            <div className={styled.buttonSubmit}><a onClick={handleSubmit}>????????</a></div>
        </div>
    </> : <Loader/>
};

export default ContactInfo;
