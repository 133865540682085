import React from 'react'
import style from './styled.module.scss'
import Dialog from '@material-ui/core/Dialog'
import {useDispatch} from "react-redux"
import LevelPassed from "./components/LevelPassed"
import {getTestForDescription} from '../../../../../redux/actions/description';
import {TestItem} from '../../../../../types/tests';
import {closePageSuccessPassedTheme, getTestThunk, setCurrentTask} from '../../../../../redux/actions/tests';

type Props = {
    progressTheme: number;
    isShowEndPageLevel: boolean;
    titleCurrentTheme: TestItem;
    authToken: string;
    id: number;
    handleShowTask: (value: boolean) => void;
}

const EndPageLevel = ({progressTheme, isShowEndPageLevel, titleCurrentTheme, authToken, id, handleShowTask}: Props) => {
    const dispatch = useDispatch()

    const closePagePassedTheme = () => {
        dispatch(closePageSuccessPassedTheme())
        dispatch(getTestForDescription(authToken, id))
        dispatch(setCurrentTask())
        handleShowTask(false)
        dispatch(getTestThunk(id, authToken))
    }

    const closeEndPageTest = () => {
        dispatch(closePageSuccessPassedTheme())
        dispatch(getTestForDescription(authToken, id))
    }

    return (
        <Dialog fullScreen open={isShowEndPageLevel} onClose={closeEndPageTest}>
            <div className={style.wrapper}>
                <LevelPassed progressTheme={progressTheme}
                             closePassed={closePagePassedTheme}
                             title={titleCurrentTheme.name}
                />
            </div>
        </Dialog>
    );
}

export default EndPageLevel
