import React, {useState} from 'react';
import s from './style.module.scss';
import AskedQuestions from "../../../../Components/AskedQuestions";
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../../../Components/Loader';
import classnames from 'classnames';
import SwitcherSubscription from '../SwitcherSubscription';
import {changeTariff} from '../../../../../redux/actions/subscription';
import {RootState} from '../../../../../redux/reducers';
import {questionsBilling} from '../FAQ/questions';
import {useTranslation} from "react-i18next";

const FreeSubscription = () => {
    const {subscriptions, currentTariff} = useSelector((state: RootState) => state.subscriptionReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isHint, setIsHint] = useState(false);

    const handleClick = () => setIsHint(!isHint);

    return subscriptions.length
        ? <div className={s.wrapper}>
            <div className={s.container}>
                <div className={s.headText}>
                    <h1>
                        {t('subscription.free.title')}
                    </h1>
                    <p className={s.parOne}>
                        {t('subscription.free.desc')}
                    </p>
                </div>

                <div className={s.hintOne}>
                    {isHint && <div className={s.hint1}/>}
                    {!isHint && <button className={s.knowHow} onClick={handleClick}>{t('subscription.btn.how')}</button>}
                    {isHint && <a href={'/conditions'}>
                        <button className={s.moreHow}>
                            {t('subscription.btn.more')}
                        </button>
                    </a>}
                </div>
                <div className={s.contentSubscriptions}>
                    <SwitcherSubscription
                        switcher={!!currentTariff}
                        handlerSwitcher={() => dispatch(changeTariff())}
                    />
                </div>

                <div className={s.choiceBlock}>
                    <div className={s.month}>
                        <h4 className={s.educatPro}>EDUCAT PRO:</h4>
                        <div className={classnames(s.tariffBlock, {
                            [s.tariffActive]: currentTariff
                        })}>
                            <p>
                                {t('subscription.month.title')}
                            </p>
                            <div className={s.tariffPlan}>
                                <h4>{subscriptions[0].prices[1].title}</h4>
                                <span>9.99 $</span>
                            </div>
                            <span className={s.yearTariff}>
                                {t('subscription.month.desc')}
                            </span>
                        </div>
                    </div>
                    <div className={s.year}>
                        <h4 className={s.educatPro}>EDUCAT PRO:</h4>
                        <div className={s.trialPeriod}>
                            {t('subscription.trial')}
                        </div>
                        <div className={s.trialPeriod}>
                            {t('subscription.profit')} {Math.floor(99.99 / 12)} $ / {t('subscription.duration')}
                        </div>
                        <div className={classnames(s.tariffBlock, {
                            [s.tariffActive]: !currentTariff
                        })}>
                            <p>
                                {t('subscription.year.cta')}
                            </p>
                            <div className={s.tariffPlan}>
                                <h4>{subscriptions[0].prices[0].title}</h4>
                                <span>
                                    <span className={s.discount}>119.88 $</span>
                                    99.99 $
                                </span>
                            </div>
                            <span className={s.yearTariff}>
                                {t('subscription.year.desc')}
                            </span>
                        </div>
                    </div>
                </div>
                <h4 className={s.titleList}>
                    {t('subscription.props.title')}
                </h4>
                <ol>
                    <li>
                        {t('subscription.props1')}
                    </li>
                    <li>
                        {t('subscription.props2')}
                    </li>
                    <li>
                        {t('subscription.props3')}
                    </li>
                    <li>
                        {t('subscription.props4')}
                    </li>
                </ol>
                <div className={s.buyTariff}>
                    <a href={'/payment'}>
                        <button>
                            {t('subscription.btn.buy')}
                        </button>
                    </a>
                    {isHint && <div className={s.hint2}/>}
                    {isHint && <div className={s.hint3}>
                        <a href={'/conditions'}>
                            {t('')}
                        </a>
                    </div>}
                </div>
                <p className={s.note}>*
                    {t('subscription.note')}
                </p>
            </div>
            <div className={s.imgRoulette}/>
            <AskedQuestions questions={questionsBilling} />
        </div>
        : <Loader/>;
};

export default FreeSubscription;
