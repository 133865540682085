import React from "react";
import Loader from "../../../Components/Loader";
import config from "../../../../etc/config";
import ErrorPage from "../../ErrorPage";
import moment from "moment"

const withTheme = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);

            this.state = {
                data: null,
                practice: null,
                loader: {
                    href: null,
                    download: null
                },
                errorPage: null,
                doneTests: []
            }
        }

        componentDidMount() {
            this.getData();

            if (window.jivo_is_initialize) {
                window.destroyChat()
            } else {
                document.addEventListener("jivoOnLoad", window.destroyChat)
            }

            document.title = "Index...";
            window.scrollTo({top: 0, left: 0});
        }


        get isPractice() {
            return this.props.location.pathname.includes("practice-theme")
        }



        commentCompletion = (comment) => {
            const {data} = this.state;
            const { created_at, review, id } = comment
            const { user } = this.props.appDelegate.state;
            const item = { created_at, review, id }
            if(user && user.name)
                item.user = user.name

            if(user && user.photo)
                item.photo = user.photo

            data.reviews.unshift(item)

            this.setState({data})
        }


        setDoneTest = (id) => {
            const {doneTests, data} = this.state;
            const idx = doneTests.findIndex(x => x.id === id);
            if (idx > -1) {
                doneTests[idx].done = true;
            }

            const testEnable = this.props.appDelegate.state.user && this.props.appDelegate.state.user.subscriptions && this.props.appDelegate.state.user.subscriptions.items && this.props.appDelegate.state.user.subscriptions.items.includes("tests")

            if ((testEnable && doneTests.findIndex(x => x.done === false) === -1) || (!testEnable && doneTests.findIndex(x => x.done === false && x.free === true) === -1)) {
                data.done = true;
                this.setState({data, doneTests});
            } else {
                this.setState({ doneTests });
            }

        };


        componentWillReceiveProps(nextProps, nextContext) {
            if (this.props.match.params.id !== nextProps.match.params.id) {
                window.scrollTo({top: 0, left: 0});
                this.setState({
                    data: null
                });
                this.getData(nextProps.match.params.id);
            }
        }


        togglePractice = (practice) => {
            this.setState({ practice, loader: {
                    href: null,
                    download: null
                } }, () => {
                document.body.style.overflowY = this.state.practice ? "hidden" : "auto";
            })
        };

        completion = (res) => {
            const evaluation = {
                comment: null,
                date: moment().format("YYYY-MM-DD"),
                file: res.url,
                state: "pending"
            };

            const {data} = this.state;

            if (!data.practice)
                data.practice = {evaluation: []}

            if (!data.practice.evaluation)
                data.practice.evaluation = [];

            data.practice.evaluation.unshift(evaluation);

            this.setState({data});

            this.props.appDelegate.setPopupAlert({ message: "В ближайшее время мы его проверим и отправим уведомление вам на почту.", title: "Домашнее задание отправленно на проверку", handler: () => this.props.appDelegate.setPopupAlert(null), buttonText: "Отлично", close: () => this.props.appDelegate.setPopupAlert(null) })

        };



        createFileForLocale = (data, type, ext) => {
            const file = new Blob([data], { type });
            const href = URL.createObjectURL(file);
            const download = `${this.state.data.name.replace(" ", "-")}-task${this.state.practice}.${ext}`;
            this.setState({ loader: { href, download } });

        };


        getData = (id) => {
            fetch(`${config.API_PREFIX}${ this.isPractice ? "practics/theme" : "themes" }/${id || this.props.match.params.id}${this.props.location.search || ""}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.props.history.push("/error")
                        this.setState({errorPage: res.status});
                        throw new Error(res.statusText);
                    }

                    return res.json();
                })
                .then(res => {
                    let doneTests = [];
                    if (res.test) {
                        for (const test of res.test) {
                            doneTests.push({ id: test.id, done: (test.done || test.attempts > 0) })
                        }
                    }
                    this.props.appDelegate.setAlert();
                    this.setState({ data: res, doneTests });
                    document.title = res.name
                })
                .catch(err => {
                    console.log(err);
                })
        };

        componentWillUnmount() {
            document.removeEventListener("jivoOnLoad", window.destroyChat)
        }

        render() {
            if (this.state.data && !this.state.errorPage) {
                return <Component themeDelegate={this} {...this.props} />
            } else  if (this.state.errorPage) {
                return this.props.location.pathname.includes("billing")
            } else {
                return <Loader />
            }

        }
    }

    HOC.displayName = `withTheme(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withTheme;
