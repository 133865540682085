import config from "../etc/config";

const SET_WEBINARS = 'SET_WEBINARS';
const SORT_WEBINARS = 'SORT_WEBINARS';

let initialState = {
    initialVideos: null,
    videos: null,
    categories: ['Все', 'HTML', 'CSS', 'JavaScript']
}

const videosReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WEBINARS: {
            return {
                ...state,
                videos: action.videos,
                initialVideos: action.videos
            }
        }
        case SORT_WEBINARS: {
            if (action.category === 'Все') {
                return {
                    ...state,
                    videos: state.initialVideos
                }
            } else {
                return {
                    ...state,
                    videos: state.initialVideos.filter(({title}) => title.includes(action.category))
                }
            }
        }
        default:
            return state;
    }
}

const setWebinars = ({videos}) => {
    return {
        type: SET_WEBINARS,
        videos
    }
}

export const sortWebinars = (category) => {
    return {
        type: SORT_WEBINARS,
        category
    }
}

export const getWebinarsForDescription = (authToken) => {
    return (dispatch) => {
        fetch(`${config.API_GAME}web/questions/videos`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        }).then(res => res.json())
            .then(result => dispatch(setWebinars(result)))
            .catch(err => {
                console.log(err);
            })
    }
}

export default videosReducer;