import React from "react"

/**
 * Hook to set document's title on component mount
 * @param title - page title to set
 */
export const useDocumentTitle = (title: string): void => {
  React.useEffect(() => {
    document.title = title;
  }, [title]);
}