import {CHANGE_LOADER_FOR_DESCRIPTION, SET_CURRENT_COURSE} from '../../constants'
import {CourseType} from '../../../types/description'
import {AnyAction} from 'redux'

let initialState: CourseType = {
    icon: '',
    title: '',
    levels: [],
    progress: null,
    questions: 0,
    videos: 0,
    comments: 0,
    id: 0,
    is_open: false,
    isLoading: false
}

const descriptionReducer = (state = initialState, {type, payload}: AnyAction): CourseType => {
    switch (type) {
        case SET_CURRENT_COURSE: {
            return {
                ...state,
                ...payload
            }
        }
        case CHANGE_LOADER_FOR_DESCRIPTION: {
            return {
                ...state,
                isLoading: payload
            }
        }
        default:
            return state;
    }
}

export default descriptionReducer;
