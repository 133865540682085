import React from "react"
import { Link, useLocation } from "react-router-dom";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes"
import {useTranslation} from "react-i18next";

function AuthModeToggler(): JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <div className={"top-button"}>
      <Link
        to={RegistrationRoutes.SIGN_IN}
        className={ `top ${pathname === RegistrationRoutes.SIGN_IN ? 'active' : ''}` }
      >
        {t('sign.in')}
      </Link>
      <Link 
        to={ RegistrationRoutes.SIGN_UP }
        className={ `top ${pathname === RegistrationRoutes.SIGN_UP ? 'active' : ''}` }
      >
        {t('sign.up')}
      </Link>
    </div>
  );
}

export default AuthModeToggler;