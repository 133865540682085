import React from 'react'
import withVacancy from "./HOCs/init";
import PopUp from "./Components/PopUp";
import {useTranslation} from "react-i18next";

const Vacancy = ({vacancyDelegate}) => {
    const { t } = useTranslation();
    return (<main className={"vacancy-page content-main"}>
        {vacancyDelegate.state.successResponded ? (
            <div className={"notify"}>
                <div className={"notify__box"}>
                    <div className={"left"}>
                        <p className={"sent"}>
                            {t('vacancy.notify.title')}
                        </p>
                        <p className={"info"}>
                            {t('vacancy.notify.desc')}
                        </p>
                    </div>
                    <div onClick={() => vacancyDelegate.setPopup("message")} className={"apply"}>
                        {t('vacancy.notify.btn')}
                    </div>
                </div>
            </div>
        ) : ""}
        <div className={"vacancy-page__content"}>
            <h2>Вакансия</h2>
            <div className={"vacancy"}>
                <div className={"vacancy__left"}>
                    <div className={"name"}>{vacancyDelegate.state.data.position}</div>
                    <div className={"company"}>
                        {vacancyDelegate.state.data.company.name}
                        <p>{vacancyDelegate.state.data.company.address}</p>
                    </div>
                    {vacancyDelegate.state.data.requests.length > 0 ? (
                        <div className={"already-responded"}>
                            {t('vacancy.responded')}
                        </div>
                    ) : ""}
                    <div className={"description"}
                         dangerouslySetInnerHTML={{__html: vacancyDelegate.state.data.description}}>
                    </div>
                </div>
                <div className={"vacancy__right"}>
                    <div className={"logo"}
                         style={{backgroundImage: `url(${vacancyDelegate.state.data.company.logo})`}}
                    />
                    <button onClick={() => {
                        if (vacancyDelegate.state.data.requests.length > 0) {
                            vacancyDelegate.setPopup("info")
                        } else {
                            vacancyDelegate.responseVacancy()
                        }
                    }} className={vacancyDelegate.state.data.requests.length > 0  ? "button active" : "button"}>
                        {vacancyDelegate.state.loading ? t('vacancy.loading') : vacancyDelegate.state.data.requests.length > 0 ? t('vacancy.watch') : t('vacancy.respond')}
                    </button>
                    <div className={"requirement"}>
                        <label>
                            {t('vacancy.exp')}
                        </label>
                        <p className={"descrip"}>{vacancyDelegate.state.data.experience}</p>
                    </div>
                    <div className={"requirement"}>
                        <label>
                            {t('vacancy.employment')}
                        </label>
                        <p className={"descrip"}>{vacancyDelegate.state.data.employment}</p>
                    </div>
                    <div className={"requirement"}>
                        <label>
                            {t('vacancy.salary')}
                        </label>
                        <p className={"descrip"}>{vacancyDelegate.state.data.salary} Р</p>
                    </div>
                    <div className={"requirement"}>
                        <label>
                            {t('vacancy.requirements')}
                        </label>
                        <p className={"technology"}>
                            {vacancyDelegate.state.data ? vacancyDelegate.state.data.skills.map((item, index) => (
                                <span key={index}> {item}</span>
                            )): ""}
                        </p>
                    </div>
                </div>
            </div>

        </div>
        {vacancyDelegate.state.popup ? <PopUp vacancyDelegate={vacancyDelegate}/> : ""}
    </main>)
}
export default withVacancy(Vacancy)