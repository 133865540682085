import React, {useState} from 'react'
import styled from './HeaderAndCategory.module.scss'
import {useDispatch} from 'react-redux'
import {
    returnAllCourses,
    sortCategoryCoursesAction,
    sortComplexityCoursesAction
} from '../../../redux/allCoursesReducer'
import arrowIcon from '../../Pages/Description/img/arrow.png'
import {
    returnAllPractices,
    sortCategoryPracticesAction,
    sortComplexityPracticesAction
} from '../../../redux/practicesReducer'
import {useTranslation} from "react-i18next";


const HeaderAndCategory = ({categories, complexities, title, text = null, type}) => {
    const dispatch = useDispatch()
    const [currentIndexCategory, setCurrentIndexCategory] = useState(0)
    const [currentIndexComplexity, setCurrentIndexComplexity] = useState(null)
    const [complexitiesShow, setComplexitiesShow] = useState(false)
    const { t } = useTranslation();

    const titleComplexities = [t('header.difficulty1'), t('header.difficulty2'), t('header.difficulty3'), t('header.difficulty4')];
    const sortCategory = (id, index) => {
        if (id === 0) {
            type === 'courses' ? dispatch(returnAllCourses()) : dispatch(returnAllPractices())
        } else {
            type === 'courses' ? dispatch(sortCategoryCoursesAction(id)) : dispatch(sortCategoryPracticesAction(id))
            setCurrentIndexComplexity(null)
        }
        setCurrentIndexCategory(index)
    }

    const handleComplexitiesShow = () => {
        setComplexitiesShow(prev => !prev)
    }

    const sortComplexity = (id, index) => {
        type === 'courses' ? dispatch(sortComplexityCoursesAction(id)) : dispatch(sortComplexityPracticesAction(id))
        setCurrentIndexComplexity(index)
        setCurrentIndexCategory(0)
    }

    return <div className={styled.wrapper}>
        <h1>{title}</h1>
        {/*{text && <p>{text}</p>}*/}
        <div className={styled.sorting}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>
                    {t('header.sort')}
                </span>
                <div className={styled.categories}>
                    {categories.map(({name, id}, index) => <div
                        className={currentIndexCategory !== index ? styled.category : `${styled.category} ${styled.activeCategory}`}
                        key={id}
                        onClick={() => sortCategory(id, index)}
                    >{name}</div>)}
                </div>
            </div>
            <div className={styled.complexities}>
                <span className={styled.titleComplexities} onClick={handleComplexitiesShow}>
                    {t('header.difficulty.title')} <img className={complexitiesShow && styled.activeIcon} src={arrowIcon} alt=">"/>
                </span>
                {complexitiesShow && <div className={styled.containerComplexities} onClick={handleComplexitiesShow}>
                    {complexities.map(({id}, index) => <span
                        className={index === currentIndexComplexity && styled.activeComplexity}
                        key={id}
                        onClick={() => sortComplexity(id, index)}
                    >{titleComplexities[id - 1]}</span>)}
                </div>}
            </div>
        </div>
    </div>
}

export default HeaderAndCategory