import React from "react";
import withFileLoader from "./withFileLoader";

const FileLoader = ({ fileLoaderDelegate, appDelegate }) => {
    return (<div className={"file-loader"}>
        <input
            // onClick={e => {
            // if (!appDelegate.state.user || appDelegate.state.user.phone_verified !== "Verified") {
            //     e.preventDefault();
            //     appDelegate.togglePhoneValidator();
            // }}}
            onChange={fileLoaderDelegate.handlerChange} type="file" id={"file-loader"} style={{display: "none"}}/>
        {fileLoaderDelegate.state.formData ? (
            <div className={"to-send"}>
                <div className={"left"}>
                    <img src={"/Images/paper.svg"} alt={""}/>
                    <div className={"job text"}>{fileLoaderDelegate.state.fileName}</div>
                </div>
                <div onClick={() => {
                    if (!fileLoaderDelegate.state.loading)
                        fileLoaderDelegate.loadFile()
                }} className={"button"}>{
                    fileLoaderDelegate.state.loading ? (
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#ffffff" stroke="none">
                                <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"/>
                            </path>
                        </svg>
                    ) : "Отправить"
                }</div>
            </div>
        ) : (
            <label htmlFor={"file-loader"} className={"download"}>
                <img src={"/Images/plus.svg"} alt={""}/>
                <p className={"file text"}>Загрузить файл</p>
            </label>
        )}
    </div>)
}


export default withFileLoader(FileLoader)
