import React from "react";
import withResizableTextField from "./withResizableTextField";


const ResizableTextField = ({name, value, placeholder, delegate, handlerChange}) => {

    return (
        <div ref={delegate.container} style={{
            width: "100%",
            backgroundColor: "#ffffff",
            boxSizing: "border-box",
            padding: "20px 24px",
            borderTop: "1px solid rgb(202, 203, 204)",
            position: "absolute",
            bottom: 0,
            right: 0
        }} className={"resize-textarea"}>
            <textarea ref={delegate.ref}
                      rows={1}
                      name={name}
                      placeholder={placeholder}
                      style={{
                          resize: "none",
                          outline: "none",
                          width: "100%",
                          overflow: "hidden",
                          paddingBottom: "12px",
                          fontSize: "16px",
                          border: "1px solid rgb(202, 203, 204)",
                          lineHeight: 1.5,
                          fontFamily: "GolosTextWebRegular, sans-serif",
                          padding: "16px 20px",
                          boxSizing: "border-box"
                      }}/>
        </div>
    )
};


export default withResizableTextField(ResizableTextField);