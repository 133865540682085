import {infoCourseAPI} from "../api/api";

const SET_INFO_COURSE = 'SET_INFO_COURSE';
const SET_INFO_TEST = 'SET_INFO_TEST';
const SET_INFO_PRACTICE = 'SET_INFO_PRACTICE';

let initialState = {
    infoCourse: null
}

const aboutCourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INFO_COURSE: {
            return {
                ...state,
                infoCourse: action.course
            }
        }
        case SET_INFO_TEST: {
            return {
                ...state,
                infoCourse: action.test
            }
        }
        case SET_INFO_PRACTICE: {
            return {
                ...state,
                infoCourse: action.practice
            }
        }
        default:
            return state;
    }
}

const setInfoCourse = (course) => {
    return {
        type: SET_INFO_COURSE,
        course
    }
}

const setInfoTest = (test) => {
    return {
        type: SET_INFO_TEST,
        test
    }
}

const setInfoPractice = (practice) => {
    return {
        type: SET_INFO_PRACTICE,
        practice
    }
}

export const getInfoCourseThunk = (id) => {
    return async (dispatch) => {
        let response = await infoCourseAPI.getInfoCourse(id)
        try {
            dispatch(setInfoCourse(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getInfoTestThunk = (id) => {
    return async (dispatch) => {
        let response = await infoCourseAPI.getInfoTest(id)
        try {
            dispatch(setInfoTest(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const getInfoPracticeThunk = (id) => {
    return async (dispatch) => {
        let response = await infoCourseAPI.getInfoPractice(id)
        try {
            dispatch(setInfoPractice(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export default aboutCourseReducer;