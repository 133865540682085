import React, {useCallback} from 'react';
import { Link, useHistory } from 'react-router-dom';
import s from './Card.module.scss';
import {useTranslation} from "react-i18next";

const Card = ({ practic, appDelegate }) => {
    const { t } = useTranslation();

    let ending = ``;
    if (practic.taskCount % 10 === 1) {
        ending = t('practices.task.ending1');
    } else if (practic.taskCount % 10 > 1 && practic.taskCount % 10 < 5) {
        ending = t('practices.task.ending2');
    } else {
        ending = t('practices.task.ending3');
    }

    const Complexity = [``, t('header.difficulty1'), t('header.difficulty2'), t('header.difficulty3'), t('header.difficulty4')];
    const history = useHistory();
    const redirectToPractics = useCallback(() => {
        appDelegate.state.user?.subscriptions.length !== 0
            ? history.push(`/description/practices/${practic.id}`)
            : history.push(`/billing`)
    }, []);

    return <div className={`
        ${s.wrapper} 
        ${practic.in_process ? s.book : ''}
        ${practic.done ? s.done : ''}
        ${practic.enabled ? s.blocked : ''}
        `}>
        <div className={s.image} style={{ backgroundImage: `url(${practic.image})` }}></div>
        <p className={s.description}>
            <span className={s.title}>{practic.name}</span>
            {practic.description}
        </p>
        {practic.done
            ? <div className={s.info}>
                <span className={s.seeCertificate}>
                    {t('practices.certificate')}
                </span>
            </div>
            : <div className={s.info}>
                <div>
                    {practic.taskCount} {t('practices.task')}{ending}
                </div>
                <div className={`${s.complexity} ${s[`complexity${practic.complexity}`]}`}>
                    {Complexity[practic.complexity]}
                </div>
            </div>
        }
        <div className={s.linkAndButton}>
            <Link to={`/about-practice/${practic.id}`}>
                {t('practices.title.desc')}
            </Link>
            <button
                onClick={redirectToPractics}>
                {practic.in_process
                    ? t('practices.btn.continue')
                    : practic.done
                        ? t('practices.btn.reset')
                        : t('practices.btn.start')}
            </button>
        </div>
    </div>
}

export default Card;