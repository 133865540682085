import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";

const SupportBoxMessenger = ({accountDelegate, data, appDelegate}) => {

    const[active, toggleActive] = useState(data.enabled)
    const[checked, toggleChecked] = useState(data.enabled)
    const[selected, setSelected] = useState([...data.messengers.filter(x => x.enabled).map(x => x.id)])
    const[tick, toggleTick] = useState(data.main_phone || "")
    const { t } = useTranslation();


    useEffect(() => {
        // Надо проверить глобально активный или нет чекбокс
        toggleChecked(data.enabled)
        toggleTick(appDelegate.state.user.phone_messenger === 1)
    }, [])

    return (
        <div onClick={() => toggleActive(!active)} className={active ? "support-box active" : "support-box"}>
            <div className={"support-box__head"}>
                <div className="head-left">
                    <img src="./Images/arrow-black.svg"/>
                    <div className="name-support">
                        {t('support.messenger.title')}
                    </div>
                </div>
                <div onClick={e => e.stopPropagation()} className="switch">
                    <input checked={checked} onChange={e => {
                        toggleActive(e.target.checked)
                        toggleChecked(e.target.checked)
                        accountDelegate.toggleSupportItems(data.id, e.target.checked)
                    }} type="checkbox" id={"support-messenger"}/>
                    <label htmlFor="support-messenger" className={"switch-label"}/>
                </div>
            </div>
            <div onClick={e => e.stopPropagation()} className="support-box__info">
                <div className={!checked ? "line disabled" : "line"}>
                    <input onChange={event => {
                        toggleTick(event.target.checked)
                        accountDelegate.togglePhoneBindToMessenger(event.target.checked)
                    }} disabled={!checked} checked={tick} type="checkbox" id={"my-phone"}/>
                    <label htmlFor="my-phone">
                        <div className={"checkbox"}/>
                        <div className={"label"}>
                            {t('support.messenger.number')} {accountDelegate.state.phone} {t('support.messenger.attached')}
                        </div>
                    </label></div>

                {selected.map(x => data.messengers.find(n => n.id === x)).filter(x => x !== null).map((item, index) => (
                    <div key={index}>
                        <label className={!checked ? "disabled" : ""} htmlFor={item.name}>{item.name}</label>
                        <div className={"messenger"}>
                            <input disabled={!checked} type="tel" id={item.name}
                                   name={"phone"}
                                   placeholder={t('account.phone')}
                                   onChange={event => {
                                       accountDelegate.changeMessengerNumber(item.id, event.target.value)
                                   }}
                                   onBlur={event => accountDelegate.saveMessengerNumber(item.id, event.target.value)}
                                   value={ item.phone ? item.phone : tick ? accountDelegate.state.phone : "" }/>
                            <button onClick={() => {
                                setSelected([...selected.filter(x => x !== item.id)])
                                accountDelegate.saveMessengerNumber(item.id, null, false)
                            }}>
                                <img src="./Images/close.svg" alt={'close'}/>
                            </button>
                        </div>
                    </div>
                ))}


                <div className={!checked ? "messenger-add disabled" : "messenger-add"}>
                    {data.messengers.filter(x => !selected.includes(x.id)).map((item, index) => (
                        <button disabled={!checked} key={index} onClick={() => {
                            setSelected([...selected, item.id])
                            accountDelegate.saveMessengerNumber(item.id, null, true)
                        }} className={"item"}>{item.name}
                            <img src="/Images/pluse-grey.svg" alt={"add"}/>
                        </button>
                    ))}

                </div>


              
            </div>
        </div>
    )
}
export default SupportBoxMessenger

