import React, {useEffect, useState} from 'react'
import styled from './style.module.scss'
import doneIcon from "../../img/done.png"
import progressIcon from "../../img/process.svg"
import lockIcon from "../../img/lock-icon.svg"
import arrowIcon from '../../img/arrow.png'
import Theme from "../Theme"
import {LevelType} from '../../../../../types/description'
import useWindowSize from '../../../../../hooks/useWindowSize'
import {useTranslation} from "react-i18next";

type PropsType = {
    levelId: number | undefined;
    courseId: number;
}

const Level = ({
                   levelId,
                   title,
                   is_open,
                   progress,
                   status = 2,
                   stages,
                   courseId
               }: LevelType & PropsType) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const [widthPage, setWidthPage] = useState(0);
    const {width} = useWindowSize();
    const { t } = useTranslation();

    useEffect(() => {
        if (width > 1070) {
            setShowDescription(true)
        }
    }, [widthPage, width]);

    const handleShowDescription = () => {
        setShowDescription(prev => !prev)
    }

    return <div className={styled.wrapper}>
        {
            status === 3
                ? <img className={styled.icon} src={doneIcon} alt="Icon" />
                : status === 2
                    ? <img className={styled.icon} src={progressIcon} alt="Progress" />
                    : <img className={styled.icon} src={lockIcon} alt="Lock" />
        }

        <div className={styled.container}>
            <div className={styled.titleDesktop}>
                <div className={styled.cell}>
                    <h3>{title}</h3>
                </div>
                <div className={styled.cell}>
                    <div className={styled.wrapperProgress}>
                        <div className={styled.lineProgress}>
                            {progress === 0 ? <span>{t('task')}</span> : <span>{t('task.single.passed')} {progress}%</span>}
                            <div className={styled.currentProgress} style={{
                                width: progress + '%',
                                background: progress === 100
                                    ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                    : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                            }} />
                        </div>
                    </div>
                    <div className={styled.iconOpen}>
                        <img onClick={handleShowDescription}
                             className={!showDescription ? styled.arrow : styled.arrowActive} src={arrowIcon} alt="=>"
                        />
                    </div>
                </div>
            </div>

            {/*верстка на 800px*/}
            <div className={`${styled.titleDesktop} ${styled.titleTablet}`}>
                <div className={styled.title}>
                    <h3>{title}</h3>
                    <div className={styled.right}>
                        <span className={styled.progress} style={{
                            color: progress === 100 ? '#62D321' : '#F16529'
                        }}>{progress}%</span>
                        <div className={styled.iconOpen}>
                            <img onClick={handleShowDescription}
                                 className={!showDescription ? styled.arrow : styled.arrowActive} src={arrowIcon}
                                 alt="=>" />
                        </div>
                    </div>
                </div>
            </div>

            {/*верстка на mobile*/}
            <div className={`${styled.titleDesktop} ${styled.titleMobile}`}>
                <div className={styled.title}>
                    <h3>{title}</h3>
                    <div className={styled.iconOpen}>
                        <img onClick={handleShowDescription}
                             className={!showDescription ? styled.arrow : styled.arrowActive} src={arrowIcon}
                             alt="=>" />
                    </div>
                </div>
                <span className={styled.progress} style={{
                    color: progress === 100 ? '#62D321' : '#F16529'
                }}>{t('task.single.passed')} {progress}%</span>
            </div>

            {showDescription && <div className={styled.themes}>
                {stages.map(({id, title, status, tasks, videos, comments, progress, themes: innerThemes}, index) => <div
                    className={styled.themeItem} key={id}><Theme
                    index={index}
                    themeId={id}
                    title={title}
                    status={status}
                    answers={tasks}
                    videos={videos}
                    comments={comments}
                    progress={progress}
                    levelId={levelId}
                    themes={innerThemes}
                    courseId={courseId}
                    is_open={is_open}
                    opened={index > 0 && stages[index - 1].themes.findIndex(x => !x.status) === -1}
                /></div>)}
            </div>}
        </div>
    </div>
}

export default Level;
