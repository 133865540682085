import React, {useEffect} from "react"
import styled from './ThemeBlog.module.scss'
import {useDispatch, useSelector} from "react-redux"
import {getBlogThunk, getPostForBlogThunk, switchBackPost, switchNextPost} from '../../../redux/blogReducer'
import Loader from "../../Components/Loader"
import AskedQuestions from "../../Components/AskedQuestions"
import YandexShare from "react-yandex-share"
import config from "../../../etc/config"
import viewIcon from './img/view-icon.png'
import {Link} from 'react-router-dom'
import {questions} from '../Billing/components/FAQ/questions';
import {useTranslation} from "react-i18next";

const ThemeBlog = (props) => {
    const dispatch = useDispatch()
    const data = props.match.params
    const blogData = useSelector(state => state.blogReducer)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getPostForBlogThunk(data.category, data.post))
        dispatch(getBlogThunk())
    }, [])

    // Переключить на следующую статью
    const handlerNextPost = () => {
        window.scrollTo(0, 72)
        dispatch(switchNextPost(blogData.currentPost.id))
    }

    // Переключить на предыдущую статью
    const handlerBackPost = () => {
        window.scrollTo(0, 72)
        dispatch(switchBackPost(blogData.currentPost.id))
    }

    return blogData.currentPost !== null ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <Link to={`/blog`}>
                {t('blog.single.back')}
            </Link>
            <h1>{blogData.currentPost.title}</h1>
            <div className={styled.categoryAndDate}>
                <span className={styled.category}>{blogData.currentPost.category.name}</span>
                <span className={styled.date}>{blogData.currentPost.date}</span>
            </div>
            <div className={styled.content}>
                    <div
                        dangerouslySetInnerHTML={{__html: blogData.currentPost.text}}
                        className={styled.description}/>
                {blogData.currentPost.promo !== null && <a className={styled.promoLink}
                                                           href={blogData.currentPost.promo.link}>{blogData.currentPost.promo.text}</a>}
            </div>
            <footer>
                <div className={styled.left}>
                    <p>
                        {t('blog.single.share')}
                    </p>
                    <YandexShare
                        content={{
                            title: `Блог Educat.`,
                            url: `${config.MAIN_URL}/blog/posts/${blogData.currentPost.category.slug}/${blogData.currentPost.slug}`
                        }}
                        theme={{lang: 'ru', services: 'vkontakte,facebook,twitter,telegram'}}
                    />
                </div>
                <div className={styled.right}>
                    <img src={viewIcon} alt="(0)"/>
                    <span>{blogData.currentPost.views}</span>
                </div>
            </footer>
            <div className={styled.switcherPost}>
                <div className={styled.containerSwitcherPostDesktop}>
                    <div className={styled.btnSwitcher} onClick={handlerBackPost}>
                        <button>&lt;</button>
                        <span>
                            {t('blog.single.previous')}
                        </span>
                    </div>
                    <div className={styled.btnSwitcher} onClick={handlerNextPost}>
                        <span>
                            {t('blog.single.next')}
                        </span>
                        <button>&gt;</button>
                    </div>
                </div>
                <div className={`${styled.containerSwitcherPostDesktop} ${styled.containerSwitcherPostMobile}`}>
                    <div className={styled.btnSwitcher}>
                        <button onClick={handlerBackPost}>&lt;</button>
                        <span>
                            {t('blog.single.fast')}
                        </span>
                        <button onClick={handlerNextPost}>&gt;</button>
                    </div>
                </div>
            </div>
        </div>
        <AskedQuestions questions={questions} />
    </div> : <Loader/>
}

export default ThemeBlog
