import React from "react"
import config from "../../../../etc/config"
import Loader from "../../../Components/Loader"
import ErrorPage from "../../ErrorPage"
import moment from "moment"


const withVacancy = (Component) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props)

            this.state = {
                data: null,
                errorPage: null,
                successResponded: false,
                successMessageSend: false,
                loading: false,
                popup: null,
                message: ""
            }
        }

        componentDidMount() {
            this.getData()
            window.scrollTo({top: 0, left: 0})
            document.title = "Вакансия"
        }

        changeMessage = e => {
            this.setState({message: e.target.value, successMessageSend: false})
        }

        setMessage = () => {
            if (!this.state.message)
                return

            this.setState({loading: true})
            fetch(`${config.API_PREFIX}jobs/${this.props.match.params.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({ text: this.state.message })
            })
                .then(res => {
                    this.setState({loading: false})
                    if (res.status === 401)
                        this.props.appDelegate.logout()

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status})
                        throw new Error(res.statusText)
                    }

                    return res.json()
                })
                .then(res => {
                    if (res.status === 0) {
                        const data = JSON.parse(JSON.stringify(this.state.data))
                        data.requests[0].text = this.state.message
                        this.setState({successMessageSend: true, data, message: ""})
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        setPopup = (popup) => {
            this.setState({popup, message: "", successMessageSend: false}, () => {
                document.body.style.overflowY = popup ? "hidden" : "auto"
            })
        }

        responseVacancy = () => {
            this.setState({loading: true})
            fetch(`${config.API_PREFIX}jobs/${this.props.match.params.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    this.setState({loading: false})
                    if (res.status === 401)
                        this.props.appDelegate.logout()

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status})
                        throw new Error(res.statusText)
                    }

                    return res.json()
                })
                .then(res => {
                    if (res.status === 0) {
                        const data = JSON.parse(JSON.stringify(this.state.data))
                        data.requests.push(res.data || {id: 0, date: moment().format("YYYY-MM-dd hh:mm")})
                        this.setState({data, successResponded: true})
                    } else {
                        alert("Server error!")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }


        getData = () => {
            fetch(`${config.API_PREFIX}jobs/${this.props.match.params.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                }
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout()

                    if (res.status === 403 || res.status === 404 || res.status === 500) {
                        this.setState({errorPage: res.status})
                        throw new Error(res.statusText)
                    }

                    return res.json()
                })
                .then(res => {
                    this.setState({data: res.job || null})
                })
                .catch(err => {
                    console.log(err)
                })
        }


        render() {
            if (!this.state.data && !this.state.errorPage)
                return <Loader/>
            else if (this.state.errorPage) {
                return <ErrorPage code={this.state.errorPage}/>
            } else
                return <Component vacancyDelegate={this} {...this.props} />
        }
    }

    HOC.displayName = `withVacancy(${Component.displayName || Component.name || "Component"})`
    return HOC
}


export default withVacancy