import React from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes"
import { AuthSubmitHandlerFactory } from "View/Pages/RegistrationAlternative/types"
import AuthModeToggler from "View/Pages/RegistrationAlternative/components/AuthModeToggler"
import AuthViaSocialMedia from "View/Pages/RegistrationAlternative/components/AuthViaSocialMedia"
import { useDocumentTitle } from "View/Pages/RegistrationAlternative/hooks/useDocumentTitle"
import { useSigninApi } from "./hooks/useSigninApi"
import { SigninFormValues, SigninProps, UseSigninApiResult } from "./types"
import { useSigninInitialValues } from "./hooks/useSigninInitialValues"
import AuthForm from "../../components/AuthForm"
import { isFieldInValid } from "../../utils/isFieldInValid"
import { AxiosError } from "axios"
import { isAxiosError } from "../../utils/isAxiosError"
import { useTranslation } from 'react-i18next';
const SigninValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Введите правильный Email')
		.required('Обязательно для заполнения'),
	password: Yup.string()
		.required('Обязательно для заполнения')
});

const SigninSubmitHandlerFactory: AuthSubmitHandlerFactory<SigninFormValues, UseSigninApiResult> = (
	appDelegate, 
	signinApiCallback,
	options = {}
) => async (
	values, 
	helpers
) => {
	try {
		const response = await signinApiCallback(values);

		if(response.data.status === 0) {
			appDelegate.authCompletion(response.data);
			if(options.onSuccess) {
				options.onSuccess(values);
			}
		}
		if(response.data.status === 1) {
			helpers.setFieldError("email", response.data.message);
			throw new Error(response.data.message);
		}

		throw new Error("Server error");

	} catch (error) {
		if(error instanceof Error) {
			if(isAxiosError(error)) {
				if(error.response.status === 401) {
					helpers.setFieldError("email", "Неверный email и/или пароль");
				} else {
					helpers.setFieldError("email", error.message);
				}
			} else {
				helpers.setFieldError("email", error.message);
			}
		}
		
		console.error('Error when attempting to signin', values, error);
	}
};


function Signin({ appDelegate }: SigninProps): JSX.Element {
	const history = useHistory();
	const signinInitialValues = useSigninInitialValues();
	const signinApiCallback = useSigninApi();
	const onSuccess = React.useCallback(() => {
		history.push("/");
	}, [history]);
  const formik = useFormik<SigninFormValues>({
    initialValues: signinInitialValues,
		validationSchema: SigninValidationSchema,
    onSubmit: SigninSubmitHandlerFactory(
			appDelegate, 
			signinApiCallback,
			{ onSuccess }
		)
  });

	const EMAIL_IS_INVALID = isFieldInValid(formik, "email");
	const PASSWORD_IS_INVALID = isFieldInValid(formik, "password");
	const FORM_IS_INVALID = EMAIL_IS_INVALID || PASSWORD_IS_INVALID;
	const { t } = useTranslation();
	useDocumentTitle(t('sign.in'));

  return (
		<AuthForm
			isInValid={FORM_IS_INVALID}
			onSubmit={formik.handleSubmit}
		>	
			<AuthModeToggler/>
			
			<AuthViaSocialMedia/>

			<label
				className={
					EMAIL_IS_INVALID ? "error" : ""
				}
				htmlFor="email"
			>
				{ EMAIL_IS_INVALID ? formik.errors.email : "Email" }
			</label>
			<input 
				name="email"
				className={
					EMAIL_IS_INVALID ? "error" : ""
				}
				value={formik.values.email}
				onChange={formik.handleChange}
				type="email" 
				id={"email"} 
				placeholder={t('auth.email')}
			/>

			<div className={"forgot"}>
				<label 
					className={
						PASSWORD_IS_INVALID ? "error" : ""
					}
					htmlFor="password"
				>
					{ PASSWORD_IS_INVALID ? formik.errors.password : t('auth.pass') }
				</label>
				<Link 
					to={
						`${RegistrationRoutes.DROP_PASSWORD}${
							formik.values.email !== '' 
								? '\?email='.concat(formik.values.email)
								: ''
						}`
					}
				>
					{t('auth.forgot')}
				</Link>
			</div>

			<input 
				name="password"
				value={formik.values.password}
				onChange={formik.handleChange}
				type="password" 
				id={"password"} 
				placeholder={t('auth.pass2')}
				className={
					PASSWORD_IS_INVALID ? "error" : ""
				}
			/>

			<button
				type={"submit"}
			>
				{t('sign.in.btn')}
			</button>
		</AuthForm>
  );
}

export default Signin;