class CourseHelper {
    static getThemesInfo = (stages) => {
        let all = 0;
        let done = 0;
        for (let i = 0; i < stages.length; i++) {
            all += stages[i].themes.length;
            done += stages[i].themes.filter(x => x.done === true).length
        }
        return { desktop: `Изучено ${done} из ${all} тем`, mobile: `${done} из ${all}` }
    };

    static getPercent = (stages) => {
        let all = 0;
        let done = 0;
        for (let i = 0; i < stages.length; i++) {
            all += stages[i].themes.length;
            done += stages[i].themes.filter(x => x.done === true).length
        }

        return done * 100 / all;
    }
}

export default CourseHelper;