import React from 'react';
import s from './PaymentMethods.module.scss'

const PaymentMethods = (props) => {

    return (
        <ul className={s.paymentMethods}>
            {
                props.methods.map((method, i) => <li key={`Method ${i}`}>
                    <div>
                        {method.name}
                    </div>
                    <div className={s.buttons}>
                        {method.methods.map((m, i) => <button key={`${m.method} ${i}`} className={s[m.class]}></button>)}
                    </div>
                </li>)
            }
        </ul>
    );
};

export default PaymentMethods;
