import React, {useState, useEffect, useRef} from "react";

const Evaluation = ({evaluation, index}) => {

    const [height, setHeight] = useState(0);
    const [isOpen, setOpen] = useState( index === 0);

    const container = useRef();
    useEffect(() => {
        setHeight(container.current.scrollHeight);
    }, [evaluation]);

    return (
        <div className={"evaluation"}>
            <div onClick={() => setOpen(!isOpen)} className={"job-box"}>
                <div className={"job"}>
                    <img src={"/Images/paper.svg"} alt={"😼"}/>
                    <a href={evaluation.file}
                       className={"name-job"}>{evaluation.file.split("/")[evaluation.file.split("/").length - 1]}</a>
                </div>
                <div className={"date"}>Отправлено: {evaluation.date}</div>
            </div>
            <div ref={container} style={{ maxHeight: isOpen ? `${height}px` : "0px" }} className={"scroll-box"}>
                <div className={"scheme"}>
                    <div className={"scheme__picture"}>
                        <div className={"left"}>
                            <img src={"/Images/group.svg"} alt={"😼"}/>
                        </div>
                        <div className={"line"}/>
                        <div className={"right"}>
                            {evaluation.state === "pending" ? <div className={"circle"}/> :
                                <img src={"/Images/group.svg"} alt={"😼"}/>}
                        </div>
                    </div>
                    <div className={"scheme__text"}>
                        <p className={"sent text"}>Отправлено</p>
                        <p className={evaluation.state === "pending" ? "wait text" : "sent text"}>{evaluation.state === "pending" ? "Ожидание проверки" : "Проверено"}</p>
                    </div>
                </div>
                <div className={"comment-cell"}>
                    <div className={"from-curator"}>Комментарии от куратора</div>
                    <div
                        className={evaluation.comment ? "comment html-data" : "comment empty"} dangerouslySetInnerHTML={{__html: evaluation.comment ? evaluation.comment : "Комментриев пока нет"}} />
                </div>
            </div>
        </div>
    )
}
export default Evaluation