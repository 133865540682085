import React from "react";



const ErrorPage = ({ code }) => (
    <div className={"error-page"}>
        <img src={`/Images/${code}.svg`} alt={""}/>
        <h3>{code === 404 ? "Страница не найдена" : code === 500 ? "Что-то пошло не так((" : code === 403 ? "У вас не хватает прав для этого контента!" : ""}</h3>
        <a href={"/"}>Перенесите меня на главную страницу</a>
    </div>
);


export default ErrorPage;