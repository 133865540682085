import React from 'react'
import styled from './style.module.scss'
import FaqQuestions from '../../Pages/FAQ/components/FaqQuestions'
import {QuestionsType} from '../../../types/questions'
import {useTranslation} from "react-i18next";

const AskedQuestions = ({questions}: QuestionsType) => {
    const { t } = useTranslation();
    return <div className={styled.wrapper}>
        <div className={styled.container}>
            <h2>
                {t('faq.block.title')}
            </h2>
            {questions.map(({title, descriptionHTML}, index) => <FaqQuestions key={index}
                                                                              title={ t(title) }
                                                                              description={ t(descriptionHTML) }
                                                                              classQuestion={"main-question"}
            />)}
        </div>
        <a href="/faq">
            {t('faq.block.link')}
        </a>
    </div>
}

export default AskedQuestions
