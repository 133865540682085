import React from 'react'
import Stage from "./Stage"
import styled from './ProgramLearn.module.scss'
import {TheoryType} from '../../../../../types/course';

type Props = {
    theories: TheoryType[];
}

const ProgramLearn = ({theories}: Props) => {
    return <div className={styled.wrapperProgramLearn}>
        <h3>Программа обучения</h3>
        <div className={styled.content}>
            {theories?.map(({title, pre_requirements, stages, id}, index) => <Stage
                key={id}
                title={title}
                index={index}
                requirements={pre_requirements}
                stages={stages}
            />)}
        </div>
    </div>
}

export default ProgramLearn
