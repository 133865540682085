import React, {useEffect} from 'react';
import {getTags} from "../../../redux/gameReducer";
import {useDispatch} from "react-redux";
import MainPage from "./MainPage/MainPage";

const Pexeso = (props) => {
    let dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTags(props.appDelegate.state.authToken))
    })

    return (<main className="practice-home content-main">
        <div className='all-courses'>
            <MainPage token={props.appDelegate.state.authToken} />
        </div>
    </main>)
}

export default Pexeso;