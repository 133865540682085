import { FormikErrors, FormikTouched } from "formik";

export const isFieldInValid = <TValues>(
  formik: {
    errors: FormikErrors<TValues>,
    touched: FormikTouched<TValues>
  },
  field: keyof TValues
):boolean => {
  return Boolean(formik.touched[field] && formik.errors[field]);
}