import React from "react"
import Course from "../Course"
import TrustBox from "../../../../Components/TrustBox";

const MyCourses = ({homeDelegate, appDelegate}) => (
    <div className={"all-courses-box"}>
        <section className={"all-courses"}>
            <h1>Мои курсы</h1>
            <div className={"top"}>
                <p>Здесь вы найдете свои программы , а также все курсы и ресурсы, которые вам могут быть интересны.</p>
            </div>
            {/*<div className={"special-offer"}>*/}
            {/*    <div className={"special-offer__left"}>*/}
            {/*        <p>Специальное предложение – Скидка 50% на пакет Educat Pro</p>*/}
            {/*        <p>Переключитесь на годовой план всего за 84 доллара в год. Меньше счетов, больше Marvel.</p>*/}
            {/*    </div>*/}
            {/*    <Link to={"/billing"} className={"button-sell"}>Получить скидку</Link>*/}
            {/*</div>*/}

            <div className={"profession"}>
                {homeDelegate.state.data.addedCourses.filter(x => homeDelegate.state.data.courses.findIndex(z => z.id === x) > -1).map(x => homeDelegate.state.data.courses.find(z => z.id === x)).map((item, index) => (
                    <Course subscribed={appDelegate.state.user.subscriptions !== null} added={true}
                            categories={homeDelegate.state.data.categories} item={item} key={index}/>
                ))}
            </div>

        </section>
        <TrustBox/>
    </div>


)
export default MyCourses