import React from "react"
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup"
import { AuthSubmitHandlerFactory } from "View/Pages/RegistrationAlternative/types";
import { RegistrationRoutes } from "View/Pages/RegistrationAlternative/assets/RegistrationRoutes";
import { useDocumentTitle } from "View/Pages/RegistrationAlternative/hooks/useDocumentTitle";
import { useResetPasswordInitialValues } from "./hooks/useResetPasswordInitialValue";
import { useResetPasswordApi } from "./hooks/useResetPasswordApi";
import { ResetPasswordFormValues, ResetPasswordProps, UseResetPasswordApiResult } from "./types";
import { isFieldInValid } from "../../utils/isFieldInValid";
import AuthForm from "../../components/AuthForm";
import { useResetPasswordSearchParams } from "./hooks/useResetPasswordSearchParams";
import {useTranslation} from "react-i18next";

const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, ({min}) => `Пароль должен содержать минимум ${min} символов`)
    .required('Обязательно для заполнения'),
  repeat: Yup.string()
    .min(6, ({min}) => `Пароль должен содержать минимум ${min} символов`)
    .when('password', {
      is: (value: string | undefined) => value && value.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        "Пароли должны совпадать"
      )
    })
});

const ResetPasswordSubmitHandlerFactory: AuthSubmitHandlerFactory<ResetPasswordFormValues, UseResetPasswordApiResult> = (
	_appDelegate, 
	resetPasswordApiCallback,
	options = {}
) => async (
	values, 
	helpers
) => {
  try {
    const response = await resetPasswordApiCallback(values);
    if(response.data.status === 0) {
      if(options.onSuccess) {
        options.onSuccess(values);
      }
    }
    if(response.data.status === 1) {
      helpers.setFieldError("password", response.data.message);
      throw new Error(response.data.message);
    }

    throw new Error("Server error");
  } catch (error) {
    console.error("Error while trying reset password by email", error);    
  }
};

function ResetPassword({appDelegate}: ResetPasswordProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const resetPasswordSearchParams = useResetPasswordSearchParams();
  const resetPasswordApiCallback = useResetPasswordApi(resetPasswordSearchParams);
  const resetPasswordInitialValues = useResetPasswordInitialValues();
  const onSuccess = React.useCallback(() => {
    history.push({
      pathname: RegistrationRoutes.SIGN_IN,
      search: `${
        resetPasswordSearchParams.email !== ''
          ? '?email='.concat(resetPasswordSearchParams.email)  : ""
      }`
    });
  }, [history]);
  const formik = useFormik<ResetPasswordFormValues>({
    initialValues: resetPasswordInitialValues,
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: ResetPasswordSubmitHandlerFactory(
      appDelegate, 
      resetPasswordApiCallback,
      { onSuccess }
    )
  });

  const PASSWORD_IS_INVALID = isFieldInValid(formik, "password");
  const REPEAT_IS_INVALID = isFieldInValid(formik, "repeat");
  const FORM_IS_INVALID = PASSWORD_IS_INVALID || REPEAT_IS_INVALID;

  useDocumentTitle(t('auth.change'));
  
  return (
    <AuthForm
      isInValid={FORM_IS_INVALID}
      onSubmit={formik.handleSubmit}
    >
      <h4 className={"or"}>
        {t('auth.change.title')}
      </h4>

      <label 
        className={PASSWORD_IS_INVALID ? "error" : ""}
        htmlFor="password"
      >
        { PASSWORD_IS_INVALID ? formik.errors.password: t('auth.pass') }
      </label>
      <input
        className={PASSWORD_IS_INVALID ? "error" : ""} 
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        type="password" 
        id={"password"} 
        placeholder={t('auth.pass')}
      />

      <label 
        className={REPEAT_IS_INVALID ? "error" : ""}
        htmlFor="repeat"
      >
        { REPEAT_IS_INVALID ? formik.errors.repeat: t('auth.pass.repeat') }
      </label>
      <input
        className={REPEAT_IS_INVALID ? "error" : ""}
        name="repeat"
        value={formik.values.repeat}
        onChange={formik.handleChange}
        type="password" 
        id={"repeat"} 
        placeholder={t('auth.pass.repeat')}
      />
    
      <button
        type={"submit"}
      >
        {t('btn.send')}
      </button>
        
      <span 
        className={"back"}
        onClick={() => history.goBack()}
      >
        {t('auth.back')}
      </span>
    </AuthForm>
  );
}

export default ResetPassword;