import React, {Fragment} from "react";
import withRegPopup from "./withRegistrationPopup";


const RegistrationPopup = ({regDelegate, appDelegate}) => {
    return (
        <div className={"registration-popup"}>
            <div style={{width: "100%", height: "100%", position: "absolute", left: 0, top: 0}}
                 onClick={appDelegate.toggleRegistration}/>
            <form onSubmit={regDelegate.submit} className={"registration-popup__container"}>
                <img onClick={appDelegate.toggleRegistration} src="/Images/close.svg" alt="" className={"close"}/>
                <h4>{regDelegate.state.formState === "registration" ? "Регистрация" : regDelegate.state.formState === "authorization" ? "Авторизация" : "Восстановление пароля"}</h4>
                {regDelegate.state.formState === "registration" ? (
                    <Fragment>
                        <p>Заполняйте контактные данные правильно, чтобы мы смогли отправить вам доступ к
                            авторизации.</p>
                        <label>Имя*</label>
                        <input className={regDelegate.state.error.indexOf("name") > -1 ? "input error" : "input"}
                               value={regDelegate.state.name} name={"name"} onChange={regDelegate.handler}
                               type={"text"}/>
                        {regDelegate.state.error.indexOf("name") > -1 ? <span className={"err"}>Укажите имя</span> : ""}
                    </Fragment>
                ) : ""}
                <label>Email*</label>
                <input className={regDelegate.state.error.indexOf("email") > -1 ? "input error" : "input"}
                       value={regDelegate.state.email} name={"email"} onChange={regDelegate.handler}
                       type={"email"}/>
                {regDelegate.state.error.indexOf("email") > -1 ? <span className={"err"}>Невалидный email</span> : ""}

                {regDelegate.state.formState === "registration" ? (
                    <Fragment>
                        <label>Телефон (не обязательно)</label>
                        <input className={regDelegate.state.error.indexOf("phone") > -1 ? "input error" : "input"}
                               value={regDelegate.state.phone} name={"phone"} onChange={regDelegate.handler}
                               type={"tel"}/>
                    </Fragment>
                ) : regDelegate.state.formState === "authorization" ? (
                    <Fragment>
                        <label>Пароль</label>
                        <input className={regDelegate.state.error.indexOf("password") > -1 ? "input error" : "input"}
                               value={regDelegate.state.password} name={"password"} onChange={regDelegate.handler}
                               type={"password"}/>
                        {regDelegate.state.error.indexOf("password") > -1 ?
                            <span className={"err"}>Введите пароль</span> : ""}
                    </Fragment>
                ) : ""}

                {regDelegate.state.buttonState === "normal" ? (
                    <Fragment>
                        <button
                            type={"submit"}>{regDelegate.state.formState === "registration" ? "Зарегистрироваться" : regDelegate.state.formState === "authorization" ? "Войти" : "Восстановить пароль"}</button>
                        {regDelegate.state.formState === "registration" ? (
                            <p onClick={() => regDelegate.toggleState("authorization")} className={"exist"}>У меня есть
                                аккаунт</p>
                        ) : (
                            <div className={"exist-box"}>
                                <p onClick={() => regDelegate.toggleState("registration")}
                                   className={"exist"}>Зарегистрироваться</p>
                                {regDelegate.state.formState === "authorization" ? (
                                    <p onClick={() => regDelegate.toggleState("reload")}
                                       className={"exist"}>Восстановить пароль</p>
                                ) : (
                                    <p onClick={() => regDelegate.toggleState("authorization")}
                                       className={"exist"}>Войти в кабинет</p>
                                )}

                            </div>
                        )}
                    </Fragment>

                ) : regDelegate.state.buttonState === "pending" ? (
                    <div className={"pending"}>
                        <img src="/Images/load-img.svg" alt=""/>
                    </div>
                ) : (
                    <div onClick={appDelegate.toggleRegistration} className={"ok"}>
                        <img src="/Images/ok-img.svg" alt=""/>
                        <span>Проверьте ваш Email</span>
                    </div>
                )}
            </form>
        </div>
    )
};


export default withRegPopup(RegistrationPopup);