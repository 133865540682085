import {coursesAPI} from "../api/api";

const SET_COURSES = 'SET_COURSES';
const RETURN_ALL_COURSES = 'RETURN_ALL_COURSES';
const SORT_CATEGORY_COURSES = 'SORT_CATEGORY';
const SORT_COMPLEXITY_COURSES = 'SORT_COMPLEXITY';

let initialState = {
    initialCourses: null,
    courses: null,
    categories: null,
    complexities: null,
}

const allCoursesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COURSES: {
            return {
                ...state,
                courses: action.courses.map(course => ({
                    ...course,
                    updates: false
                })),
                categories: action.categories,
                complexities: action.complexities,
                initialCourses: action.courses
            }
        }
        case SORT_CATEGORY_COURSES: {
            return {
                ...state,
                courses: state.initialCourses.filter(({category}) => category === action.idCategory),
            }
        }
        case SORT_COMPLEXITY_COURSES: {
            return {
                ...state,
                courses: state.initialCourses.filter(({complexity}) => complexity === action.idComplexity)
            }
        }
        case RETURN_ALL_COURSES: {
            return {
                ...state,
                courses: state.initialCourses
            }
        }
        default:
            return state;
    }
}

const setCourses = ({courses, categories, complexities}) => {
    return {
        type: SET_COURSES,
        courses, categories, complexities
    }
};

export const returnAllCourses = () => {
    return {
        type: RETURN_ALL_COURSES
    }
}

export const sortCategoryCoursesAction = (idCategory) => {
    return {
        type: SORT_CATEGORY_COURSES,
        idCategory
    }
}

export const sortComplexityCoursesAction = (idComplexity) => {
    return {
        type: SORT_COMPLEXITY_COURSES,
        idComplexity
    }
}

export const getCoursesThunk = (authToken) => {
    return async (dispatch) => {
        let response = await coursesAPI.getAllCourses(authToken)
        try {
            dispatch(setCourses(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export default allCoursesReducer;