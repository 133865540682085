import React from 'react';
import { authApi } from 'api/api';
import { 
	ResetPasswordFormValues, 
	ResetPasswordSearchParams, 
	UseResetPasswordApiResult 
} from '../types';

export const useResetPasswordApi = (
	{email, token}: ResetPasswordSearchParams
): UseResetPasswordApiResult => {
  const authSocialMediaApi = React.useCallback( async ({password}: ResetPasswordFormValues) => {
		try {
			if(email !== '' && token !== '') {
				const response = await authApi.resetPassword({email, token, password});
      	return response;
			}

			throw new Error('Email and token must exist in moment of calling expression');
		} catch (error) {
			console.error('Error while atempting to reset password by email', error);
		}
	}, [email, token]);

	return authSocialMediaApi;
}