import React from "react"
import styled from './CertificatesMainPage.module.scss'
import {useHistory} from "react-router-dom"

const CertificatesMainPage = () => {
    const history = useHistory()

    return <div className={styled.wrapper}>
        <div className={styled.container}>
            <div className={styled.left}>
                <h2>Оригинальный электронный сертификат «EDUCAT»</h2>
                <span>После прохождения серии задач и практик вы получаете Сертификат от нашей компании, которым вы можете не только поделиться с вашими друзьями, коллегами, заказчиками, но и предоставить потенциальному работодателю.</span>
                <button onClick={() => history.push('/all-courses')}>Выбрать курс</button>
            </div>
            <div className={styled.right} />
        </div>
    </div>
}

export default CertificatesMainPage