import React from 'react';
import { useHistory } from 'react-router';
import s from './Card.module.scss';
import {declOfNum} from "../../../../helpers/declOfNum";
import {useTranslation} from "react-i18next";

const Notification = {
    ON: `ON`,
    OFF: `OFF`,
    NONE: null
};

const Card = ({ course, isShowUpdates}) => {
    const path = course.enabled ? `#` : `/description/course/${course.id}`;
    const history = useHistory();
    const redirectToCourse = () => history.push(path);
    const { t } = useTranslation();
    return <div className={`
        ${s.wrapper} 
        ${course.in_process ? s.book : ''}
        ${course.done ? s.done : ''}
        ${course.notification === Notification.OFF ? s.greyBell : ''}
        ${course.notification === Notification.ON ? s.redBell : ''}
        ${course.enabled ? s.blocked : ''}
        `}>

        <div className={s.iconAndHeader}>
            <div className={s.icon}
                style={{ backgroundImage: `url(${course.image})`}}>
            </div>
            <p className={s.description}>
                <span className={s.title}>{course.name}</span>
                {course.description}
            </p>
        </div>

        <div className={s.iconAndInfoMobile}>
            <div className={s.icon}
                style={{ backgroundImage: `url(${course.image})` }}>
            </div>
            {course.inDevelopment
                ? <div className={s.inDevelopmentInfoMobile}>
                    {t('course.inprogress')}
                </div>
                : <ul className={s.infoMobile}>
                    <li>
                        {course.duration} {t('duration.month')}
                    </li>
                    <li>
                        {course.taskCount} {declOfNum(course.taskCount, [t('practices.task1'), t('practices.task2'), t('practices.task3')])}
                    </li>
                    <li>
                        {course.videosCount} {t('course.video')}
                    </li>
                </ul>
            }
        </div>

        <div className={s.headerMobile}>
        <p className={s.description}>
                <span className={s.title}>{course.name}</span>
                {course.description}
            </p>
        </div>
        
        <div className={s.infoLinkAndButton}>
            {course.inDevelopment
                ? <div className={s.inDevelopmentInfo}>
                    {t('course.inprogress')}
                </div>
                : <ul className={s.info}>
                    <li>
                        {course.duration} {t('duration.month')}
                    </li>
                    <li>
                        {course.taskCount} {declOfNum(course.taskCount, [t('practices.task1'), t('practices.task2'), t('practices.task3')])}
                    </li>
                    <li>
                        {course.videosCount} {t('course.video')}
                    </li>
                </ul>}
            <div className={s.linkAndButton}>
                <a href={course.inDevelopment || course.enabled ? null : `about-course/${course.id}`}>
                    {t('course.desc')}
                </a>
                <button 
                    onClick={redirectToCourse}
                    disabled={course.inDevelopment || course.enabled}
                >
                        {course.in_process
                            ? t('course.continue')
                            : course.done
                                ? t('course.reset')
                                : t('course.start')}
                </button>
            </div>
        </div>
    </div>
}

export default Card;