import React, {Fragment, useCallback, useMemo, useState} from 'react'
import styled from './styled.module.scss'
import bgIcon1 from '../../Pages/MainPage/img/program-learn-main-1.png'
import bgIcon2 from '../../Pages/MainPage/img/program-learn-main-2.png'
import bgIcon3 from '../../Pages/MainPage/img/program-learn-main-3.png'
import bgIcon4 from '../../Pages/MainPage/img/program-learn-main-4.png'
import bgIcon5 from '../../Pages/MainPage/img/program-learn-main-5.png'
import {useTranslation} from "react-i18next";

const StepsLearnProgramming = () => {
    const { t } = useTranslation();
    const [stepIndex, setStepIndex] = useState(0)

    const stepArray = [
        {
            background: bgIcon1,
            title: t('stepslearnprogramming.step1.title'),
            text: t('stepslearnprogramming.step1.text'),
            id: 1
        },
        {
            background: bgIcon2,
            title: t('stepslearnprogramming.step2.title'),
            text: t('stepslearnprogramming.step2.text'),
            id: 2
        },
        {
            background: bgIcon3,
            title: t('stepslearnprogramming.step3.title'),
            text: t('stepslearnprogramming.step3.text'),
            id: 3
        },
        {
            background: bgIcon4,
            title: t('stepslearnprogramming.step4.title'),
            text: t('stepslearnprogramming.step4.text'),
            id: 4
        },
        {
            background: bgIcon5,
            title: t('stepslearnprogramming.step5.title'),
            text: t('stepslearnprogramming.step5.text'),
            id: 5
        }
    ]

    const infoArray = [
        {title: t('stepslearnprogramming.info1.title'), count: "2020", text: t('stepslearnprogramming.info1.text'), id: 1},
        {title: t('stepslearnprogramming.info2.title'), count: "1000", text: t('stepslearnprogramming.info2.text'), id: 2},
        {title: t('stepslearnprogramming.info3.title'), count: "1000", text: t('stepslearnprogramming.info3.text'), id: 3},
        {title: t('stepslearnprogramming.info4.title'), count: "10000", text: t('stepslearnprogramming.info4.text'), id: 4}
    ]

    const stepImage = useMemo(() => {
        return stepArray[stepIndex]
    }, [stepIndex])

    const changeStep = useCallback((index) => {
        setStepIndex(index)
    }, [setStepIndex])

    return <div className={styled.wrapper}>
        <div className={styled.title}>
            <h4>{ t('stepslearnprogramming.block.title') }</h4>
            <span>{ t('stepslearnprogramming.block.text') }</span>
        </div>
        <div className={styled.content}>
            <div className={styled.left} style={{
                backgroundImage: `url(${stepImage.background})`
            }}/>
            <div className={styled.right}>
                <div className={styled.containerRightBlock}>
                    {stepArray.map(({title, text, id}, index) => <Fragment key={id}>
                        <div className={stepIndex !== index ? styled.cell : `${styled.cell} ${styled.active}`}
                             key={index} onMouseOver={() => changeStep(index)}>
                            <span className={styled.stepItem}>{index + 1}</span>
                            <div className={styled.titleItem}>
                                <h4>{title}</h4>
                                <span>{text}</span>
                            </div>
                        </div>
                        {stepIndex === index && <div className={styled.mobileLeft} style={{
                            backgroundImage: `url(${stepImage.background})`
                        }}/>}
                    </Fragment>)}
                </div>
            </div>
        </div>
        <div className={styled.infoBlock}>
            {infoArray.map(({title, count, text, id}) => <div className={styled.cellInfo} key={id}>
                <span className={styled.titleInfo}>{title}</span>
                <div className={styled.descriptionInfo}>
                    <h4>{count}</h4>
                    <span>{text}</span>
                </div>
            </div>)}
        </div>
    </div>
}

export default StepsLearnProgramming;
