import React from 'react'
import styled from './ComponetLoader.module.scss'

const ComponentLoader = () => {
    return <div className={styled.loader}>
        <div className={styled.image}>
            <img className={styled.cat} src={"/Images/Animation/img.svg"} alt={"😼"}/>
        </div>
    </div>
}

export default ComponentLoader