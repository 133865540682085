import React from 'react';
import styled from './Videos.module.scss';
import Video from "../Tasks/components/Video/Video";
import YandexShare from "react-yandex-share";
import config from "../../../etc/config";
import {useTranslation} from "react-i18next";

const ItemVideo = ({title, link}) => {
    const { t } = useTranslation();
    return <div className={styled.videoItem}>
        <div className={styled.imgVideo}>
            <Video link={link}/>
        </div>
        <h4>{title}</h4>
        <div className={styled.socialNetwork}>
            <p>
                {t('videos.share')}
            </p>
            <YandexShare
                content={{
                    title: t('videos.certificate'),
                    url: `${config.MAIN_URL}/webinars`
                }}
                theme={{lang: 'ru', services: 'vkontakte,facebook,twitter,telegram'}}
            />
        </div>
    </div>
}

export default ItemVideo;