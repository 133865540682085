import React, {useEffect} from 'react'


const ContactUs = () => {
  useEffect(() => {
    document.title = 'Контакты'
    window.scrollTo({top: 0, left: 0})
  })
  return (
    <div className={'content-main contact-us'}>
      <div className={'contact-us__container'}>
        <h1>Transfer Money Technologies s.r.o.</h1>
        <h2>Адрес:<br/>
          Na Strži 1702/65 (City Empiria), <br/>
          Praha 4, Nusle, 140 00 Praha 4, Česká republika, Praha.</h2>
        {/*<p>Контактный номер телефона: <br/><a href="tel:+420 733 419 478">+420 733 419 478</a></p>*/}
        {/*<p>Служба поддержки Skype:*/}
        {/*    <div className={"row"}>*/}
        {/*        <img src="./Images/skype.svg" alt={"😼"}/>*/}
        {/*        <a href="skype:roby151">lenakurd1993</a>*/}
        {/*    </div></p>*/}
        <p>Если у вас возникли технические трудности (что-то не работает или работает не так, как надо) напишите
          в службу поддержки. Мы работаем 24/7.
          <div className={'row'}>
            <img src="./Images/mail.svg" alt={'😼'}/>
            <a href="mailto:info@educat.courses">info@educat.courses</a>
          </div></p>
        {/*<p>Предложения о сотрудничестве рассматривает основатель проекта Educat Александр Мазуркевич. Все*/}
        {/*    вопросы и предложения отправляйте на почту<br/>*/}
        {/*    <div className={"row"}>*/}
        {/*        <img src="./Images/mail.svg" alt={"😼"}/>*/}
        {/*        <a href="mailto:mazur@tmtsoft.cz ">mazur@tmtsoft.cz </a></div>*/}
        {/*</p>*/}
      </div>
    </div>
  )
}

export default ContactUs