import React, {useState} from 'react'
import styled from './style.module.scss'
import classnames from 'classnames'
import {CategoryTest} from '../../../../../types/tests';
import {useTranslation} from "react-i18next";

type SortTasksType = {
    categories: CategoryTest[];
    handleCategory: (category: number) => void;
}

const SortTasks: React.FC<SortTasksType> = ({categories, handleCategory}) => {
    const [indexCategory, setIndexCategory] = useState<number>(0)
    const { t } = useTranslation();
    const changeActiveCategory = (index: number) => () => {
        setIndexCategory(index)
        handleCategory(index)
    }

    return <div className={styled.sort}>
        <h4>
            {t('task.theme')}
        </h4>
        <div className={styled.categories}>
            {categories.map(({id, name}) => <span onClick={changeActiveCategory(id)} className={classnames({
                [styled.active]: id === indexCategory
            })} key={id}>{name}</span>)}
        </div>
    </div>
}

export default SortTasks;
