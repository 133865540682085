import React from "react"
import io from "socket.io-client"
import config from "../etc/config"

const withSocket = Component => {
    class HOC extends React.Component {
        constructor(props) {
            super(props)
            this.state = {}

            this.audioContext = null
            this.socket = null
            this.audio = null


            // Events
            this.messageEvent = null
            this.userPrintEvent = null
            this.commentEvent = null
            this.successEvent = null
        }


        componentDidMount() {
            this.messageEvent = document.createEvent("Event")
            this.messageEvent.initEvent("Message", true, true)

            this.userPrintEvent = document.createEvent("Event")
            this.userPrintEvent.initEvent("userPrint", true, true)


            this.commentEvent = document.createEvent("Event")
            this.commentEvent.initEvent("commentEvent", true, true)


            this.successEvent = document.createEvent("Event")
            this.successEvent.initEvent("successEvent", true, true)

            const AudioContext = window.AudioContext || window.webkitAudioContext
            this.audioContext = new AudioContext()
            this.audio = new Audio("/Sounds/message.mp3")

            if (this.props.appDelegate.state.authToken) {
                this.connect(this.props.appDelegate.state.authToken)
            }


            document.addEventListener("printMessage", this.handlerPrint)

            // this.setupNotification()
        }

        componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.appDelegate.state.authToken && !this.socket) {
                this.connect(nextProps.appDelegate.state.authToken)
            } else {

            }
        }

        componentWillUnmount() {
            document.removeEventListener("printMessage", this.handlerPrint)
        }

        connect = (token) => {
            this.socket = io(config.SOCKET_URL, {
                extraHeaders: {
                    Authorization: `Bearer ${token}`
                }
            })

            this.socket.on('connect', () => {
                this.socket.emit("token", token)
            })

            this.socket.on('message', (data) => {
                this.handlerForNewMessage(data)
            })

            this.socket.on('comment', (data) => {
                if (this.audioContext) {
                    this.audioContext.resume().then(() => {
                        this.audio.play()
                    })
                }
                this.commentEvent.data = data
                document.dispatchEvent(this.commentEvent)
            })

            this.socket.on('success', (data) => {
                if (this.audioContext) {
                    this.audioContext.resume().then(() => {
                        this.audio.play()
                    })
                }
                this.successEvent.data = data
                document.dispatchEvent(this.successEvent)
            })

            this.socket.on('print', (data) => {
                this.userPrintEvent.data = data
                document.dispatchEvent(this.userPrintEvent)
            })
        }

        handlerPrint = (e) => {
            if (this.socket)
                this.socket.emit("print", {user: this.props.appDelegate.state.user, group_id: e.data.group_id})
        }


        handlerForNewMessage = (data) => {
            this.messageEvent.data = data
            document.dispatchEvent(this.messageEvent)
        }


        render() {
            return (
                <Component
                    socketDelegate={this}
                    {...this.props}
                />
            )
        }
    }

    HOC.displayName = `withSocket(${Component.displayName || Component.name || "Component"})`

    return HOC
}

export default withSocket
