import axios from "axios";
import config from "../etc/config";

axios.defaults.headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
}

const API_PREFIX_AXIOS = axios.create({
    baseURL: config.API_PREFIX
});

const API_GAME_AXIOS = axios.create({
    baseURL: config.API_GAME
});


export {
    API_PREFIX_AXIOS,
    API_GAME_AXIOS
};