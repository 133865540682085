import {discussionAPI} from "../api/api";

const ADD_COMMENT = 'ADD_COMMENT';
const SET_COMMENTS = 'SET_COMMENTS';
const DISABLE_BUTTON = 'DISABLE_BUTTON';
const ADD_REVIEW_ON_COMMENT = 'ADD_REVIEW_ON_COMMENT';
const CHANGED_IS_LIKE = "CHANGED_IS_LIKE";

let initialState = {
    comments: [],
    disabledButton: false
}

const discussionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMMENTS: {
            return {
                ...state,
                comments: action.comments
            }
        }
        case ADD_COMMENT: {
            let comment = {
                avatar: action.avatar,
                comment: action.comment,
                createdAt: action.createdAt,
                fullName: action.fullName,
                id: action.id,
                isLiked: action.isLiked,
                likes: action.likes,
                replies: action.replies
            }
            return {
                ...state,
                comments: [...state.comments, comment],
                disabledButton: false
            }
        }
        case DISABLE_BUTTON: {
            return {
                ...state,
                disabledButton: action.boolean
            }
        }
        case CHANGED_IS_LIKE: {
            return {
                ...state,
                comments: state.comments.map((item) => {
                    if (item.id === action.commentId) {
                        return {
                            ...item,
                            isLiked: action.isLiked,
                            likes: action.likes
                        }
                    }
                    return {...item}
                })
            }
        }
        case ADD_REVIEW_ON_COMMENT: {
            let comment = {
                avatar: action.avatar,
                comment: action.comment,
                createdAt: action.createdAt,
                fullName: action.fullName,
                id: action.id,
                isLiked: action.isLiked,
                likes: action.likes
            }
            return {
                ...state,
                comments: state.comments.map((item) => {
                    if (item.id === action.commentId) {
                        return {
                            ...item,
                            replies: [...item.replies, comment]
                        }

                    }
                    return {...item}
                })
            }
        }
        default:
            return state;
    }
}

const setComments = (comments) => {
    return {
        type: SET_COMMENTS,
        comments
    }
}

const addComment = ({avatar, comment, createdAt, fullName, id, isLiked, likes, replies}) => {
    return {
        type: ADD_COMMENT,
        avatar, comment, createdAt, fullName, id, isLiked, likes, replies
    }
}

const disableButton = (boolean) => {
    return {
        type: DISABLE_BUTTON,
        boolean
    }
}

const changedIsLike = ({isLiked, likes}, commentId) => {
    return {
        type: CHANGED_IS_LIKE,
        isLiked, likes, commentId
    }
}

const addReviewForComment = ({avatar, comment, createdAt, fullName, id, isLiked, likes}, commentId) => {
    return {
        type: ADD_REVIEW_ON_COMMENT,
        avatar, comment, createdAt, fullName, id, isLiked, likes, commentId
    }
}

export const getCommentsThunk = (authToken, questionId) => {
    return async (dispatch) => {
        let response = await discussionAPI.getCommentsForTests(authToken, questionId);
        try {
            dispatch(setComments(response.data.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const sendCommentForTaskThunk = (idTask, authToken, comment) => {
    return async (dispatch) => {
        dispatch(disableButton(true))
        let response = await discussionAPI.sendCommentForTaskThunk(idTask, authToken, comment)
        try {
            dispatch(addComment(response.data.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const replyUserOnCommentThunk = (authToken, commentId, comment) => {
    return async (dispatch) => {
        let response = await discussionAPI.replyUserOnComment(authToken, commentId, comment);
        try {
            dispatch(addReviewForComment(response.data.data, commentId))
        } catch (error) {
            console.log(error)
        }
    }
}

export const changeStatusLikeThunk = (authToken, commentId) => {
    return async (dispatch) => {
        let response = await discussionAPI.changeLikeStatus(authToken, commentId);
        try {
            dispatch(changedIsLike(response.data, commentId))
        } catch(error) {
            console.log(error)
        }
    }
}



export default discussionReducer;