import React, {useEffect, useState} from 'react';
import QuestionsAndTarif from '../../Components/QuestionsAndTarif/QuestionsAndTarif';
import Card from './components/Card/Card';
import s from './Tasks.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import SortTasks from './components/SortTasks/SortTasks';
import {Pagination} from '../../Components/Pagination';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import {AppDelegateType} from '../../../types/app-delegate';
import {RootState} from '../../../redux/reducers';
import {getAllTestsThunk, getPaginationPageTests, sortCategoryTests} from '../../../redux/actions/tests';
import Loader from '../../Components/Loader';
import {useTranslation} from "react-i18next";

type Props = {
    appDelegate: AppDelegateType;
}

const Tasks = ({appDelegate}: Props) => {
    const dispatch = useDispatch();
    const {
        tests,
        categories,
        totalTests,
        perPage,
        currentPage,
        isLoading
    } = useSelector((state: RootState) => state.tasksReducer);
    const authToken = appDelegate?.state?.authToken;
    const [category, setCategory] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllTestsThunk(authToken));
    }, []);

    const handlePagePagination = (page: number) => {
        dispatch(getPaginationPageTests(authToken, page, category));
    };

    const sortTests = (category: number) => {
        dispatch(sortCategoryTests(authToken, category));
        setCategory(category);
    };
    return tests.length ?
        <div className={s.wrapper}>
            <ScrollToTop/>
            <div className={s.container}>
                <div className={s.header} id="tasks">
                    <h1>
                        {t('task.page.title')}
                    </h1>
                    <span>
                        {t('task.page.desc')}
                    </span>
                </div>
                <SortTasks categories={categories} handleCategory={sortTests}/>
                <ul className={s.list}>
                    {
                        tests?.map(({
                                                                     id,
                                                                     img,
                                                                     name,
                                                                     countTasks,
                                                                     isComplete,
                                                                     description
                                                                 }) => <li key={`task${id}`}>
                            <Card id={id}
                                  image={img}
                                  name={name}
                                  countTasks={countTasks}
                                  isComplete={isComplete}
                                  description={description}
                                  authToken={authToken}
                            />
                        </li>)
                    }
                </ul>
            </div>
            <Pagination
                total={totalTests}
                perPage={perPage}
                currentPage={currentPage}
                handlePage={handlePagePagination}
                category={category}
            />
            <QuestionsAndTarif/>
        </div>
        : <Loader />
};

export default Tasks;
