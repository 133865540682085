import React, {useMemo} from 'react'
import styled from './ChangeSomeAnswers.module.scss'
import s from '../../Task/styled.module.scss'
import {useSelector} from "react-redux"

const ChangeSomeAnswers = ({
                               rightAnswer,
                               someAnswers,
                               changeAnswers,
                               isCorrectTask,
                               isViewAnswer,
                               passedRightAnswer
                           }) => {

    const variants = useSelector(state => state.tasksReducer.currentTask.variants)
    let answerTask = isViewAnswer ? passedRightAnswer : rightAnswer

    const objectVariants = useMemo(() => {
        if (variants) {
            return variants.map(variant => ({
                title: variant,
                isActive: someAnswers.some(({value}) => value === variant && value.length === variant.length),
                isCorrect: answerTask && answerTask.includes(variant)
            }))
        }
        return []
    }, [variants, rightAnswer, someAnswers])

    return (<div className={s.containerTask}>
        <div className={styled.checkboxWrapper}>
            {objectVariants.map(({title, isCorrect, isActive}, index) =>
                (<div className={styled.wrapperInput} key={title}><label className={isViewAnswer
                    ? isCorrect
                        ? styled.checkboxInput + ' ' + styled.isRight
                        : styled.checkboxInput
                    : isCorrect === undefined
                        ? styled.checkboxInput
                        : isCorrectTask
                            ? isActive
                                ? styled.checkboxInput + ' ' + styled.isRight
                                : styled.checkboxInput
                            : isActive
                                ? styled.checkboxInput + ' ' + styled.isFalse
                                : styled.checkboxInput}><span className={styled.variant}>{title}</span>
                    {!isViewAnswer
                        ? isCorrectTask === 0 ?
                            <input type="checkbox" id={index} onChange={() => changeAnswers(title, index)}/>
                            : isCorrect === true
                                ?
                                <input type="checkbox" id={index} onChange={() => changeAnswers(title, index)} disabled
                                       checked={isCorrect && isActive}/>
                                :
                                <input type="checkbox" id={index} onChange={() => changeAnswers(title, index)} disabled
                                       checked={!isCorrect && isActive}/>
                        : <input type="checkbox" id={index} onChange={() => changeAnswers(title, index)} disabled
                                 checked={isCorrect}/>
                    }
                    <span className={styled.checkmark}/>
                </label></div>)
            )}
        </div>
    </div>);
}

export default ChangeSomeAnswers;
