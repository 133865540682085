import React, {useEffect, useMemo, useState} from 'react'
import s from './style.module.scss'
import usePagination from '../../../hooks/usePagination'
import classnames from 'classnames'
import arrowIcon from './image/arrow-pagination.png'
import {Link} from 'react-scroll'

type PropsType = {
    total: number
    perPage: number
    currentPage: number
    handlePage: (page: number) => void
    category: number
}

export const Pagination: React.FC<PropsType> = ({total, perPage, currentPage, handlePage, category}) => {
    const {countPagination, showPagination} = usePagination(total, perPage)
    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (total === 0) {
            setActivePage(0)
        }
    }, [total])

    useEffect(() => {
        setActivePage(1)
    }, [category])

    const handlePaginationPage = (page: number) => {
        setActivePage(page)
        handlePage(page)
    }

    const maxButtons = 7;

    const range = (from: number, to: number) => {
        const range = []

        from = from > 0 ? from : 1

        for (let i = from; i <= to; i++) {
            range.push(i)
        }

        return range
    }

    const items = useMemo(() => {
        const length = countPagination.length

        const maxLength = Math.min(
            Math.max(0, maxButtons) || length,
            length
        )

        if (length <= maxLength) {
            return range(1, length)
        }

        const even = maxLength % 2 === 0 ? 1 : 0
        const left = Math.floor(maxLength / 2)
        const right = length - left + 1 + even

        if (activePage > left && activePage < right) {
            const start = activePage - left + 2
            const end = activePage + left - 2 - even

            return [1, '...', ...range(start, end), '...', length]
        } else if (activePage === left) {
            const end = activePage + left - 1 - even
            return [...range(1, end), '...', length]
        } else if (activePage === right) {
            const start = activePage - left + 1
            return [1, '...', ...range(start, length)]
        } else {
            return [
                ...range(1, left),
                '...',
                ...range(right, length),
            ]
        }
    }, [activePage, countPagination])

    return showPagination ? <div className={s.pagination}>
        <Link to="tasks" spy={true} smooth={true} duration={1000} className={s.switcher}>
            <button className={s.switch} disabled={activePage === 1} onClick={() => handlePaginationPage(activePage - 1)}>
                <img src={arrowIcon} alt={'<'}/>
            </button>
        </Link>
            {items.map((item, index) => <Link to="tasks" spy={true} smooth={true} duration={1000} key={item}>
                <span key={index} className={classnames({
                    [s.active]: item === activePage
                })}
                      onClick={() => Number.isInteger(item) ? handlePaginationPage(item as number) : null}>{item}</span>
            </Link>)}
            <Link to="tasks" spy={true} smooth={true} duration={1000}>
                <button className={s.switch} disabled={activePage === countPagination.length}
                        onClick={() => handlePaginationPage(activePage + 1)}>
                    <img src={arrowIcon} alt={'>'}/>
                </button>
            </Link>
        </div>
        : null
}
