import {localesAPI} from "../api/api";

const SET_LOCALES = 'SET_LOCALES';

let initialState = {
    locales: []
}

const localesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCALES: {
            return {
                ...state,
                locales: action.locales,
            }
        }
        default:
            return state;
    }
}

const setLocales = ({locales}) => {
    return {
        type: SET_LOCALES,
        locales
    }
}

export const getLocales = (authToken) => {
    return async (dispatch) => {
        let response = await localesAPI.getLocales(authToken)
        try {
            dispatch(setLocales(response.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export default localesReducer;