import React, {Fragment} from "react";
import Select from "../../Components/Select";
import withAccount from "./HOCs/init";
import ImageLoader from "../../Components/ImageLoader";
import Notifications from "./Components/Notifications";
import Support from "./Components/Support";
import CancelBilling from "./Components/CancelBilling/CancelBilling";
import {useTranslation} from "react-i18next";


const Account = ({accountDelegate, appDelegate}) => {
    const { t } = useTranslation();
    let data = new Date();
    let hour = data.getHours();
    return (
        <div className={"content-main account-page"}>
            <div className={"account-page__content"}>
                <h2>{hour >= 5 && hour < 12 ? t('account.greetings1') : hour >= 12 && hour < 18 ? t('account.greetings2') : hour >= 18 && hour < 24 ? t('account.greetings3') : hour >= 0 && hour < 5 ? t('account.greetings4') : ""},
                    <span>{accountDelegate.state.name}</span></h2>
                <div className="menu-account">
                    <div onClick={() => accountDelegate.setContentType("info")}
                         className={`menu ${accountDelegate.state.contentType === "info" ? "active" : ""}`}>
                        {t('account.nav.info')}
                    </div>
                    <div onClick={() => accountDelegate.setContentType("notification")}
                         className={`menu ${accountDelegate.state.contentType === "notification" ? "active" : ""}`}>
                        {t('account.nav.notifications')}
                    </div>
                    <div onClick={() => accountDelegate.setContentType("support")}
                         className={`menu ${accountDelegate.state.contentType === "support" ? "active" : ""}`}>
                        {t('account.nav.support')}
                    </div>
                    {appDelegate.state.user.subscriptions.length !== 0 && <div onClick={() => accountDelegate.setContentType("billing")}
                         className={`menu ${accountDelegate.state.contentType === "billing" ? "active" : ""}`}>
                        {t('account.nav.subscription')}
                    </div>}
                </div>


                {accountDelegate.state.contentType === "info" ? (
                    <Fragment>
                        <form>
                            {!accountDelegate.state.anonymous ? (
                                <ImageLoader token={appDelegate.state.authToken} className={"avatar"}
                                             value={accountDelegate.state.photo} resource={"user/photo"}
                                             completion={(result) => accountDelegate.handlerChange({
                                                 target: {
                                                     name: "photo",
                                                     value: result.url
                                                 }
                                             })}/>
                            ) : ""}
                            <label htmlFor="name">
                                {t('account.name')}
                            </label>
                            <input name="name"
                                   value={accountDelegate.state.name}
                                   onChange={accountDelegate.handlerChange}
                                   type="text" id={"name"} placeholder={t('account.name')}/>
                            {!accountDelegate.state.anonymous ? (
                                <Fragment>
                                    <label htmlFor="last_name">
                                        {t('account.surname')}
                                    </label>
                                    <input name="last_name"
                                           value={accountDelegate.state.last_name}
                                           onChange={accountDelegate.handlerChange}
                                           type="text" id={"last_name"} placeholder={t('account.surname')}/>
                                    <label>
                                        {t('account.sex1')}
                                    </label>
                                    <Select
                                        showField={"name"}
                                        name={"gender"}
                                        handler={(name, value) => accountDelegate.handlerChange({
                                            target: {
                                                name,
                                                value
                                            }
                                        })}
                                        value={accountDelegate.state.gender}
                                        label={t('account.sex2')}
                                        list={[{id: "male", name: t('male')}, {id: "female", name: t('female')}]}
                                    />
                                    <label htmlFor="year">
                                        {t('account.birthdate')}
                                    </label>
                                    <input name="date_of_birth"
                                           value={accountDelegate.state.date_of_birth}
                                           onChange={accountDelegate.handlerChange}
                                           type="date" id={"year"} placeholder={t('account.birthdate')}/>
                                </Fragment>
                            ) : ""}


                            <label htmlFor="email">Email</label>
                            <input type="email"
                                   id="email"
                                   name="email"
                                   value={accountDelegate.state.email}
                                   onChange={accountDelegate.handlerChange}
                                   placeholder={"Email"}
                            />

                            <label htmlFor="phone">
                                {t('account.phone')}
                            </label>
                            <input type="tel"
                                   id="phone"
                                   name="phone"
                                   value={accountDelegate.state.phone}
                                   onChange={accountDelegate.handlerChange}
                                   placeholder={t('account.phone')}
                            />


                            {!accountDelegate.state.anonymous ? (
                                <Fragment>
                                    <div className={"password-change"}>
                                        <label className={"password"}>
                                            {t('account.pass.change')}
                                        </label>
                                        <input onChange={accountDelegate.handlerChange}
                                               name={"old_password"}
                                               value={accountDelegate.state.old_password}
                                               type="password" id={"old_password"}
                                               placeholder={t('account.pass.old')}/>
                                        <input onChange={accountDelegate.handlerChange}
                                               name={"new_password"}
                                               value={accountDelegate.state.new_password}
                                               type="password" id={"new_password"}
                                               placeholder={t('account.pass.new')}/>
                                        <input onChange={accountDelegate.handlerChange}
                                               name={"repeat_password"}
                                               value={accountDelegate.state.repeat_password}
                                               type="password" id={"repeat_password"} placeholder={t('account.pass.repeat')}/>
                                    </div>
                                    <div onClick={accountDelegate.savePassword} className={"button"}>
                                        {t('account.pass.change')}
                                    </div>
                                </Fragment>
                            ) : ""}


                        </form>

                        {!accountDelegate.state.anonymous ? (
                            <Fragment>
                                <div className={"information"}>
                                    <h3>
                                        {t('account.additional')}
                                    </h3>
                                    <label htmlFor="education">
                                        {t('account.education')}
                                    </label>
                                    <Select
                                        showField={"name"}
                                        name={"education_level"}
                                        handler={(name, value) => accountDelegate.handlerChange({
                                            target: {
                                                name,
                                                value
                                            }
                                        })}
                                        value={accountDelegate.state.education_level}
                                        label={t('account.education.choose')}
                                        list={accountDelegate.state.educationTypes}
                                    />
                                </div>
                                <div className={"social"}>
                                    <h3>
                                        {t('account.socials')}
                                    </h3>
                                    <label htmlFor="twitter">Twitter</label>
                                    <input name="twitter"
                                           value={accountDelegate.state.twitter}
                                           onChange={accountDelegate.handlerChange}
                                           type="text" id={"twitter"} placeholder={" https://"}/>
                                    <label htmlFor="facebook">Facebook</label>
                                    <input name="facebook"
                                           value={accountDelegate.state.facebook}
                                           onChange={accountDelegate.handlerChange}
                                           type="text" id={"facebook"} placeholder={" https://"}/>
                                    <label htmlFor="linkedin">Linkedin</label>
                                    <input name="linkedIn"
                                           value={accountDelegate.state.linkedIn}
                                           onChange={accountDelegate.handlerChange}
                                           type="text" id={"linkedIn"} placeholder={" https://"}/>
                                </div>
                            </Fragment>
                        ) : ""}

                        <div onClick={accountDelegate.save}
                             className={"button-main"}>{accountDelegate.state.anonymous ? t('sign.up') : t('account.save')}</div>

                        {accountDelegate.state.anonymous ? (
                            <div style={{cursor: "pointer", fontSize: "14px", marginTop: "8px"}}
                                 onClick={appDelegate.logout} className={"text"}>
                                {t('account.registered')}
                            </div>
                        ) : ""}
                    </Fragment>
                ) : accountDelegate.state.contentType === "notification" && accountDelegate.state.notification ? (
                    <Notifications accountDelegate={accountDelegate} appDelegate={appDelegate}/>
                ) : accountDelegate.state.contentType === "billing" ? (
                    <CancelBilling appDelegate={appDelegate} />
                ) : (
                    <Support appDelegate={appDelegate} accountDelegate={accountDelegate}/>
                )
                }

            </div>
        </div>

    );
}


export default withAccount(Account);