import React from "react";
import config from "../../../../etc/config";

const withTask = (Component) => {
    class HOC extends React.Component {
        // eslint-disable-next-line no-useless-constructor
        constructor(props) {
            super(props);
            this.state = {
                hint: false,
                value: this.props.test.correct_answer,
                taskState: this.props.test.done ? "done" : "empty",
                attempts: this.props.test.attempts
            }
        }

        handlerChange = e => {
            if (this.props.test.type === "many_to_many") {
                let {value} = this.state;
                if (value && value.indexOf(e.target.value) > -1) {
                    value.splice(value.indexOf(e.target.value), 1);
                } else {
                    if (!value)
                        value = [];
                    value.push(e.target.value);
                }
                this.setState({ value, taskState: value.length > 0 ? "pending" : "empty" });
            } else {
                this.setState({ value: e.target.value, taskState: e.target.value ? "pending" : "empty" });
            }

        };

        pushAnswer = () => {
            fetch(`${config.API_PREFIX}answers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.props.appDelegate.state.authToken}`
                },
                body: JSON.stringify({
                    id: this.props.test.id,
                    value: this.state.value
                })
            })
                .then(res => {
                    if (res.status === 401)
                        this.props.appDelegate.logout();

                    return res.json();
                })
                .then(res => {
                    if (res.status === 0) {
                        this.setState({ taskState: res.correct ? "done" : "error", attempts: !res.correct && this.state.attempts < this.props.test.score ? this.state.attempts + 1 : this.state.attempts }, () => {
                            this.props.themeDelegate.setDoneTest(this.props.test.id);
                        })
                    } else {
                        alert(res.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        };

        setHint = (hint) => {
            this.setState({ hint })
        };


        render() {
            return <Component taskDelegate={this} {...this.props} />


        }
    }

    HOC.displayName = `withTask(${Component.displayName || Component.name || "Component"})`;
    return HOC
};


export default withTask;