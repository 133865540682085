import React, {useEffect} from 'react'
import styled from './style.module.scss'
import {useDispatch, useSelector} from "react-redux"
import Loader from "../../Components/Loader"
import Levels from "./components/Levels"
import ThemeTask from "../Tasks/components/ThemeTask/ThemeTask"
import {RootState} from '../../../redux/reducers'
import {LocationType} from '../../../types/location'
import {AppDelegateType} from '../../../types/app-delegate'
import {
    getCourseForDescription,
    getPracticeForDescription,
    getTestForDescription
} from '../../../redux/actions/description'
import {useTranslation} from "react-i18next";

type PropsType = {
    match: LocationType;
    appDelegate: AppDelegateType;
}

const Description = ({match, appDelegate}: PropsType) => {
    const currentCourse = useSelector((state: RootState) => state.descriptionReducer)
    const dispatch = useDispatch()
    const id = Number(match.params.id)
    const { t } = useTranslation();

    useEffect(() => {
        if (window.location.href.includes("/course")) {
            dispatch(getCourseForDescription(appDelegate.state?.authToken, id))
        } else if (window.location.href.includes("/test")) {
            dispatch(getTestForDescription(appDelegate.state?.authToken, id))
        } else if (window.location.href.includes("/practices")) {
            dispatch(getPracticeForDescription(appDelegate.state?.authToken, id))
        }
    }, [])

    return !currentCourse.isLoading ? <div className="content-main">
        <div className={styled.wrapper}>
            <div className={styled.container}>
                <div className={styled.header}>
                    <div className={styled.left}>
                        <img src={currentCourse.icon} alt="Icon" />
                        <div className={styled.title}>
                            <h1>{currentCourse.title}</h1>
                            <span>
                                {t('task.single.subtitle')}
                            </span>
                        </div>
                    </div>
                    <div className={styled.right}>
                        {currentCourse.progress?.completed !== null &&
                            <span>{t('task.single.learned')} {currentCourse.progress?.completed} {t('task.single.from')} {currentCourse.progress?.total} {t('task.single.themes')}</span>}
                    </div>
                </div>
                <div className={styled.levels}>
                    {currentCourse.levels.map(({
                                                   id,
                                                   title,
                                                   comments,
                                                   progress,
                                                   status,
                                                   tasks,
                                                   videos,
                                                   stages
                                               }) =>
                        <Levels
                            key={id}
                            title={title}
                            stages={stages}
                            comments={comments}
                            progress={progress}
                            status={status}
                            tasks={tasks}
                            videos={videos}
                            levelId={id}
                            is_open={currentCourse.is_open}
                            courseId={currentCourse.id}
                        />)}
                </div>
            </div>
        </div>
        {/*Всплывающее окно на задачи, открывается когда кликаем только на задачи*/}
        <ThemeTask appDelegate={appDelegate} id={id} />
    </div> : <Loader />
}

export default Description;
