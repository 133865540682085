import React, { useEffect } from 'react'
import QuestionsAndTarif from '../../Components/QuestionsAndTarif/QuestionsAndTarif'
import Card from './Card/Card'
import styled from './Practices.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Components/Loader'
import { getPracticesThunk } from '../../../redux/practicesReducer'
import HeaderAndCategory from "../../Components/HeaderAndCategory/HeaderAndCategory";
import {useTranslation} from "react-i18next";

const Practices = ({ appDelegate }) => {
    const dispatch = useDispatch();
    const practicesData = useSelector((state) => state.practicesReducer)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getPracticesThunk(appDelegate.state.authToken));
    }, []);
    // console.log(practicesData.practices)
    return practicesData.practices !== null ? <div className={styled.wrapper}>
        <div className={styled.container}>
            <HeaderAndCategory
                categories={practicesData.categories}
                complexities={practicesData.complexities}
                title={t('practices.title')}
                text={t('practices.desc')}
                type="practices"
            />
            <ul className={styled.list}>
                {
                    practicesData.practices.map((practice) => <li key={`course${practice.id}`}>
                        <Card practic={practice} appDelegate={appDelegate} />
                    </li>)
                }
            </ul>
        </div>
        <QuestionsAndTarif />
    </div> : <Loader />
}

export default Practices;
