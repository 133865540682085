import React, {useCallback, useMemo, useState} from 'react'
import styled from './TablePassedTasks.module.scss'
import mobile from './TableHOC.module.scss'
import checkedTask from '../../../Tasks/img/done.png'
import inProgressTask from '../../../Tasks/img/play.png'
import {useHistory} from 'react-router'
import arrowImg from '../../img/arrow-more.png'
import {declOfNum} from "../../../../../helpers/declOfNum";
import {useTranslation} from "react-i18next";

const TablePassedTasks = ({tests}) => {
    const history = useHistory();
    const [countShow, setCountShow] = useState(4);
    const { t } = useTranslation();
    const changePathToTest = (id) => {
        let path = `/description/test/${id}`
        history.push(path)
    }

    const handlerCountShow = useCallback(() => {
        setCountShow((prev) => prev + 4)
    }, [])

    const arrayTests = useMemo(() => {
        return tests.slice(0, countShow)
    }, [countShow])

    return <div>
        <div className={styled.wrapper}>
            <div className={styled.header}>
                <div className={styled.oneColumn}>
                    {t('task.table.course')}
                </div>
                <div className={styled.twoColumn}>
                    {t('task.table.contents')}
                </div>
                <div className={styled.threeColumn}>
                    {t('task.table.passed')}
                </div>
                <div className={styled.fourColumn}>
                    {t('task.table.link')}
                </div>
            </div>
            <div className={styled.rows}>
                {arrayTests.map(({icon, id, title, levels, progress, questions}, index) => <div key={index}
                                                                                           className={styled.rowTable}>
                    <div className={styled.oneColumn + ' ' + styled.cell}>
                        <img src={icon} alt=""/>
                        <div className={styled.infoCourse}>
                            <h4>{title}</h4>
                        </div>
                    </div>
                    <div className={styled.twoColumn + ' ' + styled.cell}>
                        <div className={styled.contentTwoColumn}>
                            <span className={styled.answers}>{questions} {declOfNum(questions, [t('task.task1'), t('task.task2'), t('task.task3')])}</span>
                            <div className={styled.tasksStep}>
                                {levels.map(({title, status}, index) => <div className={styled.itemTheme} key={index}>
                                    <span className={styled.nameStep}>{title}</span>
                                    {status === 3 && <img src={checkedTask} alt="Done"/>}
                                    {status === 2 && <img src={inProgressTask} alt="InProgress"/>}
                                    {status === 1 && <div className={styled.hideIcon} />}
                                </div>)}
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className={styled.threeColumn + ' ' + styled.cell}>
                        <div className={styled.wrapperProgress}>
                            <div className={styled.currentProgress} style={{
                                width: progress + '%',
                                background: progress === 100
                                    ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                    : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                            }} />
                        </div>
                        <span
                            style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                            {t('task.progress')} {progress}%
                    </span>
                    </div>
                    <div className={styled.fourColumn + ' ' + styled.cell}>
                        <button onClick={() => changePathToTest(id)}>
                            {t('btn.pass')}
                        </button>
                    </div>
                </div>)}
                {/*<ShowMore />*/}
                {countShow < tests.length && <footer onClick={handlerCountShow}>
                    {t('btn.more')} <img src={arrowImg} alt="=>"/>
                </footer>}
            </div>
        </div>

        <div className={`${mobile.wrapper} ${mobile.mobileWrapper}`}>
            <div className={mobile.header}>
                <div className={mobile.columnOne}>
                    {t('task.table.course')}
                </div>
                <div className={mobile.columnTwo}>
                    {t('task.table.format')}
                </div>
            </div>
            <div className={mobile.rows}>
                {arrayTests.map(({icon, id, title, levels, progress, questions}, index) => <div
                    key={index} className={mobile.rowTable}>
                    <div className={mobile.columnOne}>
                        <div className={mobile.titleCourse}>
                            <img src={icon} alt="Icon"/>
                            <div className={mobile.nameCourse}>
                                <h3>{title}</h3>
                            </div>
                        </div>
                        <div className={mobile.progressCourse}>
                            <div className={mobile.tasksStep}>
                                {levels.map(({title, status}, index) => <div className={mobile.itemTheme} key={index}>
                                    <span className={mobile.nameStep}>{title}</span>
                                    {status === 3 && <img src={checkedTask} alt="Done"/>}
                                    {status === 2 && <img src={inProgressTask} alt="InProgress"/>}
                                    {status === 1 && <div className={mobile.hideIcon} />}
                                </div>)}
                                <hr />
                            </div>
                        </div>
                        <div className={mobile.progressCourse}>
                            <div className={mobile.wrapperProgress}>
                                <div className={mobile.currentProgress} style={{
                                    width: progress + '%',
                                    background: progress === 100
                                        ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                        : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                                }} />
                            </div>
                            <span style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                                {t('task.progress')} {progress}%
                            </span>
                        </div>
                    </div>
                    <div className={mobile.columnTwo}>
                        <div className={mobile.descriptionCourse}>
                            <span>{questions} {declOfNum(questions, [t('task.task1'), t('task.task2'), t('task.task3')])}</span>
                        </div>
                        <button onClick={() => changePathToTest(id)}>
                            {t('btn.pass')}
                        </button>
                    </div>
                </div>)}
            </div>

            <div className={`${mobile.rows} ${mobile.rowsMobile}`}>
                {arrayTests.map(({icon, id, title, levels, progress, questions}, index) => <div
                    key={index} className={mobile.rowTable}>
                    <div className={mobile.columnOne}>
                        <div className={mobile.titleCourse}>
                            <div className={mobile.nameCourse}>
                                <img src={icon} alt="Icon"/>
                                <h4>{title}</h4>
                            </div>
                        </div>
                        <div className={mobile.descriptionCourse}>
                            <span>{questions} {declOfNum(questions, [t('task.task1'), t('task.task2'), t('task.task3')])}</span>
                        </div>
                        <div className={mobile.tasksStep}>
                            {levels.map(({title, status}, index) => <div className={mobile.itemTheme} key={index}>
                                {status === 3 && <img src={checkedTask} alt="Done"/>}
                                {status === 2 && <img src={inProgressTask} alt="InProgress"/>}
                                {status === 1 && <div className={mobile.hideIcon} />}
                                <span className={mobile.nameStep}>{title}</span>
                            </div>)}
                        </div>
                        <div className={mobile.progressCourse}>
                            <div className={mobile.wrapperProgress}>
                                <div className={mobile.currentProgress} style={{
                                    width: progress + '%',
                                    background: progress === 100
                                        ? 'linear-gradient(180deg, #A2E27C 0%, #83FB3F 22.4%, #62D321 58.85%, #50AE1A 100%)'
                                        : 'linear-gradient(180deg, #EB703B 0%, #FE9263 22.4%, #F16529 58.85%, #DA4F13 100%)'
                                }} />
                            </div>
                            <span style={progress !== 100 ? {color: '#8D9099'} : {color: '#202124'}}>
                                {t('task.progress')} {progress}%
                            </span>
                        </div>
                        <button onClick={() => changePathToTest(id)}>
                            {t('btn.pass')}
                        </button>
                    </div>
                </div>)}
            </div>
            {/*<ShowMore />*/}
            {countShow < tests.length && <footer onClick={handlerCountShow}>
                {t('btn.more')}<img src={arrowImg} alt="=>"/>
            </footer>}
        </div>
    </div>
}

export default TablePassedTasks;
