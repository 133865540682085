import React from 'react'
import styled from './styled.module.scss'
import {useHistory} from "react-router-dom"
import VideoItem from './components/VideoItem';
import ReactYoutube from '../../../Tasks/components/YouTube/YouTube';
import {useTranslation} from "react-i18next";

const videosArray = [
    {
        title: 'Что такое HTML',
        link: 'https://vimeo.com/516237956'
    },
    {
        title: 'Для чего нужен тег title',
        link: 'https://vimeo.com/518740214'
    },
    {
        title: 'Знакомимся с JavaScript',
        link: 'https://vimeo.com/519389802'
    },
    {
        title: 'Функции в Javascript',
        link: 'https://vimeo.com/524166129'
    },
    {
        title: 'Каскадные таблицы стилей(CSS)',
        link: 'https://vimeo.com/515387319'
    },
    {
        title: 'Свойство color(CSS)',
        link: 'https://vimeo.com/519187255'
    }
]

const interview = [
    {author: 'Alexander Nabzdorov', link: 'https://www.youtube.com/watch?v=VveEdPsz4H8'},
    {author: 'Victor Kachin', link: 'https://www.youtube.com/watch?v=otU9ALN8AAo'}
]

const VideosMainPage = () => {
    const { t } = useTranslation();
    const history = useHistory()

    return <div className={styled.wrapper}>
        <div className={styled.interview}>
            <h2>{t('interview.title')}</h2>
            <div className={"desc"}>
                <p>
                    {t('interview.desc1')}
                </p>
                <br/>
                <p>
                    {t('interview.desc2')}
                </p>
                <br/>
                <p>
                    {t('interview.desc3')}
                </p>
                <br/>
                <p>
                    {t('interview.desc4')}
                </p>
                <br/>
                <p>
                    {t('interview.desc5')}
                </p>
                <br/>
                <p>
                    {t('interview.desc6')}
                </p>
                <br/>
            </div>
            <div className={styled.content}>
                {interview.map(({link}) => <div className={styled.youtube} key={link}>
                    <ReactYoutube link={link}/>
                </div>)}
            </div>
        </div>
        {/*<div className={styled.lessons}>*/}
        {/*    <h2>Изучайте программирование в любое время и в любом месте</h2>*/}
        {/*    <div className={styled.videos}>*/}
        {/*        {videosArray.map(({title, link}) => <VideoItem key={title} title={title} link={link}/>)}*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<button onClick={() => history.push('/webinars')}>Перейти к видео</button>*/}
    </div>
}

export default VideosMainPage;
