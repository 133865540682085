import React, {useEffect, useState} from 'react'

const SupportBoxEmail =({accountDelegate, data}) => {

    const[active, toggleActive] = useState(data.enabled)
    const [checked, toggleChecked] = useState(data.enabled)

    useEffect(()=> {
        // Надо проверить глобально активный или нет чекбокс
        toggleChecked(data.enabled)
        }, [])

    return  (
        <div onClick={() => toggleActive(!active)} className={active ?  "support-box active" : "support-box"}>
            <div className={"support-box__head"}>
                <div className="head-left">
                    <img src="./Images/arrow-black.svg"/>
                    <div className="name-support">
                        Email
                    </div>
                </div>
                <div  onClick={e => e.stopPropagation()} className="switch">
                    <input checked={checked} onChange={e => {
                        toggleActive(e.target.checked)
                        toggleChecked(e.target.checked)
                        accountDelegate.toggleSupportItems(data.id, e.target.checked)
                    }} type="checkbox" id={"support-email"}/>
                    <label htmlFor="support-email" className={"switch-label"}/>
                </div>
            </div>
            <div onClick={e => e.stopPropagation()} className="support-box__info">
                <label className={!checked ? "disabled" : ""} htmlFor="email">Email</label>
                <input disabled={true} type="email" id={"email"} value={accountDelegate.state.email}/>
            </div>
        </div>
    )
}
export default SupportBoxEmail